export const languageOptions = [
  {
    value: 'cs',
    label: 'Čeština',
  },
  {
    value: 'da',
    label: 'Danish',
  },
  {
    value: 'de',
    label: 'Deutsch',
  },
  {
    value: 'et',
    label: 'Eesti',
  },
  {
    value: 'en',
    label: 'English',
  },
  {
    value: 'es',
    label: 'Español',
  },
  {
    value: 'fr',
    label: 'Français',
  },
  {
    value: 'it',
    label: 'Italiano',
  },
  {
    value: 'lv',
    label: 'Latviešu',
  },
  {
    value: 'lt',
    label: 'Lietuviu',
  },
  {
    value: 'hu',
    label: 'Magyar',
  },
  {
    value: 'nl',
    label: 'Nederlands',
  },
  {
    value: 'nb',
    label: 'Norsk',
  },
  {
    value: 'pl',
    label: 'Polski',
  },
  {
    value: 'pt',
    label: 'Português',
  },
  {
    value: 'ro',
    label: 'Română',
  },
  {
    value: 'ru',
    label: 'Русский',
  },
  {
    value: 'sk',
    label: 'Slovenský',
  },
  {
    value: 'sl',
    label: 'Slovenščina',
  },
  {
    value: 'fi',
    label: 'Suomi',
  },
  {
    value: 'sv',
    label: 'Svenska',
  },
  {
    value: 'tr',
    label: 'Türkçe',
  },
];
