import { combineReducers } from 'redux';

import register from '../components/pages/Register/reducers';
import login from '../components/pages/Login/reducers';
import forgotPassword from '../components/pages/ForgotPassword/reducers';
import tileManager from '../components/tiles/TileManager/reducers';
import tileConfigurationPopup from '../components/tiles/TileConfigurationPopup/reducers';
import app from '../components/AppInitializer/reducers';
import language from '../components/layout/LanguageSelector/reducers';
import systemStatus from '../components/layout/SystemStatus/reducers';
import devices from '../components/pages/Devices/reducers';
import tileCharts from '../components/tiles/TileChart/reducers';
import alarms from '../components/pages/Alarms/reducers';
import history from '../components/pages/history/reducers';
import notifications from '../components/layout/TopMenu/reducers';
import addDevice from '../components/pages/AddDevice/reducers';
import productRegistration from '../components/pages/ProductRegistration/reducers';
import tilesData from '../components/tiles/TileData/reducers';
import textDb from '../text-db/reducers';
import profilePage from '../components/pages/ProfilePage/reducers';
import profileTab from '../components/pages/ProfilePage/components/ProfileTab/reducers';
import addressTab from '../components/pages/ProfilePage/components/AddressTab/reducers';
import systemProfileProfileTab from '../components/pages/SystemProfile/components/ProfileTab/reducers';
import systemProfileSecurityTab from '../components/pages/SystemProfile/components/SecurityTab/reducers';
import systemProfileAddressTab from '../components/pages/SystemProfile/components/AddressTab/reducers';
import servicePartner from '../components/pages/SystemProfile/components/ServicePartnerTab/reducers';
import aboutManufacturerTab from '../components/pages/SystemProfile/components/AboutManufacturerTab/reducers';
import systemProfileDeleteTab from '../components/pages/SystemProfile/components/DeleteTab/reducers';
import shop from '../components/Shop/reducers';
import tileSettings from '../components/tiles/TileSettings/reducers';
import firmwares from '../components/pages/UpdateDeviceFirmware/reducers';
import invitation from '../components/pages/Invitation/reducers';
import confirmEmailUpdate from '../components/pages/ConfirmEmailUpdate/reducers';
import deviceScheduling from '../components/pages/DeviceScheduling/reducer';

import deviceMenu from '../components/pages/DeviceSettings/device-menu/reducers';
import smartGuide from '../components/tiles/SmartGuide/reducers';
import redeemVoucher from '../components/pages/RedeemVoucher/reducers';
import features from '../components/FeaturesFlags/reducers';
import agreements from '../components/pages/TermsOfService/reducers';
import notificationSettingsTab from '../components/pages/ProfilePage/components/NotificationSettingsTab/reducers';
import thirdPartyAppsTab from '../components/pages/ProfilePage/components/ThirdPartyApps/reducers';
import faq from '../components/pages/Faq/reducers';

const rootReducer = combineReducers({
  app,
  register,
  login,
  forgotPassword,
  tileManager,
  language,
  systemStatus,
  devices,
  tileConfigurationPopup,
  tileCharts,
  alarms,
  notifications,
  history,
  addDevice,
  productRegistration,
  servicePartner,
  tilesData,
  textDb,
  profilePage,
  profileTab,
  addressTab,
  systemProfileProfileTab,
  systemProfileSecurityTab,
  systemProfileAddressTab,
  aboutManufacturerTab,
  systemProfileDeleteTab,
  notificationSettingsTab,
  shop,
  tileSettings,
  firmwares,
  invitation,
  deviceMenu,
  smartGuide,
  redeemVoucher,
  features,
  agreements,
  confirmEmailUpdate,
  deviceScheduling,
  thirdPartyAppsTab,
  faq,
});

export const createRootReducers = (injectedReducers = {}) => {
  return combineReducers({
    app,
    register,
    login,
    forgotPassword,
    tileManager,
    language,
    systemStatus,
    devices,
    tileConfigurationPopup,
    tileCharts,
    alarms,
    notifications,
    history,
    addDevice,
    productRegistration,
    servicePartner,
    tilesData,
    textDb,
    profilePage,
    profileTab,
    addressTab,
    systemProfileProfileTab,
    systemProfileAddressTab,
    systemProfileSecurityTab,
    aboutManufacturerTab,
    notificationSettingsTab,
    shop,
    tileSettings,
    firmwares,
    invitation,
    deviceMenu,
    smartGuide,
    redeemVoucher,
    features,
    agreements,
    confirmEmailUpdate,
    deviceScheduling,
    thirdPartyAppsTab,
    faq,
    ...injectedReducers,
  });
};

export default rootReducer;
