import React, { useState, useEffect } from 'react';
import PropTypes, { string } from 'prop-types';
import { connect } from 'react-redux';
import Modal from '../../../../../layout/Modal';
import { FormattedMessage } from 'react-intl';
import * as client from '../../../../../Shop/client';
import Price from '../../../../../Shop/components/Price';
import { Button, Spinner, Title } from 'jpi-cloud-web-ui-components';
import TermsOfServiceModal from '../TermsOfServiceModal';
import { isStripeSpecificBrand as isStripeBrand, redirectUserToPaymentWindow } from '../../storeUtils';
import { isEmpty } from 'lodash';
import UpdateCardDetail from '../PaymentProviderSettings/components/UpdateCardDetail';
import AddCardDetail from '../PaymentProviderSettings/components/AddCardDetail';
import { push } from 'connected-react-router';

import './productmodal.scss';

const productModal = ({
  productDetails,
  toggleProductDetailsPopup,
  toggleProductDetails,
  cart,
  paymentProviders,
  selectedPaymentProvider,
  createOrderCall,
  order,
  paymentDetails,
  language,
  selectPaymentSettingTab,
  goToPage,
  selectedSystem,
  paidBrands,
}) => {
  const [isErrorOccurred, setErrorOccurred] = useState(false);
  const [errors, setErrors] = useState([]);
  const [isTermsOfService, setToggleTermsOfService] = useState(false);
  const [cardDetails, setCardDetails] = useState([]);
  const { brandId = '' } = selectedSystem || {};
  const { stripeSpecificPaidBrands } = paidBrands;
  const isStripeSpecificBrand = isStripeBrand(stripeSpecificPaidBrands, brandId);

  useEffect(() => {
    if (paymentProviders[0]?.settings) {
      const [{ settings = {} }] = paymentProviders;
      const { settings: { savedCards = [] } = {} } = settings;
      setCardDetails(savedCards);
    }
  }, [paymentProviders]);

  const createOrder = async () => {
    await createOrderCall(cart, selectedPaymentProvider, paymentProviders);
  };

  const toggleTermsOfService = () => {
    setToggleTermsOfService(!isTermsOfService);
    toggleProductDetailsPopup(false);
  };

  useEffect(() => {
    if (order.data && order.data.order && productDetails === true) {
      toggleProductDetailsPopup(false);
      goToPage('/store/orders/' + order.data.order.id);
    }
  }, [order.data]);

  useEffect(() => {
    const isPaymentDetails = !isEmpty(paymentDetails?.data);
    if (isPaymentDetails) {
      const [{ internalName } = {}] = paymentProviders;
      const { hostedPaymentPageUrl } = paymentDetails.data;
      const { languageTag } = language;
      if (internalName !== 'stripe-recurring') {
        redirectUserToPaymentWindow(hostedPaymentPageUrl, languageTag, internalName);
      }
    }
  }, [paymentDetails]);

  useEffect(() => {
    try {
      if (order && order.error && order.error.internal_error) {
        setErrorOccurred(!isErrorOccurred);
        const internalError = JSON.parse(order.error.internal_error);
        setErrors([
          {
            key: internalError.error,
            message: internalError.messages[0],
          },
        ]);
      }
    } catch (err) {
      setErrors([]);
    }
  }, [order.error]);

  return (
    <>
      {cart && cart.lineItems && (
        <Modal className="checkout-modal-dialog" show={productDetails}>
          <div className="ProductModal">
            <div className="popup-modal">
              <div className="ProductModal__heading">
                <Title
                  className="ProductTitle"
                  titleTranslationId="shop.cart.checkout.title"
                  defaultMessage="Checkout"
                />
              </div>
              {!cart ? (
                <Spinner dark />
              ) : (
                <div className="ProductModal__subscription-wrapper">
                  {cart.lineItems.map(
                    ({
                      id,
                      variant: {
                        product: { handle },
                      },
                      title,
                      totalPrice,
                    }) => (
                      <div className="ProductModal__productMapping" key={id}>
                        <div className="ProductModal__partition" />
                        <div className="ProductModal__content-wrapper">
                          <div className="ProductModal__product-heading">
                            <FormattedMessage id={'product.' + handle + '.title'} defaultMessage={title} />
                          </div>
                          <div className="ProductModal__taxAndSubtotal">
                            <div className="ProductModal__subtotal-wrapper">
                              <p className="ProductModal__subtotal-heading">
                                <FormattedMessage id="shop.subtotal" defaultMessage="Subtotal" />
                                &#58;
                              </p>
                            </div>
                            <div className="ProductModal__tax-wrapper">
                              <p className="ProductModal__subtotal-price">
                                <Price currency={cart.currencyCode} price={totalPrice} />
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                  <div className="ProductModal__partition" />
                  <div className="ProductModal__shippingAndTotal">
                    <div className="ProductDetails__product-heading responsive-heading"></div>
                    <div className="ProductDetails__content-wrapper responsive-shippingAndTotalWrapper">
                      <div className="ProductModal__key-wrapper">
                        <p className="ProductModal__tax-heading">
                          <FormattedMessage id="shop.tax" defaultMessage="Tax" />
                          &#58;
                        </p>
                        <p className="ProductModal__shipping-heading">
                          <FormattedMessage id="shop.shipping" defaultMessage="Shipping" />
                          &#58;
                        </p>
                        <p className="ProductModal__total-price-heading">
                          <FormattedMessage id="shop.total" defaultMessage="Total" />
                          &#58;
                        </p>
                      </div>
                      <div className="ProductModal__value-wrapper">
                        <p className="ProductModal__tax-price">
                          <Price currency={cart.currencyCode} price={cart.totalTax} />
                        </p>
                        <p className="ProductModal__shipping-price">
                          <Price currency={cart.currencyCode} price={'0.00'} />
                        </p>
                        <p className="ProductModal__total-price">
                          <Price currency={cart.currencyCode} price={cart.totalPrice} />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {order.loading ? (
                <Spinner dark />
              ) : (
                <div className="ProductDetail__paymentSettings">
                  {!paymentProviders[0]?.handleReccuring ? (
                    <div className="ProductModal__toPayment-button">
                      <Button
                        onClick={() => {
                          createOrder();
                        }}
                      >
                        <FormattedMessage id="shop.cart.productModal.button.toPayment" defaultMessage="To Payment" />
                      </Button>
                    </div>
                  ) : !paymentProviders[0]?.settings ? (
                    <Spinner dark />
                  ) : cardDetails?.length > 0 ? (
                    <>
                      <UpdateCardDetail
                        savedCardDetail={cardDetails[0]}
                        selectPaymentSettingTab={selectPaymentSettingTab}
                      />
                      {order.error &&
                        order.error.internal_error &&
                        !isEmpty(errors) &&
                        errors.map(({ key, message }, index) => (
                          <div className="ProductModal__errorMessage" key={index}>
                            <FormattedMessage id={`shop.cart.productModal.${key}`} defaultMessage={message} />
                          </div>
                        ))}
                      <div className="ProductModal__button-wrapper">
                        <Button
                          className="link--secondary ProductModal__payment-button"
                          onClick={() => {
                            createOrder();
                          }}
                        >
                          <FormattedMessage id="button.confirm" defaultMessage="Confirm" />
                        </Button>
                      </div>
                    </>
                  ) : (
                    <>
                      <AddCardDetail isStripeSpecificBrand={isStripeSpecificBrand} />
                      <div className="ProductModal__button-wrapper">
                        <Button
                          className="link--secondary addCardDetail__addCard-button"
                          onClick={selectPaymentSettingTab}
                        >
                          <FormattedMessage id="paymentProviders.dibs-ticket.addNewCard" defaultMessage="Add Card" />
                        </Button>
                      </div>
                    </>
                  )}
                  <div className="ProductModal__button-wrapper">
                    <Button
                      className="ProductModal__cancel-button"
                      onClick={() => {
                        toggleProductDetails();
                        setErrors([]);
                      }}
                    >
                      <FormattedMessage id="button.back" defaultMessage="back" />
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Modal>
      )}
      <TermsOfServiceModal
        isTermsOfService={isTermsOfService}
        toggleTermsOfService={toggleTermsOfService}
        toggleProductDetailsPopup={toggleProductDetailsPopup}
      />
    </>
  );
};

productModal.propTypes = {
  productDetails: PropTypes.bool.isRequired,
  toggleProductDetailsPopup: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  goToPage: PropTypes.func.isRequired,
  createIframe: PropTypes.func,
  createOrder: PropTypes.func.isRequired,
  toggleProductDetails: PropTypes.func.isRequired,
  paymentProviders: PropTypes.arrayOf(
    PropTypes.shape({
      internalName: PropTypes.string.isRequired,
    })
  ),
  cart: PropTypes.shape({
    init: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.shape({
      key: PropTypes.string.isRequired,
      internal_error: PropTypes.shape({
        err: PropTypes.arrayOf(
          PropTypes.shape({
            key: PropTypes.string.isRequired,
            messages: PropTypes.string.isRequired,
          })
        ),
      }),
      messages: PropTypes.arrayOf(string),
    }),
    lineItems: PropTypes.arrayOf(
      PropTypes.shape({
        variant: PropTypes.shape({
          product: PropTypes.shape({
            id: PropTypes.string.isRequired,
            handle: PropTypes.string.isRequired,
          }),
        }),
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      })
    ),
    totalPrice: PropTypes.string.isRequired,
    totalTax: PropTypes.string.isRequired,
    currencyCode: PropTypes.string.isRequired,
  }),
  paymentDetails: PropTypes.shape({
    data: PropTypes.shape({
      hostedPaymentPageUrl: PropTypes.string.isRequired,
    }),
  }),
  selectedPaymentProvider: PropTypes.shape({
    paymentParams: PropTypes.shape({
      data: PropTypes.string.isRequired,
    }),
  }),
  order: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    data: PropTypes.shape({
      id: PropTypes.string,
    }),
    error: PropTypes.shape({
      internal_error: PropTypes.shape({
        err: PropTypes.arrayOf(
          PropTypes.shape({
            key: PropTypes.string.isRequired,
            messages: PropTypes.string.isRequired,
          })
        ),
      }),
    }),
  }),
  language: PropTypes.shape({
    languageTag: PropTypes.string.isRequired,
  }),
  selectPaymentSettingTab: PropTypes.func.isRequired,
  selectedSystem: PropTypes.shape({
    brandId: PropTypes.string.isRequired,
  }),
  paidBrands: PropTypes.shape({
    stripeSpecificPaidBrands: PropTypes.array,
  }),
};

const mapStateToProps = (state) => ({
  ...state.shop,
  language: state.language,
  selectedSystem: state.app.selectedSystem,
  paidBrands: state.shop.paidBrands,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  createOrderCall(cart, paymentProvider, paymentProviderData) {
    dispatch(client.createOrder(cart, paymentProvider, paymentProviderData));
  },
  goToPage(path) {
    dispatch(push(path));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(productModal);
