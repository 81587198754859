import React from 'react';
import { connect } from 'react-redux';
import { Link } from '../../../../Link';
import StoreLink from '../StoreLink/StoreLink';
import LanguageSelector from '../../../LanguageSelector';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import './desktop-menu.scss';
import MenuContext from '../../MenuContext';
import { freeBrand } from '../../../../constants/constants';
import classNames from 'classnames';
import { Button } from 'jpi-cloud-web-ui-components';
import { logoutUser } from '../../../../../components/pages/Login/actions';

const DesktopSubMenu = ({ subMenuItems, align, redirectIotStore, selectedSystem, logoutUser }) => (
  <MenuContext.Consumer>
    {(context) => (
      <div className={classNames({ desktopSubMenu: true, 'desktopSubMenu--expand-left': align === 'right' })}>
        <div className="desktopSubMenu__list">
          {subMenuItems
            .filter((item) => !item.hidden && (!item.visible || item.visible(context)))
            .map((val) => {
              if (val.id === 'menu.items.iot-store') {
                return <StoreLink val={val} redirectIotStore={redirectIotStore} selectedSystem={selectedSystem} />;
              }
              if (val.id === 'menu.items.logout') {
                return (
                  <Button className="desktopSubMenu__button desktopSubMenu__link" onClick={logoutUser}>
                    <FormattedMessage id={val.id} defaultMessage={val.name} />
                  </Button>
                );
              }
              if (!(selectedSystem && selectedSystem.brandId.toLowerCase() === freeBrand && val.name === 'Premium')) {
                return (
                  <Link
                    key={val.id}
                    className={val.disabled ? 'desktopSubMenu__link-disabled' : 'desktopSubMenu__link'}
                    to={val.disabled ? '' : val.path}
                  >
                    {val.id === 'menu.items.devices' && context.deviceUpdateAvailable && (
                      <span className="badge badge-danger">!</span>
                    )}
                    {val.menuName && val.menuName(context) ? (
                      <FormattedMessage id="menu.items.device-main-menu" defaultMessage={val.menuName(context)} />
                    ) : (
                      <FormattedMessage id={val.id} defaultMessage={val.name} />
                    )}
                  </Link>
                );
              }
            })}
        </div>
      </div>
    )}
  </MenuContext.Consumer>
);

DesktopSubMenu.propTypes = {
  subMenuItems: PropTypes.array,
  align: PropTypes.string,
  redirectIotStore: PropTypes.func,
  selectedSystem: PropTypes.object,
  logoutUser: PropTypes.func,
};

const DesktopMenuItem = ({
  id,
  name,
  children,
  align,
  useIcon,
  icon,
  redirectIotStore,
  disabled,
  selectedSystem,
  logoutUser,
}) => {
  const customStyle = {
    backgroundImage: 'url(' + icon + ')',
    backgroundSize: 'contain',
  };
  const alignClass = align === 'right' ? ' desktopMenu__item--right' : '';

  if (id === 'menu.headers.language') {
    return (
      <li id={id} className={'desktopMenu__item--languageSelector desktopMenu__item' + alignClass}>
        <LanguageSelector />
      </li>
    );
  }

  return (
    <MenuContext.Consumer>
      {(context) => {
        return (
          !(selectedSystem && selectedSystem.brandId.toLowerCase() === freeBrand && name === 'Store') && (
            <li id={id} className={'desktopMenu__item' + alignClass}>
              {!useIcon ? (
                <button className="desktopMenu__itemLink">
                  {id === 'menu.headers.system' && context.deviceUpdateAvailable && (
                    <span className="badge badge-danger">!</span>
                  )}
                  <FormattedMessage id={id} defaultMessage={name} />
                  <span className="caret"></span>
                </button>
              ) : (
                <button
                  type="button"
                  aria-label="Profile Settings"
                  style={customStyle}
                  className={'desktopMenu__itemLink desktopMenu__itemLink--icon'}
                >
                  {' '}
                </button>
              )}

              <DesktopSubMenu
                subMenuItems={
                  disabled && disabled(context)
                    ? children.map((item) => ({ ...item, disabled: true }))
                    : children.map((item) => ({ ...item, disabled: item.disabled && item.disabled(context) }))
                }
                align={align}
                selectedSystem={selectedSystem}
                redirectIotStore={redirectIotStore}
                logoutUser={logoutUser}
              />
            </li>
          )
        );
      }}
    </MenuContext.Consumer>
  );
};

DesktopMenuItem.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  children: PropTypes.array,
  align: PropTypes.string,
  useIcon: PropTypes.bool,
  icon: PropTypes.string,
  disabled: PropTypes.func,
  redirectIotStore: PropTypes.func,
  selectedSystem: PropTypes.object,
  logoutUser: PropTypes.func,
};

const DesktopMenu = ({ menuItems, redirectIotStore, selectedSystem, logout }) => (
  <nav className="desktopMenu">
    <ul className="desktopMenu__list">
      {menuItems.map((val, index) => (
        <DesktopMenuItem
          key={index}
          {...val}
          redirectIotStore={redirectIotStore}
          selectedSystem={selectedSystem}
          logoutUser={logout}
        />
      ))}
    </ul>
  </nav>
);

DesktopMenu.propTypes = {
  menuItems: PropTypes.array,
  redirectIotStore: PropTypes.func,
  selectedSystem: PropTypes.object,
  logout: PropTypes.func,
};

const mapStateToProps = ({ app: { selectedSystem } }) => ({
  selectedSystem,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logoutUser(false)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DesktopMenu));
