import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

export const NavigationItem = ({ href, id, defaultMessage, className }) => (
  <li className={classNames('navigation-item', className)}>
    <a href={href}>
      <FormattedMessage id={id} defaultMessage={defaultMessage} />
    </a>
  </li>
);

NavigationItem.propTypes = {
  href: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  defaultMessage: PropTypes.string.isRequired,
  className: PropTypes.string,
};
