import * as api from '../../../../api';

export const GET_DEVICE_MENU_REQUEST = 'device-menu/GET_DEVICE_MENU_REQUEST';
export const GET_DEVICE_MENU_RESPONSE = 'device-menu/GET_DEVICE_MENU_RESPONSE';
export const getDeviceMenu = (deviceId, id) => async (dispatch) => {
  dispatch({ type: GET_DEVICE_MENU_REQUEST });
  let res;

  try {
    res = await api.getDeviceMenu(deviceId, id);
  } catch (error) {
    return false;
  }

  dispatch({ type: GET_DEVICE_MENU_RESPONSE, menu: res.data, deviceId, id });
  return true;
};

export const INVOKE_DEVICE_EVENT_REQUEST = 'device-menu/INVOKE_DEVICE_EVENT_REQUEST';
export const INVOKE_DEVICE_EVENT_RESPONSE = 'device-menu/INVOKE_DEVICE_EVENT_RESPONSE';
export const invokeDeviceEvent = (deviceId, eventId) => async (dispatch) => {
  dispatch({ type: INVOKE_DEVICE_EVENT_REQUEST });

  await api.invokeDeviceEvent(deviceId, eventId);

  dispatch({ type: INVOKE_DEVICE_EVENT_RESPONSE });
};

export const EDIT_DEVICE_MENU_RAW_SETTING = 'device-menu/EDIT_DEVICE_MENU_RAW_SETTING';
export const EDIT_DEVICE_MENU_RAW_SETTING_COMPLETE = 'device-menu/EDIT_DEVICE_MENU_RAW_SETTING_COMPLETE';
export const editMenuRawSetting = (deviceId, menuId, parameterId, value, unit) => async (dispatch) => {
  dispatch({ type: EDIT_DEVICE_MENU_RAW_SETTING, deviceId, menuId, parameterId, value, unit });
  await api.patchMenuRawSetting(deviceId, menuId, parameterId, value, unit);
  dispatch({ type: EDIT_DEVICE_MENU_RAW_SETTING_COMPLETE });
};
