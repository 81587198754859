import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Title } from 'jpi-cloud-web-ui-components';
import './about-page.scss';

const AboutPage = () => (
  <div className="page-content">
    <div className="about-page">
      <Title titleTranslationId="about.title" defaultMessage="About myUplink" />
      <p>
        <FormattedMessage
          id="about.title.description"
          defaultMessage="The internet and myUplink gives you a quick overview and the present status of your system. You get a good overall view and good information for monitoring and controlling your heating and hot water. If your system is affected by a malfunction, you receive an alert via e-mail that allows you to react quickly."
        />
      </p>
      <p>
        {' '}
        <FormattedMessage
          id="about.description"
          defaultMessage="myUplink also gives you the opportunity to control comfort, no matter where you are."
        />
      </p>
      <h3>
        {' '}
        <FormattedMessage id="about.contact.information.title" defaultMessage="Contact Information" />
      </h3>
      <h4>
        {' '}
        <FormattedMessage id="label.address" defaultMessage="Address" />
      </h4>
      <FormattedMessage id="about.company" defaultMessage="myUpTech AB" />
      <br />
      <FormattedMessage id="about.company.address1" defaultMessage="Box 14" />
      <br />
      <FormattedMessage id="about.company.address2" defaultMessage="285 21 Markaryd" />
      <br />
      <FormattedMessage id="about.country" defaultMessage="Sweden" />
      <br />
      <h4>
        {' '}
        <FormattedMessage id="about.organization.registered.title" defaultMessage="Org. Reg. No." />
      </h4>
      <FormattedMessage id="about.company.registration.number" defaultMessage="556633-8140" />
      <br />
      <h4>
        <FormattedMessage id="contact.fromEmail" defaultMessage="E-mail" />
      </h4>
      <FormattedMessage id="about.company.email" defaultMessage="info@myuplink.com" />
      <br />
      <h4>
        {' '}
        <FormattedMessage id="label.phone" defaultMessage="Phone" />{' '}
      </h4>
      <FormattedMessage id="about.company.phone.number" defaultMessage="+46 433 27 30 00" />
      <br />
      <br />
    </div>
  </div>
);

export default AboutPage;
