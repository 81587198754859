import React, { useState } from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { Button } from 'jpi-cloud-web-ui-components';
import CancelSubscription from '../CancelSubscription/CancelSubscription';
import ResumeSubscription from '../ResumeSubscription/ResumeSubscription';
import Price from '../../../../../../Shop/components/Price';
import './yearlySubscription.scss';
import PropTypes from 'prop-types';

const YearlySubscription = ({
  status,
  validUntil,
  id,
  isDemo,
  isProductAvailableInCart,
  currencyCode,
  price,
  deviceId,
}) => {
  const [cancelSubscriptionModel, setCancelSubscription] = useState(false);
  const [resumeSubscriptionModel, setResumeSubscription] = useState(false);
  const yearlyDueDate = moment.utc(validUntil).format('MMMM D YYYY');
  return (
    <div className="yearlySubscription">
      {(status === 'active' || status === 'failed') && (
        <div className="yearlySubscription__subscription">
          <p className="yearlySubscription__subscriptionTitle">
            <FormattedMessage
              id="shop.page.subscriptionDetail.Title"
              defaultMessage="Your next yearly subscription payment will be."
            />
          </p>
          <div className="yearlySubscription__border" />
          <div className="yearlySubscription__dueDateAndPrice">
            <p className="yearlySubscription__yearlyDueDate">
              <FormattedMessage id="shop.page.subscriptionDetails.dueDate" defaultMessage="Due" />
              &emsp;
              {yearlyDueDate}
            </p>
            {currencyCode && price && (
              <p className="yearlySubscription__price">
                <Price currency={currencyCode} price={price} />
              </p>
            )}
          </div>
          <div className="yearlySubscription__border" />
        </div>
      )}
      {status === 'canceled' && moment.utc(validUntil) >= moment.utc() ? (
        <>
          <p className="yearlySubscription__yearlyDueDate">
            <FormattedMessage id="shop.page.validTo" defaultMessage="Valid until" />:
            <span className="yearlySubscription__yearlyValidUntilDate">{yearlyDueDate}</span>
          </p>
          <div className="yearlySubscription__resumeSubscription-message">
            <FormattedMessage
              id="shop.page.subscriptionDetails.resumeSubscriptionMessage"
              defaultMessage="If you wish to resume your subscription, the subscription
                will restart with the remaining days of the subscription period"
            />
          </div>
          <Button
            className="yearlySubscription__resumeSubscription-button"
            onClick={() => {
              setResumeSubscription(!resumeSubscriptionModel);
            }}
          >
            <FormattedMessage id="button.resumeSubscription" defaultMessage="Resume Subscription" />
          </Button>
        </>
      ) : (
        <>
          <div className="yearlySubscription__cancellation-message">
            <FormattedMessage
              id="shop.page.subscriptionDetails.cancellationMessage"
              defaultMessage="If you wish to cancel your subscription, yearly subscription will get cancelled.
            You can, at any time during your yearly subscriptions period, resume the cancelled subscription
            without having to pay the yearly fee.This would restart your subscription with the remaining days of the
            subscription period."
            />
          </div>
          <Button
            className="yearlySubscription__cancelSubscription-button"
            onClick={() => {
              setCancelSubscription(!cancelSubscriptionModel);
            }}
            disabled={isDemo || isProductAvailableInCart}
          >
            <FormattedMessage id="shop.page.cancleSubscription" defaultMessage="Cancel subscription" />
          </Button>
        </>
      )}
      <CancelSubscription
        cancelSubscriptionModel={cancelSubscriptionModel}
        setCancelSubscription={setCancelSubscription}
        subscriptionId={id}
        deviceId={deviceId}
      />
      <ResumeSubscription
        resumeSubscriptionModel={resumeSubscriptionModel}
        setResumeSubscription={setResumeSubscription}
        subscriptionId={id}
        deviceId={deviceId}
      />
    </div>
  );
};

YearlySubscription.propTypes = {
  status: PropTypes.string,
  validUntil: PropTypes.string,
  id: PropTypes.string,
  isDemo: PropTypes.string,
  isProductAvailableInCart: PropTypes.string,
  currencyCode: PropTypes.string,
  price: PropTypes.string,
  deviceId: PropTypes.string,
};

export default YearlySubscription;
