import climatemasterIcons from '../climatemaster';
import ventilation from './ventilation.svg';
import iecAuto from './iec_auto.svg';
import iecLow from './iec_low.svg';
import iecMed from './iec_med.svg';
import iecHigh from './iec_high.svg';

export default {
  ...climatemasterIcons,
  ventilation,
  iecAuto,
  iecLow,
  iecMed,
  iecHigh,
};
