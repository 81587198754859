import systemMode from './climatemaster_mode.svg';
import setpoint from './climatemaster_setpoint.svg';
import climatemasterOff from './climatemaster_off.svg';
import climatemasterOn from './climatemaster_on.svg';
import climatemasterAuto from './climatemaster_auto.svg';
import climatemasterHeating from './climatemaster_heating.svg';
import climatemasterCooling from './climatemaster_cooling.svg';
import climatemasterEmergency from './climatemaster_emergency.svg';
import zoning from './climatemaster_zoning.svg';
import temperature from './climatemaster_temperature.svg';
import humidity from './climatemaster_humidity.svg';
import fanMode from './climatemaster_fan.svg';
import myUplinkIcons from '../myuplink';

export default {
  ...myUplinkIcons,
  systemMode,
  setpoint,
  climatemasterOff,
  climatemasterOn,
  climatemasterAuto,
  climatemasterHeating,
  climatemasterCooling,
  climatemasterEmergency,
  zoning,
  temperature,
  humidity,
  fanMode,
};
