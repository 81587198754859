import {
  CREATE_SYSTEM_ADDRESS_FAILED,
  CREATE_SYSTEM_ADDRESS_RESPONSE_SUCCEEDED,
  CHANGE_SYSTEM_ADDRESS_RESPONSE_SUCCEDED,
} from './actions';

const initialState = {
  address: null,
  addressError: null,
  systemError: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_SYSTEM_ADDRESS_FAILED:
      return { ...state, addressError: action.error };
    case CREATE_SYSTEM_ADDRESS_RESPONSE_SUCCEEDED:
      return { ...state, addressError: null, address: action.address };
    case CHANGE_SYSTEM_ADDRESS_RESPONSE_SUCCEDED:
      return { ...state, addressError: null, address: action.address };
    default:
      return state;
  }
};
