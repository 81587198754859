import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import uuid from 'uuid';
import EditProfileInfo from './EditProfileInfo';
import { FormattedMessage } from 'react-intl';
import { changeUserProfile, getTimeZones } from './actions';
import { getAddressInfo, changeAddressInfo, createAddressInfo } from '../AddressTab/actions';
import { changeEmail } from '../../actions';
import PasswordValidationForm from '../PasswordValidationForm';
import { Spinner } from 'jpi-cloud-web-ui-components';

class ProfileTab extends React.Component {
  state = {
    isProfileEditShown: false,
    isLoaded: false,
    isEmailConfirmationSent: false,
    showPasswordForm: false,
    formValues: null,
    isValueSaved: false,
    oldEmail: '',
    newEmail: '',
  };

  async componentDidMount() {
    if (!(this.props.profileTab && this.props.profileTab.timeZones)) {
      await this.props.getTimeZones();
    }

    if (this.props.addressId) {
      await this.props.getAddressInfo(this.props.addressId);
    }
    this.setState({ ...this.state, isLoaded: true });
  }

  onCancelEnterPassword = () => {
    this.setState({ ...this.state, showPasswordForm: false });
  };

  handlePasswordChange = (e) => {
    this.setState({ ...this.state, formValues: { ...this.state.formValues, password: e.target.value } });
  };

  onProfileSubmit = async (values, { setSubmitting, setShowError }) => {
    const oldUserProfile = this.props.userInfo;

    const addressId = this.props.addressId || uuid();

    const newValues = {
      city: values.city.trim(),
      country: values.country,
      id: values.id,
      lineOne: values.lineOne.trim(),
      lineTwo: values.lineTwo && values.lineTwo.trim(),
      postalCode: values.postalCode.trim(),
      region: values.region && values.region.trim(),
    };

    setSubmitting(true);
    const newUserProfile = {
      ...oldUserProfile,
      fullName: values.fullName.trim(),
      tzId: values.tzId.id ? values.tzId.id : values.tzId,
      addressId: values.addressId,
    };

    if (values.newEmail.trim() && !values.password) {
      setSubmitting(false);
      this.setState((current) => ({
        ...current,
        showPasswordForm: true,
        formValues: { ...values, password: '' },
      }));
    } else {
      if (values.newEmail.trim()) {
        const emailChangeSuccess = await this.props.changeEmail(
          this.props.userInfo.id,
          values.oldEmail.trim(),
          values.newEmail.trim(),
          values.password.trim()
        );

        if (!emailChangeSuccess) {
          setSubmitting(false);
          setShowError(true);

          return;
        }
        this.setState({
          ...this.state,
          isEmailConfirmationSent: true,
          oldEmail: this.state.formValues.oldEmail,
          newEmail: this.state.formValues.newEmail,
        });
        this.setState({ ...this.state, showPasswordForm: false });
        setTimeout(() => {
          this.setState({ ...this.state, isEmailConfirmationSent: false });
        }, 10000);
      }
    }

    if (this.props.addressId) {
      await this.props.changeAddressInfo(newValues);
      this.setState({ ...this.state, isValueSaved: true });
    } else {
      newUserProfile.addressId = addressId;
      await this.props.createAddressInfo(addressId, newValues);
    }

    if (await this.props.changeUserProfile(this.props.userInfo.id, newUserProfile)) {
      this.setState({ ...this.state, isProfileEditShown: false, isValueSaved: true });
    }

    setSubmitting(false);
    this.setState({ ...this.state, isValueSaved: false });
  };

  render() {
    return (
      <>
        {!this.state.isLoaded ? (
          <Spinner dark />
        ) : this.state.isEmailConfirmationSent ? (
          <div className="confirmation-label">
            <FormattedMessage
              id="email-tab.confirmation"
              defaultMessage="We have sent an email to confirm your change to the new address."
            />
          </div>
        ) : (
          <EditProfileInfo
            timeZones={this.props.profileTab.timeZones}
            userInfo={this.props.userInfo}
            requestError={this.props.requestError}
            onCancel={this.onCancelProfileEditing}
            onSubmit={this.onProfileSubmit}
            fullAddress={this.props.addressTab.address}
            addressId={this.props.addressId}
            isValueSaved={this.state.isValueSaved}
            oldEmail={this.state.oldEmail}
            newEmail={this.state.newEmail}
          />
        )}

        {this.state.formValues && (
          <PasswordValidationForm
            onSubmit={this.onProfileSubmit}
            showPasswordForm={this.state.showPasswordForm}
            formValues={this.state.formValues}
            onCancelEnterPassword={this.onCancelEnterPassword}
            handlePasswordChange={this.handlePasswordChange}
            requestError={this.props.requestError}
          />
        )}
      </>
    );
  }
}

ProfileTab.propTypes = {
  userInfo: PropTypes.object.isRequired,
  profileTab: PropTypes.object,
  changeUserProfile: PropTypes.func.isRequired,
  getTimeZones: PropTypes.func.isRequired,
  changeAddressInfo: PropTypes.func.isRequired,
  createAddressInfo: PropTypes.func.isRequired,
  addressTab: PropTypes.object.isRequired,
  addressId: PropTypes.string.isRequired,
  changeEmail: PropTypes.func.isRequired,
  getAddressInfo: PropTypes.func.isRequired,
  requestError: PropTypes.string,
};

export default connect(
  ({ profileTab, addressTab }) => ({
    profileTab,
    addressTab,
  }),
  {
    changeUserProfile,
    getTimeZones,
    changeAddressInfo,
    createAddressInfo,
    changeEmail,
    getAddressInfo,
  }
)(ProfileTab);
