import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import PremiumFeatureTooltip from '../../layout/PremiumFeatureTooltip';

import './toggle-switch.scss';

function ToggleSwitch({
  label,
  textOn = 'On',
  textOff = 'Off',
  disabled,
  isChecked,
  disabledDueToOffline,
  pendingValue = null,
  premiumFeatureType,
  onChange,
}) {
  const [checked, setChecked] = useState(false);

  const handleClick = () => {
    setChecked(!checked);

    onChange(!checked ? 1 : 0);
  };

  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);

  return (
    <div
      className="toggle-switch-wrapper"
      style={{ opacity: pendingValue === null ? 1 : 0.5, cursor: pendingValue === null ? 'inherit' : 'not-allowed' }}
    >
      <label htmlFor={`toggle-switch-${label}`} className="switch">
        <input
          id={`toggle-switch-${label}`}
          name="toggle-switch"
          type="checkbox"
          role="switch"
          aria-label={label}
          disabled={disabled || disabledDueToOffline || pendingValue !== null}
          checked={checked ? 'checked' : ''}
          aria-checked={checked}
          onChange={handleClick}
        />
        <span className="slider round"></span>
      </label>
      {disabled && (
        <PremiumFeatureTooltip premiumFeatureType={premiumFeatureType ? premiumFeatureType : 'permissionAccess'} />
      )}
      {disabledDueToOffline && <PremiumFeatureTooltip translationId="addDevice.systemSelector.offline" />}

      {(textOn || textOff) && <div className="toggle-text">{checked ? textOn : textOff}</div>}
    </div>
  );
}

ToggleSwitch.propTypes = {
  isChecked: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  pendingValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  disabledDueToOffline: PropTypes.bool,
  textOn: PropTypes.string,
  textOff: PropTypes.string,
  premiumFeatureType: PropTypes.string,
  label: PropTypes.string,
};

export default ToggleSwitch;
