import React from 'react';
import './tile.scss';
import PropTypes from 'prop-types';
import { DragSource, DropTarget } from 'react-dnd';
import handleViewport from 'react-in-viewport';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import minimizeIcon from '../../../assets/img/minimize.svg';
import expandIcon from '../../../assets/img/expand.svg';
import moveIcon from '../../../assets/img/Move.png';
import menuIcon from '../../../assets/img/menu.svg';

import { eventEmitter, events as insightEvents } from '../../tracking/AppInsights';
import { events, groups } from '../../../services/clarity/events';
import { setupTracker } from '../../../services/clarity';

const trackEvent = setupTracker(groups.DASHBOARD);

class Tile extends React.Component {
  state = {
    openMenu: false,
    isLoaded: false,
  };

  expand() {
    const { tileType = '' } = this.props;
    const event = [events.TILE_EXPAND, tileType].join('_');

    trackEvent(event); // Clarity
    eventEmitter.emit(insightEvents.track, event);

    this.props.onExpand();
  }

  minimize() {
    this.props.onMinimize();
  }

  delete(e) {
    e.preventDefault();
    this.props.onDelete();
    this.closeMenu();
  }

  edit(e) {
    e.preventDefault();
    this.props.onEdit();
    this.closeMenu();
  }

  openMenu() {
    document.addEventListener('click', this.closeMenu, false);
    this.setState({ openMenu: true });
  }

  closeMenu = (e) => {
    if (e !== undefined && this.node && this.node.contains(e.target)) {
      return;
    }

    document.removeEventListener('click', this.closeMenu, false);
    this.setState({ openMenu: false });
  };

  componentWillUnmount() {
    document.removeEventListener('click', this.closeMenu, false);
  }

  renderDragHandle() {
    const { movable, expanded, hasExpandedTile, connectDragSource } = this.props;
    if (movable && !expanded && !hasExpandedTile) {
      return connectDragSource(
        <p className="tile__drag_handle">
          <img src={moveIcon} className="icon" alt="move tile" />
        </p>
      );
    }
  }

  renderExpandButton() {
    const { expandable, expanded } = this.props;
    if (!expandable) return;

    if (expanded) {
      return (
        <button type="button" className="tile__minimize_button" onClick={(e) => this.minimize(e)}>
          <img src={minimizeIcon} className="icon" alt="Minimize tile" />
        </button>
      );
    }

    return (
      <button type="button" className="tile__expand_button" onClick={(e) => this.expand(e)}>
        <img src={expandIcon} className="icon" alt="Expand tile" />
      </button>
    );
  }

  renderMenuButton() {
    const { editable } = this.props;
    if (editable) {
      return (
        <button type="button" className="tile__menu_button" onClick={() => this.openMenu()}>
          <img src={menuIcon} className="icon" alt="Tile menu" />
        </button>
      );
    }
    return;
  }

  renderTileMenu = () => {
    const { editable, deletable } = this.props;
    const { openMenu } = this.state;
    if (editable || deletable) {
      return (
        <div className={'tileMenu' + (openMenu ? ' tileMenu--open' : '')}>
          <div className="tileMenu__overlay"></div>
          <div
            ref={(node) => {
              this.node = node;
            }}
            className="tileMenu__wrapper"
          >
            {editable ? (
              <div className="tileMenu__item">
                <a className="tileMenu__itemLink" href="tileMenu__itemLink" onClick={(e) => this.edit(e)}>
                  <FormattedMessage id="button.edit" defaultMessage="Edit" />
                </a>
              </div>
            ) : null}
            {deletable ? (
              <div className="tileMenu__item">
                <a className="tileMenu__itemLink" href="tileMenu__itemLink" onClick={(e) => this.delete(e)}>
                  <FormattedMessage id="button.delete" defaultMessage="Delete" />
                </a>
              </div>
            ) : null}
          </div>
        </div>
      );
    }
    return;
  };

  componentDidUpdate() {
    const { isLoaded } = this.state;
    const { inViewport } = this.props;
    if (inViewport && !isLoaded) {
      this.setState({ isLoaded: true });
    }
  }

  render() {
    const {
      expanded,
      position,
      isWide,
      lastTwo,
      isDragging,
      connectDropTarget,
      connectDragPreview,
      connectDragSource,
      hasExpandedTile,
    } = this.props;

    const { isLoaded } = this.state;

    let contentWrapperClasses = 'tile__contentWrapper';
    contentWrapperClasses += ' tile__contentWrapper--' + position;
    let contentExpandedClass = '';
    if (expanded) {
      contentExpandedClass += ' tile__contentWrapper--expanded';
    }
    if (isDragging) {
      contentWrapperClasses += ' tile__contentWrapper--is-dragging';
    }

    if (!isLoaded) {
      contentWrapperClasses += ' tile__contentWrapper--not-loaded';
    }

    const wrapDragSource = (dom) => {
      if (!hasExpandedTile) {
        return connectDragSource(dom);
      }
      return dom;
    };

    return connectDragPreview(
      connectDropTarget(
        wrapDragSource(
          <div
            className={
              'tile' + (isWide ? ' tile--wide' : '') + (lastTwo ? ' tile--last-two' : '') + ' ' + contentExpandedClass
            }
            id={this.props.tileType}
          >
            <div className={contentWrapperClasses}>
              {this.props.menuVisible && this.renderMenuButton()}
              {this.renderTileMenu()}
              {this.renderExpandButton()}
              {this.props.children}
            </div>
          </div>
        )
      )
    );
  }
}

Tile.defaultProps = {
  expanded: false,
  onExpand: () => {},
  onMinimize: () => {},
  onDrag: () => {},
  onDragEnd: () => {},
  onDelete: () => {},
  onEdit: () => {},
  position: '',
  hasExpandedTile: false,
  expandable: true,
  editable: true,
  movable: true,
  deletable: true,
  menuVisible: true,
};

Tile.propTypes = {
  children: PropTypes.any,
  isWide: PropTypes.bool,
  lastTwo: PropTypes.bool,
  expanded: PropTypes.bool,
  expandable: PropTypes.bool,
  menuVisible: PropTypes.bool,
  deletable: PropTypes.bool,
  editable: PropTypes.bool,
  findTile: PropTypes.func,
  onExpand: PropTypes.func,
  onMinimize: PropTypes.func,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  movable: PropTypes.bool,
  onDrag: PropTypes.func,
  onDragEnd: PropTypes.func,
  position: PropTypes.string,
  inViewport: PropTypes.bool,
  hasExpandedTile: PropTypes.bool,
  connectDragSource: PropTypes.func.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  connectDragPreview: PropTypes.func.isRequired,
  isDragging: PropTypes.bool.isRequired,
  tileType: PropTypes.string,
};

let timeout;

const tileSource = {
  beginDrag: (props) => {
    return {
      id: props.id,
      originalIndex: props.findTile(props.id).index,
    };
  },
};

const tileTarget = {
  canDrop: () => false,
  hover: (props, monitor) => {
    const { id: draggedId } = monitor.getItem();
    const { id: overId, moveTile, findTile } = props;

    if (timeout) clearTimeout(timeout);
    if (draggedId === overId) return;

    timeout = setTimeout(() => {
      if (!findTile || !moveTile) return;

      const { index: overIndex } = findTile(overId);
      moveTile(draggedId, overIndex);
    }, [500]);
  },
};

function cardCollect(connect) {
  return {
    connectDropTarget: connect.dropTarget(),
  };
}

function cardSourceCollect(connect, monitor) {
  return {
    connectDragPreview: connect.dragPreview(),
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
}

const DragDropTile = _.flow(
  DragSource('tile', tileSource, cardSourceCollect),
  DropTarget('tile', tileTarget, cardCollect)
)(handleViewport(Tile, { rootMargin: '-5%' }));

export default DragDropTile;
