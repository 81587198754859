import React, { useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import { Button, Spinner } from 'jpi-cloud-web-ui-components';

import { eventEmitter, events } from '../../../../tracking/AppInsights';
import { deleteMode } from '../../../../../api';

export const errorMessages = {
  403: {
    id: 'scheduling.edit-modes.delete.active-mode',
    defaultMessage: 'You are not alowed to delete this mode, please check your permissions.',
  },
  unexpected: {
    id: 'errors.unexpected-support',
    defaultMessage: 'Unexpected error occurred, please contact support.',
  },
};

const DeleteMode = ({ intl, deviceId, selectedMode, onConfirm, onCancel }) => {
  const [loading, setLoading] = useState(false);
  const [isModeActive, setModeActive] = useState(false);

  const handleConfirm = async () => {
    setLoading(true);
    try {
      const response = await deleteMode(deviceId, selectedMode?.modeId);
      if (response?.status > 199 && response?.status < 205) return onConfirm();

      setLoading(false);

      throw new Error('Mode deletion status code unexpected');
    } catch (error) {
      const {
        response: { status },
      } = error;
      if (status === 403) {
        setLoading(false);
        return NotificationManager.error(intl.formatMessage(errorMessages[403]));
      }
      if (status === 409) return setModeActive(true); // check for error_message presence

      NotificationManager.error(intl.formatMessage(errorMessages.unexpected));
      eventEmitter.emit(events.logError, error.toJSON());

      setLoading(false);
    }
  };

  const isActiveMessage =
    selectedMode?.isActive || isModeActive
      ? {
          id: 'scheduling.edit-mode.delete.forbidden',
          defaultMessage: 'This mode is already in use by one or more event(s) and cannot be deleted',
        }
      : {
          id: 'scheduling.edit-mode.confirmDelete',
          defaultMessage: 'Are you sure you wish to delete the selected mode?',
        };

  return (
    <div className="popup-body">
      <div className="popup-header">
        <p className="text" role="status">
          <FormattedMessage {...isActiveMessage} />
        </p>
      </div>
      <div className="popup-bottom-panel">
        {loading ? (
          <div role="progressbar">
            <Spinner dark />
          </div>
        ) : (
          <>
            {!selectedMode.isActive && (
              <Button className="button--secondary" type="button" onClick={handleConfirm}>
                <FormattedMessage id="button.delete" defaultMessage="Delete" />
              </Button>
            )}
            <Button className="button--default" type="button" onClick={onCancel}>
              {selectedMode.isActive ? (
                <FormattedMessage id="button.ok" defaultValue="OK" />
              ) : (
                <FormattedMessage id="scheduling.title.cancel" defaultMessage="Cancel" />
              )}
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

DeleteMode.propTypes = {
  intl: PropTypes.object,
  deviceId: PropTypes.string, // TODO: Use hook for getting this prop
  selectedMode: PropTypes.object,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default injectIntl(DeleteMode);
