import React from 'react';

import { eventEmitter, events as insightEvents } from './AppInsights';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    eventEmitter.emit(insightEvents.logError, { error, info });
  }

  render() {
    if (this.state.hasError) {
      // TODO: Setup Fallback page UI
      return (
        <div
          style={{
            paddingTop: '30vh',
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            margin: 'auto',
            width: '65%',
          }}
        >
          <h1>Ooops! Something went wrong...</h1>
          <h3>
            Please try <a href={window.location.href}>reloading</a> the page or <a href="/contact">contact</a> support.
          </h3>
        </div>
      );
    }

    // eslint-disable-next-line react/prop-types
    return this.props.children;
  }
}

export default ErrorBoundary;
