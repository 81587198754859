import React, { Component } from 'react';
import Tabs from '../../../../layout/Tabs/Tabs';
import { email, tabs } from '../../constants';
import { FormattedMessage } from 'react-intl';
import SystemList from './components/SystemList';
import { getUserProfile } from '../ProfileTab/actions';
import { onChangeActiveTab } from './actions';
import { getUserSystems } from '../../../../AppInitializer/actions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import './notificationsettingstab.scss';

class NotificationSettingsTab extends Component {
  static propTypes = {
    userId: PropTypes.string,
    systems: PropTypes.array.isRequired,
    getUserProfile: PropTypes.func.isRequired,
    getUserSystems: PropTypes.func.isRequired,
    onChangeActiveTab: PropTypes.func.isRequired,
    activeTab: PropTypes.string.isRequired,
  };

  onClickTabItem = async (tab) => {
    this.props.onChangeActiveTab(tab);
  };

  async componentDidMount() {
    if (this.props.userId) {
      await this.props.getUserProfile(this.props.userId);
    }
  }

  render() {
    const { systems } = this.props;
    return (
      <div className="notification-tab">
        {systems.length === 0 ? (
          <div className="notification__isSystemAvailable">
            <FormattedMessage id="notification.message.isSystemAvailable" defaultMessage="No System Connected." />
          </div>
        ) : (
          <Tabs labels={tabs} onClickTabItem={this.onClickTabItem} activeTab={this.props.activeTab}>
            <div className="notification__description-text">
              {this.props.activeTab === email ? (
                <div className="description-text-email">
                  <FormattedMessage
                    id="notification.message.description"
                    defaultMessage="Select those systems you want to receive notifications about via email"
                  />
                </div>
              ) : (
                <div className="description-text-push_notification">
                  <FormattedMessage
                    id="notification.message.description-push"
                    defaultMessage="Select those systems you want to receive push notifications about"
                  />
                </div>
              )}
            </div>
            <SystemList userId={this.props.userId} />
          </Tabs>
        )}
      </div>
    );
  }
}

export default connect(
  (state) => ({
    userId: state.app.userInfo.id,
    systems: state.app.systems,
    activeTab: state.notificationSettingsTab.activeTab,
  }),
  {
    getUserProfile,
    getUserSystems,
    onChangeActiveTab,
  }
)(NotificationSettingsTab);
