import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Carousel } from 'react-bootstrap';
import Content from '../SignOutLayout/components/Content';
import './slideshow.scss';
const SLIDE_CONTENT_TAB_INDEX = 0;

const Slideshow = ({ slides }) => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel
      activeIndex={index}
      onSelect={handleSelect}
      nextIcon={<span className="glyphicon glyphicon-chevron-right"></span>}
      prevIcon={<span className="glyphicon glyphicon-chevron-left"></span>}
    >
      {slides.map((item) => (
        <Carousel.Item key={item.text}>
          <Content>
            <h3 tabIndex={SLIDE_CONTENT_TAB_INDEX}>
              <FormattedMessage id={item.title} defaultMessage="Title" />
            </h3>
            <p className="carousel-text" tabIndex={SLIDE_CONTENT_TAB_INDEX}>
              <FormattedMessage id={item.text} defaultMessage="Text" />
            </p>
          </Content>
          <div className="image">
            {item.imageComponent ? (
              item.imageComponent
            ) : (
              <img tabIndex={SLIDE_CONTENT_TAB_INDEX} src={item.image} alt={item.alt} />
            )}
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

Slideshow.propTypes = {
  slides: PropTypes.array.isRequired,
};

export default Slideshow;
