import React from 'react';
import PropTypes from 'prop-types';

const WizardSteps = ({ currentStep, children }) => (Array.isArray(children) ? children[currentStep] : children);

WizardSteps.propTypes = {
  currentStep: PropTypes.number.isRequired,
  children: PropTypes.array.isRequired,
};

class Wizard extends React.Component {
  static propTypes = {
    stepsCount: PropTypes.number.isRequired,
    children: PropTypes.func.isRequired,
  };

  state = {
    currentStep: 0,
  };

  goNext = () => {
    if (this.state.currentStep !== this.props.stepsCount - 1) {
      this.setState({ currentStep: this.state.currentStep + 1 });
    }
  };

  goPrevious = () => {
    if (this.state.currentStep !== 0) {
      this.setState({ currentStep: this.state.currentStep - 1 });
    }
  };

  render() {
    const { goNext, goPrevious } = this;
    const { children } = this.props;
    const { currentStep } = this.state;

    return children({ currentStep, goNext, goPrevious });
  }
}

export { Wizard, WizardSteps };
