import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Button, Title } from 'jpi-cloud-web-ui-components';

import { Chart, ChartConfiguration } from './components';
import Link from '../../inputs/Link';

import { USER_SECURITY_LEVELS } from '../SystemProfile/components/SecurityTab/costants';
import { chartNumber, checkIsFirstChart } from './chart-utils';

import * as historyStorage from '../../../api/history';
import * as actions from './actions';

import { events, groups } from '../../../services/clarity/events';
import { setupTracker } from '../../../services/clarity';

import { isStripeSpecificBrand } from '../Store/components/storeUtils';
import { isWeekStartsOnSunday } from '../../../api/countries';

import './history.scss';

const trackEvent = setupTracker(groups.HISTORY);

export const updateMomentStartWeek = (userCountryCode) => {
  moment.updateLocale(moment.locale(), {
    week: {
      dow: isWeekStartsOnSunday(userCountryCode) ? 0 : 1,
    },
  });
};

// TODO: Move to functional and add test
class History extends Component {
  state = {
    aggregationMethod: 'average',
    openChartConfigModal: false,
    showSecondChart: false,
    numberOfChart: chartNumber[0],
  };

  changeAggregationMethod() {
    if (this.state.aggregationMethod === 'average') {
      this.setState({ aggregationMethod: 'none' });
    } else {
      this.setState({ aggregationMethod: 'average' });
    }
  }

  loadSystemData() {
    const { selectedSystem, getDevicesAndParameters } = this.props;

    if (!selectedSystem) return;
    getDevicesAndParameters(selectedSystem.id);
  }

  componentDidMount() {
    this.loadSystemData();

    if (this.props.userCountryCode) {
      updateMomentStartWeek(this.props.userCountryCode);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedSystem, getDevicesAndParameters, userCountryCode } = this.props;

    if ((!prevProps.selectedSystem && selectedSystem) || prevProps.selectedSystem?.id !== selectedSystem?.id) {
      getDevicesAndParameters(selectedSystem.id);
      this.setState({ showSecondChart: false });
    }

    if (prevState.numberOfChart !== this.state.numberOfChart) {
      checkIsFirstChart(this.state.numberOfChart);
    }

    if (prevProps.userCountryCode !== userCountryCode) {
      updateMomentStartWeek(userCountryCode);
    }
  }

  componentWillUnmount() {
    this.loadSystemData();
  }

  toggleSecondChart = () => {
    this.setState({ showSecondChart: true });

    trackEvent(events.TOGGLE_SECOND_CHART);
  };

  showChartConfig = (value) => {
    this.setState({ openChartConfigModal: value });

    trackEvent(events.TOGGLE_SECOND_CHART);
  };

  render() {
    const {
      isDemo,
      selectedSystem,
      charts,
      secondChart,
      dateRange,
      dateFrom,
      dateTo,
      dateRangeForSecondChart,
      dateToForSecondChart,
      dateFromForSecondChart,
      deleteChart,
      selectedLanguage,
    } = this.props;

    if (!selectedSystem) return null;

    const { brandId = '' } = selectedSystem;
    const showSecondChart = this.state.showSecondChart || historyStorage.hasSecondChart();
    const {
      premiumFeatures: { history: isPremiumHistory } = {},
      paidBrands: { stripeSpecificPaidBrands },
    } = this.props;
    const linkTarget = {
      pathname: '/store',
      state: {
        applied: true,
      },
    };

    const userIsViewer = selectedSystem.userRole === USER_SECURITY_LEVELS.viewer;

    const isSubscriptionValid = localStorage.getItem('IS_SUBSCRIPTION_VALID');

    return (
      <div className="page-content">
        <div className="history">
          <div className="premiumMessage">
            {!isPremiumHistory && !isSubscriptionValid && isStripeSpecificBrand(stripeSpecificPaidBrands, brandId) && (
              <p>
                <FormattedMessage
                  id="history.premiumMessage"
                  defaultMessage="Some of the features of History page are unavailable as you have not subscribed to Premium Subscription. Click {here} to subscribe."
                  values={{
                    here: (
                      <u className="premiumMessage-here">
                        <Link to={linkTarget}>
                          <FormattedMessage id="history.here" defaultMessage="here" />
                        </Link>
                      </u>
                    ),
                  }}
                />
              </p>
            )}
          </div>
          <Title titleTranslationId="history.title" defaultMessage="History" />
          {[0, 1].map(
            (i) =>
              !(i === 1 && !showSecondChart) && (
                <Chart
                  key={`${selectedSystem.id}_${chartNumber[i]}`}
                  {...this.props}
                  isDemo={isDemo}
                  charts={i ? secondChart : charts}
                  aggregationMethod={this.state.aggregationMethod}
                  dateRange={i ? dateRangeForSecondChart : dateRange}
                  dateFrom={i ? dateFromForSecondChart : dateFrom}
                  dateTo={i ? dateToForSecondChart : dateTo}
                  numberOfChart={chartNumber[i]}
                  showChartConfig={this.showChartConfig}
                  setNumberOfChart={() => this.setState({ numberOfChart: chartNumber[i] })}
                  deleteChart={async () => {
                    if (!i) {
                      await charts.map((chart) => deleteChart(chart.deviceId, chart.parameterId, chartNumber[0]));
                      historyStorage.removeFirstChart();
                    } else {
                      await secondChart.map((chart) => deleteChart(chart.deviceId, chart.parameterId, chartNumber[1]));
                      historyStorage.removeSecondChart();
                    }
                  }}
                  deleteChartParameter={deleteChart}
                  selectedLanguage={selectedLanguage}
                />
              )
          )}
          {!showSecondChart && (
            <Button className="button--primary chart-button" type="button" onClick={this.toggleSecondChart}>
              <FormattedMessage id="history-list.second-graph" defaultMessage="Add new graph" />
            </Button>
          )}
          {showSecondChart && (
            <Button
              className="button--primary chart-button"
              type="button"
              onClick={async () => {
                await secondChart.map((chart) => deleteChart(chart.deviceId, chart.parameterId, chartNumber[1]));
                historyStorage.removeSecondChart();
                this.setState({ showSecondChart: false });
              }}
            >
              <FormattedMessage id="history-list.remove-graph" defaultMessage="Remove chart" />
            </Button>
          )}
          <ChartConfiguration
            systemId={selectedSystem.id}
            showChartConfigModal={this.state.openChartConfigModal}
            showChartConfig={this.showChartConfig}
            isFirstChart={checkIsFirstChart(this.state.numberOfChart)}
            {...this.props}
            aggregationMethod={this.state.aggregationMethod}
            userIsViewer={userIsViewer}
            charts={this.state.numberOfChart === chartNumber[1] ? secondChart : charts}
            dateRange={this.state.numberOfChart === chartNumber[1] ? dateRangeForSecondChart : dateRange}
            dateFrom={this.state.numberOfChart === chartNumber[1] ? dateFromForSecondChart : new Date(dateFrom)}
            dateTo={this.state.numberOfChart === chartNumber[1] ? dateToForSecondChart : new Date(dateTo)}
          />
        </div>
      </div>
    );
  }
}

History.propTypes = {
  selectedSystem: PropTypes.object,
  paidBrands: PropTypes.shape({
    stripeSpecificPaidBrands: PropTypes.array,
  }),
};

export default connect(
  ({
    history,
    shop,
    app: { selectedSystem, userLoggedIn, userInfo },
    features: { premiumFeatures },
    language: { selectedLanguage },
  }) => ({
    ...history,
    selectedSystem: selectedSystem,
    userLoggedIn: userLoggedIn,
    isDemo: userInfo.isDemo,
    premiumFeatures,
    paidBrands: shop.paidBrands,
    userCountryCode: userInfo?.address?.country?.countryCode,
    selectedLanguage,
  }),
  actions
)(History);
