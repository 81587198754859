export const submitStatus = {
  success: 'success',
  invalid: 'invalid',
  unknown: 'unknown',
};

export const messageMinLength = 10;
export const messageMaxLength = 5000;

export const errorMessages = {
  'from-email.email': {
    id: 'email.error.validation.email',
    defaultMessage: 'E-Mail is not valid',
  },
  'from-email.required': {
    id: 'email.error.validation.required',
    defaultMessage: 'E-Mail is mandatory field',
  },
  'topic.required': {
    id: 'topic.error.validation.required',
    defaultMessage: 'Please choose a subject',
  },
  'system.required': {
    id: 'system.error.validation.required',
    defaultMessage: 'Please choose a system',
  },
  'summary.required': {
    id: 'summary.error.validation.required',
    defaultMessage: 'Summary is mandatory field',
  },
  'message.required': {
    id: 'description.error.validation.required',
    defaultMessage: 'You must enter 10 or more than 10 characters to proceed',
  },
  'messageMaxLength.required': {
    id: 'descriptionMaxLength.error.validation.required',
    defaultMessage: 'Please enter less than 5000 characters to proceed',
  },
};

export const topics = [
  {
    id: 36,
    value: {
      id: 'contact.topics.technicalSupport',
      defaultMessage: 'Technical Support',
    },
  },
  {
    id: 41,
    value: {
      id: 'contact.topics.reportBugs',
      defaultMessage: 'Report a Bug',
    },
  },
  {
    id: 38,
    value: {
      id: 'contact.topics.security',
      defaultMessage: 'Security',
    },
  },
  {
    id: 37,
    value: {
      id: 'contact.topics.premiumPayment',
      defaultMessage: 'Premium and Payment',
    },
  },
  {
    id: 60,
    value: {
      id: 'contact.topics.onlineStore',
      defaultMessage: 'Online Store',
    },
  },
  {
    id: 43,
    value: {
      id: 'contact.topics.feedback',
      defaultMessage: 'Feedback',
    },
  },
  {
    id: 39,
    value: {
      id: 'contact.topics.otherQuestions',
      defaultMessage: 'Other Questions',
    },
  },
];
