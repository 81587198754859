import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { Title } from 'jpi-cloud-web-ui-components';
import './cookies-page.scss';

const CookiesPage = () => (
  <div className="page-content">
    <Title titleTranslationId="cookies.title" defaultMessage="Cookies" />
    <FormattedHTMLMessage id="cookies.text" defaultMessage="Cookies" />
  </div>
);

export default CookiesPage;
