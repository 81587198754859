import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Spinner } from 'jpi-cloud-web-ui-components';

import { SettingPresentationTypes } from '../TileSettings';
import { getTileData } from './actions';

class TileData extends React.Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
    tile: PropTypes.object.isRequired,
    tilesData: PropTypes.object,
    selectedSystem: PropTypes.object,
    getTileData: PropTypes.func.isRequired,
  };

  state = {
    loading: true,
  };

  async componentDidMount() {
    const { tile, getTileData } = this.props;

    if (tile.properties.displayParameters.length > 0) {
      this.setState({ loading: true });
      const parameterIds = [
        ...tile.properties.displayParameters.filter((dp) => !!dp).map((dp) => dp.id),
        ...tile.properties.settingParameters
          .filter((p) => p.parameter.presentation === SettingPresentationTypes.display)
          .map((p) => p.id),
      ];

      await getTileData(tile.id, tile.deviceId, [...parameterIds]);
      this.setState({ loading: false });
    } else {
      this.setState({ loading: false });
    }
  }

  displayParametersEqual = (firstParams, secondParams) => {
    if (firstParams.length !== secondParams.length) {
      return false;
    }

    return !firstParams.some((p, i) => p !== secondParams[i]);
  };

  chartsEqual = (firstChart, secondChart) => {
    if (firstChart != null && secondChart != null) {
      return firstChart.id === secondChart.id;
    }

    return true;
  };

  async componentDidUpdate(prevProps) {
    const { tile, getTileData } = this.props;

    if (
      (prevProps.tilesData[tile.id] && !this.props.tilesData[tile.id]) ||
      !this.chartsEqual(prevProps.tile.properties.chart, tile.properties.chart) ||
      !this.displayParametersEqual(prevProps.tile.properties.displayParameters, tile.properties.displayParameters)
    ) {
      this.setState({ loading: true });

      await getTileData(tile.id, tile.deviceId, [
        ...tile.properties.displayParameters.filter((dp) => !!dp).map((dp) => dp.id),
        ...tile.properties.settingParameters.filter((dp) => !!dp).map((dp) => dp.id),
      ]);
      this.setState({ loading: false });
    }
  }

  render() {
    const { tile, tilesData } = this.props;
    const data = tilesData[tile.id];

    if (this.state.loading) return <Spinner />;

    const tileWithData = {
      ...tile,
      properties: {
        ...tile.properties,
        displayParameters: tile.properties.displayParameters.map((dp) => {
          return {
            ...dp,
            ...(dp && {
              value: data && data[dp.id].value,
              unit: data && data[dp.id].unit,
            }),
          };
        }),
        settingParameters: tile.properties.settingParameters.map((sp) => {
          return {
            ...sp,
            ...(sp && {
              value: data && data[sp.id] && data[sp.id].value,
              unit: data && data[sp.id] && data[sp.id].unit,
            }),
          };
        }),
      },
    };

    return this.props.children(tileWithData);
  }
}

export default connect(
  ({ tilesData, app: { selectedSystem } }) => ({
    tilesData,
    selectedSystem,
  }),
  { getTileData }
)(TileData);
