import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import { Button } from 'jpi-cloud-web-ui-components';
import PropTypes from 'prop-types';

class DeleteVacation extends React.PureComponent {
  static propTypes = {
    intl: PropTypes.object,
    editableVacation: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
  };

  render() {
    const { onSubmit, onCancel } = this.props;

    return (
      <div className="popup-bottom-panel">
        <Button className="button--secondary" type="button" onClick={onSubmit}>
          <FormattedMessage id="button.delete" defaultMessage="Delete" />
        </Button>
        <Button className="button--default" type="button" onClick={onCancel}>
          <FormattedMessage id="scheduling.title.cancel" defaultMessage="Cancel" />
        </Button>
      </div>
    );
  }
}

export default injectIntl(DeleteVacation);
