import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import './servicepartner.scss';

import { Spinner } from 'jpi-cloud-web-ui-components';

const ServicePartnerPhone = ({ phone, isDemo }) =>
  !isDemo ? <a href={`tel:${phone}`}>{phone}</a> : <span>{phone}</span>;

ServicePartnerPhone.propTypes = {
  phone: PropTypes.string.isRequired,
  isDemo: PropTypes.bool.isRequired,
};

const ServicePartnerEmail = ({ email, isDemo }) =>
  !isDemo ? <a href={`mailto:${email}`}>{email}</a> : <span>{email}</span>;

ServicePartnerEmail.propTypes = {
  email: PropTypes.string.isRequired,
  isDemo: PropTypes.bool.isRequired,
};

const ServicePartnerInfoItem = ({ className, itemName, children }) => (
  <div className={className}>
    <div className="label">
      <FormattedMessage id={'label.' + itemName} defaultMessage={itemName} />
    </div>
    <div className="value">{children}</div>
  </div>
);

ServicePartnerInfoItem.propTypes = {
  className: PropTypes.string.isRequired,
  itemName: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
};

const ContactInfo = ({ servicePartner, isPhoneCopied, isEmailCopied, isDemo, handleCopyPhone, handleCopyEmail }) => (
  <div className="first-column col-lg-6 col-md-6 col-xs-12">
    <ServicePartnerInfoItem className="name" itemName="name">
      {servicePartner.name}
    </ServicePartnerInfoItem>
    <ServicePartnerInfoItem className="phone" itemName="phone">
      <ServicePartnerPhone phone={servicePartner.contactPhone} isDemo={isDemo} />
      <div className="copy-link" onClick={handleCopyPhone}>
        <i className={isPhoneCopied ? 'fa fa-check' : 'fa fa-copy'} />
      </div>
    </ServicePartnerInfoItem>
    <ServicePartnerInfoItem className="email" itemName="email">
      <ServicePartnerEmail email={servicePartner.contactEmail} isDemo={isDemo} />
      <div className="copy-link" onClick={handleCopyEmail}>
        <i className={isEmailCopied ? 'fa fa-check' : 'fa fa-copy'} />
      </div>
    </ServicePartnerInfoItem>
  </div>
);

ContactInfo.propTypes = {
  servicePartner: PropTypes.object.isRequired,
  isPhoneCopied: PropTypes.bool.isRequired,
  isEmailCopied: PropTypes.bool.isRequired,
  isDemo: PropTypes.bool.isRequired,
  handleCopyPhone: PropTypes.func.isRequired,
  handleCopyEmail: PropTypes.func.isRequired,
};

const AddressInfo = ({ address }) => (
  <div className="col-lg-6 col-md-6 col-xs-12 second-column">
    <ServicePartnerInfoItem className="address" itemName="address">
      <div className="line-one">{address.lineOne}</div>
      {address.lineTwo && <div className="line-two">{address.lineTwo}</div>}
      <div className="city">
        {address.postalCode} {address.city}
      </div>
      <div className="country">{address.country && address.country.name}</div>
    </ServicePartnerInfoItem>
  </div>
);

AddressInfo.propTypes = {
  address: PropTypes.object.isRequired,
};

class ServicePartnerTab extends Component {
  state = {
    isPhoneCopied: false,
    isEmailCopied: false,
    isLoaded: false,
  };

  async componentDidMount() {
    this.setState({ ...this.state, isLoaded: true });
  }

  handleCopyPhone = () => {
    this.copyToClipboard(this.props.servicePartner.contactPhone);
    this.setState({ ...this.state, isPhoneCopied: true, isEmailCopied: false });
    setTimeout(() => {
      this.setState({ ...this.state, isPhoneCopied: false });
    }, 2000);
  };

  handleCopyEmail = () => {
    this.copyToClipboard(this.props.servicePartner.contactEmail);
    this.setState({ ...this.state, isPhoneCopied: false, isEmailCopied: true });
    setTimeout(() => {
      this.setState({ ...this.state, isEmailCopied: false });
    }, 2000);
  };

  copyToClipboard(value) {
    const element = document.createElement('textarea');
    element.value = value;
    document.body.appendChild(element);
    element.select();
    document.execCommand('copy');
    document.body.removeChild(element);
    this.setState({ ...this.state, isCopySuceeded: true });
  }

  render() {
    if (!this.state.isLoaded) {
      return <Spinner dark />;
    }

    const address = this.props.servicePartner.address;
    const servicePartner = this.props.servicePartner;
    const isDemo = (this.props.userInfo && this.props.userInfo.isDemo) || false;

    return (
      <div className="service-partner-wrapper">
        {servicePartner.id !== 'spNotFound' && (
          <ContactInfo
            isDemo={isDemo}
            servicePartner={servicePartner}
            isPhoneCopied={this.state.isPhoneCopied}
            isEmailCopied={this.state.isEmailCopied}
            handleCopyPhone={this.handleCopyPhone}
            handleCopyEmail={this.handleCopyEmail}
          />
        )}
        {servicePartner.id !== 'spNotFound' && address && <AddressInfo address={address} />}
        {servicePartner.id === 'spNotFound' && (
          <div className="service-partner-not-found">
            <FormattedMessage
              id="system-profile.service-partner.not-found"
              defaultMessage="A service partner for your system was not found."
            />
          </div>
        )}
      </div>
    );
  }
}

ServicePartnerTab.propTypes = {
  servicePartner: PropTypes.object.isRequired,
  selectedSystem: PropTypes.object,
  userInfo: PropTypes.object,
};

export default connect(
  ({ servicePartner: { servicePartner }, app: { selectedSystem, userInfo } }) => ({
    servicePartner,
    selectedSystem,
    userInfo,
  }),
  {}
)(ServicePartnerTab);
