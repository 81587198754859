export const groups = {
  HISTORY: 'history', // maybe: charts
  DASHBOARD: 'dashboard', // maybe: tiles
};

export const events = {
  TOGGLE_SECOND_CHART: 'toggle_second_chart',
  SHOW_CHART_CONFIG: 'show_chart_config_modal',
  CHART_CONFIG_PARAM_ADDED: 'chart_config_param_added',
  // controls
  CHART_POINTS_SHOW: 'chart_points_show',
  CHART_ZOOM_OUT: 'chart_zoom_out',
  CHART_ZOOM_IN: 'chart_zoom_in',
  CHART_EXPORT: 'chart_export',
  CHART_EXPAND: 'chart_expand',
  // periods
  CHART_PERIOD_DAY: 'chart_period_day',
  CHART_PERIOD_WEEK: 'chart_period_week',
  CHART_PERIOD_MONTH: 'chart_period_month',
  CHART_PERIOD_YEAR: 'chart_period_year',
  CHART_PERIOD_CUSTOM: 'chart_period_custom',
  // tiles
  TILE_EXPAND: 'tile_expand',
  TILE_CHART: 'tile_chart',
};
