import { email } from '../../constants';
import {
  UPDATE_SYSTEM_FOR_EMAIL_NOTIFICATIONS_REQUEST,
  UPDATE_SYSTEM_FOR_EMAIL_NOTIFICATIONS_RESPONSE_SUCCEDED,
  UPDATE_SYSTEM_FOR_EMAIL_NOTIFICATIONS_FAILED,
  UPDATE_SYSTEM_FOR_PUSH_NOTIFICATIONS_REQUEST,
  UPDATE_SYSTEM_FOR_PUSH_NOTIFICATIONS_RESPONSE_SUCCEDED,
  UPDATE_SYSTEM_FOR_PUSH_NOTIFICATIONS_FAILED,
  CHANGE_NOTIFICATION_SETTINGS_ACTIVE_TAB,
} from './actions';

const initialState = {
  activeTab: email,
  isSystemUpdating: false,
  error: '',
  errorOccured: false,
  isDisabledAllUpdating: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SYSTEM_FOR_EMAIL_NOTIFICATIONS_REQUEST:
      return { ...state, isSystemUpdating: true };
    case UPDATE_SYSTEM_FOR_EMAIL_NOTIFICATIONS_RESPONSE_SUCCEDED:
      return { ...state, isSystemUpdating: false };
    case UPDATE_SYSTEM_FOR_EMAIL_NOTIFICATIONS_FAILED:
      return { ...state, isSystemUpdating: false, errorOccured: true, error: action.error };
    case UPDATE_SYSTEM_FOR_PUSH_NOTIFICATIONS_REQUEST:
      return { ...state, isSystemUpdating: true };
    case UPDATE_SYSTEM_FOR_PUSH_NOTIFICATIONS_RESPONSE_SUCCEDED:
      return { ...state, isSystemUpdating: false };
    case UPDATE_SYSTEM_FOR_PUSH_NOTIFICATIONS_FAILED:
      return { ...state, isSystemUpdating: false, errorOccured: true, error: action.error };
    case CHANGE_NOTIFICATION_SETTINGS_ACTIVE_TAB:
      return { ...state, activeTab: action.activeTab };
    default:
      return state;
  }
};
