import moment from 'moment';
import {
  GET_DEVICES_PARAMETERS_RESPONSE,
  GET_POINTS_FOR_PARAMETER_RESPONSE,
  DELETE_CHART,
  DELETE_SECOND_CHART,
  SET_DATE_RANGE,
  SET_DATE_RANGE_FOR_SECOND_CHART,
  FULL_SCREEN,
  GET_DEVICES_PARAMETERS_REQUEST,
  GET_POINTS_FOR_PARAMETER_RESPONSE_FOR_SECOND_CHART,
  SET_CACHED_PARAMETERS_RESPONSE,
  SET_CACHED_PARAMETERS_RESPONSE_FOR_SECOND_CHART,
  SET_LOADING_CHART,
  SET_PRELOADING_CHART,
} from './actions';
import { DateRange } from './chart-utils';

const getInitialState = () => {
  return {
    parameters: null,
    charts: [],
    secondChart: [],
    dateRange: DateRange.Day,
    dateRangeForSecondChart: DateRange.Day,
    dateFrom: moment().startOf('day'),
    dateTo: moment(),
    dateFromForSecondChart: moment().startOf('day'),
    dateToForSecondChart: moment(),
    fullScreen: '',
    loading: '',
    preloading: '',
  };
};

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case GET_DEVICES_PARAMETERS_REQUEST:
      return { ...getInitialState(), parameters: null };
    case GET_DEVICES_PARAMETERS_RESPONSE:
      return { ...state, parameters: action.data };
    case SET_CACHED_PARAMETERS_RESPONSE: {
      return {
        ...state,
        parameters: action.data,
        charts: action.chart,
        dateRange: action.dateRange,
        dateFrom: action.dateFrom,
        dateTo: action.dateTo,
      };
    }
    case SET_CACHED_PARAMETERS_RESPONSE_FOR_SECOND_CHART: {
      return {
        ...state,
        parameters: action.data,
        secondChart: action.chart,
        dateRangeForSecondChart: action.dateRange,
        dateFromForSecondChart: action.dateFrom,
        dateToForSecondChart: action.dateTo,
      };
    }
    case SET_PRELOADING_CHART:
      return { ...state, preloading: action.chartNumber };
    case SET_LOADING_CHART:
      return { ...state, loading: action.chartNumber };
    case GET_POINTS_FOR_PARAMETER_RESPONSE:
      return {
        ...state,
        loading: '',
        preloading: '',
        charts: [
          ...state.charts,
          {
            deviceId: action.deviceId,
            device: action.device,
            parameterId: action.parameterId,
            parameter: action.parameter,
            unit: action.unit,
            points: action.points,
            precedingPoint: action.precedingPoint,
            category: action.category,
          },
        ],
      };
    case GET_POINTS_FOR_PARAMETER_RESPONSE_FOR_SECOND_CHART:
      return {
        ...state,
        loading: '',
        preloading: '',
        secondChart: [
          ...state.secondChart,
          {
            deviceId: action.deviceId,
            device: action.device,
            parameterId: action.parameterId,
            parameter: action.parameter,
            unit: action.unit,
            points: action.points,
            precedingPoint: action.precedingPoint,
            category: action.category,
          },
        ],
      };
    case DELETE_CHART:
      return {
        ...state,
        charts: state.charts.filter((c) => c.deviceId !== action.deviceId || c.parameterId !== action.parameterId),
      };
    case DELETE_SECOND_CHART:
      return {
        ...state,
        secondChart: state.secondChart.filter(
          (c) => c.deviceId !== action.deviceId || c.parameterId !== action.parameterId
        ),
      };
    case SET_DATE_RANGE:
      return { ...state, dateRange: action.dateRange, dateFrom: action.dateFrom, dateTo: action.dateTo };
    case SET_DATE_RANGE_FOR_SECOND_CHART:
      return {
        ...state,
        dateRangeForSecondChart: action.dateRange,
        dateFromForSecondChart: action.dateFrom,
        dateToForSecondChart: action.dateTo,
      };
    case FULL_SCREEN:
      return { ...state, fullScreen: action.value };
    default:
      return state;
  }
};
