import React from 'react';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import Cart from './components/Cart';
import OrderHistory from './components/OrderHistory';
import { history } from '../../../store/configureStore';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PaymentSettings from './components/PaymentSettings';
import { USER_SECURITY_LEVELS } from '../SystemProfile/components/SecurityTab/costants';
import { push } from 'connected-react-router';
import * as client from '../../Shop/client';
import queryString from 'query-string';
import Products from './components/Products';
import './store.scss';
import { isStripeSpecificBrand as isStripeBrand } from './components/storeUtils';

const getUserCountryFromProps = (userInfo) => _.get(userInfo, 'address.country.name', '');

const getSelectedSystemCountryFromProps = (selectedSystem) => _.get(selectedSystem, 'address.country.name', '');

const isShopAvailableForCountry = (supportedCountries, userCountryName) =>
  supportedCountries &&
  supportedCountries.loaded &&
  supportedCountries.countries &&
  supportedCountries.countries.includes(userCountryName);

const doesUserHaveRightsToBuy = (selectedSystem) => selectedSystem.role === USER_SECURITY_LEVELS.admin;

class Store extends React.Component {
  constructor(props) {
    super(props);
  }

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    selectedSystem: PropTypes.object.isRequired,
    userInfo: PropTypes.object.isRequired,
    addToCart: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    goToPage: PropTypes.func.isRequired,
    removeFromCart: PropTypes.func.isRequired,
    getEComSupportedCountries: PropTypes.func.isRequired,
    getUserEcomSubscriptions: PropTypes.func.isRequired,
    supportedCountries: PropTypes.object.isRequired,
    userSubscriptions: PropTypes.array.isRequired,
    selectedSystemBrandId: PropTypes.string.isRequired,
    shop: PropTypes.shape({
      products: PropTypes.object.isRequired,
      cart: PropTypes.object.isRequired,
      paymentProviderSettings: PropTypes.object.isRequired,
    }),
    devices: PropTypes.shape({
      subscriptions: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    }),
    paidBrands: PropTypes.shape({
      stripeSpecificPaidBrands: PropTypes.array,
    }),
  };

  state = {
    selectedTab: 'subscriptions',
  };

  async componentDidMount() {
    window.parent.postMessage(
      {
        id: 'order-callback',
        path: window.location.pathname,
      },
      window.location.origin
    );

    window.addEventListener(
      'message',
      function (event) {
        if (
          event.origin === window.location.origin &&
          event.data &&
          event.data.id &&
          event.data.id === 'order-callback'
        ) {
          this.goToPage(event.data.path);
        }

        if (
          event.origin === window.location.origin &&
          event.data &&
          event.data.id &&
          event.data.id === 'order-cancel'
        ) {
          this.goToPage(event.data.path);
        }
      }.bind({ goToPage: (path) => this.props.goToPage(path) })
    );

    const {
      selectedSystem,
      paidBrands: { allPaidBrands },
    } = this.props;
    const { brandId = '' } = selectedSystem || {};
    const { products, supportedCountries } = this.props.shop;
    const isPaidBrand = allPaidBrands.includes(brandId?.toLowerCase());

    if (!supportedCountries || !supportedCountries.loaded) {
      await this.props.getEComSupportedCountries();
    }

    if (selectedSystem && !products.loading && isPaidBrand) {
      await this.props.getUserEcomSubscriptions(selectedSystem.devices[0].id, selectedSystem.brandId);
    }

    if (this.props.match.params.id) {
      this.setState({ ...this.state, selectedTab: 'order-history' });
    }

    if (queryString.parse(this.props.location.search).activeTab === 'payment_settings') {
      this.setState({ ...this.state, selectedTab: 'payment-settings' });
    }
  }

  selectPaymentSettingTab = () => {
    this.setState((current) => ({ ...current, selectedTab: 'payment-settings' }));
  };

  addProductToCart(product, variant) {
    this.props.addToCart(this.props.shop.cart, product, variant);
  }

  removeProductFromCart(item) {
    this.props.removeFromCart(this.props.shop.cart, item);
  }

  selectTab = (tab) => {
    if (this.props.match.params.id) {
      window.location.reload(history.push('/store'));
    }
    this.setState({ ...this.state, selectedTab: tab });
  };

  render() {
    const userCountryName = getUserCountryFromProps(this.props.userInfo).toLowerCase();
    const UNITED_STATES = 'united states';
    const isUserCountryUS = userCountryName?.includes(UNITED_STATES);
    const selectedSystemCountryName = getSelectedSystemCountryFromProps(this.props.selectedSystem).toLowerCase();
    const selectedSystem = this.props.selectedSystem;
    const {
      selectedSystemBrandId = '',
      paidBrands: { stripeSpecificPaidBrands, allPaidBrands },
    } = this.props;
    const { brandId = '' } = selectedSystem || {};
    const isStripeSpecificBrand = isStripeBrand(stripeSpecificPaidBrands, selectedSystemBrandId);
    const isPaidBrand = allPaidBrands.includes(brandId?.toLowerCase());
    const isCLMBrand = stripeSpecificPaidBrands?.includes(brandId.toLowerCase());
    const isAddCardButtonDisabled = isCLMBrand && !isUserCountryUS;
    const getCurrentTab = () => {
      switch (this.state.selectedTab) {
        case 'subscriptions':
          if (!selectedSystem) return null;
          return (
            <Products
              addToCart={(variantId) => this.addProductToCart(this.props.userSubscriptions.subscriptions, variantId)}
              cart={this.props.shop.cart}
              userSubscriptions={this.props.userSubscriptions.subscriptions}
              isloadingSubscriptions={this.props.userSubscriptions.loading}
              hasErrorFetchingSubscription={this.props.userSubscriptions.error}
              selectedSystem={selectedSystem}
              supportedCountries={this.props.supportedCountries}
              isDemo={this.props.userInfo && this.props.userInfo.isDemo}
              hasRightsToBuy={doesUserHaveRightsToBuy(selectedSystem)}
              isShopAvailableForUser={isShopAvailableForCountry(this.props.supportedCountries, userCountryName)}
              isShopAvailableForSystem={isShopAvailableForCountry(
                this.props.supportedCountries,
                selectedSystemCountryName
              )}
              products={{ currencyCode: this.props.shop.cart.currencyCode }}
              stripeSpecificbrandList={stripeSpecificPaidBrands}
              isPaidBrand={isPaidBrand}
            />
          );
        case 'payment-settings':
          return (
            <PaymentSettings
              cart={this.props.shop.cart}
              dispatch={this.props.dispatch}
              currencyCode={this.props.shop.cart.currencyCode}
              isStripeSpecificBrand={isStripeSpecificBrand}
              selectTab={this.selectTab}
              stripeSpecificBrandList={stripeSpecificPaidBrands}
              isAddCardButtonDisabled={isAddCardButtonDisabled}
            />
          );
        case 'order-history':
          return <OrderHistory dispatch={this.props.dispatch} match={this.props.match} />;
        case 'shopping-cart':
          return (
            <Cart
              cart={this.props.shop.cart}
              selectedSystem={this.props.selectedSystem}
              removeProduct={(item) => this.removeProductFromCart(item)}
              dispatch={this.props.dispatch}
              activeTab={queryString.parse(this.props.location.search).activeTab}
              productVariant={queryString.parse(this.props.location.search).variant}
              selectPaymentSettingTab={this.selectPaymentSettingTab}
            />
          );
        default:
          return null;
      }
    };

    if (window.self !== window.top) {
      document.body.style['display'] = 'none';
      return null;
    }

    return (
      <>
        <div className="page-content">
          <div className="store-page">
            <section className="store-section" id="store-section">
              <div
                onClick={() => this.selectTab('subscriptions')}
                className={this.state.selectedTab === 'subscriptions' ? 'tab selected' : 'tab'}
              >
                <FormattedMessage id="shop.page.tab.subscriptions" defaultMessage="Subscriptions" />
              </div>
              {isStripeSpecificBrand && doesUserHaveRightsToBuy(selectedSystem) && (
                <div
                  onClick={() => this.selectTab('payment-settings')}
                  className={this.state.selectedTab === 'payment-settings' ? 'tab selected' : 'tab'}
                >
                  <FormattedMessage id="shop.page.tab.paymentSettings" defaultMessage="Payment Settings" />
                </div>
              )}
              <div
                onClick={() => this.selectTab('order-history')}
                className={this.state.selectedTab === 'order-history' ? 'tab selected' : 'tab'}
              >
                <FormattedMessage id="shop.page.tab.orderHistory" defaultMessage="Order History" />
              </div>
              <div
                onClick={() => this.selectTab('shopping-cart')}
                className={`${this.state.selectedTab === 'shopping-cart' ? 'tab selected' : 'tab'} ${
                  this.props.shop.cart.lineItems.length > 0 ? ' shopping-cart-tab' : ''
                }`}
              >
                <FormattedMessage id="shop.page.tab.shoppingCart" defaultMessage="Shopping Cart" />
                {this.props.shop.cart.lineItems.length > 0 && (
                  <span className="remove-icon icon-danger">{this.props.shop.cart.lineItems.length}</span>
                )}
              </div>
            </section>
            <div className="tab-content">{getCurrentTab()}</div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ app, ...state }) => ({
  selectedSystem: app.selectedSystem,
  shop: state.shop,
  devices: state.devices,
  userInfo: app.userInfo,
  supportedCountries: state.shop.supportedCountries,
  userSubscriptions: state.shop.userSubscriptions,
  selectedSystemBrandId: app.selectedSystem?.brandId,
  paidBrands: state.shop.paidBrands,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  goToPage(path) {
    dispatch(push(path));
  },
  getEComSupportedCountries() {
    dispatch(client.getEComSupportedCountries());
  },
  getUserEcomSubscriptions(deviceId, brandId) {
    dispatch(client.getUserEcomSubscriptions(deviceId, brandId));
  },
  addToCart(cart, product, variant) {
    dispatch(client.addToCart(cart, product, variant));
  },
  removeFromCart(cart, cartItem) {
    dispatch(client.removeFromCart(cart, cartItem));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(Store);
