import {
  GET_TILE_LIST,
  GET_TILE_LIST_SUCCEEDED,
  GET_TILE_LIST_FAILED,
  GET_PREPOPULATION_STATUS,
  GET_PREPOPULATION_STATUS_SUCCEEDED,
  GET_PREPOPULATION_STATUS_FAILED,
  GET_WEATHER_TILE_DATA_REQUEST,
  GET_WEATHER_TILE_DATA_RESPONSE,
  REMOVE_SYSTEM_TILES,
  REMOVE_SYSTEM_TILES_SUCCEEDED,
  REMOVE_SYSTEM_TILES_FAILED,
  REMOVE_TILES_BY_DEVICE,
  REMOVE_TILES_BY_DEVICE_FAILED,
  REMOVE_TILES_BY_DEVICE_SUCCEEDED,
  SET_SYSTEM_AS_PREPOPULATED,
  UNSET_SYSTEM_AS_PREPOPULATED,
  EXPAND_TILE,
  MINIMIZE_TILE,
  MOVE_TILE,
  ADD_TILE,
  REMOVE_TILE,
  EDIT_TILE,
} from './actions';

const initialState = {
  init: false,
  loading: false,
  tiles: [],
  expandedTile: null,
  hasBeenPrepopulated: false,
  weatherData: {},
};

const expandTile = (tiles, expandedTile) => {
  return tiles.map((obj) => (obj.id === expandedTile.id ? { ...obj, expanded: true } : obj));
};

const minimizeTile = (tiles, minimizedTile) => {
  return tiles.map((obj) => (obj.id === minimizedTile.id ? { ...obj, expanded: false } : obj));
};

const moveTile = (tiles, tile, toIndex) => {
  const newTiles = [...tiles];

  const fromIndex = tiles.findIndex((t) => t.id === tile.id);

  newTiles.splice(fromIndex, 1); // removing what you are dragging.
  newTiles.splice(toIndex, 0, tile); // inserting it into hoverIndex.

  return newTiles;
};

const updateTile = (tiles, updatedTile) => {
  return tiles.map((tile) => {
    if (updatedTile.id === tile.id) {
      return { ...tile, ...updatedTile };
    }
    return tile;
  });
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_TILE_LIST:
      return { ...state, loading: true, init: true, expandedTile: null };
    case GET_TILE_LIST_SUCCEEDED:
      return { ...state, loading: false, tiles: action.data };
    case GET_TILE_LIST_FAILED:
      return { ...state, loading: false, tiles: action.data.tiles };
    case REMOVE_SYSTEM_TILES:
      return { ...state, loading: true };
    case REMOVE_SYSTEM_TILES_FAILED:
      return { ...state, loading: false };
    case REMOVE_SYSTEM_TILES_SUCCEEDED:
      return { ...state, loading: false, tiles: [] };
    case REMOVE_TILES_BY_DEVICE:
      return { ...state, loading: true };
    case REMOVE_TILES_BY_DEVICE_FAILED:
      return { ...state, loading: false };
    case REMOVE_TILES_BY_DEVICE_SUCCEEDED:
      return { ...state, loading: false, tiles: action.tiles };
    case GET_PREPOPULATION_STATUS:
      return { ...state, loading: true };
    case GET_PREPOPULATION_STATUS_SUCCEEDED:
      return { ...state, loading: false, hasBeenPrepopulated: action.data };
    case GET_PREPOPULATION_STATUS_FAILED:
      return { ...state, loading: false, hasBeenPrepopulated: action.data };
    case SET_SYSTEM_AS_PREPOPULATED:
      return { ...state, hasBeenPrepopulated: true };
    case UNSET_SYSTEM_AS_PREPOPULATED:
      return { ...state, hasBeenPrepopulated: false };
    case EXPAND_TILE:
      return {
        ...state,
        loading: false,
        tiles: expandTile(state.tiles, action.tile),
        expandedTile: { ...action.tile, expanded: true },
      };
    case MINIMIZE_TILE:
      return { ...state, loading: false, tiles: minimizeTile(state.tiles, action.tile), expandedTile: null };
    case MOVE_TILE:
      return { ...state, loading: false, tiles: moveTile(state.tiles, action.tile, action.toIndex) };
    case ADD_TILE:
      return { ...state, loading: false, tiles: [...state.tiles, action.tile] };
    case REMOVE_TILE:
      return { ...state, loading: false, tiles: state.tiles.filter((t) => t.id != action.tile.id) };
    case EDIT_TILE:
      return { ...state, loading: false, tiles: updateTile(state.tiles, action.tile) };
    case GET_WEATHER_TILE_DATA_RESPONSE:
      return { ...state, weatherData: action.weatherData };
    case GET_WEATHER_TILE_DATA_REQUEST:
    default:
      return state;
  }
};
