import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../layout/Modal';
import { Button } from 'jpi-cloud-web-ui-components';
import { FormattedMessage, injectIntl } from 'react-intl';
import { formatErrorMessage } from '../../../../localization/message-formatting';

const errorMessages = {
  unknown: {
    id: 'migration.error.unknown',
    defaultMessage: 'An error has occurred. Try again later.',
  },
};

const MigrationConfirmation = ({ isShown, onDiscard, onConfirm, requestError, intl }) => {
  return (
    <Modal show={isShown} backdrop="static" onHide={onDiscard}>
      <div className="migration-configuration-popup">
        <h2 className="text-left">
          <FormattedMessage
            id="registration.migrateAlert.message"
            defaultMessage="We have noticed that you already have an account which can be migrated from NIBE Uplink. Please log in using your NIBE Uplink credentials to start the migration."
          />
        </h2>
        <h4>
          {requestError && (
            <p className="text-danger">
              {formatErrorMessage(intl, errorMessages, requestError)}({requestError})
            </p>
          )}
        </h4>
        <div className="popup-bottom-panel button-wrapper">
          <Button type="button" onClick={onDiscard} className="button--default">
            <FormattedMessage id="migrations.confirmation.buttons.abort" defaultMessage="Abort" />
          </Button>
          <Button type="button" onClick={onConfirm} className="button--secondary">
            <FormattedMessage id="migrations.confirmation.buttons.proceed" defaultMessage="Proceed" />
          </Button>
        </div>
      </div>
    </Modal>
  );
};

MigrationConfirmation.propTypes = {
  isShown: PropTypes.bool,
  onDiscard: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  requestError: PropTypes.object,
  intl: PropTypes.object,
};

export default injectIntl(MigrationConfirmation);
