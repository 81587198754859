import indoorClimate from './indoor-climate.svg';
import hotWater from './hotWater.svg';
import weather from './weather.svg';
import status from './status.svg';
import ventilation from './ventilation.svg';
import pool from './pool.svg';
import solar from './solar.svg';
// AIT is the parent company of CTC
import ait from '../ait';

export default {
  ...ait,
  indoorClimate,
  hotWater,
  weather,
  status,
  ventilation,
  pool,
  solar,
};
