import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Button, Spinner } from 'jpi-cloud-web-ui-components';

import ConfirmPendingMessage from '../ConfirmPendingMessage/ConfirmPendingMessage';

import Link from '../../../../../../inputs/Link';
import {
  setSelectedDevice,
  getDevices,
  updateDeviceFirmware,
  updateStatus,
  hideConfirmOnDevice,
} from '../../../../actions';
import downloadCloud from '../../../../../../../assets/img/download-cloud.svg';
import { USER_SECURITY_LEVELS } from '../../../../../../pages/SystemProfile/components/SecurityTab/costants';
import PremiumFeatureTooltip from '../../../../../../layout/PremiumFeatureTooltip';
import ReleaseNotesLink from '../../../../../../ReleaseNotesLink';

import './requestUpdate.scss';

class RequestUpdate extends React.PureComponent {
  static propTypes = {
    selectedDevice: PropTypes.object.isRequired,
    updateInfo: PropTypes.object,
    setSelectedDevice: PropTypes.func.isRequired,
    updateDeviceFirmware: PropTypes.func.isRequired,
    fwUpdateInitializationStatus: PropTypes.string,
    selectedSystem: PropTypes.object,
    getDevices: PropTypes.func.isRequired,
    status: PropTypes.string.isRequired,
    hideConfirmOnDevice: PropTypes.func.isRequired,
    isConfirmOnDeviceShow: PropTypes.bool.isRequired,
  };

  state = {
    showConfirmModal: undefined,
    isLoading: false,
  };

  componentDidUpdate() {
    const { status } = this.props;

    if (
      (status === 'confirmPending' || status === 'updateConfirm') &&
      !(updateStatus.error === this.props.fwUpdateInitializationStatus)
    ) {
      this.setState({
        showConfirmModal: true,
      });
    }
  }

  toggleModal = () => {
    this.setState({
      showConfirmModal: false,
      isLoading: true,
    });

    this.props.hideConfirmOnDevice();
  };

  onCancelHandler = async () => {
    await this.props.getDevices(this.props.selectedSystem.id);
    this.props.setSelectedDevice();
  };

  render() {
    const {
      selectedDevice: device,
      updateInfo,
      selectedDevice,
      fwUpdateInitializationStatus,
      isConfirmOnDeviceShow,
    } = this.props;

    const updateInfoStatus = updateInfo || selectedDevice.firmware;

    const firmwareInfo = device.id
      ? updateInfo
      : updateInfo.slaves && updateInfo.slaves.find((slave) => slave.slaveDeviceId === device.slaveDeviceId);

    const isUpdateDisabled =
      this.props.selectedSystem &&
      (this.props.selectedSystem.userRole === USER_SECURITY_LEVELS.viewer ||
        this.props.selectedSystem.userRole === USER_SECURITY_LEVELS.manager);

    return (
      <>
        {this.state.isLoading ? (
          <Spinner dark />
        ) : (
          <div className="update-available">
            <div className="row">
              <div className="col-md-8">
                <div className="device-name">
                  {device.name && device.name.length ? device.name : device && device.productName}
                </div>
              </div>
              {firmwareInfo && (
                <div className={`col-md-4 download-link ${isUpdateDisabled ? 'disable-link' : ''}`}>
                  <Link
                    keyFor={firmwareInfo.fwPackageUri}
                    to={firmwareInfo.fwPackageUri}
                    className={isUpdateDisabled ? 'disable-download-link' : 'download-link'}
                  >
                    <FormattedMessage id={'devices.download-to-computer'} defaultMessage="Download to computer" />
                    &nbsp;
                    <img className="icon" src={downloadCloud} />
                  </Link>
                </div>
              )}
              {isUpdateDisabled ? <PremiumFeatureTooltip translationId="premiumFeatures.permissionAccess" /> : null}
            </div>
            <div className="row">
              <div className="col-md-12 update-info">
                <div className="confirm-message">
                  <FormattedMessage id={'devices.are-you-sure'} defaultMessage="Are you sure you want to update?" />
                </div>
                {updateInfoStatus ? (
                  <div className="version">
                    <FormattedMessage id={'devices.version'} defaultMessage="Version" /> :
                    {(device.id && updateInfoStatus.desiredFwVersion) ||
                      (device.slaveDeviceId &&
                        updateInfoStatus.slaves.find((s) => {
                          return s.slaveDeviceId === device.slaveDeviceId;
                        }).desiredFwVersion)}
                    &nbsp;
                    <ReleaseNotesLink
                      firmwareTypeId={device?.firmwareId || device?.firmware?.firmwareId}
                      className="release-link"
                    />
                  </div>
                ) : null}
                <div className="small-text">
                  <FormattedMessage
                    id={'devices.update-will-take-few-minutes'}
                    defaultMessage="Update will take few minutes."
                  />
                </div>
                <div className="small-text">
                  <FormattedMessage
                    id={'devices.recommendation-message'}
                    defaultMessage="We always recommend that you are in the facility/building of the product to ensure that the update works correctly."
                  />
                </div>
              </div>
              {updateStatus.error === fwUpdateInitializationStatus && (
                <div className="error-message text-center">
                  <FormattedMessage
                    id="devices.something-went-wrong"
                    defaultMessage="Something went wrong! Failed to update firmware."
                  />
                </div>
              )}
            </div>
            <div className="button-wrapper">
              <Button className="button button--default" onClick={this.onCancelHandler} type="button">
                <FormattedMessage id="devices.cancel" defaultMessage="Cancel" />
              </Button>

              {
                <Button
                  type="button"
                  className="button button--secondary"
                  onClick={() => {
                    this.props.updateDeviceFirmware(device.id || device.gatewayDeviceId, device.slaveDeviceId || '');
                  }}
                  disabled={
                    updateStatus.inProgress === fwUpdateInitializationStatus ||
                    (this.props.selectedSystem &&
                      (this.props.selectedSystem.userRole === USER_SECURITY_LEVELS.viewer ||
                        this.props.selectedSystem.userRole === USER_SECURITY_LEVELS.manager))
                  }
                >
                  <FormattedMessage id="devices.update" defaultMessage="Update" />
                </Button>
              }
              {isUpdateDisabled ? <PremiumFeatureTooltip translationId="premiumFeatures.permissionAccess" /> : null}
            </div>
            {isConfirmOnDeviceShow && (
              <ConfirmPendingMessage showConfirmModal={this.state.showConfirmModal} toggleModal={this.toggleModal} />
            )}
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = ({ app: { selectedSystem }, systemSettings, devices }) => ({
  ...systemSettings,
  ...devices,
  selectedSystem,
});

const mapDispatchToProps = {
  setSelectedDevice,
  getDevices,
  updateDeviceFirmware,
  hideConfirmOnDevice,
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestUpdate);
