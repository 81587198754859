import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Dropdown from '../../../../inputs/Dropdown';

import moment from 'moment';
import './timePicker.scss';

class TimePicker extends React.PureComponent {
  static propTypes = {
    time: PropTypes.string.isRequired,
    onTimeChange: PropTypes.func.isRequired,
    isAmPmHours: PropTypes.bool,
    disabled: PropTypes.bool,
    step: PropTypes.number,
  };

  formatHour = (hourStr, isAmPmHours) => {
    if (isAmPmHours) {
      const hour = parseInt(hourStr);
      const pmShift = hour <= 11 ? 0 : 12;
      const suffix = pmShift ? 'PM' : 'AM';
      const normalizedHour = hour % 12 !== 0 ? hour - pmShift : 12;

      return `${normalizedHour < 10 ? '0' : ''}${normalizedHour} ${suffix}`;
    }

    return `${hourStr}`;
  };

  onHoursChange = (value) => {
    const currentTime = moment(this.props.time, 'HH:mm');
    const time = `${value}:${currentTime.format('mm')}:${currentTime.format('ss')}`;

    this.props.onTimeChange(time);
  };

  onMinutesChange = (value) => {
    const currentTime = moment(this.props.time, 'HH:mm');
    const time = `${currentTime.format('HH')}:${value}:${currentTime.format('ss')}`;

    this.props.onTimeChange(time);
  };

  getMinutes = (step) => {
    return Array.apply(null, Array(60 / step)).map((_, i) => (i * step < 10 ? `0${i * step}` : `${i * step}`));
  };

  getHours = () => {
    return Array.apply(null, Array(24)).map((_, i) => (i < 10 ? `0${i}` : `${i}`));
  };

  render() {
    const { isAmPmHours, disabled, step = 15 } = this.props;
    const minutes = this.getMinutes(step);
    const hours = this.getHours();
    const parsedTime = moment(this.props.time, 'HH:mm');

    return (
      <Fragment>
        <Dropdown
          id="schedule-hours"
          className="timepicker-hours"
          disabled={disabled}
          tooltipGetter={(item) => item}
          items={hours}
          selectedItem={parsedTime.format('HH')}
          onSelect={(value) => this.onHoursChange(value)}
          labelGetter={(value) => this.formatHour(value, isAmPmHours)}
        />
        <Dropdown
          id="schedule-minutes"
          disabled={disabled}
          tooltipGetter={(item) => item}
          items={minutes}
          selectedItem={parsedTime.format('mm')}
          onSelect={(value) => this.onMinutesChange(value)}
          labelGetter={(e) => e}
        />
      </Fragment>
    );
  }
}

export default TimePicker;
