import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import * as locales from 'react-date-range/dist/locale';
import { Calendar } from 'react-date-range';
import classNames from 'classnames';
import moment from 'moment';

import { Button, Spinner } from 'jpi-cloud-web-ui-components';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import PremiumFeatureTooltip from '../../../../components/layout/PremiumFeatureTooltip';

import VacationPopup from './VacationPopup';

import { getScheduleConfig, getVacationSchedules, setVacationSchedules } from '../actions';
import { isWeekStartsOnSunday } from '../../../../api/countries';
import { weekdayFormat } from './VacationPopup/config';

import './vacation-schedule.scss';

// TODO: Add TS
function VacationSchedule(vacationProps) {
  const {
    devices,
    selectedLanguage,
    vacationSchedules,
    scheduleConfig,
    userIsViewer,
    getVacationSchedules,
    userCountryCode,
  } = vacationProps;

  const [loading, setLoading] = useState(false);
  const [vacationPopupVisible, setVacationPopupVisible] = useState(false);
  const [editableVacation, setEditableVacation] = useState(null);

  const initialize = async () => {
    setLoading(true);

    const [mainDevice = {}] = devices;
    await getVacationSchedules(mainDevice.id);

    setLoading(false);
  };

  const handleDateClick = (date) => {
    const d = moment(date);
    const selectedVacation = vacationSchedules.find(
      (sv) => d.isSameOrAfter(moment(sv.start), 'day') && d.isSameOrBefore(moment(sv.end), 'day')
    );

    if (!selectedVacation) return;

    setEditableVacation(selectedVacation);
    setVacationPopupVisible(true);
  };

  const handleClosePopup = () => {
    setVacationPopupVisible(false);
    setEditableVacation(null);
  };

  const vacationDayRender = (_) => {
    const [match] = ranges.filter(({ startDate, endDate }) =>
      moment(_).isBetween(moment(startDate).add(-1, 'day'), moment(endDate).endOf('day'))
    );

    const dateDay = (
      <span className={match && !match.enabled ? 'vacation-day-disabled' : 'vacation-day'}>{_.getDate()}</span>
    );

    if (!match?.name) return dateDay;

    return (
      <OverlayTrigger
        trigger={['hover', 'focus']}
        placement="top"
        overlay={
          <Popover id="timeslot">
            <p>{match.name}</p>
          </Popover>
        }
      >
        {dateDay}
      </OverlayTrigger>
    );
  };

  useEffect(() => {
    initialize();
  }, []);

  const ranges =
    vacationSchedules.length === 0
      ? [
          {
            enabled: true,
            startDate: new Date(),
            endDate: new Date(),
            key: 'placeholder',
          },
        ]
      : vacationSchedules.map((vs, i) => ({
          key: `vacation#${i}`,
          startDate: new Date(vs.start),
          endDate: new Date(vs.end),
          enabled: vs.isEnabled,
          name: vs.name,
        }));

  const locale = Object.keys(locales).find((_) => _.includes(selectedLanguage));
  const weekStartsOnIndex = isWeekStartsOnSunday(userCountryCode) ? 0 : 1;

  return (
    <div className="vacation-schedule">
      {loading && (
        <div role="progressbar">
          <Spinner dark />
        </div>
      )}
      {!loading && (
        <Fragment>
          <div>
            <h4>
              <FormattedMessage id="vacation.title" defaultMessage="Vacation" />
            </h4>
            <p>
              <FormattedMessage
                id="vacation.subtitle"
                defaultMessage="Select a planned vacation from the calendar to edit or use add to plan a new vacation"
              />
            </p>
          </div>
          <div className="dateRangeWrapper">
            <div
              className={classNames('icon', {
                disabled: userIsViewer || vacationSchedules.length >= scheduleConfig.maxVacationsNumber,
              })}
            >
              <Button
                type="button"
                className="button--round"
                onClick={() => setVacationPopupVisible(true)}
                disabled={userIsViewer || vacationSchedules.length >= scheduleConfig.maxVacationsNumber}
              >
                +
              </Button>
              <span
                className={classNames(
                  'icon-text icon-text-brand',
                  (userIsViewer || vacationSchedules.length >= scheduleConfig.maxVacationsNumber) && 'disabled'
                )}
              >
                <FormattedMessage id="button.add" defaultMessage="Add" />
              </span>
              {userIsViewer && <PremiumFeatureTooltip premiumFeatureType="permissionAccess" />}
              {!userIsViewer && vacationSchedules.length >= scheduleConfig.maxVacationsNumber && (
                <PremiumFeatureTooltip translationId="scheduling.edit-vacation.maxNumVacations" />
              )}
            </div>
            <div aria-label="Calendar">
              <Calendar
                months={1}
                ranges={ranges}
                shownDate={new Date()}
                displayMode="dateRange"
                direction="vertical"
                scroll={{ enabled: false }}
                locale={locales[locale] || locales.enUS}
                weekdayDisplayFormat={weekdayFormat}
                weekStartsOn={weekStartsOnIndex}
                onChange={handleDateClick}
                dayContentRenderer={vacationDayRender}
                showMonthAndYearPickers
              />
            </div>
          </div>
          {!userIsViewer && vacationPopupVisible && (
            <VacationPopup
              isPopupOpen={vacationPopupVisible}
              editableVacation={editableVacation || undefined}
              weekStartsOnIndex={weekStartsOnIndex}
              closePopup={handleClosePopup}
              {...vacationProps}
            />
          )}
        </Fragment>
      )}
    </div>
  );
}

VacationSchedule.propTypes = {
  devices: PropTypes.array.isRequired,
  vacationSchedules: PropTypes.array.isRequired,
  scheduleConfig: PropTypes.object.isRequired,
  selectedLanguage: PropTypes.string.isRequired,
  userIsViewer: PropTypes.bool.isRequired,
  selectedSystem: PropTypes.object,
  setVacationSchedules: PropTypes.func,
  getVacationSchedules: PropTypes.func,
  getScheduleConfig: PropTypes.func,
  modes: PropTypes.array,
};

export const stateProps = ({
  app: { selectedSystem, userInfo },
  language: { selectedLanguage },
  deviceScheduling: { modes, vacationSchedules, scheduleConfig },
  devices: { devices },
}) => ({
  scheduleConfig,
  selectedSystem,
  vacationSchedules,
  selectedLanguage,
  devices,
  modes,
  userCountryCode: userInfo?.address?.country?.countryCode,
});

export const actionProps = {
  setVacationSchedules,
  getVacationSchedules,
  getScheduleConfig,
};

export default connect(stateProps, actionProps)(VacationSchedule);
