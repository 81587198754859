import {
  USER_AUTH_STATUS_CHANGED,
  GET_USER_INFO_RESPONSE,
  GET_USER_SYSTEMS_RESPONSE,
  SELECT_SYSTEM,
  GET_COUNTRIES_RESPONSE,
  GET_LATEST_AGREEMENTS_VERSIONS_RESPONSE,
  GET_CLOUD_STATUS_RESPONSE,
  DISCONNECT_SYSTEM_RESPONSE_SUCCEEDED,
  GET_LAST_USED_SYSTEM_RESPONSE,
  SET_APP_LOADED_STATUS,
  SET_APP_LOADING_STATUS,
  SET_USER_DATA_STATE,
  GET_LAST_USED_LANGUAGE_RESPONSE,
  RENAME_SYSTEM_REQUEST,
  SET_LANGUAGES,
  SET_DEVICE_UPDATE_AVAILABLE,
  GET_USER_SYSTEMS_REQUEST,
} from './actions';
import {
  UPDATE_SYSTEM_FOR_EMAIL_NOTIFICATIONS_RESPONSE_SUCCEDED,
  UPDATE_SYSTEM_FOR_PUSH_NOTIFICATIONS_RESPONSE_SUCCEDED,
} from '../pages/ProfilePage/components/NotificationSettingsTab/actions';
import { ISSUE_TOKEN_RESPONSE, LOGOUT_USER } from '../pages/Login/actions';
import { CHANGE_EMAIL_RESPONSE_SUCCEDED } from '../pages/ProfilePage/actions';
import { CHANGE_USER_PROFILE_RESPONSE_SUCCEDED } from '../pages/ProfilePage/components/ProfileTab/actions';
import { CHANGE_ADDRESS_RESPONSE_SUCCEDED } from '../pages/ProfilePage/components/AddressTab/actions';
import { UserDataState } from './user-data-state';
import {
  CHANGE_SYSTEM_ADDRESS_RESPONSE_SUCCEDED,
  CREATE_SYSTEM_ADDRESS_RESPONSE_SUCCEEDED,
} from '../pages/SystemProfile/components/AddressTab/actions';
import { GET_STATUS_REQUEST_SUCCEEDED } from '../layout/SystemStatus/actions';

const initialState = {
  userLoggedIn: false,
  userInfo: null,
  url: {},
  countries: [],
  latestAgreements: [],
  status: {},
  systemsLoaded: false,
  systems: [],
  selectedSystem: null,
  lastUsedLanguage: 'en-US',
  appLoaded: false,
  appLoading: false,
  userDataState: UserDataState.EMPTY,
  allowRedirect: true,
  deviceUpdateAvailable: false,
};

const removeSystem = (state = initialState, system) => {
  const systems = state.systems.filter((s) => s.id !== system.id);

  const isSystemSelected = state.selectedSystem && state.selectedSystem.id === system.id;
  const selectedSystem = isSystemSelected ? systems[0] || null : state.selectedSystem;

  return { ...state, systems, selectedSystem };
};

const renameSystem = (state = initialState, system, name) => {
  const systems = state.systems.map((s) => {
    if (s.id === system.id) {
      return { ...s, name };
    }
    return s;
  });

  if (state.selectedSystem && state.selectedSystem.id === system.id) {
    const selectedSystem = { ...state.selectedSystem, name };
    return { ...state, systems, selectedSystem };
  }

  return { ...state, systems };
};

const changeSelectedSystemAddress = (state = initialState, address) => {
  if (!(state.selectedSystem && address && address.id)) {
    return state;
  }

  const addressId = address.id;
  const selectedSystem = { ...state.selectedSystem, addressId, address };

  const systems = state.systems.map((s) => {
    if (s.id === selectedSystem.id) {
      return selectedSystem;
    }
    return s;
  });

  return { ...state, selectedSystem, systems };
};

const setSystemStatus = (state = initialState, toUpdate) => {
  const systems = state.systems.map((system) =>
    system.id === toUpdate.id ? { ...system, online: toUpdate.online } : system
  );

  if (state.selectedSystem && state.selectedSystem.id === toUpdate.id) {
    const selectedSystem = { ...state.selectedSystem, online: toUpdate.online };
    return { ...state, systems, selectedSystem };
  }

  return { ...state, systems };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_SYSTEMS_REQUEST:
      return { ...state, systemsLoaded: false };
    case GET_USER_SYSTEMS_RESPONSE:
      return { ...state, systemsLoaded: true, systems: action.systems };
    case SELECT_SYSTEM:
      return { ...state, selectedSystem: action.system };
    case DISCONNECT_SYSTEM_RESPONSE_SUCCEEDED:
      return removeSystem(state, action.system);
    case GET_USER_INFO_RESPONSE:
      return {
        ...state,
        userInfo: {
          ...action.userInfo,
          spSubscription: action.userInfo.spSubscription && action.userInfo.spSubscription.toLowerCase() === 'true',
        },
      };
    case USER_AUTH_STATUS_CHANGED:
      return { ...state, userLoggedIn: action.loggedIn, url: action.url };
    case GET_COUNTRIES_RESPONSE:
      return { ...state, countries: action.countries };
    case GET_CLOUD_STATUS_RESPONSE:
      return { ...state, status: action.status };
    case GET_LATEST_AGREEMENTS_VERSIONS_RESPONSE:
      return { ...state, latestAgreements: action.agreements };
    case ISSUE_TOKEN_RESPONSE:
      return { ...state, userLoggedIn: true };
    case LOGOUT_USER:
      return {
        ...state,
        userInfo: null,
        userLoggedIn: false,
        systemsLoaded: false,
        systems: [],
        selectedSystem: null,
        userDataState: UserDataState.EMPTY,
        allowRedirect: Boolean(action.allowRedirect),
        lastUsedSystemId: null,
        lastUsedSystemBrand: null,
      };
    case CHANGE_EMAIL_RESPONSE_SUCCEDED:
      return { ...state, userInfo: { ...state.userInfo, email: action.email } };
    case CHANGE_USER_PROFILE_RESPONSE_SUCCEDED:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          fullName: action.userProfile.fullName,
          tzId: action.userProfile.tzId,
          unitSystem: action.userProfile.unitSystem,
        },
      };
    case CHANGE_ADDRESS_RESPONSE_SUCCEDED:
      return { ...state, userInfo: { ...state.userInfo, address: action.address } };
    case GET_LAST_USED_SYSTEM_RESPONSE:
      return { ...state, lastUsedSystemId: action.lastUsedSystemId, lastUsedSystemBrand: action.lastUsedSystemBrand };
    case GET_LAST_USED_LANGUAGE_RESPONSE:
      return { ...state, lastUsedLanguage: action.lastUsedLanguage };
    case SET_APP_LOADED_STATUS:
      return { ...state, appLoaded: action.status };
    case SET_APP_LOADING_STATUS:
      return { ...state, appLoading: action.status };
    case SET_USER_DATA_STATE:
      return { ...state, userDataState: action.state };
    case RENAME_SYSTEM_REQUEST:
      return renameSystem(state, action.system, action.name);
    case CHANGE_SYSTEM_ADDRESS_RESPONSE_SUCCEDED:
    case CREATE_SYSTEM_ADDRESS_RESPONSE_SUCCEEDED:
      return changeSelectedSystemAddress(state, action.address);
    case SET_LANGUAGES:
      return { ...state, languages: action.languages };
    case SET_DEVICE_UPDATE_AVAILABLE:
      return { ...state, deviceUpdateAvailable: action.status };
    case GET_STATUS_REQUEST_SUCCEEDED:
      return setSystemStatus(state, action.data);
    case UPDATE_SYSTEM_FOR_EMAIL_NOTIFICATIONS_RESPONSE_SUCCEDED:
      return { ...state, systems: action.systems };
    case UPDATE_SYSTEM_FOR_PUSH_NOTIFICATIONS_RESPONSE_SUCCEDED:
      return { ...state, systems: action.systems };

    default:
      return state;
  }
};
