import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { languageSelected, setLastUsedLanguage } from '../../layout/LanguageSelector/actions';
import { getUserInfo } from '../../AppInitializer/actions';
import './base-legal-page.scss';

const ExistingLanguages = ['sv', 'en', 'de']; /// TODO: Add new languages if appear

class BaseLegalPage extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    content: PropTypes.object.isRequired,
    selectedLanguage: PropTypes.string,
    userInfo: PropTypes.object,
    userLoggedIn: PropTypes.bool,
    languageSelected: PropTypes.func.isRequired,
    setLastUsedLanguage: PropTypes.func.isRequired,
    getUserInfo: PropTypes.func.isRequired,
  };

  async componentDidMount() {
    if (this.props.userLoggedIn && !this.props.userInfo) {
      await this.props.getUserInfo();
    }

    const language = this.props.match.params.locale
      ? this.props.match.params.locale.substring(0, 2).toLowerCase()
      : 'en';
    const requestedLanguage = ExistingLanguages.find((e) => e == language) || 'en';
    const selectedLanguage = this.props.selectedLanguage;

    if (requestedLanguage != selectedLanguage) {
      this.props.languageSelected(requestedLanguage, this.props.userInfo || { id: null });
      this.props.setLastUsedLanguage(requestedLanguage);
    }
  }

  render() {
    return <div className="legal-container">{this.props.content}</div>;
  }
}

export default connect(
  ({ language: { selectedLanguage }, app: { userInfo, userLoggedIn } }) => ({
    selectedLanguage,
    userInfo,
    userLoggedIn,
  }),
  {
    languageSelected,
    setLastUsedLanguage,
    getUserInfo,
  }
)(BaseLegalPage);
