import { shape, string, number, arrayOf, bool } from 'prop-types';

export const weeklySchedulesType = arrayOf(
  shape({
    events: arrayOf(
      shape({
        enabled: bool,
        modeId: number,
        phantom_id: string,
        startDay: string,
        startTime: string,
        stopDay: string,
        stopTime: string,
      })
    ),
    weekFormat: string,
    weeklyScheduleId: number,
  })
);
