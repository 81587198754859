import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Input } from 'jpi-cloud-web-ui-components';
import { formatErrorMessage } from '../../../../../localization/message-formatting';

const errorMessages = {
  'new-email.email': {
    id: 'profile.email.error.validation.new-email.email',
    defaultMessage: 'New E-Mail is not valid',
  },
  'new-email.required': {
    id: 'profile.email.error.validation.new-email.required',
    defaultMessage: 'New E-Mail is mandatory field',
  },
  'password.required': {
    id: 'password.error.validation.required',
    defaultMessage: 'Password is mandatory field',
  },
  password_wrong: {
    id: 'profile.email.error.request.password.invalid',
    defaultMessage: 'Supplied password is not correct',
  },
  email_already_taken: {
    id: 'profile.email.error.request.email.taken',
    defaultMessage: 'The E-Mail address is already in use. Please try another E-Mail address',
  },
};

const EditEmailForm = (props) => {
  return (
    <>
      <div className="address-row">
        <FormattedMessage id="label.currentEmail" defaultMessage="Current Email">
          {(placeholder) => (
            <Input
              disabled
              placeholder={placeholder}
              name="currentEmail"
              type="text"
              value={props.values.oldEmail}
              defaultValue={props.values.oldEmail}
            />
          )}
        </FormattedMessage>
        <Input
          placeholder={
            props.isPendingNewEmail
              ? props.intl.formatMessage({ id: 'label.pendingNewEmail' })
              : props.intl.formatMessage({ id: 'label.newEmail' })
          }
          type="text"
          name="newEmail"
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          value={props.values.newEmail}
          error={
            props.errors.newEmail &&
            props.touched.newEmail &&
            formatErrorMessage(props.intl, errorMessages, props.errors.newEmail)
          }
        />
      </div>
    </>
  );
};

EditEmailForm.propTypes = {
  intl: PropTypes.object,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  isPendingNewEmail: PropTypes.bool.isRequired,
};

export default injectIntl(EditEmailForm);
