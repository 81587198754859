import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as yup from 'yup';
import { Button, Input } from 'jpi-cloud-web-ui-components';
import './profile.scss';
import { formatErrorMessage } from '../../../../../localization/message-formatting';

const systemNameMax = 64;

const schema = yup.object().shape({
  newName: yup.string().max(systemNameMax, 'newname.max').required('newname.required'),
});

const errorMessages = {
  'newname.required': {
    id: 'system-profile.profile.error.validation.name.required',
    defaultMessage: 'Name cannot be empty',
  },
  'newname.max': {
    id: 'system-profile.profile.error.validation.name.max',
    defaultMessage: `Name cannot be longer than ${systemNameMax} characters`,
  },
  system_not_found: {
    id: 'system-profile.profile.error.request.system.not-found',
    defaultMessage: 'System not found.',
  },
  rename_system_forbidden: {
    id: 'system-profile.profile.error.request.system.forbidden',
    defaultMessage: 'You don\'t have permissions to rename system',
  },
  unknown: {
    id: 'generic.error.request.unknown',
    defaultMessage: 'An error has occurred. Try again later.',
  },
};

const EditProfileForm = ({ selectedSystem, onSubmit, onCancel, requestError, intl }) => {
  return (
    <Formik initialValues={{ oldName: selectedSystem.name, newName: '' }} validationSchema={schema} onSubmit={onSubmit}>
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <div>
          <form onSubmit={handleSubmit} className="form--half">
            <FormattedMessage id="label.oldSystemName" defaultMessage="Old system name">
              {(placeholder) => (
                <Input
                  disabled
                  placeholder={placeholder}
                  name="oldName"
                  type="text"
                  value={values.oldName}
                  defaultValue={values.oldName}
                />
              )}
            </FormattedMessage>
            <FormattedMessage id="label.newSystemName" defaultMessage="New system name">
              {(placeholder) => (
                <Input
                  placeholder={placeholder}
                  type="text"
                  name="newName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.newName}
                  error={errors.newName && touched.newName && formatErrorMessage(intl, errorMessages, errors.newName)}
                />
              )}
            </FormattedMessage>
            {requestError && (
              <p className="text-danger">
                {formatErrorMessage(intl, errorMessages, requestError) ||
                  formatErrorMessage(intl, errorMessages, 'unknown')}
              </p>
            )}
            <div className="button-wrapper">
              <Button className="button--secondary" type="submit" disabled={isSubmitting}>
                <FormattedMessage id="button.save" defaultMessage="Save" />
              </Button>
              <Button className="button--default" type="button" onClick={onCancel} disabled={isSubmitting}>
                <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
              </Button>
            </div>
          </form>
        </div>
      )}
    </Formik>
  );
};

EditProfileForm.propTypes = {
  selectedSystem: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  requestError: PropTypes.string,
  intl: PropTypes.object,
};

export default injectIntl(EditProfileForm);
