import {
  ISSUE_TOKEN_REQUEST,
  ISSUE_TOKEN_RESPONSE,
  VALIDATE_CREDENTIALS_REQUEST,
  VALIDATE_CREDENTIALS_RESPONSE_SUCCEEDED,
  SET_UP_AGREEMENTS_RESPONSE_FAILED,
  SET_UP_AGREEMENTS_RESPONSE_SUCCEEDED,
  VALIDATE_OLD_USER_CREDENTIALS_RESPONSE_SUCCEEDED,
  MIGRATE_OLD_USER_RESPONSE_FAILED,
  MIGRATE_OLD_USER_RESPONSE_SUCCEEDED,
  LOGIN_FAILED,
  SET_UP_AGREEMENTS_REQUEST,
  VALIDATE_OLD_USER_CREDENTIALS_REQUEST,
  MIGRATE_OLD_USER_REQUEST,
} from './actions';

const initialState = {
  loading: false,
  error: null,
  acceptedAgreements: null,
  modalError: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ISSUE_TOKEN_REQUEST:
      return { ...state, loading: true };
    case ISSUE_TOKEN_RESPONSE:
      return { ...state, loading: false, modalError: null };
    case VALIDATE_CREDENTIALS_REQUEST:
      return { ...state, loading: true, error: null };
    case VALIDATE_CREDENTIALS_RESPONSE_SUCCEEDED:
      return { ...state, loading: false, acceptedAgreements: action.data, modalError: null };
    case SET_UP_AGREEMENTS_REQUEST:
      return { ...state, loading: true };
    case SET_UP_AGREEMENTS_RESPONSE_FAILED:
      return { ...state, loading: false, modalError: action.error };
    case SET_UP_AGREEMENTS_RESPONSE_SUCCEEDED:
      return { ...state, loading: false, modalError: null };
    case VALIDATE_OLD_USER_CREDENTIALS_REQUEST:
      return { ...state, loading: true };
    case VALIDATE_OLD_USER_CREDENTIALS_RESPONSE_SUCCEEDED:
      return { ...state, loading: false, oldUserId: action.data && action.data.id };
    case MIGRATE_OLD_USER_REQUEST:
      return { ...state, loading: true };
    case MIGRATE_OLD_USER_RESPONSE_FAILED:
      return { ...state, loading: false, modalError: action.error };
    case MIGRATE_OLD_USER_RESPONSE_SUCCEEDED:
      return { ...state, loading: false, modalError: null, oldUserId: null };
    case LOGIN_FAILED:
      return { ...state, loading: false, error: action.error };
    default:
      return state;
  }
};
