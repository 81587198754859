import PropTypes from 'prop-types';
import React from 'react';

const AccessoryList = ({ list }) => {
  return (
    <div className="list">
      <div className="accessories">
        {list.map((a) => (
          <div key={a.id} className="accessory-item">
            {a.name}
          </div>
        ))}
      </div>
    </div>
  );
};

AccessoryList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default AccessoryList;
