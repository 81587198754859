import React, { useState } from 'react';
import Modal from '../../../../layout/Modal';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Button, Input } from 'jpi-cloud-web-ui-components';
import { formatErrorMessage } from '../../../../../localization/message-formatting';

import './passwordvalidationform.scss';

const PasswordValidationForm = (props) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [isPasswordEmpty, setPasswordError] = useState('');
  const [showError, setShowError] = useState(false);

  const errorMessages = {
    'password.required': {
      id: 'password.error.validation.required',
      defaultMessage: 'Password is mandatory field',
    },
    unknown: {
      id: 'generic.error.request.unknown',
      defaultMessage: 'An error has occurred. Try again later.',
    },
    password_wrong: {
      id: 'profile.email.error.request.password.invalid',
      defaultMessage: 'Supplied password is not correct',
    },
    email_already_taken: {
      id: 'profile.email.error.request.email.taken',
      defaultMessage: 'The E-Mail address is already in use. Please try another E-Mail address',
    },
  };

  const handleOnChange = () => {
    if (!props.formValues.password) {
      setPasswordError('password.error.validation.required');
      setShowError(true);
      return;
    } 
      setSubmitting(true);
      props.onSubmit(props.formValues, {
        setSubmitting: () => {
          isSubmitting;
        },
        setShowError
      });
    
  };

  const handlePasswordChange = (e) => {
    setPasswordError('');
    props.handlePasswordChange(e);
    setSubmitting(false);
  };

  return (
    <Modal show={props.showPasswordForm}>
      {props.formValues && (
        <div className="passwordValidationForm">
          <div className="popup-modal">
            <div className="passwordValidationForm__wrapper">
              <p className="passwordValidationForm__title">
                <FormattedMessage
                  id="profile.password.title"
                  defaultMessage="To change your email address you must enter your password"
                />
              </p>
              <div className="passwordValidationForm__password-wrapper">
                <Input
                  placeholder={props.intl.formatMessage({ id: 'label.currentPassword' })}
                  type="password"
                  name="password"
                  value={props.formValues.password}
                  onChange={(e) => handlePasswordChange(e)}
                  error={
                    showError &&
                    (props.requestError || isPasswordEmpty) &&
                    formatErrorMessage(
                      props.intl,
                      errorMessages,
                      isPasswordEmpty ? isPasswordEmpty : props.requestError
                    )
                  }
                />
              </div>
              <div className="passwordValidationForm__button-wrapper">
                <Button
                  className="passwordValidationForm__button-cancel"
                  onClick={() => {
                    setShowError(false);
                    props.onCancelEnterPassword();
                  }}
                >
                  <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
                </Button>
                <Button
                  className="passwordValidationForm__button-save"
                  type="button"
                  onClick={() => handleOnChange()}
                  disabled={isSubmitting}
                >
                  <FormattedMessage id="button.save" defaultMessage="Save" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

PasswordValidationForm.propTypes = {
  intl: PropTypes.object,
  showPasswordForm: PropTypes.bool.isRequired,
  formValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancelEnterPassword: PropTypes.func.isRequired,
  handlePasswordChange: PropTypes.func.isRequired,
  requestError: PropTypes.string.isRequired,
};
export default injectIntl(PasswordValidationForm);
