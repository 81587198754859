import React from 'react';
import PropTypes from 'prop-types';

import TileManager from '../../tiles/TileManager';
import SystemStatus from '../../layout/SystemStatus';

import './home.scss';

const HomePage = ({ history }) => {
  return (
    <div className="page-content">
      <div className="home__wrapper">
        <div className="row">
          <SystemStatus push={history.push} />
          <TileManager />
        </div>
      </div>
    </div>
  );
};

HomePage.propTypes = {
  history: PropTypes.object.isRequired,
};

export default HomePage;
