import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { Title } from 'jpi-cloud-web-ui-components';
import 'react-tabs/style/react-tabs.css';
import './privacypolicy-page.scss';

const PrivacyPolicyPage = () => {
  return (
    <div className="page-content">
      <Title titleTranslationId="privacy.title" defaultMessage="Privacy Policy" />
      <FormattedHTMLMessage id="privacy-policy" />
    </div>
  );
};

export default PrivacyPolicyPage;
