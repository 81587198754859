import {
  GET_STATUS_REQUEST,
  GET_STATUS_REQUEST_SUCCEEDED,
  GET_STATUS_REQUEST_FAILED,
  SET_STATUS_REQUEST,
  SET_STATUS_REQUEST_FAILED,
  SET_STATUS_REQUEST_SUCCEEDED,
  SET_SMART_HOME_MODE,
  smartHomeMode,
} from './actions';

const initialState = {
  currentStatus: 'online',
  loading: false,
  isSmartHomeLoading: false,
  smartHomeMode: smartHomeMode.default,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_STATUS_REQUEST:
      return { ...state, loading: true };
    case GET_STATUS_REQUEST_SUCCEEDED:
      return { ...state, loading: false, currentStatus: action.data.status };
    case GET_STATUS_REQUEST_FAILED:
      return { ...state, loading: false, currentStatus: action.data };
    case SET_STATUS_REQUEST:
      return { ...state, isSmartHomeLoading: true };
    case SET_STATUS_REQUEST_FAILED:
      return { ...state, isSmartHomeLoading: false };
    case SET_STATUS_REQUEST_SUCCEEDED:
      return { ...state, isSmartHomeLoading: false, smartHomeMode: action.smartHomeMode };
    case SET_SMART_HOME_MODE:
      return { ...state, smartHomeMode: action.smartHomeMode };
    default:
      return state;
  }
};
