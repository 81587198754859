import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { LineSeries, AreaSeries, VerticalRectSeries, LineMarkSeries, CustomSVGSeries } from 'react-vis';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Button, Spinner } from 'jpi-cloud-web-ui-components';

import DateRange from './date-range';
import Controls from './controls';
import {
  ChartType,
  DISCRETE_COLOR_RANGE,
  findTimeDiff,
  onChangeChart,
  findScrollWidth,
  onHover,
  chartNumber,
} from '../../chart-utils';
import ChartParameters from './chart-parameters.js';
import AutoSizerChart from './AutoSizerChart';

import * as historyStorage from '../../../../../api/history';

const getRandomColor = () => DISCRETE_COLOR_RANGE[Math.floor(Math.random() * DISCRETE_COLOR_RANGE.length)];

const defaultState = {
  showPoints: false,
  selectedZoom: null,
  combinedPoints: [],
  precedingPoints: [],
  chartsParameters: [],
  isPointerOnChart: false,
};
function Chart({
  charts = [],
  dateRange,
  setDateRange,
  updateChart,
  dateFrom,
  dateTo,
  fullScreen,
  toggleFullScreen,
  loading,
  preloading,
  premiumFeatures,
  isDemo,
  parameters,
  numberOfChart,
  setNumberOfChart,
  showChartConfig,
  deleteChart,
  deleteChartParameter,
  aggregationMethod,
  selectedLanguage,
}) {
  const chartDivRef = useRef();
  const [state, stateSetter] = useState(defaultState);

  const [zoomInRange, setZoomRange] = useState(10);
  const [hoValues, setHoValues] = useState(null);
  const [colorsMap, setColorsMap] = useState({});
  const [typesMap, setTypesMap] = useState({});

  const setState = (newState = {}) => stateSetter((prevState) => ({ ...prevState, ...newState }));
  const [disableYear, setDisableYear] = useState(false);

  const addToStorage = (newState) => {
    const { chartsParameters } = newState;

    if (loading || !chartsParameters.length) return;
    const [first] = chartNumber;

    const chartDataToBeCached = {
      charts: chartsParameters,
      range: dateRange,
      dateFrom,
      dateTo,
    };

    const saver = numberOfChart === first ? historyStorage.setFirstChartData : historyStorage.setSecondChartData;

    saver(chartDataToBeCached);
  };

  useEffect(() => {
    const { chartsParameters } = state;

    let combinedPoints = [];
    const precedingPoints = [];

    const updatedTypes = { ...typesMap };
    const updatedColors = { ...colorsMap };

    const updatedCharts = charts.map((chart) => {
      combinedPoints = combinedPoints.concat(chart.points);

      if (chart.precedingPoint) precedingPoints.push(chart.precedingPoint);

      const existingChart = chartsParameters.find((_) => _.parameterId === chart.parameterId) || chart;
      const availableColors = [...DISCRETE_COLOR_RANGE].filter((_) => !Object.values(updatedColors).includes(_));

      const color = colorsMap[chart.parameterId] || availableColors.shift();
      const chartType =
        chart.chartType ?? existingChart.chartType ?? typesMap[existingChart.parameterId] ?? ChartType.line;

      updatedTypes[existingChart.parameterId] = chartType;
      updatedColors[chart.parameterId] = color;

      return {
        ...existingChart,
        chartType,
        color,
      };
    });

    setColorsMap(updatedColors);
    setTypesMap(updatedTypes);

    const map = new Map();
    const filteredPoints = [];

    for (const point of combinedPoints) {
      if (!map.has(point.timestamp)) {
        map.set(point.timestamp);
        filteredPoints.push({
          timestamp: point.timestamp,
          value: point.value.toFixed(point.decimals),
        });
      }
    }

    const convertedCombinedPoints = filteredPoints.map((p) => ({
      x: moment(p.timestamp),
      y: p.value,
    }));

    const convertedPrecedingPoints = precedingPoints.map((p) => ({
      x: moment(p.timestamp),
      y: p.value,
    }));

    const newState = {
      ...state,
      combinedPoints: convertedCombinedPoints,
      precedingPoints: convertedPrecedingPoints,
      chartsParameters: updatedCharts,
    };

    addToStorage(newState);
    setState(newState);
  }, [charts]);

  useEffect(() => {
    document.body.style.overflow = fullScreen === numberOfChart ? 'hidden' : 'auto';
  }, [fullScreen]);

  useEffect(() => {
    const wheelListener = (event) => disableScroll(event);
    const chartDiv = chartDivRef.current;

    if (chartDiv) {
      chartDiv.addEventListener('wheel', wheelListener, { passive: false });
    }

    return () => {
      chartDiv.removeEventListener('wheel', wheelListener);
    };
  }, [chartDivRef]);

  useEffect(() => {
    setState({
      chartsParameters:
        charts.map((chart, index) => ({
          ...chart,
          chartType: ChartType.line,
          color: DISCRETE_COLOR_RANGE[index] || getRandomColor(),
        })) || [],
    });

    const isSubscriptionValid = JSON.parse(localStorage.getItem('IS_SUBSCRIPTION_VALID'));
    //isSubscription valid was a string, so it was returning true if there is true/false in the localstorage
    // hence did the conversion

    if (premiumFeatures.history || (!premiumFeatures.history && isSubscriptionValid)) {
      setDisableYear(false);
    } else if (!premiumFeatures.history && !isSubscriptionValid) {
      setDisableYear(true);
    }

    window.addEventListener('keydown', keyPressEvent, { passive: false });

    return () => {
      window.removeEventListener('keydown', keyPressEvent);
    };
  }, []);

  const disableScroll = (e) => {
    if (!state.selectedZoom) return;
    e.preventDefault();
  };

  const keyPressEvent = (e) => {
    if (
      ['ArrowDown', 'ArrowUp', 'ArrowLeft', 'ArrowRight'].includes(e.key) &&
      state.isPointerOnChart &&
      state.selectedZoom
    ) {
      e.preventDefault();
      e.key === 'ArrowDown' || e.key === 'ArrowLeft'
        ? setState({
            selectedZoom: findScrollWidth(dateRange, true, state.selectedZoom, dateFrom, dateTo),
          })
        : setState({
            selectedZoom: findScrollWidth(dateRange, false, state.selectedZoom, dateFrom, dateTo),
          });
    }
  };

  const setZoom = (area) => {
    if (!area) {
      setState({ selectedZoom: null });
      return;
    }

    setState({
      selectedZoom: {
        left: moment(area.left).isAfter(dateFrom) ? area.left : dateFrom,
        right: moment(area.right).isBefore(dateTo) ? area.right : dateTo,
      },
    });
  };

  const handleZoomInClick = (isZoomIn) => {
    const { selectedZoom } = state;

    let timeDiff;
    if (!isZoomIn) {
      if (zoomInRange > 9) return;

      const startDate = moment(selectedZoom?.left);
      const endDate = moment(selectedZoom?.right);

      timeDiff = findTimeDiff(endDate, startDate, zoomInRange);

      setZoomRange(zoomInRange + 1);
      setZoom({
        bottom: 0,
        left: startDate.subtract(timeDiff, 'hours'),
        right: endDate.add(timeDiff, 'hours'),
        top: 0,
      });

      if (zoomInRange === 9) {
        setZoom(null);
      }
      return;
    }

    if (zoomInRange < 1) return;

    const startDate = moment(selectedZoom?.left || dateFrom);
    const endDate = moment(selectedZoom?.right || dateTo);

    timeDiff = findTimeDiff(endDate, startDate, zoomInRange);
    setZoomRange(zoomInRange - 1);
    setZoom({
      bottom: 0,
      left: startDate.add(timeDiff, 'hours'),
      right: endDate.subtract(timeDiff, 'hours'),
      top: 0,
    });
  };

  const scrollGraph = (event) => {
    const {
      nativeEvent: { deltaX, deltaY },
    } = event;

    if ((deltaX > 0 || deltaY > 0) && state.selectedZoom) {
      setState({
        selectedZoom: findScrollWidth(dateRange, false, state.selectedZoom, dateFrom, dateTo),
      });
    }
    if ((deltaX < 0 || deltaY < 0) && state.selectedZoom) {
      setState({
        selectedZoom: findScrollWidth(dateRange, true, state.selectedZoom, dateFrom, dateTo),
      });
    }
  };

  const getChart = (chart, data, curve) => {
    const { color } = chart;

    switch (chart.chartType) {
      case ChartType.column:
        return (
          <VerticalRectSeries
            key={`${chart.deviceId}_${chart.parameterId}`}
            data={data.filter((d) => d.y)}
            style={{ stroke: color, fill: color }}
          />
        );
      case ChartType.area:
        return state.showPoints ? (
          [
            <CustomSVGSeries
              customComponent="circle"
              size={7}
              key={`${chart.deviceId}_${chart.parameterId}_custom`}
              data={data.filter((d) => d.y)}
              curve={curve}
              style={{ stroke: color, fill: color }}
              opacity={0.3}
            />,
            <AreaSeries
              getNull={(d) => d.y !== null}
              key={`${chart.deviceId}_${chart.parameterId}`}
              data={data}
              curve={curve}
              style={{ stroke: color, fill: color }}
              opacity={0.3}
            />,
          ]
        ) : (
          <AreaSeries
            getNull={(d) => d.y !== null}
            key={`${chart.deviceId}_${chart.parameterId}`}
            data={data}
            curve={curve}
            style={{ stroke: color, fill: color }}
            opacity={0.3}
          />
        );
      default:
        return state.showPoints ? (
          <LineMarkSeries
            getNull={(d) => d.y !== null}
            key={`${chart.deviceId}_${chart.parameterId}`}
            data={data}
            curve={curve}
            fill={color}
            style={{ stroke: color }}
          />
        ) : (
          <LineSeries
            getNull={(d) => d.y !== null}
            key={`${chart.deviceId}_${chart.parameterId}`}
            data={data}
            curve={curve}
            style={{ stroke: color }}
          />
        );
    }
  };

  const handleDateRange = async (range, aggregationMethod, numberOfChart, dateFrom, dateTo) => {
    await setDateRange(range, aggregationMethod, numberOfChart, dateFrom, dateTo);
    setState({ selectedZoom: null });
  };

  const handleBrush = (area) => {
    if (!area) return;

    setZoomRange(zoomInRange - 1);
    setZoom(area);
  };

  const deleteParameter =
    ({ deviceId, parameterId }) =>
    () => {
      if (charts.length === 1) {
        return handleClearAll();
      }

      deleteChartParameter(deviceId, parameterId, chartNumber[Number(!(numberOfChart === chartNumber[0]))]);

      const newColorMap = Object.entries(colorsMap).filter(([id]) => id !== parameterId);
      setColorsMap(Object.fromEntries(newColorMap));
    };

  const handleTypeChange = (chart, chartType = ChartType.line) => {
    const { chartsParameters = [] } = state;
    const newState = {
      ...state,
      chartsParameters: onChangeChart(chart, chartType, chartsParameters),
    };

    setTypesMap({ ...typesMap, [chart.parameterId]: chartType });

    addToStorage(newState);
    setState(newState);
  };

  const toggleAddParam = () => {
    showChartConfig(true);
    setNumberOfChart();
  };

  const handleClearAll = () => {
    setState(defaultState);
    setZoomRange(10);
    setColorsMap({});
    setTypesMap({});

    deleteChart();
  };

  const handleHover = (hoValue) => {
    if (loading) return;

    setHoValues(onHover(hoValue, charts, parameters));
  };

  const { showPoints, combinedPoints, precedingPoints, chartsParameters = [] } = state;

  return (
    <div className={fullScreen === numberOfChart ? 'full-screen' : 'chart'}>
      <div className="chart-control-group chart-top-controls">
        <DateRange
          premiumFeatures={premiumFeatures}
          isDemo={isDemo}
          range={dateRange}
          dateFrom={dateFrom}
          dateTo={dateTo}
          aggregationMethod={aggregationMethod}
          numberOfChart={numberOfChart}
          setDateRange={handleDateRange}
          updateChart={updateChart}
          disabled={disableYear}
          isLoading={loading === numberOfChart}
          selectedLanguage={selectedLanguage}
        />
        <Controls
          showPoints={showPoints}
          numberOfChart={numberOfChart}
          setPoints={(e) => {
            setState({ showPoints: e ? numberOfChart : '' });
          }}
          setZoom={handleZoomInClick}
          zoomInRange={zoomInRange}
          selectedZoomPoints={state.selectedZoom}
          isParameterSelected={state.chartsParameters.length}
          setFullScreen={toggleFullScreen}
          fullScreen={fullScreen}
          data={charts.map((c) => {
            const parameter = 'Parameter';
            const time = 'Time';
            const value = 'Value';
            const obj = {
              [parameter]: `[${c.parameter}][${c.unit}][${c.parameterId}];`,
              [time]: c.points.map((points) => `${points.timestamp};`),
              [value]: c.points.map((points) => `${points.value.toFixed(points.decimals)};`),
            };
            return obj;
          })}
        />
      </div>
      {loading === numberOfChart || preloading === numberOfChart ? (
        <div className="spinner-wrapper">
          <Spinner />
        </div>
      ) : (
        <div
          role={'dialog'}
          ref={chartDivRef}
          className="chart-wrapper"
          onWheel={scrollGraph}
          onPointerEnter={() => setState({ isPointerOnChart: true })}
          onPointerLeave={() => setState({ isPointerOnChart: false })}
        >
          <AutoSizerChart
            showPoints={showPoints}
            selectedZoom={state.selectedZoom}
            fullScreen={fullScreen}
            charts={charts}
            combinedPoints={combinedPoints}
            precedingPoints={precedingPoints}
            dateRange={dateRange}
            dateFrom={new Date(dateFrom)}
            dateTo={new Date(dateTo) || new Date()}
            parameters={parameters}
            chartsParameters={chartsParameters}
            hoveredValues={hoValues}
            onNearestX={handleHover}
            onMouseLeave={() => setHoValues(null)}
            onBrushEnd={handleBrush}
            getChart={getChart}
            hoverDelay={300}
          />
        </div>
      )}

      <div className="chart-parameter-list-wrapper">
        {loading === numberOfChart ||
          preloading === numberOfChart ||
          chartsParameters
            .sort((a, b) => a.parameter.localeCompare(b.parameter))
            .map((param, index) => (
              <ChartParameters
                key={`${param.name}-${index}`}
                parameter={param}
                deleteChartParameter={deleteParameter(param)}
                changeChartType={handleTypeChange}
              />
            ))}
      </div>

      <div className="btn-container">
        <Button className="button--primary select-value-btn" onClick={toggleAddParam}>
          <FormattedMessage id="add.parameter" defaultMessage="Add Parameter" />
        </Button>
        {charts?.length > 0 && (
          <Button className="button--default clear-value-btn" onClick={handleClearAll}>
            <FormattedMessage id="clear.values" defaultMessage="Clear All" />
          </Button>
        )}
      </div>
    </div>
  );
}

Chart.propTypes = {
  loading: PropTypes.string.isRequired,
  preloading: PropTypes.string.isRequired,
  charts: PropTypes.array.isRequired,
  premiumFeatures: PropTypes.object.isRequired,
  dateRange: PropTypes.string.isRequired,
  setDateRange: PropTypes.func.isRequired,
  updateChart: PropTypes.func.isRequired,
  setCustomDateRange: PropTypes.func,
  dateFrom: PropTypes.instanceOf(Date),
  dateTo: PropTypes.instanceOf(Date),
  fullScreen: PropTypes.string,
  toggleFullScreen: PropTypes.func,
  aggregationMethod: PropTypes.string,
  isDemo: PropTypes.bool,
  parameters: PropTypes.array,
  showChartConfig: PropTypes.func,
  numberOfChart: PropTypes.string,
  setNumberOfChart: PropTypes.func,
  deleteChart: PropTypes.func,
  deleteChartParameter: PropTypes.func,
  selectedLanguage: PropTypes.string,
};

export default Chart;
