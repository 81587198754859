import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'jpi-cloud-web-ui-components';

// eslint-disable-next-line
import * as InvitationTypes from './invitation-types';

import './systemlist.scss';

/**
 * SystemRow component.
 *
 * @param {InvitationTypes.SystemRow} props - Component props.
 * @returns {React.ReactNode} Rendered SystemList component.
 */
const SystemRow = ({ system, handleChange, isSelected, hasOnlyOneSystemAvailable }) => (
  <tr className="list list-item">
    <td className="list-item--value">
      <Checkbox
        id={system.id}
        name={`selectedSystem-${system.id}`}
        onChange={() => handleChange(isSelected, system)}
        checked={hasOnlyOneSystemAvailable || isSelected}
        disabled={hasOnlyOneSystemAvailable}
      />
    </td>
    <td className="list-item--value">{system.name}</td>
    <td className="list-item--value">{system.devices && system.devices.length > 0 && system.serialNumber}</td>
    <td className="list-item--value" />
  </tr>
);

SystemRow.propTypes = {
  system: PropTypes.object.isRequired,
  isSelected: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  hasOnlyOneSystemAvailable: PropTypes.bool,
};

export default SystemRow;
