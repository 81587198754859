import React from 'react';
import { FormattedMessage } from 'react-intl';
import './subscriptionFailureMessage.scss';

const subscriptionsFailureMessage = () => {
  return (
    <div className="Subscription__failedMessage">
      <p className="Subscription__failedMessage__title">
        <FormattedMessage
          id="shop.page.failedYearlySubscription.title"
          defaultMessage="Your Yearly Subscription is expiring"
        />
      </p>
      <p className="Subscription__failedMessage__description">
        <FormattedMessage
          id="shop.page.failedSubscription.message"
          defaultMessage="If you wish to continue your subscription, please update your payment details."
        />
      </p>
    </div>
  );
};

export default subscriptionsFailureMessage;
