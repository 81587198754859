import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button } from 'jpi-cloud-web-ui-components';

class AddTile extends React.Component {
  static propTypes = {
    onCreateTile: PropTypes.func.isRequired,
    getTileTypes: PropTypes.func.isRequired,
    selectedSystemId: PropTypes.string,
    isTypesLoaded: PropTypes.bool,
    history: PropTypes.object.isRequired,
  };

  startCreateTile = () => {
    if (!this.props.selectedSystemId) {
      this.props.history.push('/add-device');
    } else {
      this.props.onCreateTile();

      if (!this.props.isTypesLoaded) this.props.getTileTypes(this.props.selectedSystemId);
    }
  };

  render() {
    return (
      <div className="addTile">
        <Button className="button--round" onClick={() => this.startCreateTile()} type="button">
          <FormattedMessage id="tiles.addTile" defaultMessage="Add new tile">
            {(hover) => <span title={hover}>+</span>}
          </FormattedMessage>
        </Button>
      </div>
    );
  }
}

export default withRouter(AddTile);
