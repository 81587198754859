import moment from 'moment';
import { getPoints, getPrecedingPoint } from '../../../api';
import { getPredictedPoint } from '../../pages/history/chart-utils';

/**
 * @typedef {{ timestamp: Date | String, range: String }} RangedTimestamp
 */

export const GET_TILE_CHART_DATA_REQUEST = 'chartTile/GET_TILE_CHART_DATA_REQUEST';
export const GET_TILE_CHART_DATA_RESPONSE = 'chartTile/GET_TILE_CHART_DATA_RESPONSE';

export const DateRange = {
  Year: 'Year',
  Month: 'Month',
  Week: 'Week',
  Day: 'Day',
  Today: 'Today',
  Tomorrow: 'Tomorrow',
};

const formatDate = (d) => moment(d).utc().format('YYYY-MM-DD HH:mm:ss');

/**
 * @param {RangedTimestamp}
 * @returns boolean
 */
const lastPointInRange = ({ timestamp, range }) => {
  try {
    const prevDate = new Date(timestamp);
    const daysDiff = new Number(new Date() - prevDate) / (1000 * 3600 * 24).toFixed();

    if (DateRange.Day === range) return daysDiff < 1;
    if (DateRange.Week === range) return daysDiff < 7;

    const dd = new Date();
    const daysOfMonth = new Date(dd.getFullYear(), dd.getMonth() + 1, 0).getDate();
    if (DateRange.Month === range) return daysDiff < daysOfMonth;

    return daysDiff < 365;
  } catch (error) {
    return false;
  }
};

/**
 * @param {String} range
 * @returns Date | null
 */
const modifyLastPointTimestamp = (range) => {
  const dd = new Date();
  try {
    const timeByRange = {
      [DateRange.Day]: () => new Date(dd.setDate(dd.getDate() - 1)),
      [DateRange.Week]: () => new Date(dd.setDate(dd.getDate() - 7)),
      [DateRange.Month]: () => new Date(dd.setMonth(dd.getMonth() - 1)),
      [DateRange.Year]: () => new Date(dd.setFullYear(dd.getFullYear() - 1)),
    };
    return new Date(timeByRange[range]());
  } catch (e) {
    return null;
  }
};

export const getTileChartData = (deviceId, parameterId, range) => async (dispatch) => {
  const dateTo = getDateTo(range);
  const dateFrom = getDateFrom(range);
  dispatch({ type: GET_TILE_CHART_DATA_REQUEST });

  const points = await getPoints(deviceId, parameterId, 'none', 'average', formatDate(dateFrom), formatDate(dateTo));
  let precedingPoint, predictedPoint;

  if (points.length > 0) {
    const firstPoint = points[0];
    precedingPoint = (await getPrecedingPoint(deviceId, parameterId, formatDate(dateFrom))) || {
      ...firstPoint,
      timestamp: moment(dateFrom).utc().subtract(10, 'days').format('YYYY-MM-DD HH:mm:ssZ'),
    };

    const lastPoint = points[points.length - 1];
    predictedPoint = getPredictedPoint(lastPoint, dateTo);
    const lastTimestamp = lastPoint.timestamp;

    pushLastPoint(range, points, lastPoint);

    if (!lastPointInRange({ timestamp: lastPoint.timestamp, range })) {
      lastPoint.timestamp = modifyLastPointTimestamp(range) || lastTimestamp;
    }
  }

  dispatch({ type: GET_TILE_CHART_DATA_RESPONSE, points, precedingPoint, predictedPoint });
};

const pushLastPoint = (range, points, lastPoint) => {
  switch (range) {
    case DateRange.Today:
      points.push({ ...lastPoint, timestamp: dayEnd(0) });
      break;
    case DateRange.Tomorrow:
      points.push({ ...lastPoint, timestamp: dayEnd(1) });
      break;
    default:
      points.push({ ...lastPoint, timestamp: dayEnd(0) });
      break;
  }
};

const dayStart = (day) => {
  const date = new Date();
  date.setDate(date.getDate() + day);
  return new Date(date.setHours(0, 0, 0, 0));
};

const dayEnd = (day) => {
  const date = new Date();
  date.setDate(date.getDate() + day);
  return new Date(date.setHours(23, 59, 59, 999));
};

export const getDateTo = (dateRange) => {
  let dateTo = new Date();
  switch (dateRange) {
    case DateRange.Today:
      dateTo = dayEnd(0);
      break;
    case DateRange.Tomorrow:
      dateTo = dayEnd(1);
      break;
  }
  return dateTo;
};

export const getDateFrom = (dateRange) => {
  let dateFrom = new Date();
  switch (dateRange) {
    case DateRange.Year:
      dateFrom.setFullYear(dateFrom.getFullYear() - 1);
      break;
    case DateRange.Month:
      dateFrom.setMonth(dateFrom.getMonth() - 1);
      break;
    case DateRange.Week:
      dateFrom.setDate(dateFrom.getDate() - 7);
      break;
    case DateRange.Today:
      dateFrom = dayStart(0);
      break;
    case DateRange.Tomorrow:
      dateFrom = dayStart(1);
      break;
    default:
      dateFrom.setDate(dateFrom.getDate() - 1);
      break;
  }
  return dateFrom;
};

export const CLEAN_CHART_DATA = 'chartTile/CLEAN_CHART_DATA';
export const cleanChartData = () => (dispatch) => {
  dispatch({ type: CLEAN_CHART_DATA });
};
