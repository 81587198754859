export const generateD3TimeLocales = ({
  messages,
  decimal = ',',
  thousands = '\xa0',
  grouping = [3],
  dateTime = '%A, %e %B %Y г. %X',
  date = '%d.%m.%Y',
  time = '%H:%M:%S',
  periods = ['AM', 'PM'],
}) => ({
  decimal,
  thousands,
  grouping,
  dateTime,
  date,
  time,
  periods,
  days: [
    messages['scheduling.Sunday'],
    messages['scheduling.Monday'],
    messages['scheduling.Tuesday'],
    messages['scheduling.Wednesday'],
    messages['scheduling.Thursday'],
    messages['scheduling.Friday'],
    messages['scheduling.Saturday'],
  ],
  shortDays: [
    messages['scheduling.Sunday'].substring(0, 2),
    messages['scheduling.Monday'].substring(0, 2),
    messages['scheduling.Tuesday'].substring(0, 2),
    messages['scheduling.Wednesday'].substring(0, 2),
    messages['scheduling.Thursday'].substring(0, 2),
    messages['scheduling.Friday'].substring(0, 2),
    messages['scheduling.Saturday'].substring(0, 2),
  ],
  months: [
    messages['history.month.January'],
    messages['history.month.February'],
    messages['history.month.March'],
    messages['history.month.April'],
    messages['history.month.May'],
    messages['history.month.June'],
    messages['history.month.July'],
    messages['history.month.August'],
    messages['history.month.September'],
    messages['history.month.October'],
    messages['history.month.November'],
    messages['history.month.December'],
  ],
  shortMonths: [
    messages['history.month.January'].substring(0, 2),
    messages['history.month.February'].substring(0, 2),
    messages['history.month.March'].substring(0, 2),
    messages['history.month.April'].substring(0, 2),
    messages['history.month.May'].substring(0, 2),
    messages['history.month.June'].substring(0, 2),
    messages['history.month.July'].substring(0, 2),
    messages['history.month.August'].substring(0, 2),
    messages['history.month.September'].substring(0, 2),
    messages['history.month.October'].substring(0, 2),
    messages['history.month.November'].substring(0, 2),
    messages['history.month.December'].substring(0, 2),
  ],
});
