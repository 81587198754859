import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

const ProductsError = ({ productErrors, brandId = '' }) => {
  const hasProductErrors = productErrors.length > 0;
  return hasProductErrors ? (
    productErrors.map((error) => (
      <div
        key={error.key}
        className={error.key.includes('free') ? 'Product__description-free_services' : 'Product__error'}
      >
        <FormattedMessage
          id={`shop.page.${error.key}`}
          defaultMessage={error.error}
          values={{
            here: brandId,
          }}
        />
      </div>
    ))
  ) : (
    <div className="Product__description-free_services">
      <FormattedMessage
        id="shop.page.freeEcomServices"
        defaultMessage="Currently the myUplink services are available for testing free of charge for your system. This testing period is subject to change and can without prior notice ended and go back to regular pricing."
      />
    </div>
  );
};

ProductsError.propTypes = {
  productErrors: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      error: PropTypes.string.isRequired,
    })
  ).isRequired,
  brandId: PropTypes.string,
};
export default ProductsError;
