import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

// eslint-disable-next-line
import * as InvitationTypes from './invitation-types';

import './systemlist.scss';

/**
 * SystemRowHeader component.
 *
 * @param {InvitationTypes.SystemRowHeader} props - Component props.
 * @returns {React.ReactNode} Rendered SystemList component.
 */
const SystemRowHeader = ({ id, defaultMessage, formElement }) => (
  <th className={'list-item--value'} style={{ textAlign: 'initial' }}>
    {defaultMessage ? <FormattedMessage id={id} defaultMessage={defaultMessage} /> : formElement}
  </th>
);

SystemRowHeader.propTypes = {
  id: PropTypes.string.isRequired,
  defaultMessage: PropTypes.string.isRequired,
  formElement: PropTypes.element,
};

export default SystemRowHeader;
