/* eslint-disable no-console */
import React from 'react';
import { LineSeries } from 'react-vis';
import PropTypes from 'prop-types';

const PlaceholderChart = ({ dateFrom, dateTo }) => {
  return (
    <LineSeries
      opacity={0}
      data={[
        { x: dateFrom, y: 0 },
        { x: dateTo, y: 100 },
      ]}
    />
  );
};

PlaceholderChart.propTypes = {
  dateFrom: PropTypes.instanceOf(Date).isRequired,
  dateTo: PropTypes.instanceOf(Date).isRequired,
};

export default React.memo(PlaceholderChart, (prev, next) => {
  const isDateFromUpdated = prev.dateFrom.valueOf() !== next.dateFrom.valueOf();
  const isDateToUpdated = prev.dateTo.valueOf() !== next.dateTo.valueOf();
  return !(isDateFromUpdated || isDateToUpdated);
});
