import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { ModalBody, ModalFooter } from 'react-bootstrap';
import Modal from '../../../../../layout/Modal';
import { Button } from 'jpi-cloud-web-ui-components';

const DisconnectUserConfirmation = ({ showModal, disconnecting, toggleModal, disconnectUser }) => (
  <Modal show={showModal} backdrop={true} onHide={toggleModal}>
    <ModalBody>
      <p className="lead text-left">
        <FormattedMessage
          id="security.ConfirmationMessage"
          defaultMessage="You are about to disconnect a user from a system. Please click Disconnect button to confirm this action. This action cannot be undone."
        />
      </p>
    </ModalBody>
    <ModalFooter>
      <Button className="button--danger" onClick={disconnectUser} type="button" disabled={disconnecting}>
        <FormattedMessage id="security.Disconnect" defaultMessage="Disconnect" />
      </Button>
      <Button className="button--default" onClick={toggleModal} type="button">
        <FormattedMessage id="security.Cancel" defaultMessage="Cancel" />
      </Button>
    </ModalFooter>
  </Modal>
);

DisconnectUserConfirmation.propTypes = {
  showModal: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  disconnectUser: PropTypes.func.isRequired,
  disconnecting: PropTypes.bool,
};

export default DisconnectUserConfirmation;
