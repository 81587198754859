import {
  REDEEM_VOUCHER_REQUEST,
  REDEEM_VOUCHER_RESPONSE_FAILED,
  REDEEM_VOUCHER_RESPONSE_SUCCEEDED,
  CLEAR_ERROR_MESSAGE,
} from './actions';

const initialState = {
  loading: false,
  redeemVoucherModel: null,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REDEEM_VOUCHER_REQUEST:
      return { ...state, loading: true };
    case REDEEM_VOUCHER_RESPONSE_SUCCEEDED:
      return { ...state, loading: false, redeemVoucherModel: action.redeemVoucherModel, error: null };
    case REDEEM_VOUCHER_RESPONSE_FAILED:
      return { ...state, loading: false, error: action.error };
    case CLEAR_ERROR_MESSAGE:
      return { ...state, error: null };
    default:
      return state;
  }
};
