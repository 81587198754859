import * as api from '../../../../../api';

export const GET_ADDRESS_REQUEST = ' system/GET_ADDRESS_REQUEST';
export const GET_ADDRESS_RESPONSE_SUCCEDED = ' system/GET_ADDRESS_RESPONSE_SUCCEDED';
export const GET_ADDRESS_NOT_FOUND = ' system/GET_ADDRESS_NOT_FOUND';
export const GET_ADDRESS_FAILED = ' system/GET_ADDRESS_FAILED';

export const getAddressInfo = (addressId) => async (dispatch) => {
  dispatch({ type: GET_ADDRESS_REQUEST });
  try {
    const response = await api.getAddressInfo(addressId);
    if (response.status === 200) {
      dispatch({ type: GET_ADDRESS_RESPONSE_SUCCEDED, address: response.data });
      return true;
    }
  } catch (e) {
    if (e.response && e.response.status === 404) {
      dispatch({ type: GET_ADDRESS_NOT_FOUND });
      return false;
    }
  }

  dispatch({ type: GET_ADDRESS_FAILED });
  return false;
};

export const CHANGE_SYSTEM_ADDRESS_REQUEST = 'system/CHANGE_ADDRESS_REQUEST';
export const CHANGE_SYSTEM_ADDRESS_RESPONSE_SUCCEDED = 'system/CHANGE_ADDRESS_RESPONSE_SUCCEDED';
export const CHANGE_SYSTEM_ADDRESS_FAILED = ' system/CHANGE_ADDRESS_FAILED';

export const changeAddressInfo = (address, systemId) => async (dispatch) => {
  dispatch({ type: CHANGE_SYSTEM_ADDRESS_REQUEST });
  try {
    const response = await api.changeSystemAddressInfo(address, systemId);
    if (response.status == 200 || response.status === 204) {
      dispatch({ type: CHANGE_SYSTEM_ADDRESS_RESPONSE_SUCCEDED, address: response.data });
      return true;
    }
  } catch (e) {
    if (e.response && e.response.status === 400) {
      dispatch({ type: CHANGE_SYSTEM_ADDRESS_FAILED, error: e.response.data.description });
      return false;
    }
  }

  dispatch({ type: CHANGE_SYSTEM_ADDRESS_FAILED, error: 'unknown' });
  return false;
};

export const CREATE_SYSTEM_ADDRESS_REQUEST = 'system/CREATE_ADDRESS_REQUEST';
export const CREATE_SYSTEM_ADDRESS_RESPONSE_SUCCEEDED = 'system/CREATE_ADDRESS_RESPONSE_SUCCEEDED';
export const CREATE_SYSTEM_ADDRESS_FAILED = ' system/CREATE_ADDRESS_FAILED';

export const createAddressInfo = (addressId, address, systemId) => async (dispatch) => {
  const newAddress = { ...address, id: addressId };

  dispatch({ type: CREATE_SYSTEM_ADDRESS_REQUEST });

  try {
    const response = await api.createSystemAddressInfo(newAddress, systemId);
    if (response.status === 201 || response.status === 200) {
      dispatch({ type: CREATE_SYSTEM_ADDRESS_RESPONSE_SUCCEEDED, address });
      return true;
    }
  } catch (e) {
    if (e.response && e.response.status === 400) {
      dispatch({ type: CREATE_SYSTEM_ADDRESS_FAILED, error: e.response.data.error_description });
      return false;
    }
  }

  dispatch({ type: CREATE_SYSTEM_ADDRESS_FAILED, error: 'unknown' });
  return false;
};
