import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import TileIcons, { getTileIcon } from '../../tiles/GenericTile/tile-icons';
import PremiumFeatureTooltip from '../../layout/PremiumFeatureTooltip';

import { Button } from 'jpi-cloud-web-ui-components';

import './option-input.scss';

const OptionContent = ({ option, brandId }) => {
  const iconUrl = getTileIcon(TileIcons, brandId, option.icon);

  return (
    <div key={option.text} className="option-item">
      {option.icon ? (
        <div>
          <img src={iconUrl} className="option-icon" />
          {iconUrl === TileIcons.default.status && (
            <div title={String(option.icon)?.toUpperCase()} className="fallback-text">
              {option.text || 'N/A'}
            </div>
          )}
        </div>
      ) : (
        <span>{option.text || 'N/A'}</span>
      )}
    </div>
  );
};

OptionContent.propTypes = {
  brandId: PropTypes.string.isRequired,
  option: PropTypes.object.isRequired,
};

const OptionInput = ({
  value: initialValue,
  pendingValue,
  brandId,
  enumValues,
  onChange,
  disabledDueToOffline,
  disabled,
}) => {
  const [value, setValue] = useState(initialValue);
  const timeout = useRef(null);
  const valueRef = useRef(value);
  valueRef.current = value;

  const sendPatchSettingRequest = (currentValue) => {
    setValue(currentValue);
    if (!onChange) return;

    clearTimeout(timeout.current);

    timeout.current = setTimeout(() => {
      timeout.current = null;

      if (currentValue !== valueRef.current) {
        return;
      }

      onChange(currentValue);
    }, 1000);
  };

  useEffect(() => {
    if (timeout.current || initialValue !== valueRef.current) return;

    setValue(initialValue);

    return () => {
      clearTimeout(timeout.current);
    };
  }, [initialValue]);

  const changeOption = (newValue) => {
    setValue(newValue);
    sendPatchSettingRequest(newValue);
  };

  const optionsDisabled = disabled || disabledDueToOffline || pendingValue !== null;

  return (
    <div className="option-input-container">
      <div role="list" className="options-list" disabled={optionsDisabled}>
        {enumValues.map((option, i) => (
          <Button
            type="button"
            key={`option#${option.value}-${i}`}
            style={{ opacity: optionsDisabled ? 0.5 : 1 }}
            onClick={() => changeOption(parseInt(option.value))}
            className={classNames('button--option', {
              'selected-option': parseInt(option.value) === value,
            })}
            disabled={optionsDisabled}
          >
            <OptionContent brandId={brandId} option={option} />
          </Button>
        ))}
      </div>
      {disabledDueToOffline && <PremiumFeatureTooltip translationId="addDevice.systemSelector.offline" />}
    </div>
  );
};

OptionInput.propTypes = {
  value: PropTypes.number,
  pendingValue: PropTypes.number,
  brandId: PropTypes.string.isRequired,
  enumValues: PropTypes.array,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  disabledDueToOffline: PropTypes.bool,
};

export default OptionInput;
