import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import DisconnectSystemConfirmation from './DisconnectSystemConfirmation';
import { Button } from 'jpi-cloud-web-ui-components';

const DisconnectSystem = ({ system, showModal, toggleModal, disconnectSystem, isDemo }) => (
  <div>
    <h3 className="remove-section">
      <div className="remove-section-message">
        <FormattedMessage id="system-profile.delete.remove-message" defaultMessage="Remove system" />
        &nbsp;{system && system.name}
      </div>
    </h3>
    <span
      className={classNames('button-wrapper', isDemo ? 'disabled' : '')}
      onClick={() => {
        toggleModal(system);
      }}
    >
      <Button className="button button--danger disconnect-button" type="submit">
        <FormattedMessage id="devices.Disconnect" defaultMessage="Disconnect" />
      </Button>
    </span>
    <DisconnectSystemConfirmation toggleModal={toggleModal} showModal={showModal} disconnectSystem={disconnectSystem} />
  </div>
);

DisconnectSystem.propTypes = {
  system: PropTypes.object.isRequired,
  showModal: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  disconnectSystem: PropTypes.func.isRequired,
  isDemo: PropTypes.bool,
};

export default DisconnectSystem;
