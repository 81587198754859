import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as yup from 'yup';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Input } from 'jpi-cloud-web-ui-components';
import './newpassword.scss';

import { verifyRecoveryToken, resetPassword } from './actions';
import { formatErrorMessage } from '../../../localization/message-formatting';

const schema = yup.object().shape({
  password: yup
    .string()
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$/, 'password.regexp')
    .test('consecutiveSymbols', 'password.consecutive', (v) => !/(.)\1\1/.test(v))
    .max(128, 'password.maxlength')
    .required('password.required'),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref('password'), null], 'password.match')
    .required('password.confirm.required'),
});

const errorMessages = {
  'password.match': {
    id: 'password.error.validation.match',
    defaultMessage: 'Passwords must match',
  },
  'password.regexp': {
    id: 'password.error.validation.regexp',
    defaultMessage: 'Password must be at least 8 characters long with uppercase letters, numbers and symbols',
  },
  'password.consecutive': {
    id: 'password.error.validation.consecutive',
    defaultMessage: 'Password cannot contain more than 2 identical characters',
  },
  'password.maxlength': {
    id: 'password.error.validation.maxlength',
    defaultMessage: 'Password must be no longer than 128 characters',
  },
  'password.required': {
    id: 'password.error.validation.required',
    defaultMessage: 'Password is mandatory field',
  },
  'password.confirm.required': {
    id: 'password.error.validation.confirm.required',
    defaultMessage: 'Password confirm is mandatory field',
  },
};

const NewPasswordFormInner = ({ onSubmit, intl }) => {
  useEffect(() => {
    document.title = intl.formatMessage({
      id: 'password-reset.page-title',
      defaultMessage: 'Reset your password - myUplink',
    });
  });

  return (
    <Formik initialValues={{ password: '', passwordConfirmation: '' }} validationSchema={schema} onSubmit={onSubmit}>
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit} className="form">
          <h1 id="reset-password" className="form__heading">
            <FormattedMessage id="password-reset.set-new-password" defaultMessage="Sätt nytt lösenord" />
          </h1>
          <div className="form__text form__paragraph--no-padding-top form__paragraph--no-margin-bottom">
            <FormattedMessage id="register.or" defaultMessage="or" />
            &nbsp;
            <Link className="form__link lowercase" to="/login">
              <FormattedMessage id="login.login" defaultMessage="Logga in" />
            </Link>
          </div>
          <FormattedMessage id="password-reset.password-input" defaultMessage="Password">
            {(placeholder) => (
              <Input
                id="reset-password"
                placeholder={placeholder}
                value={values.password}
                aria-required="true"
                onChange={handleChange}
                onBlur={handleBlur}
                type="password"
                name="password"
                error={errors.password && touched.password && formatErrorMessage(intl, errorMessages, errors.password)}
              />
            )}
          </FormattedMessage>
          <FormattedMessage id="password-reset.confirmation-input" defaultMessage="Confirmation">
            {(placeholder) => (
              <Input
                placeholder={placeholder}
                value={values.passwordConfirmation}
                aria-required="true"
                onChange={handleChange}
                onBlur={handleBlur}
                type="password"
                name="passwordConfirmation"
                error={
                  errors.passwordConfirmation &&
                  touched.passwordConfirmation &&
                  formatErrorMessage(intl, errorMessages, errors.passwordConfirmation)
                }
              />
            )}
          </FormattedMessage>
          <div className="button-wrapper--large">
            <Button className="button--secondary" type="submit" disabled={isSubmitting}>
              <FormattedMessage
                id="password-reset.changeButton"
                defaultMessage="Sätt nytt lösenord"
                description="Set new password on reset password page"
              />
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

NewPasswordFormInner.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  requestError: PropTypes.string,
  intl: PropTypes.object,
};

const NewPasswordForm = injectIntl(NewPasswordFormInner);

const LinkValidationError = ({ userLoggedIn, intl }) => {
  useEffect(() => {
    document.title = intl.formatMessage({
      id: 'password-reset.page-title.expired-link',
      defaultMessage: 'Reset password link expired - myUplink',
    });
  });

  return (
    <form className="form">
      <h1 id="reset-password" className="form__heading">
        <FormattedMessage id="password-reset.error.invalid-link" defaultMessage="Link is expired or invalid." />
      </h1>
      {!userLoggedIn && (
        <>
          <p className="form__text">
            <FormattedMessage
              id="password-reset.error.invalid-link-text"
              defaultMessage="Please try log in or reset your password again"
            />
          </p>
          <div className="button-wrapper--large">
            <Link to="/login">
              <Button className="button--secondary" type="button">
                <FormattedMessage
                  id="password-reset.go-to-login-page"
                  defaultMessage="Gå till loggga in"
                  description="Go to login page on reset password error token page"
                />
              </Button>
            </Link>
          </div>
        </>
      )}
    </form>
  );
};

LinkValidationError.propTypes = {
  userLoggedIn: PropTypes.bool,
  intl: PropTypes.object.isRequired,
};

const PasswordChanged = ({ userLoggedIn }) => (
  <form className="form">
    <h1 id="reset-password" className="form__heading">
      <FormattedMessage id="password-reset.success-message" default="Password has been updated" />
    </h1>
    {!userLoggedIn && (
      <>
        <p className="form__paragraph form__paragraph--no-padding-top">
          <FormattedMessage id="password-reset.success-message-text" defaultMessage="You can now login." />
        </p>
        <div className="button-wrapper--large">
          <Link to="/login">
            <Button className="button--secondary" type="button">
              <FormattedMessage
                id="password-reset.go-to-login-page"
                defaultMessage="Go to log in page"
                description="Go to login page on reset password error token page"
              />
            </Button>
          </Link>
        </div>
      </>
    )}
  </form>
);

PasswordChanged.propTypes = {
  userLoggedIn: PropTypes.bool,
};

const getTokenFromQueryString = (qs) => {
  const parsedQueryString = queryString.parse(qs);
  return parsedQueryString.token;
};

const PageState = {
  loading: 'loading',
  passwordInput: 'passwordInput',
  linkValidationError: 'linkValidationError',
  success: 'success',
};

class NewPassword extends React.Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    verifyRecoveryToken: PropTypes.func.isRequired,
    resetPassword: PropTypes.func.isRequired,
    error: PropTypes.string,
    userLoggedIn: PropTypes.bool,
    intl: PropTypes.object.isRequired,
  };

  state = {
    pageState: PageState.loading,
  };

  componentDidMount() {
    const token = getTokenFromQueryString(this.props.location.search);
    this.props
      .verifyRecoveryToken(token)
      .then(() => {
        this.setState({ pageState: PageState.passwordInput });
      })
      .catch(() => {
        this.setState({ pageState: PageState.linkValidationError });
      });
  }

  onSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const token = getTokenFromQueryString(this.props.location.search);
    this.props
      .resetPassword(token, values.password.trim())
      .then(() => {
        this.setState({ pageState: PageState.success });
        setSubmitting(false);
      })
      .catch(() => {
        this.setState({ pageState: PageState.linkValidationError });
        setSubmitting(false);
      });
  };
  render() {
    const { userLoggedIn, intl } = this.props;

    const body = {
      [PageState.loading]: <FormattedMessage id="password-reset.loading" />,
      [PageState.passwordInput]: <NewPasswordForm onSubmit={this.onSubmit} requestError={this.props.error} />,
      [PageState.linkValidationError]: <LinkValidationError userLoggedIn={userLoggedIn} intl={intl} />,
      [PageState.success]: <PasswordChanged userLoggedIn={userLoggedIn} />,
    }[this.state.pageState];

    return <>{body}</>;
  }
}

export default connect(
  ({ app: { userLoggedIn }, loginPage }) => ({
    ...loginPage,
    userLoggedIn,
  }),
  {
    verifyRecoveryToken,
    resetPassword,
  }
)(injectIntl(NewPassword));
