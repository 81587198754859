import React, { Fragment } from 'react';
import { array, number, func, object } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { Button } from 'jpi-cloud-web-ui-components';

import ScheduleTooltip from '../ScheduleTooltip';
import PremiumFeatureTooltip from '../../../../layout/PremiumFeatureTooltip';

class SelectEventMode extends React.PureComponent {
  static propTypes = {
    modes: array,
    modeSettings: array,
    scheduleConfig: object.isRequired,
    selectedMode: object,
    goNext: func.isRequired,
    goPrevious: func.isRequired,
    eventCount: number.isRequired,
    onDeleteEvent: func,
    onSelectedChanged: func.isRequired,
    onDisableEvent: func,
    onEnableEvent: func,
  };

  state = {
    modeSettings: [],
  };

  componentDidMount() {
    const { modes, scheduleConfig } = this.props;
    const modeSettings = modes.reduce(
      (modeSettings, mode) => ({
        ...modeSettings,
        [mode.modeId]: mode.settings.map((s) => ({
          ...s,
          ...scheduleConfig.modeSettings.find((ms) => ms.settingId === s.settingId),
        })),
      }),
      {}
    );

    this.setState({
      modeSettings,
    });
  }

  toggleMode = (mode) => {
    if (this.props.selectedMode === mode) {
      return;
    }

    this.props.onSelectedChanged(mode);
  };

  render() {
    const {
      modes,
      selectedMode,
      goNext,
      goPrevious,
      onDeleteEvent,
      onDisableEvent,
      onEnableEvent,
      scheduleConfig,
      eventCount,
    } = this.props;

    const deleteLastEventAvailable = !(scheduleConfig.allowUnscheduled || eventCount > 1);

    return (
      <Fragment>
        <div className="popup-body">
          <div className="popup-header">
            <p className="text">
              <FormattedMessage
                id="scheduling.create-event.title.step1.text"
                defaultMessage="Which mode do you want to plan?"
              />
            </p>
          </div>
          <div className="popup-main">
            {modes.map((mode) => (
              <div key={mode.modeId} className={classNames('popup-item', { active: selectedMode === mode })}>
                <Button type="button" key={mode.modeId} className="button--info" onClick={() => this.toggleMode(mode)}>
                  <span className="mode-name">{mode.name}</span>
                </Button>
                <ScheduleTooltip modeSettings={this.state.modeSettings[mode.modeId] || []} hidden={mode.modeId === 0} />
              </div>
            ))}
          </div>
        </div>

        <div className="popup-bottom-panel">
          <Button className="button--secondary" type="submit" onClick={goNext} disabled={!selectedMode}>
            <FormattedMessage id="scheduling.title.next" defaultMessage="Next" />
          </Button>
          {scheduleConfig.eventDisableAvailable && onDisableEvent && (
            <Button
              className="button--default"
              type="button"
              onClick={onDisableEvent}
              disabled={selectedMode && selectedMode.modeId === 0}
            >
              <FormattedMessage id="scheduling.title.disable-event" defaultMessage="Disable event" />
            </Button>
          )}
          {scheduleConfig.eventDisableAvailable && onEnableEvent && (
            <Button className="button--default" type="button" onClick={onEnableEvent}>
              <FormattedMessage id="scheduling.title.enable-event" defaultMessage="Enable event" />
            </Button>
          )}
          {onDeleteEvent && selectedMode && (
            <Button
              className="button--default delete-event"
              type="submit"
              onClick={onDeleteEvent}
              disabled={deleteLastEventAvailable}
            >
              <FormattedMessage id="scheduling.title.delete" defaultMessage="Delete event" />
            </Button>
          )}
          {deleteLastEventAvailable && (
            <PremiumFeatureTooltip
              translationId="scheduling.title.delete-last-event"
              defaultMessage="You can't delete the last event in the schedule"
            />
          )}
          <Button onClick={goPrevious} className="button--default" type="button">
            <FormattedMessage id="scheduling.title.cancel" defaultValue="Cancel" />
          </Button>
        </div>
      </Fragment>
    );
  }
}

export default SelectEventMode;
