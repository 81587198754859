import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button } from 'jpi-cloud-web-ui-components';

import './updatecarddetail.scss';

const UpdateCardDetail = ({ savedCardDetail, selectPaymentSettingTab }) => {
  const { cardType = '', cardNoMask = '', cardExpDate = '' } = savedCardDetail;
  return (
    <div className="updateCardDetail">
      <div className="updateCardDetail__wrapper">
        <div className="updateCardDetail__cardDetails">
          {cardType}&#58; {cardNoMask}
          <p>
            <FormattedMessage id="paymentProviders.dibs-ticket.cardExpire" defaultMessage="Valid until" />
            &#58; {moment(cardExpDate).format('MM/YY')}
          </p>
        </div>
        <div className="updateCardDetail__buttonWrapper">
          <Button className="updateCardDetail__updateCard-button" onClick={selectPaymentSettingTab}>
            <FormattedMessage id="paymentProviders.dibs-ticket.updateCard" defaultMessage="Update card" />
          </Button>
        </div>
      </div>
    </div>
  );
};

UpdateCardDetail.propTypes = {
  savedCardDetail: PropTypes.shape({
    cardType: PropTypes.string.isRequired,
    cardNoMask: PropTypes.string.isRequired,
    cardExpDate: PropTypes.string.isRequired,
  }),
  selectPaymentSettingTab: PropTypes.func.isRequired,
};

export default UpdateCardDetail;
