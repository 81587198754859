import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './hamburger-menu-button.scss';

class MenuButton extends Component {
  state = {
    open: this.props.open,
    color: this.props.color ? this.props.color : 'black',
    prevProps: {},
  };

  static getDerivedStateFromProps(props, state) {
    const { prevProps } = state;
    if (prevProps.open != props.open || prevProps.color != props.color) {
      return {
        open: props.open,
        color: props.color,
        prevProps: props,
      };
    }
    return {
      prevProps: props,
    };
  }

  handleClick = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    return (
      <div
        className={'hamburgerMenuButton' + (this.state.open ? ' hamburgerMenuButton--open' : '')}
        onClick={this.props.onClick ? this.props.onClick : this.handleClick}
      >
        <div className="hamburgerMenuButton__line hamburgerMenuButton__line--top" />
        <div className="hamburgerMenuButton__line hamburgerMenuButton__line--middle" />
        <div className="hamburgerMenuButton__line hamburgerMenuButton__line--bottom" />
      </div>
    );
  }
}

MenuButton.propTypes = {
  open: PropTypes.bool,
  color: PropTypes.string,
  onClick: PropTypes.func,
};

export default MenuButton;
