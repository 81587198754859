const lastUsedSystemIdKey = 'LAST_USED_SYSTEM';
const lastUsedSystemBrandKey = 'LAST_USED_SYSTEM_BRAND';

export const set = (systemId, brandId) => {
  localStorage.setItem(lastUsedSystemIdKey, systemId);
  localStorage.setItem(lastUsedSystemBrandKey, brandId);
  return Promise.resolve();
};

export const get = () => {
  const systemId = localStorage.getItem(lastUsedSystemIdKey);
  const brandId = localStorage.getItem(lastUsedSystemBrandKey);

  return new Promise((resolve) => {
    const response = {
      status: 200,
      data: {
        id: systemId,
        brandId: brandId,
      },
    };

    resolve(response);
  });
};
