import * as api from '../../../../../api';

export const GET_BRAND_DETAILS_REQUEST = 'aboutManugacturer/GET_BRAND_DETAILS_REQUEST';
export const GET_BRAND_DETAILS_RESPONSE = 'aboutManugacturer/GET_BRAND_DETAILS_RESPONSE';
export const getBrandDetails = (brandId) => async (dispatch) => {
  dispatch({ type: GET_BRAND_DETAILS_REQUEST });
  try {
    const brandDetails = await api.getBrandDetails(brandId);
    if (brandDetails.status === 200 && brandDetails.data) {
      const addressId = brandDetails.data.addressId;
      if (addressId) {
        try {
          const brandAddress = await api.getAddressInfo(brandDetails.data.addressId);
          if (brandAddress.status === 200) {
            dispatch({
              type: GET_BRAND_DETAILS_RESPONSE,
              brandDetails: brandDetails.data,
              brandAddress: brandAddress.data,
            });
            return;
          }
        } catch (err) {
          console.warn(err);
        }
      }

      dispatch({ type: GET_BRAND_DETAILS_RESPONSE, brandDetails: brandDetails.data, brandAddress: {} });
      return;
    }
  } catch (err) {
    console.warn(err);
  }

  dispatch({ type: GET_BRAND_DETAILS_RESPONSE, brandDetails: {}, brandAddress: {} });
};
