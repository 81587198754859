import React from 'react';
import PropTypes from 'prop-types';
import { NavigationItem } from './NavigationItem';
import CustomLanguageSelector from '../CustomLanguageSelector/CustomLanguageSelector';
import './navigation.scss';

const Navigation = ({ url, showLangShortCodes, showLangIcon, isLoginHeader = false }) => {
  return (
    <nav className="navigation">
      <ul className="navigation-list">
        <NavigationItem
          className="active"
          href={'javascript:void(0)'}
          id="unauth.menu.private"
          defaultMessage="Consumers"
        />
        <NavigationItem href={url && url.WEB_PRO_URL} id="unauth.menu.professional" defaultMessage="Professionals" />
        <NavigationItem href={url && url.WEB_DEV_URL} id="unauth.menu.developer" defaultMessage="Developers" />
        {isLoginHeader && (
          <li className="navigation-item language-selector-li">
            <CustomLanguageSelector showShortCodes={showLangShortCodes} showIcon={showLangIcon} />
          </li>
        )}
      </ul>
      {!isLoginHeader && <CustomLanguageSelector showShortCodes={showLangShortCodes} showIcon={showLangIcon} />}
    </nav>
  );
};

Navigation.propTypes = {
  url: PropTypes.string.isRequired,
  showLangShortCodes: PropTypes.bool,
  showLangIcon: PropTypes.bool,
  isLoginHeader: PropTypes.bool,
};

export default Navigation;
