import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import './orderdetails.scss';

const orderDetails = ({ order }) => {
  const { data = {} } = order || {};
  const { orderDetails = {}, customer = {}, vatDetails = {} } = data;
  const { shippingAddress = {} } = orderDetails;
  const {
    firstName = '',
    lastName = '',
    address1 = '',
    address2 = '',
    city = '',
    zip = '',
    country = '',
  } = shippingAddress;
  const { email = '' } = customer;
  const { name = '', details = '', address = {} } = vatDetails;
  const { lineOne, lineTwo, country: vatCountry = '' } = address;
  return (
    <div className="OrderDetails__detailsWrapper">
      <div className="OrderDetails__userDetailsWrapper">
        <p className="OrderDetails__userDetailsTitle">
          <FormattedMessage id="orders.orderPage.userDetails" defaultMessage="Your Details" />
        </p>
        <div className="OrderDetails__userDetails">
          <p className="OrderDetails__userName">{`${firstName} ${lastName}`}</p>
          <p className="OrderDetails__userEmail">{email}</p>
          <p className="OrderDetails__userAddress">
            {address1} {address2 ? address2 : ''}
          </p>
          <p className="OrderDetails__userCity">{city}</p>
          <p className="OrderDetails__userZipCode">{zip}</p>
          <p className="OrderDetails__userCountry">{country}</p>
        </div>
      </div>
      <div className="OrderDetails__companyDetailsWrapper">
        <p className="OrderDetails__companyDetails">
          <FormattedMessage id="orders.orderPage.companyDetails" defaultMessage="Our Details" />
        </p>
        <p className="OrderDetails__orderVatDetails">
          <span className="OrderDetails__orderVatDetailsInfo">
            <p>
              {name}
              <p></p>
              {lineOne}
              <p></p>
              {lineTwo}
              <p></p>
              {vatCountry}
              <p></p>
              {details}
            </p>
          </span>
        </p>
      </div>
    </div>
  );
};

orderDetails.propTypes = {
  order: PropTypes.object.isRequired,
};
export default orderDetails;
