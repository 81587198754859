// @ts-check
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Checkbox, Input } from 'jpi-cloud-web-ui-components';
// eslint-disable-next-line
import * as InvitationTypes from './invitation-types';
import { systemCells } from './const';

import SystemRowHeader from './SystemRowHeader';
import SystemRow from './SystemRow';

import './systemlist.scss';

/**
 * SystemList component.
 *
 * @param {InvitationTypes.SystemList} props - Component props.
 * @returns {React.ReactNode} Rendered SystemList component.
 */
const SystemList = ({
  filteredSystemList,
  allSelected,
  searchTerm,
  setSearchTerm,
  hasOnlyOneSystemAvailable,
  selectedSystems,
  handleSelectSystem,
  toggleAllSelected,
  maxNumberOfGroups,
}) => {
  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div className="invitation-system-list">
      <table className="invitation-table">
        <tr className="list list-item list-item--header">
          {systemCells.map((systemCell) => (
            <Fragment key={systemCell.id}>
              <SystemRowHeader
                id={systemCell.id}
                defaultMessage={systemCell.defaultMessage}
                formElement={
                  systemCell.id === 'group.systems-list.select' ? (
                    <Checkbox
                      id="select-all"
                      name="select-all"
                      onChange={toggleAllSelected}
                      checked={allSelected}
                      disabled={hasOnlyOneSystemAvailable}
                      label={<FormattedMessage id="invitation.selectAll" defaultMessage="Select All" />}
                      onLabelClick={toggleAllSelected}
                    />
                  ) : systemCell.id === 'group.systems-list.search' && !hasOnlyOneSystemAvailable ? (
                    <div className="invitation-table-search-wrapper">
                      <Input type="text" name="search" onChange={handleChange} value={searchTerm} />
                      <i className="fas fa-magnifying-glass"></i>
                    </div>
                  ) : null
                }
              />
            </Fragment>
          ))}
        </tr>
        {filteredSystemList.map((system) => (
          <SystemRow
            key={system.id}
            hasOnlyOneSystemAvailable={hasOnlyOneSystemAvailable}
            handleChange={handleSelectSystem}
            system={system}
            isSelected={!!selectedSystems.find((selectedSystem) => selectedSystem.id === system.id)}
          />
        ))}
      </table>
      <div style={{ height: '15px' }} />
      <div className={`${selectedSystems.length > maxNumberOfGroups ? 'error' : ''} invitation-selection-info`}>
        <FormattedMessage id="invitation.numberSelected" defaultMessage="Maximum Selected:" />
        {selectedSystems.length}
        {selectedSystems.length > maxNumberOfGroups && (
          <FormattedMessage
            id="invitation.numberSelectedAboveMax"
            defaultMessage="(You can’t select more than {count} {count, plural, one {system} other {systems}})"
            values={{ count: maxNumberOfGroups }}
          />
        )}
      </div>
    </div>
  );
};

SystemList.propTypes = {
  filteredSystemList: PropTypes.array,
  handleSelectSystem: PropTypes.func,
  toggleAllSelected: PropTypes.func,
  selectedSystems: PropTypes.array,
  maxNumberOfGroups: PropTypes.number,
  allSelected: PropTypes.bool,
  searchTerm: PropTypes.string,
  setSearchTerm: PropTypes.func,
  hasOnlyOneSystemAvailable: PropTypes.bool,
};

export default SystemList;
