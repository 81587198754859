import energy from './contura-energy.svg';
import fanMode from './contura-fan.svg';
import systemMode from './contura-system.svg';
import status from './contura-status.svg';
import temperature from './contura-temperature.svg';
import conturaAutoModeHigh from './contura-automode-high.svg';
import conturaAutoModeLow from './contura-automode-low.svg';
import conturaAutoModeMedium from './contura-automode-medium.svg';
import conturaModeHigh from './contura-mode-high.svg';
import conturaModeLow from './contura-mode-low.svg';
import conturaModeMedium from './contura-mode-medium.svg';
import conturaStateFire from './contura-state-fire.svg';
import conturaStateRefill from './contura-state-refill.svg';
import conturaStateStandby from './contura-state-standby.svg';
import conturaStateIgnite from './contura-state-ignite.svg';
import electricalPrice from './el-prices-dark.png';
import myUplinkIcons from '../myuplink';

export default {
  ...myUplinkIcons,
  electricalPrice,
  energy,
  fanMode,
  systemMode,
  status,
  temperature,
  conturaAutoModeHigh,
  conturaAutoModeLow,
  conturaAutoModeMedium,
  conturaModeHigh,
  conturaModeLow,
  conturaModeMedium,
  conturaStateFire,
  conturaStateRefill,
  conturaStateStandby,
  conturaStateIgnite,
};
