import React from 'react';
import { Button, Input } from 'jpi-cloud-web-ui-components';
import { Formik } from 'formik';
import * as yup from 'yup';
import { formatErrorMessage } from '../../../../../localization/message-formatting';
import { FormattedMessage, injectIntl } from 'react-intl';

import PropTypes from 'prop-types';

const errorMessages = {
  'mode-name.required': {
    id: 'scheduling.mode-name.error.validation.required',
    defaultMessage: 'Mode is mandatory field',
  },
  'mode-name.duplicate': {
    id: 'scheduling.mode-name.error.validation.duplicate',
    defaultMessage: 'Mode name already exists',
  },
};

const addModeSchema = yup.object().shape({
  modeName: yup.string().trim().required('mode-name.required'),
});

class AddMode extends React.PureComponent {
  static propTypes = {
    intl: PropTypes.object,
    modes: PropTypes.array.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
  };

  validate = (values) => {
    const errors = {};

    if (values.modeName) {
      const modeName = values.modeName.toLowerCase();
      const isNameExist = this.props.modes.some((mode) => mode.name.toLowerCase() === modeName);

      if (isNameExist) {
        errors.modeName = 'mode-name.duplicate';
      }
    }

    return errors;
  };

  render() {
    const { intl, onSubmit, onCancel } = this.props;

    return (
      <div className="popup-body">
        <div className="popup-header">
          <p className="text">
            <FormattedMessage id="scheduling.mode-name.title" defaultMessage="Name for new mode" />
          </p>
        </div>
        <Formik
          initialValues={{ modeName: '' }}
          validationSchema={addModeSchema}
          onSubmit={onSubmit}
          validate={this.validate}
        >
          {({ values, errors, handleChange, handleSubmit, handleBlur }) => (
            <form onSubmit={handleSubmit} className="add-mode-wrapper">
              <FormattedMessage id="scheduling.mode-name.placeholder" defaultMessage="New mode">
                {(placeholder) => (
                  <Input
                    placeholder={placeholder}
                    type="text"
                    name="modeName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.modeName}
                    maxLength={20}
                    error={errors.modeName && formatErrorMessage(intl, errorMessages, errors.modeName)}
                  />
                )}
              </FormattedMessage>

              <div className="popup-bottom-panel">
                <Button className="button--secondary" type="submit" disabled={Object.keys(errors).length > 0}>
                  <FormattedMessage id="scheduling.title.add" defaultMessage="Add" />
                </Button>
                <Button className="button--default" type="button" onClick={onCancel}>
                  <FormattedMessage id="scheduling.title.cancel" defaultMessage="Cancel" />
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    );
  }
}

export default injectIntl(AddMode);
