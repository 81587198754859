import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Button } from 'jpi-cloud-web-ui-components';

import { setSelectedDevice, getDevices } from '../../../../actions';

import './success.scss';

class Success extends React.PureComponent {
  static propTypes = {
    selectedDevice: PropTypes.object.isRequired,
    setSelectedDevice: PropTypes.func.isRequired,
    getDevices: PropTypes.func.isRequired,
    selectedSystem: PropTypes.object,
  };

  onDoneHandler = async () => {
    await this.props.getDevices(this.props.selectedSystem.id);
    this.props.setSelectedDevice();
  };

  render() {
    return (
      <div className="update-success">
        <div className="row">
          <div className="col-sm-8">
            <div className="device-name">{this.props.selectedDevice.name}</div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 progress-section">
            <strong>
              <FormattedMessage id="devices.done" defaultMessage="Done" />
            </strong>
            <div className="progress">
              <div className="progress-bar"></div>
            </div>
          </div>
          <div className="col-sm-12 text-center action-buttons">
            <Button className="button button--secondary" onClick={() => this.onDoneHandler()} type="button">
              <FormattedMessage id="devices.done" defaultMessage="Done" />
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ app: { selectedSystem }, devices: { selectedDevice } }) => ({
  selectedDevice,
  selectedSystem,
});

const mapDispatchToProps = {
  setSelectedDevice,
  getDevices,
};

export default connect(mapStateToProps, mapDispatchToProps)(Success);
