import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Spinner } from 'jpi-cloud-web-ui-components';

import './restarting.scss';

class Restarting extends React.PureComponent {
  static propTypes = {
    selectedDevice: PropTypes.object.isRequired,
  };
  render() {
    return (
      <div className="update-restarting">
        <div className="row">
          <div className="col-sm-8">
            <div className="device-name">{this.props.selectedDevice.name}</div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 status-section">
            <FormattedMessage id="devices.rebooting-the-device" defaultMessage="Rebooting the device" />
            <Spinner />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ devices: { selectedDevice } }) => ({ selectedDevice });

export default connect(mapStateToProps, {})(Restarting);
