import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import * as client from '../../../../../../Shop/client';
import Modal from '../../../../../../layout/Modal';
import { Button, Title } from 'jpi-cloud-web-ui-components';

import './cancelsubscription.scss';
import { connect } from 'react-redux';

const CancelSubscription = ({
  subscriptionId,
  cancelSubscription,
  setCancelSubscription,
  cancelSubscriptionModel,
  deviceId,
}) => {
  const confirmCancelSubscription = async () => {
    await cancelSubscription(subscriptionId, deviceId);
    setCancelSubscription(!cancelSubscriptionModel);
  };

  return (
    <Modal className="checkout-modal-dialog" show={cancelSubscriptionModel}>
      <div className="cancelSubscription">
        <div className="popup-modal">
          <div className="cancelSubscription_wrapper">
            <div className="cancelSubscription__title">
              <Title
                className="subscriptionTitle"
                titleTranslationId="shop.page.cancelSubscription"
                defaultMessage="Cancel Subscription"
              />
            </div>
            <div className="cancelSubscription__description">
              <FormattedMessage
                id="shop.page.aboutToCancelSubscription"
                defaultMessage="Are you sure you want to cancel your subscription to myUplink"
              />
            </div>
            <div className="cancelSubscription__button-wrapper">
              <Button
                className="cancelSubscription__cancelSubscription-button"
                onClick={() => {
                  confirmCancelSubscription();
                }}
              >
                <FormattedMessage id="button.cancelSubscription" defaultMessage="Cancel Subscription" />
              </Button>
              <Button
                className="cancelSubscription__back-button"
                onClick={() => {
                  setCancelSubscription(!cancelSubscriptionModel);
                }}
              >
                <FormattedMessage id="button.back" defaultMessage="Back" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

CancelSubscription.propTypes = {
  cancelSubscriptionModel: PropTypes.bool.isRequired,
  setCancelSubscription: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  cancelSubscription: PropTypes.func.isRequired,
  subscriptionId: PropTypes.string.isRequired,
  deviceId: PropTypes.string.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  cancelSubscription(id, deviceId) {
    dispatch(client.cancelSubscription(id, deviceId));
  },
});

export default connect(null, mapDispatchToProps)(CancelSubscription);
