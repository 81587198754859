import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Modal from '../../../../../../layout/Modal';
import { ModalBody, ModalFooter } from 'react-bootstrap';
import { Button } from 'jpi-cloud-web-ui-components';

const ConfirmPendingMessage = ({ showConfirmModal, toggleModal }) => {
  return (
    <Modal show={showConfirmModal} className="popup-modal" backdrop="static">
      <ModalBody>
        <div className="confirm-message">
          <p className="text">
            <FormattedMessage
              id="devices.confirm-pending-message"
              defaultMessage="The request is being processed. Please wait until the update is confirmed on device."
            />
          </p>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button className="button--default" onClick={toggleModal} type="button">
          <FormattedMessage id="devices.close" defaultMessage="Close" />
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ConfirmPendingMessage.propTypes = {
  showConfirmModal: PropTypes.bool,
  toggleModal: PropTypes.func.isRequired,
};

export default ConfirmPendingMessage;
