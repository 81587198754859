import React from 'react';
import PropTypes from 'prop-types';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import LocalizationWrapper from './localization/LocalizationWrapper';
import App from './components/App';

const Root = ({ store, history }) => (
  <Provider store={store}>
    <LocalizationWrapper>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </LocalizationWrapper>
  </Provider>
);

Root.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default Root;
