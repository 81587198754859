import indoorClimate from './IndoorClimate.png';
import hotWater from './HotWater.png';
import ventilation from './Ventilation.png';
import pool from './Pool.png';
import solar from './Solar.png';
import status from './Status.png';
import weather from './Weather.png';
import clearDay from './clear-day.svg';
import clearNight from './clear-night.svg';
import cloudy from './cloudy.svg';
import fog from './fog.svg';
import hail from './hail.svg';
import partlyCloudyDay from './partly-cloudy-day.svg';
import partlyCloudyNight from './partly-cloudy-night.svg';
import rain from './rain.svg';
import sleet from './sleet.svg';
import snow from './snow.svg';
import thunderstorm from './thunderstorm.svg';
import wind from './wind.svg';
import electricalPrice from './el-prices.png';
import myUplinkIcons from '../myuplink';

export default {
  ...myUplinkIcons,
  electricalPrice,
  indoorClimate,
  hotWater,
  ventilation,
  pool,
  solar,
  status,
  weather,
  clearDay,
  clearNight,
  cloudy,
  fog,
  hail,
  partlyCloudyDay,
  partlyCloudyNight,
  rain,
  sleet,
  snow,
  thunderstorm,
  wind,
};
