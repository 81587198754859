import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

const PremiumAvailable = ({ formattedValidUntil }) => {
  return (
    <div className="Product__isPremiumAvailable">
      <p className="Product__validTo">
        <FormattedMessage id="shop.page.validTo" defaultMessage="Valid until" />
        &#58; &nbsp;
        {formattedValidUntil}
      </p>
      <p className="Product__existing-subscription-paragraph">
        <FormattedMessage
          id="shop.product.subscriptionExisting"
          defaultMessage={
            'You have an active subscription for this product, you can renew it when less then 30 days remaining.'
          }
        />
      </p>
    </div>
  );
};

PremiumAvailable.propTypes = {
  formattedValidUntil: PropTypes.string.isRequired,
};

export default PremiumAvailable;
