//@ts-check
import { defaultValueLowerLimit } from '../../constants/constants';
// eslint-disable-next-line
import * as GenericTileTypes from './generic-tile-types';

/**
 * Takes the value and returns formatted representation. If kind == "Str", display value as is.
 *
 * Props definition.
 *
 * @typedef {object} Props
 * @property {GenericTileTypes.Parameter} parameter - Display parameter
 *
 *
 * @param {Props} parameterObj
 * @returns {GenericTileTypes.FormattedDisplayValue}
 */
export const getFormattedValue = ({ parameter }) => {
  const { unit, value, parameter: param } = parameter;
  const { kind, decimals, scaleVal, enumValues = [] } = param || {};

  const noneValue = '--';

  const parameterHasScaleVal = !isNaN(Number(scaleVal)) && typeof Number(scaleVal) === 'number';
  const parameterHasDecimals = typeof Number(decimals) === 'number';

  let formattedValue = value || noneValue;
  let displayUnit = '';

  if (unit !== 'String') {
    displayUnit = unit || '';
  }

  if (kind?.toLowerCase() === 'str') {
    return {
      displayUnit,
      formattedValue,
    };
  }

  let number = Number(value);

  const isEnumValue = enumValues.length > 0 && enumValues.some((ev) => Number(ev.value) === value);
  if (isEnumValue) {
    const enumValue = enumValues.find((ev) => Number(ev.value) === value);
    return { enumValueText: enumValue?.text || '' };
  }

  try {
    if (number <= defaultValueLowerLimit) {
      return {
        displayUnit,
        formattedValue: noneValue,
      };
    }

    if (parameterHasScaleVal) {
      number *= Number(scaleVal);
    }

    if (parameterHasDecimals) {
      number = Number(number.toFixed(Number(decimals)));
    }

    if (!isNaN(number) && number >= defaultValueLowerLimit) {
      formattedValue = number.toString();
    } else {
      formattedValue = noneValue;
    }
  } catch (err) {
    formattedValue = noneValue;
    console.warn('Error happened while parsing parameter data', err);
  }

  return {
    displayUnit,
    formattedValue,
  };
};
