import { GET_TEXT_RESPONSE } from './actions';

const initialState = {};

const saveText = (state, translations) => {
  return translations.reduce((prev, current) => {
    return { ...prev, [current.id]: current };
  }, state);
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_TEXT_RESPONSE:
      return saveText(state, action.translations);
    default:
      return state;
  }
};
