import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from '../../inputs/Dropdown';
import { FormattedMessage, injectIntl } from 'react-intl';
import { TilePropertyKeys, getTileProperties } from '../../tiles/TileManager/TileTypes';
import { Button, Input, Spinner } from 'jpi-cloud-web-ui-components';

export const ParameterTypes = {
  Display: 'display',
  Chart: 'chart',
  Setting: 'setting',
};

const left = 0;
const right = 1;

class TilePropertyEditor extends React.Component {
  static propTypes = {
    onBack: PropTypes.func,
    onSubmit: PropTypes.func,
    tileType: PropTypes.string,
    tileNameTextId: PropTypes.string,
    setTitle: PropTypes.func.isRequired,
    getTitle: PropTypes.func.isRequired,
    getChartValue: PropTypes.func.isRequired,
    setChartValue: PropTypes.func.isRequired,
    properties: PropTypes.object,
    removeChartValue: PropTypes.func.isRequired,
    getDisplayParameter: PropTypes.func.isRequired,
    setDisplayParameter: PropTypes.func.isRequired,
    removeDisplayParameter: PropTypes.func.isRequired,
    setSettingsParameters: PropTypes.func.isRequired,

    groupId: PropTypes.string,
    tileParameters: PropTypes.array,

    getTileParameters: PropTypes.func.isRequired,
    intl: PropTypes.object,
    loading: PropTypes.bool,
  };

  async componentDidMount() {
    await this.props.getTileParameters(this.props.groupId, this.props.tileType, this.props.tileNameTextId);

    const requiresParameterPopulation =
      !this.props.getDisplayParameter(left) && !this.props.getDisplayParameter(right) && !this.props.getChartValue();

    if (requiresParameterPopulation) {
      const displayTileParameters = [...this.props.tileParameters].filter((param) => {
        return param.parameterTypes.some((t) => t === ParameterTypes.Display && t !== ParameterTypes.Setting);
      });
      const sortedDisplayParameters = sortByTilePrioAndId(displayTileParameters);

      if (sortedDisplayParameters.length >= 2) {
        this.props.setDisplayParameter(left, sortedDisplayParameters[0]);
        this.props.setDisplayParameter(right, sortedDisplayParameters[1]);
      } else if (sortedDisplayParameters.length === 1) {
        this.props.setDisplayParameter(left, sortedDisplayParameters[0]);
      }

      const chartTileParameters = [...this.props.tileParameters].filter((param) => {
        return param.parameterTypes.some((t) => t === ParameterTypes.Chart && t !== ParameterTypes.Setting);
      });
      const sortedChartParameters = sortByTilePrioAndId(chartTileParameters);

      if (sortedChartParameters.length >= 1) {
        this.props.setChartValue(sortedChartParameters[0]);
      }
    }
  }

  state = {
    title: null,
    oldTitle: null,
  };

  render() {
    const {
      onBack,
      onSubmit,
      tileType,
      getTitle,
      getChartValue,
      setTitle,
      setChartValue,
      removeChartValue,
      getDisplayParameter,
      setDisplayParameter,
      removeDisplayParameter,
      tileParameters,
      setSettingsParameters,
      loading,
    } = this.props;

    const TileProperties = getTileProperties(tileType);

    if (!tileParameters) return null;

    if (!this.state.title && this.state.title !== '') {
      this.setState({ title: getTitle() });
      this.setState({ oldTitle: getTitle() });
    }

    const getProperties = () => {
      return DropdownsStub.filter((p) => TileProperties.includes(p.name));
    };

    const saveTile = () => {
      var settingParameters = tileParameters.filter((p) =>
        p.parameterTypes.some((t) => t === ParameterTypes.Setting && t !== ParameterTypes.Display)
      );
      setSettingsParameters(settingParameters);

      onSubmit();
    };

    const DropdownsStub = [
      {
        name: TilePropertyKeys.LEFT_HAND_VALUE,
        title: this.props.intl.formatMessage({ id: 'tiles.configuration.lefthand' }),
        placeholder: this.props.intl.formatMessage({ id: 'tiles.configuration.lefthand.empty' }),
        getValue: () => getDisplayParameter(left),
        setValue: (value) => setDisplayParameter(left, value),
        removeValue: () => removeDisplayParameter(left),
        ignoreInternalState: true,
        values: sortByTilePrioAndLocalizedName(
          tileParameters
            .filter((p) => p.parameterTypes.some((t) => t === ParameterTypes.Display && t !== ParameterTypes.Setting))
            .filter((p) => p !== getDisplayParameter(right))
        ),
      },
      {
        name: TilePropertyKeys.RIGHT_HAND_VALUE,
        title: this.props.intl.formatMessage({ id: 'tiles.configuration.righthand' }),
        placeholder: this.props.intl.formatMessage({ id: 'tiles.configuration.righthand.empty' }),
        getValue: () => getDisplayParameter(right),
        setValue: (value) => setDisplayParameter(right, value),
        removeValue: () => removeDisplayParameter(right),
        ignoreInternalState: true,
        values: sortByTilePrioAndLocalizedName(
          tileParameters
            .filter((p) => p.parameterTypes.some((t) => t === ParameterTypes.Display && t !== ParameterTypes.Setting))
            .filter((p) => p !== getDisplayParameter(left))
        ),
      },
      {
        name: TilePropertyKeys.CHART_VALUE,
        title: this.props.intl.formatMessage({ id: 'tiles.configuration.chart' }),
        placeholder: this.props.intl.formatMessage({ id: 'tiles.configuration.chart.empty' }),
        getValue: getChartValue,
        setValue: setChartValue,
        removeValue: () => removeChartValue(),
        ignoreInternalState: true,
        values: sortByTilePrioAndLocalizedName(
          tileParameters.filter((p) =>
            p.parameterTypes.some((t) => t === ParameterTypes.Chart && t !== ParameterTypes.Setting)
          )
        ),
      },
    ];

    const hasDisplayParameters = DropdownsStub.some((stub) => stub.values.length > 0);
    const submitDisabled = () => this.state.title === '';
    const leftProperty = getDisplayParameter(left) || null;
    const rightProperty = getDisplayParameter(right) || null;

    return (
      <div className="tile-configuration-popup">
        <h2>
          <FormattedMessage
            id="tiles.configuration.edit-properties.title"
            defaultMessage="Edit content of {tileName} tile"
            values={{
              tileName: this.state.oldTitle,
            }}
          />
        </h2>
        <div className="popup-body">
          <div className="popup-input">
            <Input
              value={this.state.title}
              onBlur={(e) => setTitle(e.target.value, true)}
              onChange={(e) => this.setState({ title: e.target.value })}
              placeholder={this.props.intl.formatMessage({
                id: 'tiles.configuration.tileName',
                defaultMessage: 'Tile name',
              })}
            />
          </div>

          {loading && <Spinner dark />}

          {!loading &&
            getProperties().map((prop, i) => {
              const currentValue = prop.getValue();
              const items =
                prop.name === TilePropertyKeys.LEFT_HAND_VALUE && rightProperty
                  ? prop.values.filter((p) => p.id !== rightProperty.id)
                  : prop.name === TilePropertyKeys.RIGHT_HAND_VALUE && leftProperty
                  ? prop.values.filter((p) => p.id !== leftProperty.id)
                  : prop.values;

              const tileValue = items.find((value) => value.id === (currentValue && currentValue.id));

              return (
                items.length > 0 && (
                  <div className="tile-property-container" key={i}>
                    <div className="tile-property-title">{prop.title}:</div>
                    <div className="tile-property-wrapper">
                      <div className="tile-property-selector">
                        <Dropdown
                          className="border"
                          id={'tile-property' + i}
                          items={items}
                          selectedItem={tileValue && tileValue.id ? tileValue : null}
                          labelGetter={(e) => e.name}
                          onSelect={prop.setValue}
                          placeholder={prop.placeholder}
                          ignoreInternalState={prop.ignoreInternalState}
                        />
                      </div>
                      {tileValue && tileValue.id ? (
                        <Button
                          onClick={prop.removeValue}
                          className="button--default tile-property-remove"
                          type="button"
                        >
                          X
                        </Button>
                      ) : null}
                    </div>
                  </div>
                )
              );
            })}
        </div>
        <div className={hasDisplayParameters ? 'popup-bottom-panel' : 'popup-bottom-panel empty-parameter-list'}>
          <Button onClick={onBack} className="button--default" type="button">
            <FormattedMessage id="tiles.configuration.buttons.back" defaultValue="Back" />
          </Button>
          <Button onClick={saveTile} className="button--secondary" type="button" disabled={submitDisabled()}>
            <FormattedMessage id="tiles.configuration.buttons.submit" defaultValue="Submit" />
          </Button>
        </div>
      </div>
    );
  }
}

export default injectIntl(TilePropertyEditor);

export function sortByTilePrioAndId(tileParameters) {
  var sortedTileParameters = [...tileParameters];

  sortedTileParameters.sort((paramA, paramB) => {
    if (paramA.parameter.tilePrio && !paramB.parameter.tilePrio) {
      return -1;
    }

    if (!paramA.parameter.tilePrio && paramB.parameter.tilePrio) {
      return 1;
    }

    if (paramA.parameter.tilePrio && paramB.parameter.tilePrio) {
      if (+paramA.parameter.tilePrio < +paramB.parameter.tilePrio) {
        return -1;
      }
      if (+paramA.parameter.tilePrio > +paramB.parameter.tilePrio) {
        return 1;
      }
    }

    if (+paramA.id < +paramB.id) {
      return -1;
    }
    if (+paramA.id > +paramB.id) {
      return 1;
    }

    return 0;
  });

  return sortedTileParameters;
}

export function sortByTilePrioAndLocalizedName(tileParameters) {
  var sortedTileParameters = [...tileParameters];

  sortedTileParameters.sort((paramA, paramB) => {
    if (paramA.parameter.tilePrio && !paramB.parameter.tilePrio) {
      return -1;
    }

    if (!paramA.parameter.tilePrio && paramB.parameter.tilePrio) {
      return 1;
    }

    if (paramA.parameter.tilePrio && paramB.parameter.tilePrio) {
      if (+paramA.parameter.tilePrio < +paramB.parameter.tilePrio) {
        return -1;
      }
      if (+paramA.parameter.tilePrio > +paramB.parameter.tilePrio) {
        return 1;
      }
    }

    if (paramA.name < paramB.name) {
      return -1;
    }
    if (paramA.name > paramB.name) {
      return 1;
    }

    return 0;
  });

  return sortedTileParameters;
}

export function sortByPrio(tileParameters) {
  var sortedTileParameters = [...tileParameters];

  sortedTileParameters.sort((paramA, paramB) => {
    if (paramA.parameter.prio && !paramB.parameter.prio) {
      return -1;
    }

    if (!paramA.parameter.prio && paramB.parameter.prio) {
      return 1;
    }

    if (paramA.parameter.prio && paramB.parameter.prio) {
      if (+paramA.parameter.prio < +paramB.parameter.prio) {
        return -1;
      }
      if (+paramA.parameter.prio > +paramB.parameter.prio) {
        return 1;
      }
    }
  });

  return sortedTileParameters;
}
