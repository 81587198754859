import { GET_ALARMS_REQUEST, GET_ALARMS_RESPONSE_SUCCEEDED } from './actions';

import {
  RESET_ALARM_RESPONSE_SUCCEEDED,
  ACTIVATE_AID_MODE_ALARM_REQUEST,
  ACTIVATE_AID_MODE_ALARM_RESPONSE_SUCCEEDED,
  ACTIVATE_AID_MODE_ALARM_RESPONSE_FAILED,
  RESET_ALARM_REQUEST,
  RESET_ALARM_RESPONSE_FAILED,
} from './components/AlarmsListItem/actions';

const initialState = {
  loading: false,
  alarms: {
    inbox: null,
    archived: null,
  },
  resetedAlarms: [],
  alarmsInAidMode: [],
  deletingAlarmsId: [],
};

const isReseted = (alarm, alarmToReset) =>
  alarm.deviceId === alarmToReset.deviceId && alarm.alarmId === alarmToReset.alarmId;

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALARMS_REQUEST:
      if (state.alarms[action.alarmsType] && state.alarms[action.alarmsType].deviceId !== action.deviceId) {
        return {
          ...state,
          alarms: {
            inbox: null,
            archived: null,
          },
        };
      }
      return { ...state, loading: true };
    case GET_ALARMS_RESPONSE_SUCCEEDED: {
      const alarms = state.alarms[action.alarmsType] ? state.alarms[action.alarmsType].alarms : [];
      return {
        ...state,
        alarms: {
          ...state.alarms,
          [action.alarmsType]: {
            alarms: [...(action.resetAlarms ? [] : alarms), ...action.alarms],
            deviceId: action.deviceId,
            page: action.page,
            totalCount: action.totalCount,
          },
        },
        loading: false,
      };
    }
    case RESET_ALARM_REQUEST:
      return {
        ...state,
        resetedAlarms: [...state.resetedAlarms, action.alarm],
      };
    case RESET_ALARM_RESPONSE_SUCCEEDED:
      return {
        ...state,
        alarms: {
          inbox: {
            ...state.alarms.inbox,
            alarms: state.alarms.inbox.alarms.filter((a) => !isReseted(a, action.alarm)),
          },
          archived: {
            ...state.alarms.archived,
            alarms: state.alarms.archived.alarms.concat(
              state.alarms.inbox.alarms.filter((a) => isReseted(a, action.alarm))
            ),
          },
        },
        resetedAlarms: state.resetedAlarms.filter((a) => !isReseted(a, action.alarm)),
      };
    case RESET_ALARM_RESPONSE_FAILED:
      return {
        ...state,
        resetedAlarms: state.resetedAlarms.filter((a) => !isReseted(a, action.alarm)),
      };
    case ACTIVATE_AID_MODE_ALARM_REQUEST:
      return {
        ...state,
        alarmsInAidMode: [
          ...state.alarmsInAidMode,
          {
            deviceId: action.alarm.deviceId,
            aidMode: action.aidMode,
          },
        ],
      };
    case ACTIVATE_AID_MODE_ALARM_RESPONSE_SUCCEEDED:
      return {
        ...state,
        alarms: {
          ...state.alarms,
          inbox: {
            ...state.alarms.inbox,
            alarms: state.alarms.inbox.alarms.map((e) =>
              e.deviceId === action.request.alarm.deviceId ? { ...e, activeAidMode: action.request.aidMode } : e
            ),
          },
        },
        alarmsInAidMode: state.alarmsInAidMode.filter((a) => a.deviceId !== action.request.alarm.deviceId),
      };
    case ACTIVATE_AID_MODE_ALARM_RESPONSE_FAILED:
      return {
        ...state,
        alarmsInAidMode: state.alarmsInAidMode.filter((a) => a.deviceId !== action.request.alarm.deviceId),
      };

    default:
      return state;
  }
};
