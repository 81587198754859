import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import InfoIcon from '../InfoIcon';

import './scheduleTooltip.scss';

const ScheduleTooltip = ({ modeSettings, hidden }) => {
  const filteredSettings = modeSettings.filter((s) => !!s.name);

  const ModeSettingsPopover = (
    <Popover id="popover-basic" className="schedule-tooltip">
      <p className="popover-title">
        {filteredSettings.length > 0 ? (
          <FormattedMessage id="scheduling.mode-settings.title" defaultMessage="Mode settings" />
        ) : (
          <FormattedMessage
            id="scheduling.no-mode-settings.title"
            defaultMessage="This mode doesn't have any selected settings"
          />
        )}
      </p>
      <ul className="settings-list">
        {filteredSettings.map((setting) => {
          const overrider = setting.enumValues.find((ev) => ev.value === setting.value);
          return (
            <li className="settings-item" key={setting.settingId}>
              <div className="settings-item--name">{setting.name}</div>
              <div className="settings-item--value">
                {overrider ? (
                  overrider.text
                ) : setting.type === 'OnOff' ? (
                  setting.value === 1 ? (
                    <FormattedMessage id="scheduling.onOff.on" defaultMessage="On" />
                  ) : (
                    <FormattedMessage id="scheduling.onOff.off" defaultMessage="Off" />
                  )
                ) : (
                  `${
                    (
                      (setting &&
                        setting.enumValues &&
                        setting.enumValues.find((val) => val.value === setting.value)) ||
                      {}
                    ).name ||
                    Number(setting.value * Number(setting.scaleValue)).toFixed(Number(setting.decimalsNumber)) ||
                    Number(setting.defaultValue * Number(setting.scaleValue)).toFixed(Number(setting.decimalsNumber))
                  } ${setting.unit}`
                )}
              </div>
            </li>
          );
        })}
      </ul>
    </Popover>
  );

  return (
    <>
      {!hidden && (
        <OverlayTrigger trigger={['hover', 'focus']} placement="left" overlay={ModeSettingsPopover}>
          <div className="icon-wrapper">
            <InfoIcon />
          </div>
        </OverlayTrigger>
      )}
      {hidden && (
        <div className="icon-wrapper grey">
          <InfoIcon />
        </div>
      )}
    </>
  );
};

ScheduleTooltip.propTypes = {
  modeSettings: PropTypes.array.isRequired,
  hidden: PropTypes.bool.isRequired,
};

export default ScheduleTooltip;
