import axios from 'axios';

const WEB_PRO_URL = process.env.WEB_PRO_URL || '';
const myUplinkValuesIndex = 0;

const CONFIG = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export const getLocalLatestAgreementsVersionsList = async () => {
  const tosMetadata = await axios.get(`${WEB_PRO_URL}/terms-of-service-metadata.json`, CONFIG);
  const privacyPolicyMetadata = await axios.get(`${WEB_PRO_URL}/private-policy-metadata.json`, CONFIG);
  const proTosMetadata = await axios.get(`${WEB_PRO_URL}/terms-of-service-pro-metadata.json`, CONFIG);

  const latestAgreements = {
    termsOfService: tosMetadata.data[myUplinkValuesIndex].version,
    privacyPolicy: privacyPolicyMetadata.data[myUplinkValuesIndex].version,
    proTermsOfService: proTosMetadata.data[myUplinkValuesIndex].version,
  };

  return latestAgreements;
};
