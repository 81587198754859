import { GET_TILE_CHART_DATA_REQUEST, GET_TILE_CHART_DATA_RESPONSE, CLEAN_CHART_DATA } from './actions';

const initialState = {
  loading: false,
  points: [],
  precedingPoint: null,
  predictedPoint: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_TILE_CHART_DATA_REQUEST:
      return { ...state, loading: true };
    case GET_TILE_CHART_DATA_RESPONSE:
      return {
        ...state,
        loading: false,
        points: action.points,
        precedingPoint: action.precedingPoint,
        predictedPoint: action.predictedPoint,
      };
    case CLEAN_CHART_DATA:
      return { initialState };
    default:
      return state;
  }
};
