//@ts-check
import React from 'react';
import PropTypes from 'prop-types';

import { getFormattedValue } from '../GenericTile/utils';
// eslint-disable-next-line
import * as Types from '../GenericTile/generic-tile-types';

/**
 * Props definition.
 *
 * @typedef {object} Props
 * @property {Types.Parameter} parameter - Display parameter
 */

/**
 *
 * @param {Props} param0
 * @returns {React.ReactElement}
 */
const DisplayParameter = ({ parameter }) => {
  const { displayUnit, formattedValue, enumValueText } = getFormattedValue({ parameter });

  if (enumValueText) {
    return <div className="settingName display-parameter">{enumValueText}</div>;
  }

  return (
    <div className="settingName display-parameter">
      {parameter?.unit?.trim() === '$' ? (
        <>
          {displayUnit} {formattedValue}
        </>
      ) : (
        <span>
          {formattedValue} {!!parameter.unit && displayUnit}
        </span>
      )}
    </div>
  );
};

DisplayParameter.propTypes = {
  parameter: PropTypes.object.isRequired,
};

export default DisplayParameter;
