export const USER_STATUS = {
  Approved: 'Approved',
  PendingApprove: 'PendingApprove',
};

// For HP24Account accounts, the potential statuses on connect user successfully include: UserInvited and RequestedConfirmation
// UserInvited represents the status when a user is not in the system and is invited to connect to the system
// RequestedConfirmation represents the status when a user is in the system and requires confirmation
export const ConnectHP24AccountStatus = {
  UserInvited: 'UserInvited',
  RequestedConfirmation: 'RequestedConfirmation',
};

export const USER_SECURITY_LEVELS = {
  admin: 'admin',
  servicePartner: 'servicePartner',
  viewer: 'viewer',
  manager: 'manager',
  hp24installer: 'hp24installer',
};

export const securityLevels = [
  {
    value: USER_SECURITY_LEVELS.viewer,
    text: {
      id: 'security.Viewer',
      defaultMessage: 'Viewer',
    },
  },
  {
    value: USER_SECURITY_LEVELS.manager,
    text: {
      id: 'security.Manager',
      defaultMessage: 'Manager',
    },
  },
  {
    value: USER_SECURITY_LEVELS.admin,
    text: {
      id: 'security.Admin',
      defaultMessage: 'Admin',
    },
  },
];

export const systemRoles = [
  ...securityLevels,
  {
    value: USER_SECURITY_LEVELS.hp24installer,
    text: {
      id: 'security.HP24Account',
      defaultMessage: 'Heatpump24',
    },
  },
  {
    value: USER_SECURITY_LEVELS.servicePartner,
    text: {
      id: 'security.servicePartner',
      defaultMessage: 'Service Partner',
    },
  },
];

export const securityLevelsPriorities = {
  [USER_SECURITY_LEVELS.admin]: 0,
  [USER_SECURITY_LEVELS.servicePartner]: 1,
  [USER_SECURITY_LEVELS.manager]: 2,
  [USER_SECURITY_LEVELS.viewer]: 3,
  [USER_SECURITY_LEVELS.hp24installer]: 4,
};
