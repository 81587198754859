import * as api from '../../../../../api';

export const RENAME_PROFILE_REQUEST = 'system-profile/RENAME_PROIFLE_REQUEST';
export const RENAME_PROFILE_RESPONSE_SUCCEEDED = 'system-profile/RENAME_PROFILE_RESPONSE_SUCCEEDED';
export const RENAME_PROFILE_REQUEST_FAILED = 'system-profile/RENAME_REQUEST_PROFILE_FAILED';
export const renameSystemProfile = (system, name) => async (dispatch) => {
  const errors = {
    404: 'system_not_found',
    403: 'rename_system_forbidden',
    unknown: 'unknown',
  };

  dispatch({ type: RENAME_PROFILE_REQUEST });

  try {
    const response = await api.changeSystemName(system.id, name);
    if (response.status === 200) {
      dispatch({ type: RENAME_PROFILE_RESPONSE_SUCCEEDED });
      return true;
    }
  } catch (e) {
    if (e.response) {
      dispatch({
        type: RENAME_PROFILE_REQUEST_FAILED,
        error: errors[e.response.status] || errors.unknown,
      });
      return false;
    }
  }

  dispatch({ type: RENAME_PROFILE_REQUEST_FAILED, error: errors.unknown });
  return false;
};

export const RESET_PROFILE_FORM_ERROR_REQUEST = 'system-profile/RESET_PROFILE_FORM_ERROR_REQUEST';
export const resetProfileError = () => (dispatch) => dispatch({ type: RESET_PROFILE_FORM_ERROR_REQUEST });
