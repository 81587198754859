import React from 'react';
import PropTypes from 'prop-types';

const Spacer = ({ x, y, inline }) => (
  <div
    style={{
      width: `${typeof x === 'string' ? x : `${x}px`}`,
      height: `${typeof y === 'string' ? y : `${y}px`}`,
      display: inline ? 'inline-block' : 'block',
    }}
  ></div>
);

Spacer.defaultProps = {
  x: 'auto',
  y: 'auto',
  inline: false,
};

Spacer.propTypes = {
  x: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  y: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inline: PropTypes.bool,
};

export default Spacer;
