import React from 'react';

export const Warranty = () => (
  <>
    <h2>5 Jahre Herstellergarantie</h2>
    <p>
      Wir sind von der Qualität und Leistungsfähigkeit unserer Produkte so überzeugt, dass Sie mit jeder Wärmepumpe eine
      Herstellergarantie von 5 Jahren erhalten können.
    </p>

    <h3>So einfach geht´s:</h3>

    <ul>
      <li>Die Anlage wird bei NIBE online registriert.</li>
      <li>
        Die Registrierung erfolgt durch den Anlagenbetreiber oder das Installationsunternehmen innerhalb von 24 Monaten
        nach Herstelldatum.
      </li>
      <li>Die Registrierung muss innerhalb von 180 Tagen nach der Inbetriebnahme erfolgen.</li>
      <li>Die maximale Laufzeit des Verdichters beträgt 1200 Stunden.</li>
    </ul>

    <h3>Voraussetzungen – Kurzübersicht</h3>
    <p>
      Die NIBE Systemtechnik GmbH gewährt eine Herstellergarantie ausschließlich zu den von ihr hergestellten und in
      Deutschland ausgelieferten Produkten.
    </p>
    <p>Die Herstellergarantie gilt unter Einhaltung:</p>
    <ul>
      <li>der Garantiebedingungen</li>
      <li>der Online-Registrierung</li>
      <li>einer fachgerechten Inbetriebnahme</li>
      <li>einer regelmäßigen Wartung</li>
    </ul>

    <p>Die dazu erforderliche Seriennummer kann im Display der Wärmepumpe abgelesen werden.</p>
    <p>Bitte melden Sie Ihre Wärmepumpe rechtzeitig an.</p>
    <p>Produkte der S-Serie registrieren sie unter myuplink.com und alle anderen unter nibeuplink.com.</p>

    <h3>Garantiebedingungen</h3>
    <p>
      Ihre Anlage erhält eine 5-Jahres-Garantie, wenn alle nachfolgend beschriebenen Voraussetzungen erfüllt sind und
      unsere Garantieerklärung durch eine Registrierung der Anlage angenommen wurde.
    </p>

    <h3>5 Jahre Herstellergarantie</h3>

    <p>
      Die NIBE Systemtechnik GmbH (nachfolgend „Hersteller“) gewährt gegenüber dem Endkunden (nachfolgend „Kunde“) eine
      Herstellergarantie zu den von ihr hergestellten und in Deutschland ausgelieferten
    </p>

    <ul>
      <li>
        <b> Wärmepumpen</b>
      </li>
      <li>
        <b>Lüftungsgeräten</b>
      </li>
    </ul>

    <p>(nachfolgend „Produkt“) zu den nachfolgenden Bedingungen.</p>

    <h3>Produktgarantie</h3>
    <p>
      Der Hersteller garantiert, dass die von ihm gelieferten Produkte frei von Material- und Verarbeitungsfehlern sind,
      die Einfluss auf deren Funktionsfähigkeit haben. Diese Erklärung bezieht sich auf Produkte, die in Deutschland
      gekauft und installiert wurden sowie in Deutschland betrieben werden.
    </p>

    <h3>Erklärungsempfänger und Registrierung</h3>
    <p>
      Der Hersteller gibt diese Garantieerklärung ausschließlich gegenüber Kunden ab, die die Produkte für ihren
      Eigenbedarf erworben und erstmalig in Betrieb genommen haben. Der Hersteller gibt diese Garantieerklärung nicht
      gegenüber Händlern oder Installationsbetrieben ab. Diese Erklärung gilt auch nicht gegenüber Kunden, die die
      Produkte als gebrauchte Produkte nach einer bereits erfolgten Inbetriebnahme erworben haben.
    </p>
    <p>
      Die Garantieerklärung wird nur gegenüber Erklärungsempfängern wirksam, die ihre Produkte bei NIBE registriert
      haben. Die Registrierung gilt als Annahme der Garantieerklärung. Die Registrierung hat innerhalb von 180 Tagen ab
      Inbetriebnahme über die Anlage eines Uplink Accounts durch den Kunden oder den Anlagen-Errichter
      (Installationsbetrieb) für den Kunden zu erfolgen.
    </p>
    <p>Produkte der S-Serie: myuplink.com Alle anderen: nibeuplink.com</p>

    <h3>Garantieleistung – Rechte aus der Garantie</h3>
    <p>Im Garantiefall wird der Hersteller den Fehler auf eigene Kosten und nach eigenem Ermessen</p>

    <ul>
      <li>durch eine Reparatur oder</li>
      <li>durch die Lieferung neuer oder generalüberholter Teile beheben.</li>
    </ul>

    <p>
      Dabei übernimmt der Hersteller auch die zur Behebung des Fehlers erforderlichen Materialkosten, Arbeitskosten und
      Fahrtkosten.
    </p>

    <p>
      Die Garantieerklärung gilt neben den allgemeinen gesetzlichen und vertraglichen Gewährleistungsansprüchen, die dem
      Kunden gegenüber seinem Vertragspartner aufgrund des Erwerbs der Produkte zustehen, und ist hiervon unabhängig.
    </p>

    <h3>Garantiedauer, Ausschlussfrist, Verjährung</h3>
    <p>Die Garantieleistung wird für eine Dauer von 5 Jahren gewährt.</p>
    <p>Die Garantiedauer beginnt mit dem Datum der Inbetriebnahme der Produkte gemäß Inbetriebnahmeprotokoll.</p>
    <p>
      Ansprüche aus der Garantie sind unverzüglich, spätestens jedoch 2 Monate nach Kenntnis eines Fehlers in Textform
      geltend zu machen.
    </p>
    <p>Nach Ablauf dieser Frist ist die Geltendmachung von Rechten aus der Garantie ausgeschlossen.</p>
    <p>Für die Fristwahrung kommt es auf den rechtzeitigen Zugang bei dem Vertragspartner des Endkunden an.</p>
    <p>Ansprüche aus der Garantie verjähren nach Ablauf von 6 Monaten ab dem Ende der Garantiefrist.</p>

    <h3>Leistungsausschluss</h3>
    <p>Folgende Fehler sind von der Herstellergarantie nichtumfasst:</p>

    <ul>
      <li>Fehler, die auf eine fehlerhafte Installation zurückzuführen sind;</li>

      <li>Fehler, die auf Abweichungen von Vorgaben des Herstellers zu Standardhydrauliken zurückzuführen sind;</li>

      <li>Fehler, die auf Fehlbedienungen zurückzuführen sind;</li>

      <li>Fehler, die auf äußere Einflüsse, höhere Gewalt oder Eingriffe Dritter zurückzuführen sind;</li>

      <li>
        Fehler, die lediglich eine optische Beeinträchtigung darstellen und keinen Einfluss auf die Funktionsfähigkeit
        der Produkte haben;
      </li>

      <li>
        Fehler, Schäden oder Verschleißerscheinungen, die ihre Ursache in einem bestimmungswidrigen und von den
        Spezifikationen des Herstellers abweichenden Gebrauch haben. Maßstab hierfür sind die Vorgaben im
        Benutzerhandbuch.
      </li>
    </ul>

    <h3>Ebenfalls nicht von der Garantie umfasst sind</h3>
    <ul>
      <li>Anlagenteile, die nicht von dem Hersteller geliefert wurden;</li>

      <li>Produkte, an denen die Fabrikationsnummer entfernt oder unkenntlich gemacht wurde;</li>

      <li>Produkte, in die vom Hersteller nicht autorisiertes Zubehör eingebaut wurde;</li>

      <li>
        Produkte, die Merkmale aufweisen, die auf Reparaturen oder sonstige Eingriffe von nicht durch den Hersteller
        autorisierten Werkstätten schließen lassen.
      </li>

      <li>Wartungs- und Verschleißteile</li>

      <li>
        Bauseitige Mängel, Fremdverschulden und äußere Einflüsse (z.B. Frost, Transportschäden, unsachgemäße Lagerung,
        Falschbedienung, äußere Gewalteinwirkung, ungeeignetes Heiz-/Brauchwasser)
      </li>
    </ul>

    <p>
      Die Garantieerklärung gewährt keinen Anspruch auf den Ersatz von Folgeschäden jeglicher Art oder jegliche
      sonstigen Schadenersatzansprüche. Von dieser Einschränkung sind zwingende gesetzliche Bestimmungen ausgenommen,
      nach denen der Hersteller außerhalb dieser freiwilligen Garantieerklärung haftet.
    </p>

    <h3>Besondere Anspruchsvoraussetzungen</h3>
    <p>
      Ansprüche aus der Hersteller- und Produktgarantie können nur bei Vorliegen folgender Voraussetzungen geltend
      gemacht werden:
    </p>

    <ul>
      <li>
        Der Errichter der Anlage, bei dem es sich um einen zugelassenen Fachbetrieb handeln muss, hat die Anlage nach
        den Vorgaben des Herstellers nachweisbar durch qualifiziertes Fachpersonal in Betrieb genommen oder durch einen
        autorisierten Servicebetrieb oder Mitarbeiter des Herstellers in Betrieb nehmen lassen. Dabei ersetzt diese
        Inbetriebnahme nicht die Abnahme des Kunden gegenüber dem Fachbetrieb.
      </li>
      <li>Die Laufzeit des Verdichters beträgt zur Inbetriebnahme maximal 1200 Stunden.</li>
      <li>
        Die Inbetriebnahme muss auf einem Inbetriebnahme-Formular des Herstellers dokumentiert werden. (Verfügbar auf{' '}
        <a href="https://www.nibe.de" target="blank">
          www.nibe.de
        </a>
        )
      </li>
      <li>Die Inbetriebnahme darf nicht später als 24 Monate nach der Herstellung des Produktes erfolgt sein.</li>
      <li>
        Der Kunde lässt das Produkt regelmäßig innerhalb der hierfür vorgesehenen Intervalle von einem sachkundigen
        Fachhandwerker warten. Die erste Wartung ist nach dem ersten Betriebsjahr durchzuführen, jedoch spätestens bis
        zum 15. Monat nach Inbetriebnahme. Die weiteren Wartungen sind vor Ablauf weiterer 24 Monate, gerechnet ab der
        letzten Wartung, durchzuführen. Die Wartung ist auf Formularen des Herstellers zu protokollieren. Das
        Wartungsprotokoll muss das Datum der Wartung, einen Stempel und eine Unterschrift des durchführenden
        Fachbetriebes enthalten.
      </li>
    </ul>

    <p>
      Das Formular eines Wartungsprotokolls liegt jeder Wärmepumpe bei und kann auch unter nibe.de abgerufen werden.
    </p>
    <p>
      Die jeweils aktuellen Wartungsinhalte finden sich ebenfalls unter:{' '}
      <a href="https://www.nibe.de" target="blank">
        www.nibe.de
      </a>
      .
    </p>

    <h3>Geltendmachung von Ansprüchen</h3>
    <p>
      Die Geltendmachung der Ansprüche aus der Garantie hat gegenüber dem jeweiligen Vertragspartner des Endkunden
      (Installationsbetrieb, Verkäufer etc.) zu erfolgen.
    </p>

    <p>
      Für die Geltendmachung von Ansprüchen aus der Garantie ist die Vorlage des Inbetriebnahmeprotokolls und der
      Protokolle zu den bis dahin fällig gewordenen Wartungen zwingend erforderlich.
    </p>

    <p>
      Eine direkte Geltendmachung von Ansprüchen gegenüber dem Hersteller kann nur dann erfolgen, wenn der
      Vertragspartner des Endkunden nicht mehr existiert oder die Garantieleistung endgültig abgelehnt hat.
    </p>

    <p>
      Die Ansprüche auf die Garantieleistung sind dann gegenüber der NIBE Systemtechnik GmbH, Am Reiherpfahl 3, 29223
      Celle geltend zu machen.
    </p>

    <h3>Geltungsdauer</h3>
    <p>
      Die vorstehenden Garantiebedingungen gelten für alle Produkte, die ab dem 01.01.2020 in Betrieb genommen wurden.
    </p>

    <h3>Wartungshinweis</h3>
    <p>
      Damit Ihr Haustechniksystem stets einwandfrei und energiesparend arbeitet, empfehlen wir eine regelmäßige Wartung
      auch nach Ablauf der Herstellergarantie.
    </p>
    <p>
      Stand: 05/2022 Die aktuellen Garantiebedingungen finden Sie unter{' '}
      <a href="https://www.nibe.de" target="blank">
        nibe.de
      </a>
    </p>
  </>
);
