import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import PremiumFeatureTooltip from '../../layout/PremiumFeatureTooltip';

import './textbox.scss';

const TextBox = ({
  value,
  maxLength,
  type,
  typeRegex,
  premiumFeatureType,
  disabled,
  disabledDueToOffline,
  label,
  onTextBoxValueChange,
}) => {
  const [textBoxValue, setTextBoxValue] = useState(value);
  const [showRegexpMatchError, setShowRegexpMatchError] = useState(false);

  const onChangeValue = ({ target }) => {
    if (type === 'numbers' ? Number(target.value) >= 0 : true) {
      if (!typeRegex || (target.value.match(typeRegex) && target.value.match(typeRegex).length)) {
        setShowRegexpMatchError(false);
        setTextBoxValue(target.value);
      } else {
        setShowRegexpMatchError(true);
        setTextBoxValue(target.value);
      }
    }
    return;
  };

  const onKeyPressHandler = ({ target, charCode }) => {
    if (type === 'numbers' ? Number(target.value) >= 0 : true) {
      if (!typeRegex || (target.value.match(typeRegex) && target.value.match(typeRegex).length)) {
        if (charCode === 13) {
          onTextBoxValueChange(target.value);
        }
      }
    }
  };

  const onBlurHandler = ({ target }) => {
    if (type === 'numbers' ? Number(target.value) >= 0 : true) {
      if (!typeRegex || (target.value.match(typeRegex) && target.value.match(typeRegex).length)) {
        onTextBoxValueChange(target.value);
      }
    }
  };

  return (
    <div className="textbox-content-wrapper">
      {label}{' '}
      {
        <i className="fa fa-question-circle">
          <PremiumFeatureTooltip
            translationId={`textBox.text-helper.none.${type}`}
            defaultMessage="Please fill a valid format."
          />
        </i>
      }
      {showRegexpMatchError && (
        <p className="inputComponent__error">
          <FormattedMessage id={'textBox.invalid-input.error'} defaultMessage="Invalid input format" />
        </p>
      )}
      <label className="textbox" disabled={disabled || disabledDueToOffline}>
        <input
          className="textbox__input-field"
          type="text"
          maxLength={parseInt(maxLength)}
          onChange={onChangeValue}
          onKeyPress={onKeyPressHandler}
          onBlur={onBlurHandler}
          value={textBoxValue}
          disabled={disabled || disabledDueToOffline}
        />
      </label>
      {disabled && <PremiumFeatureTooltip premiumFeatureType={premiumFeatureType ? premiumFeatureType : 'textbox'} />}
      {disabledDueToOffline && <PremiumFeatureTooltip translationId="addDevice.systemSelector.offline" />}
    </div>
  );
};

TextBox.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxLength: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  typeRegex: PropTypes.string.isRequired,
  textHelper: PropTypes.string.isRequired,
  premiumFeatureType: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  disabledDueToOffline: PropTypes.bool.isRequired,
  label: PropTypes.string,
  onTextBoxValueChange: PropTypes.func.isRequired,
};

export default TextBox;
