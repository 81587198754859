import React from 'react';
import PropTypes from 'prop-types';
import { Button, Input } from 'jpi-cloud-web-ui-components';
import { ModalBody, ModalFooter } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Formik } from 'formik';
import * as yup from 'yup';
import { formatErrorMessage } from '../../../../localization/message-formatting';
import Modal from '../../../layout/Modal';

const schemaStepOne = yup.object().shape({
  password: yup.string().required('password.required'),
});

const errorMessages = {
  'password.required': {
    id: 'password.error.validation.required',
    defaultMessage: 'Password is mandatory field',
  },
  'invalid request': {
    id: 'password.error.request.invalid',
    defaultMessage: 'Password is invalid. Please try again.',
  },
  unknown: {
    id: 'migration.error.unknown',
    defaultMessage: 'An error has occurred. Try again later.',
  },
};

const PasswordConfirmation = ({ email, onSubmit, showModal, toggleModal, requestError, intl }) => {
  return (
    <Formik
      initialValues={{ email: email, password: '' }}
      validationSchema={schemaStepOne}
      onSubmit={onSubmit}
      enableReinitialized={true}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <Modal show={showModal} backdrop="static" onHide={toggleModal}>
          <form onSubmit={handleSubmit}>
            <ModalBody className="acceptedAgreementsForm">
              <h2>
                <FormattedMessage
                  id="passwordConfirmation.title"
                  defaultMessage="Please enter your old NIBE Uplink account password to confirm migration"
                />
              </h2>
              <div className="registerForm__inputWrapper">
                <div className="inputComponent">
                  <input
                    disabled
                    className="inputComponent__field"
                    name="email"
                    type="text"
                    defaultValue={values.email}
                  />
                </div>
                <div className="inputComponent">
                  <FormattedMessage id="register.input-password" defaultMessage="Password">
                    {(placeholder) => (
                      <Input
                        placeholder={placeholder}
                        type="password"
                        name="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        error={
                          errors.password &&
                          touched.password &&
                          formatErrorMessage(intl, errorMessages, errors.password)
                        }
                      />
                    )}
                  </FormattedMessage>
                </div>
              </div>
              <h4>
                {requestError && <p className="text-danger">{formatErrorMessage(intl, errorMessages, requestError)}</p>}
              </h4>
            </ModalBody>
            <ModalFooter>
              <div className="button-wrapper">
                <Button className="button--default" type="button" onClick={toggleModal}>
                  <FormattedMessage id="migrations.confirmation.buttons.abort" defaultMessage="Cancel" />
                </Button>
                <Button className="button--secondary" type="submit" disabled={isSubmitting}>
                  <FormattedMessage
                    id="migrations.confirmation.buttons.proceed"
                    defaultMessage="Accept"
                    description="Accept agreements button"
                  />
                </Button>
              </div>
            </ModalFooter>
          </form>
        </Modal>
      )}
    </Formik>
  );
};

PasswordConfirmation.propTypes = {
  email: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  showModal: PropTypes.any.isRequired,
  toggleModal: PropTypes.func.isRequired,
  requestError: PropTypes.any,
  intl: PropTypes.object,
};

export default injectIntl(PasswordConfirmation);
