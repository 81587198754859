import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Modal from '../../../../../layout/Modal';
import { Button, Title } from 'jpi-cloud-web-ui-components';
import TermsOfService from '../TermsOfServiceModal';
import ProductModal from '../ProductModal';

import './UserDetailModal.scss';

const userDetailModal = ({
  cart,
  isUserDetail,
  toggleUserDetailPopup,
  productDetails,
  toggleProductDetailsPopup,
  selectPaymentSettingTab,
}) => {
  const [isTermsOfService, setToggleTermsOfService] = useState(false);
  const [agreementAccepted, setAgreementAccepted] = useState(false);

  const toggleTermsOfService = () => {
    setToggleTermsOfService(!isTermsOfService);
    toggleUserDetailPopup(!isUserDetail);
  };

  const onBackClick = () => {
    toggleUserDetailPopup();
  };

  const toggleProductDetails = () => {
    toggleProductDetailsPopup(!productDetails);
    toggleUserDetailPopup(!isUserDetail);
  };

  return (
    <>
      {cart && cart.shippingAddress && (
        <Modal className="checkout-modal-dialog" show={isUserDetail}>
          <div className="UserDetail">
            <div className="popup-modal">
              <div className="UserDetail__heading">
                <Title
                  className="UserDetailTitle"
                  titleTranslationId="shop.cart.checkout.title"
                  defaultMessage="Checkout"
                />
              </div>
              <div className="UserDetail__description">
                <FormattedMessage
                  id="shop.cart.userDetails.description"
                  defaultMessage="Check your details are correct"
                />
              </div>
              <div className="UserDetail__content-wrapper">
                <div className="UserDetail__row-wrapper">
                  <p className="UserDetail__key">{'Name'}</p>
                  <p className="UserDetail__value">
                    {cart.shippingAddress.firstName + ' ' + cart.shippingAddress.lastName}
                  </p>
                </div>
                <div className="UserDetail__row-wrapper">
                  <p className="UserDetail__key">{'Email'}</p>
                  <p className="UserDetail__value">{cart.email}</p>
                </div>
                <div className="UserDetail__row-wrapper">
                  <p className="UserDetail__key">{'Address'}</p>
                  <p className="UserDetail__value">
                    {cart.shippingAddress.address1 + ' ' + cart.shippingAddress.address2}
                  </p>
                </div>
                <div className="UserDetail__row-wrapper">
                  <p className="UserDetail__key">{'City'}</p>
                  <p className="UserDetail__value">{cart.shippingAddress.city}</p>
                </div>
                <div className="UserDetail__row-wrapper">
                  <p className="UserDetail__key">{'Post code'}</p>
                  <p className="UserDetail__value">{cart.shippingAddress.zip}</p>
                </div>
                <div className="UserDetail__row-wrapper">
                  <p className="UserDetail__key">{'Country'}</p>
                  <p className="UserDetail__value">{cart.shippingAddress.country}</p>
                </div>
              </div>
              <div className="UserDetail__button-wrapper">
                <Button
                  className="userDetail__next-button"
                  onClick={() => {
                    toggleProductDetails();
                  }}
                >
                  <FormattedMessage id="button.next" defaultMessage="Next" />
                </Button>
                <Button
                  className="UserDetail__back-button"
                  onClick={() => {
                    onBackClick();
                  }}
                >
                  <FormattedMessage id="button.back" defaultMessage="Back" />
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}
      <TermsOfService
        isTermsOfService={isTermsOfService}
        toggleTermsOfService={toggleTermsOfService}
        setAgreementAccepted={setAgreementAccepted}
        agreementAccepted={agreementAccepted}
        setToggleTermsOfService={setToggleTermsOfService}
      />
      <ProductModal
        productDetails={productDetails}
        toggleProductDetailsPopup={toggleProductDetailsPopup}
        toggleProductDetails={toggleProductDetails}
        selectPaymentSettingTab={selectPaymentSettingTab}
      />
    </>
  );
};

userDetailModal.propTypes = {
  cart: PropTypes.shape({
    shippingAddress: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      address1: PropTypes.string.isRequired,
      address2: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      zip: PropTypes.string.isRequired,
      country: PropTypes.string.isRequired,
    }),
    email: PropTypes.string.isRequired,
  }),
  isUserDetail: PropTypes.bool.isRequired,
  productDetails: PropTypes.bool.isRequired,
  toggleUserDetailPopup: PropTypes.func.isRequired,
  toggleProductDetailsPopup: PropTypes.func.isRequired,
  selectPaymentSettingTab: PropTypes.func.isRequired,
};
export default userDetailModal;
