export const weekStartsOnSunday = [
  '032', // Argentina
  '084', // Belize
  '068', // Bolivia
  '076', // Brazil
  '124', // Canada
  '152', // Chile
  '156', // China
  '170', // Colombia
  '188', // Costa Rica
  '214', // Dominican Republic
  '218', // Ecuador
  '222', // El Salvador
  '320', // Guatemala
  '340', // Honduras
  '344', // Hong Kong
  '376', // Israel
  '388', // Jamaica
  '392', // Japan
  '404', // Kenya
  '446', // Macao
  '484', // Mexico
  '558', // Nicaragua
  '591', // Panama
  '604', // Peru
  '608', // Philippines
  '630', // Puerto Rico
  '710', // South Africa
  '410', // South Korea
  '158', // Taiwan
  '840', // United States
  '581', // United States Minor Outlying Islands (the)
  '862', // Venezuela
  '716', // Zimbabwe
];

export const alpha2CountryCodesWhereAmPmHours = [
  'US', // United States
  'CA', // Canada
  'GB', // United Kingdom
  'IE', // Ireland
  'AU', // Australia
  'IN', // India
  'PK', // Pakistan
  'PH', // Philippines
  'MY', // Malaysia
  'NZ', // New Zealand
  'ZA', // South Africa
  'BD', // Bangladesh
  'EG', // Egypt
  'SA', // Saudi Arabia
  'AE', // United Arab Emirates
  'MX', // Mexico
  'BR', // Brazil
  'AR', // Argentina
  'CL', // Chile
  'CO', // Colombia
  'PE', // Peru
  'CR', // Costa Rica
  'DO', // Dominican Republic
  'EC', // Ecuador
  'SV', // El Salvador
  'GT', // Guatemala
  'HN', // Honduras
  'IL', // Israel
  'JM', // Jamaica
  'NI', // Nicaragua
  'PA', // Panama
  'PR', // Puerto Rico
  'TW', // Taiwan
  'VE', // Venezuela
  'ZW', // Zimbabwe
  'UM', // United States Minor Outlying Islands (the)
];
