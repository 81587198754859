import React, { useEffect } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  YAxis,
  HorizontalGridLines,
  VerticalGridLines,
  XAxis,
  LineMarkSeries,
  Crosshair,
  XYPlot,
  Borders,
  Highlight,
} from 'react-vis';
import {
  determineCurveType,
  calculateYDomain,
  calculateXDomain,
  yDomainMargin,
  findCurveStyle,
  translateXAxisLabel,
  generatePointSeries,
  crosshairIsInSelectedRange,
  month,
} from '../../chart-utils';
import PlaceholderChart from './PlaceholderChart';
import { defaultValueLowerLimit } from '../../../../constants/constants';

let timeout;
const AutoSizerChart = (props) => {
  const {
    selectedZoom,
    combinedPoints,
    precedingPoints,
    dateRange,
    dateFrom,
    dateTo,
    parameters,
    chartsParameters,
    charts,
    hoveredValues,
    onMouseLeave,
    getChart,
    fullScreen,
    onNearestX,
    onBrushEnd,
    showPoints,
    hoverDelay = 0,
  } = props;
  const yValues = combinedPoints
    .concat(precedingPoints)
    .map((point) => (point && point.y && point.y >= defaultValueLowerLimit ? point.y : null));
  const miniChartXDomain = calculateXDomain(dateRange, dateFrom, dateTo);
  const minYValue = Math.min(...yValues);
  const maxYValue = Math.max(...yValues);

  const xDomain = selectedZoom ? [selectedZoom.left, selectedZoom.right] : miniChartXDomain;
  const yDomain = combinedPoints.length > 0 ? calculateYDomain(minYValue, maxYValue, yDomainMargin) : [0, 100];

  const handleNearestX = (...args) => {
    clearTimeout(timeout);

    timeout = setTimeout(() => {
      onNearestX(...args);
    }, hoverDelay);
  };

  const handleMouseLeave = () => {
    clearTimeout(timeout);
    onMouseLeave();
  };

  useEffect(() => {
    return () => clearTimeout(timeout);
  }, []);

  return (
    <AutoSizer>
      {({ width, height }) => (
        <XYPlot
          xType="time"
          xDomain={xDomain}
          yDomain={yDomain}
          margin={{ left: 66, right: 20 }}
          onMouseLeave={handleMouseLeave}
          width={width}
          height={height}
          dontCheckIfEmpty={true}
        >
          <HorizontalGridLines />
          <VerticalGridLines />
          <XAxis tickFormat={translateXAxisLabel} tickTotal={month.length} />
          {chartsParameters.map((c, index) => {
            const data = generatePointSeries(c, dateRange, showPoints);
            const curve = determineCurveType(findCurveStyle(c, parameters));
            return getChart(c, data, curve, index);
          })}
          {combinedPoints && combinedPoints.length > 0 ? (
            <LineMarkSeries data={combinedPoints} onNearestX={handleNearestX} opacity={0} />
          ) : (
            <PlaceholderChart dateFrom={dateFrom} dateTo={dateTo} />
          )}
          <Borders
            style={{
              left: { fill: '#f5f5f5', height: '440px' },
              right: { fill: '#f5f5f5', height: '440px' },
              top: { display: 'none' },
              bottom: { display: 'none' },
            }}
          />
          <YAxis />
          {charts.length > 0 && (
            <Highlight key={`${dateRange}_${fullScreen}`} enableY={false} onBrushEnd={onBrushEnd} />
          )}
          {crosshairIsInSelectedRange(hoveredValues, selectedZoom) && (
            <Crosshair
              className="crosshair"
              values={[hoveredValues]}
              titleFormat={() => ({
                title: 'Timestamp',
                value: hoveredValues.x.format('MMMM Do YYYY, HH:mm:ss'),
              })}
              itemsFormat={() => hoveredValues.values}
            />
          )}
        </XYPlot>
      )}
    </AutoSizer>
  );
};

AutoSizerChart.propTypes = {
  selectedZoom: PropTypes.object,
  combinedPoints: PropTypes.array.isRequired,
  precedingPoints: PropTypes.array.isRequired,
  dateRange: PropTypes.string.isRequired,
  dateFrom: PropTypes.instanceOf(Date).isRequired,
  dateTo: PropTypes.instanceOf(Date).isRequired,
  parameters: PropTypes.array.isRequired,
  chartsParameters: PropTypes.array.isRequired,
  charts: PropTypes.array.isRequired,
  hoveredValues: PropTypes.object,
  onMouseLeave: PropTypes.func.isRequired,
  getChart: PropTypes.func.isRequired,
  fullScreen: PropTypes.string.isRequired,
  onNearestX: PropTypes.func.isRequired,
  onBrushEnd: PropTypes.func.isRequired,
  showPoints: PropTypes.bool,
  hoverDelay: PropTypes.number,
};

export default React.memo(AutoSizerChart, (prev, next) => {
  const isSelectedZoomUpdated = !_.isEqual(prev.selectedZoom, next.selectedZoom);
  const isDateFromUpdated = prev.dateFrom.valueOf() !== next.dateFrom.valueOf();
  const isDateToUpdated = prev.dateTo.valueOf() !== next.dateTo.valueOf();
  const isRangeUpdated = prev.dateRange !== next.dateRange;
  const isFullScreenUpdated = prev.fullScreen !== next.fullScreen;
  const isParametersUpdated = !_.isEqual(prev.parameters, next.parameters);
  const isChartParametersUpdated = !_.isEqual(prev.chartsParameters, next.chartsParameters);
  const isChartsUpdated = !_.isEqual(prev.charts, next.charts);
  const isHoveredValuesUpdated = !_.isEqual(prev.hoveredValues, next.hoveredValues);
  const isShowPointsChanged = prev.showPoints !== next.showPoints;
  return !(
    isSelectedZoomUpdated ||
    isDateFromUpdated ||
    isDateToUpdated ||
    isRangeUpdated ||
    isParametersUpdated ||
    isFullScreenUpdated ||
    isChartParametersUpdated ||
    isChartsUpdated ||
    isHoveredValuesUpdated ||
    isShowPointsChanged
  );
});
