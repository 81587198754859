import React from 'react';
import PropTypes from 'prop-types';
import { Logo } from '../../../../Logo';
import Navigation from '../Navigation';
import './header.scss';
import classNames from 'classnames';

const Header = ({
  url,
  showLangShortCodes = false,
  showLangIcon = true,
  showLogo = true,
  isLoginHeader = false,
  className,
}) => {
  return (
    <header className={classNames('header', className)}>
      <div className="header-inner page-centered">
        {showLogo && <Logo />}
        <Navigation
          url={url}
          showLangShortCodes={showLangShortCodes}
          showLangIcon={showLangIcon}
          isLoginHeader={isLoginHeader}
        />
      </div>
    </header>
  );
};

Header.propTypes = {
  url: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  showLangShortCodes: PropTypes.bool,
  showLangIcon: PropTypes.bool,
  className: PropTypes.string,
  showLogo: PropTypes.bool,
  isLoginHeader: PropTypes.bool,
};

export default Header;
