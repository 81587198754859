import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody } from 'react-bootstrap';
import { Button } from 'jpi-cloud-web-ui-components';
import { FormattedMessage } from 'react-intl';

import './device-menu-help-modal.scss';

const DeviceMenuHelpModal = ({ showHelpModal, hideHelpModal, menu }) => {
  const helpText = menu.help.text.split('\\n');
  return (
    <Modal show={showHelpModal}>
      <ModalBody className="modal-container">
        <p className="modal-heading modal-text">
          <FormattedMessage id="menu.systems.help" defaultMessage="Help" />
        </p>
        <p className="modal-text modal-help-text-heading">{menu.name.text}</p>
        {helpText &&
          helpText.length > 0 &&
          helpText.map((text, index) => (
            <p className="modal-text modal-help-text" key={index}>
              {text}
            </p>
          ))}
        <Button className="button--default button--bottom-spacing back-button" type="button" onClick={hideHelpModal}>
          <FormattedMessage id="devices.close" defaultMessage="Close" />
        </Button>
      </ModalBody>
    </Modal>
  );
};

DeviceMenuHelpModal.propTypes = {
  showHelpModal: PropTypes.bool.isRequired,
  hideHelpModal: PropTypes.func.isRequired,
  menu: PropTypes.object.isRequired,
};
export default DeviceMenuHelpModal;
