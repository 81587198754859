import {
  GET_IOT_STORE_REDIRECT_URL_REQUEST,
  GET_NOTIFICATIONS_REQUEST,
  GET_NOTIFICATIONS_RESPONSE_SUCCEEDED,
} from './actions';

const initialState = {
  amount: 0,
  notifications: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS_REQUEST:
      return { ...state, alarms: 0, notifications: [] };
    case GET_NOTIFICATIONS_RESPONSE_SUCCEEDED:
      return { ...state, amount: action.notifications.length, notifications: action.notifications };
    case GET_IOT_STORE_REDIRECT_URL_REQUEST:
      return { ...state };
    default:
      return state;
  }
};
