import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as client from '../../Shop/client';
import { Title } from 'jpi-cloud-web-ui-components';

import './ordercancel.scss';

const OrderCancel = ({ order, cancelOrder }) => {
  useEffect(() => {
    window.parent.postMessage(
      {
        id: 'order-cancel',
        path: window.location.pathname,
      },
      window.location.origin
    );

    if (order && order.data && order.data.order && order.data.order.id) {
      cancelOrder(order.data.order.id);
    }
  }, []);

  if (window.self !== window.top) {
    document.body.style['display'] = 'none';
    return null;
  }

  return (
    <div className="page-content">
      <Title titleTranslationId="orderCancel.page.heading" defaultMessage={'Your order was canceled!'} />
      <p className="orderCancel__paragraph">
        <FormattedMessage
          id="orderCancel.page.paragraph"
          defaultMessage={
            'The payment flow was aborted at the payment provider service. The order has not been completed and you have not been charged the order amount. If you have any questions please contact the Support'
          }
        />
      </p>
    </div>
  );
};

OrderCancel.propTypes = {
  order: PropTypes.object.isRequired,
  cancelOrder: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  ...state.shop,
});

const mapDispatchToProps = (dispatch) => ({
  cancelOrder(orderId) {
    dispatch(client.cancelOrder(orderId));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(OrderCancel);
