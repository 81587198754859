import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { getUserSystems, disconnectSystem } from '../../AppInitializer/actions';
import { Spinner, Title } from 'jpi-cloud-web-ui-components';
import { disconnectDevice, getDevices, setSelectedDevice } from './actions';
import { getUserSubscriptions } from '../../FeaturesFlags/actions';
import { removeTilesByDevice } from '../../tiles/TileManager/actions';
import DevicesList from './components/DeviceList';
import SelectedDevice from './components/SelectedDevice';
import DisconnectDeviceConfirmation from './components/DisconnectDeviceConfirmation';

import './devices.scss';

class Devices extends React.Component {
  static propTypes = {
    selectedSystem: PropTypes.object,
    userInfo: PropTypes.object,
    devices: PropTypes.arrayOf(PropTypes.object).isRequired,
    loading: PropTypes.bool,
    isManageEnabled: PropTypes.bool,

    getDevices: PropTypes.func.isRequired,
    disconnectDevice: PropTypes.func.isRequired,
    disconnectSystem: PropTypes.func.isRequired,
    getUserSystems: PropTypes.func.isRequired,
    goToPage: PropTypes.func.isRequired,
    getUserSubscriptions: PropTypes.func.isRequired,
    removeTilesByDevice: PropTypes.func.isRequired,
    setSelectedDevice: PropTypes.func.isRequired,
    selectedDevice: PropTypes.object,
  };

  state = {
    showModalDevice: false,
    showModalSystem: false,
    selectedDevice: null,
  };

  componentDidMount() {
    this.props.selectedSystem && this.props.selectedSystem.id && this.props.getDevices(this.props.selectedSystem.id);
  }

  async componentDidUpdate(prevProps) {
    if (
      (!prevProps.selectedSystem && !!this.props.selectedSystem) ||
      (prevProps.selectedSystem &&
        this.props.selectedSystem &&
        this.props.selectedSystem.id !== prevProps.selectedSystem.id)
    ) {
      const devices = await this.props.getDevices(this.props.selectedSystem.id);
      if (devices && devices.length > 0) {
        await this.props.getUserSubscriptions(devices[0].id);
      }
    }
  }

  confirmSystemDisconnect = (system) => {
    this.toggleModalSystem(system);
  };

  disconnectDevice = () => {
    const { selectedSystem, userInfo } = this.props;
    const { selectedDevice } = this.state;

    this.props.removeTilesByDevice(selectedSystem.id, userInfo.id, selectedDevice.id);
    this.props.disconnectDevice(selectedDevice, selectedSystem);
    this.toggleModalDevice(null);
  };

  updateFirmware = (device) => {
    this.props.goToPage(`/update-device/${device.serialNumber}/${device.firmwareId}`);
  };

  toggleModalDevice = (device) => {
    this.setState({ ...this.state, showModalDevice: !this.state.showModalDevice, selectedDevice: device || null });
  };

  toggleModalSystem = () => {
    this.setState({ ...this.state, showModalSystem: !this.state.showModalSystem });
  };

  render() {
    return (
      <div className="page-content">
        <div className="devices__wrapper">
          {this.props.loading ? (
            <Spinner />
          ) : this.props.devices && this.props.devices.length > 0 ? (
            <Title titleTranslationId="devices.Devices for">
              &nbsp;{this.props.selectedSystem && this.props.selectedSystem.name} &nbsp; &nbsp;
            </Title>
          ) : (
            <h1 align="center">
              <FormattedMessage id="devices.DevicesEmptyList" defaultMessage="Device list is empty" />
            </h1>
          )}
          {this.props.selectedDevice ? (
            <SelectedDevice />
          ) : (
            <>
              <DevicesList
                devices={this.props.devices}
                updateFirmware={this.updateFirmware}
                onSelect={(device) => this.props.setSelectedDevice(device)}
              />
            </>
          )}
        </div>
        <DisconnectDeviceConfirmation
          toggleModal={this.toggleModalDevice}
          showModal={this.state.showModalDevice}
          disconnectDevice={this.disconnectDevice}
        />
      </div>
    );
  }
}

const mapStateToProps = ({
  app: { selectedSystem, userInfo, selectedDevice },
  devices,
  features: { premiumFeatures },
}) => ({
  selectedSystem,
  userInfo,
  selectedDevice,
  isManageEnabled: premiumFeatures && premiumFeatures.manage,
  ...devices,
});

const mapDispatchToProps = {
  getDevices,
  disconnectDevice,
  disconnectSystem,
  getUserSystems,
  goToPage: push,
  getUserSubscriptions,
  removeTilesByDevice,
  setSelectedDevice,
};

export default connect(mapStateToProps, mapDispatchToProps)(Devices);
