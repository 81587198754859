import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { getDateFrom, getDateTo } from './actions';
import AutoSizer from 'react-virtualized-auto-sizer';
import { XYPlot, YAxis, XAxis, HorizontalGridLines, Crosshair, LineSeries, Borders } from 'react-vis';
import { applyTickFormat } from '../../pages/history/components/chart/tick-format.js';
import { determineCurveType, calculateYDomain, month } from '../../pages/history/chart-utils';
import { dateTimeFormatString, defaultValueLowerLimit } from '../../constants/constants';

// BUG: https://github.com/uber/react-vis/issues/1395
const parseDate = (date) => moment(date, dateTimeFormatString);
const yDomainMargin = 5;
const DATE_MONTH = 'Month';

export default class LineChart extends React.Component {
  state = {
    hoveredValues: [],
  };

  onMouseLeave = () => this.setState({ hoveredValues: [] });

  onNearestX = (value, points, unit) => {
    const sameChartPoint = points.find((p) => moment(p.timestamp).isSame(value.x));
    if (!sameChartPoint) return null;

    const previewVal = sameChartPoint
      ? (Math.round(sameChartPoint.value * 100) / 100).toFixed(sameChartPoint.decimals) + ''
      : 0;

    const hoveredValue = {
      x: parseDate(value.x),
      value: [
        {
          title: value.x.format('MMMM Do YYYY, HH:mm:ss'),
          value: `${previewVal < defaultValueLowerLimit ? '--' : previewVal} ${unit}`,
        },
      ],
    };

    return hoveredValue;
  };

  crosshairIsInSelectedRange = () => {
    const { hoveredValues } = this.state;
    if (!hoveredValues) {
      return false;
    }
    const dateFrom = parseDate(getDateFrom(this.props.selectedDateRange));
    const dateTo = parseDate(getDateTo(this.props.selectedDateRange));

    return hoveredValues.x >= dateFrom && hoveredValues.x <= dateTo;
  };

  generatePointSeries(points) {
    return points.map((p) => {
      const yValue = Number(Number(p.value).toFixed(p.decimals));
      const hasValidYValue = p.value !== null && p.value !== undefined && yValue >= defaultValueLowerLimit;
      return {
        x: parseDate(p.timestamp),
        y: hasValidYValue ? yValue : null,
      };
    });
  }

  render() {
    const {
      points,
      precedingPoint,
      predictedPoint,
      selectedDateRange,
      unit,
      chartBackgroundColor,
      chartLineColor,
      chartTextColor,
      curve,
    } = this.props;
    const { hoveredValues } = this.state;
    const dateFrom = parseDate(getDateFrom(selectedDateRange));
    const dateTo = parseDate(getDateTo(selectedDateRange));
    const yValues = points.map((point) =>
      point && point.value && point.value >= defaultValueLowerLimit ? point.value : null
    );
    const minYValue = Math.min(...yValues);
    const maxYValue = Math.max(...yValues);

    const yDomain = points.length > 0 ? calculateYDomain(minYValue, maxYValue, yDomainMargin) : [0, 100];

    const data = this.generatePointSeries(points, precedingPoint, predictedPoint);

    const axisStyle = {
      overflow: 'visible',
      text: { fill: `${chartTextColor}`, fontSize: 14 },
    };

    const isMonthSelected = selectedDateRange === DATE_MONTH;

    return (
      <AutoSizer>
        {({ width, height }) => (
          <XYPlot
            margin={{ left: 100 }}
            xType="time"
            xDomain={[dateFrom, dateTo]}
            style={{ overflow: 'hidden', backgroundColor: `${chartBackgroundColor}` }}
            yDomain={yDomain}
            width={width}
            height={height}
            fill={`${chartBackgroundColor}`}
            opacity={1}
            onMouseLeave={this.onMouseLeave}
          >
            <HorizontalGridLines />
            <XAxis
              tickFormat={(value) => applyTickFormat(value, isMonthSelected)}
              // FIXME: total ticks not updating on date unit change
              tickTotal={month.length}
              style={axisStyle}
            />
            {data.length > 0 ? (
              <LineSeries
                getNull={(d) => d.y !== null}
                margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
                data={data}
                onNearestX={(val) => {
                  this.setState({ hoveredValues: this.onNearestX(val, points, unit) });
                }}
                fill={`${chartLineColor}`}
                stroke={`${chartLineColor}`}
                curve={determineCurveType(curve)}
              />
            ) : (
              <LineSeries
                opacity={0}
                data={[
                  { x: dateFrom, y: 0 },
                  { x: dateTo, y: 100 },
                ]}
                color={`${chartLineColor}`}
              />
            )}
            <Borders
              style={{
                left: { fill: `${chartBackgroundColor}`, height: '360px', width: '56px' },
                right: { fill: `${chartBackgroundColor}`, height: '360px' },
                top: { display: 'none' },
                bottom: { display: 'none' },
              }}
            />
            <YAxis style={axisStyle} />
            {this.crosshairIsInSelectedRange() && (
              <Crosshair values={[hoveredValues]} titleFormat={() => null} itemsFormat={() => hoveredValues.value} />
            )}
          </XYPlot>
        )}
      </AutoSizer>
    );
  }
}

LineChart.propTypes = {
  points: PropTypes.arrayOf(PropTypes.object).isRequired,
  precedingPoint: PropTypes.object,
  predictedPoint: PropTypes.object,
  unit: PropTypes.string.isRequired,
  selectedDateRange: PropTypes.string.isRequired,
  chartBackgroundColor: PropTypes.string,
  chartLineColor: PropTypes.string,
  chartTextColor: PropTypes.string,
  curve: PropTypes.string,
};
