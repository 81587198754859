export const GET_PRODUCTS = 'store/GET_PRODUCTS';
export const GET_PRODUCTS_SUCCEEDED = 'store/GET_PRODUCTS_SUCCEEDED';
export const GET_PRODUCTS_FAILED = 'store/GET_PRODUCTS_FAILED';

export const GET_ECOM_SUPPORTED_COUNTRIES = 'store/GET_ECOM_SUPPORTED_COUNTRIES';
export const GET_ECOM_SUPPORTED_COUNTRIES_SUCCEEDED = 'store/GET_ECOM_SUPPORTED_COUNTRIES_SUCCEEDED';
export const GET_ECOM_SUPPORTED_COUNTRIES_FAILED = 'store/GET_ECOM_SUPPORTED_COUNTRIES_FAILED';

export const INIT_CART = 'store/INIT_CART';
export const INIT_CART_SUCCEEDED = 'store/INIT_CART_SUCCEEDED';
export const INIT_CART_FAILED = 'store/INIT_CART_FAILED';

export const GET_PAYMENT_PROVIDERS = 'store/GET_PAYMENT_PROVIDERS';
export const GET_PAYMENT_PROVIDERS_SUCCEEDED = 'store/GET_PAYMENT_PROVIDERS_SUCCEEDED';
export const GET_PAYMENT_PROVIDERS_FAILED = 'store/GET_PAYMENT_PROVIDERS_FAILED';

export const GET_ORDER_PAYMENT_PROVIDERS = 'store/GET_ORDER_PAYMENT_PROVIDERS';
export const GET_ORDER_PAYMENT_PROVIDERS_SUCCEEDED = 'store/GET_ORDER_PAYMENT_PROVIDERS_SUCCEEDED';
export const GET_ORDER_PAYMENT_PROVIDERS_FAILED = 'store/GET_ORDER_PAYMENT_PROVIDERS_FAILED';

export const SELECT_PAYMENT_PROVIDER = 'store/SELECT_PAYMENT_PROVIDER';

export const GET_USER_PAYMENT_PROVIDER_SETTINGS = 'store/GET_USER_PAYMENT_PROVIDER_SETTINGS';
export const GET_USER_PAYMENT_PROVIDER_SETTINGS_SUCCEEDED = 'store/GET_USER_PAYMENT_PROVIDER_SETTINGS_SUCCEEDED';
export const GET_USER_PAYMENT_PROVIDER_SETTINGS_FAILED = 'store/GET_USER_PAYMENT_PROVIDER_SETTINGS_FAILED';

export const UPDATE_PAYMENT_PROVIDER_SETTINGS = 'store/UPDATE_PAYMENT_PROVIDER_SETTINGS';
export const UPDATE_PAYMENT_PROVIDER_SETTINGS_SUCCEEDED = 'store/UPDATE_PAYMENT_PROVIDER_SETTINGS_SUCCEEDED';
export const UPDATE_PAYMENT_PROVIDER_SETTINGS_FAILED = 'store/UPDATE_PAYMENT_PROVIDER_SETTINGS_FAILED';

export const GET_CART = 'store/GET_CART';
export const GET_CART_SUCCEEDED = 'store/GET_CART_SUCCEEDED';
export const GET_CART_FAILED = 'store/GET_CART_FAILED';

export const ADD_TO_CART = 'store/ADD_TO_CART';
export const ADD_TO_CART_SUCCEEDED = 'store/ADD_TO_CART_SUCCEEDED';
export const ADD_TO_CART_FAILED = 'store/ADD_TO_CART_FAILED';

export const REMOVE_FROM_CART = 'store/REMOVE_FROM_CART';
export const REMOVE_FROM_CART_SUCCEEDED = 'store/REMOVE_FROM_CART_SUCCEEDED';
export const REMOVE_FROM_CART_FAILED = 'store/REMOVE_FROM_CART_FAILED';

export const UPDATE_PRODUCT_IN_CART = 'store/UPDATE_PRODUCT_IN_CART';
export const UPDATE_PRODUCT_IN_CART_SUCCEEDED = 'store/UPDATE_PRODUCT_IN_CART_SUCCEEDED';
export const UPDATE_PRODUCT_IN_CART_FAILED = 'store/UPDATE_PRODUCT_IN_CART_FAILED';

export const ADD_ADDRESS_TO_CART = 'store/ADD_ADDRESS_TO_CART';
export const ADD_ADDRESS_TO_CART_SUCCEEDED = 'store/ADD_ADDRESS_TO_CART_SUCCEEDED';
export const ADD_ADDRESS_TO_CART_FAILED = 'store/ADD_ADDRESS_TO_CART_FAILED';

export const ADD_DISCOUNT = 'store/ADD_DISCOUNT';
export const ADD_DISCOUNT_SUCCEEDED = 'store/ADD_DISCOUNT_SUCCEEDED';
export const ADD_DISCOUNT_FAILED = 'store/ADD_DISCOUNT_FAILED';

export const REMOVE_DISCOUNT = 'store/REMOVE_DISCOUNT';
export const REMOVE_DISCOUNT_SUCCEEDED = 'store/REMOVE_DISCOUNT_SUCCEEDED';
export const REMOVE_DISCOUNT_FAILED = 'store/REMOVE_DISCOUNT_FAILED';

export const CREATE_ORDER = 'store/CREATE_ORDER';
export const CREATE_ORDER_SUCCEEDED = 'store/CREATE_ORDER_SUCCEEDED';
export const CREATE_ORDER_FAILED = 'store/CREATE_ORDER_FAILED';

export const GET_ORDER = 'store/GET_ORDER';
export const GET_ORDER_SUCCEEDED = 'store/GET_ORDER_SUCCEEDED';
export const GET_ORDER_FAILED = 'store/GET_ORDER_FAILED';

export const PAY_PENDING_ORDER = 'store/PAY_PENDING_ORDER';
export const PAY_PENDING_ORDER_SUCCEEDED = 'store/PAY_PENDING_ORDER_SUCCEEDED';
export const PAY_PENDING_ORDER_FAILED = 'store/PAY_PENDING_ORDER_FAILED';

export const ABORT_CREATE_ORDER = 'store/ABORT_CREATE_ORDER';

export const CLOSE_CREATE_ORDER = 'store/CLOSE_CREATE_ORDER';

export const CANCEL_ORDER = 'store/CANCEL_ORDER';
export const CANCEL_ORDER_SUCCEEDED = 'store/CANCEL_ORDER_SUCCEEDED';
export const CANCEL_ORDER_FAILED = 'store/CANCEL_ORDER_FAILED';

export const SET_SHOP_ID = 'store/SET_SHOP_ID';

export const GET_SUBSCRIPTIONS = 'store/GET_SUBSCRIPTIONS';
export const GET_SUBSCRIPTIONS_SUCCEEDED = 'store/GET_SUBSCRIPTIONS_SUCCEEDED';
export const GET_SUBSCRIPTIONS_FAILED = 'store/GET_SUBSCRIPTIONS_FAILED';

export const GET_USERS_ECOM_SUBSCRIPTIONS = 'store/GET_USERS_ECOM_SUBSCRIPTIONS';
export const GET_USERS_ECOM_SUBSCRIPTIONS_SUCCEEDED = 'store/GET_USERS_ECOM_SUBSCRIPTIONS_SUCCEEDED';
export const GET_USERS_ECOM_SUBSCRIPTIONS_FAILED = 'store/GET_USERS_ECOM_SUBSCRIPTIONS_FAILED';

export const GET_ORDERS = 'store/GET_ORDERS';
export const GET_ORDERS_SUCCEEDED = 'store/GET_ORDERS_SUCCEEDED';
export const GET_ORDERS_FAILED = 'store/GET_ORDERS_FAILED';
export const GET_ORDER_STATUS_SUCCEEDED = 'store/GET_ORDER_STATUS_SUCCEEDED';

export const GET_PAYMENT_ID_URL = 'store/GET_PAYMENT_ID_URL';
export const GET_PAYMENT_ID_URL_SUCCEEDED = 'store/GET_PAYMENT_ID_URL_SUCCEEDED';
export const GET_PAYMENT_ID_URL_FAILED = 'store/GET_PAYMENT_ID_URL_FAILED';

export const CANCEL_SUBSCRIPTION = 'store/CANCEL_SUBSCRIPTION';
export const CANCEL_SUBSCRIPTION_SUCCEEDED = 'store/CANCEL_SUBSCRIPTION_SUCCEEDED';
export const CANCEL_SUBSCRIPTION_FAILED = 'store/CANCEL_SUBSCRIPTION_FAILED';

export const RESUME_SUBSCRIPTION = 'store/RESUME_SUBSCRIPTION';
export const RESUME_SUBSCRIPTION_SUCCEEDED = 'store/RESUME_SUBSCRIPTION_SUCCEEDED';
export const RESUME_SUBSCRIPTION_FAILED = 'store/RESUME_SUBSCRIPTION_FAILED';

export const GET_PAID_BRANDS = 'store/GET_PAID_BRANDS';
export const GET_PAID_BRANDS_SUCCEEDED = 'store/GET_PAID_BRANDS_SUCCEEDED';
export const GET_PAID_BRANDS_FAILED = 'store/GET_PAID_BRANDS_FAILED';

export const setShopIdAction = (shopId) => ({
  type: SET_SHOP_ID,
  shopId: shopId,
});

export const getProductsAction = () => ({
  type: GET_PRODUCTS,
});

export const getProductsSucceededAction = (products) => ({
  type: GET_PRODUCTS_SUCCEEDED,
  products: products,
});

export const getProductsFailedAction = (error) => ({
  type: GET_PRODUCTS_FAILED,
  error: error,
});

export const getEComSupportedCountriesAction = () => ({
  type: GET_ECOM_SUPPORTED_COUNTRIES,
});

export const getEComSupportedCountriesSucceededAction = (supportedCountries) => ({
  type: GET_ECOM_SUPPORTED_COUNTRIES_SUCCEEDED,
  supportedCountries: supportedCountries,
});

export const getEComSupportedCountriesFailedAction = (error) => ({
  type: GET_ECOM_SUPPORTED_COUNTRIES_FAILED,
  error: error,
});

export const initCartAction = () => ({
  type: INIT_CART,
});

export const initCartSucceededAction = (cart) => ({
  type: INIT_CART_SUCCEEDED,
  cart: cart,
});

export const initCartFailedAction = (error) => ({
  type: INIT_CART_FAILED,
  error: error,
});

export const getPaymentProvidersAction = (cart) => ({
  type: GET_PAYMENT_PROVIDERS,
  cart: cart,
});

export const getPaymentProvidersSucceededAction = (paymentProviders) => ({
  type: GET_PAYMENT_PROVIDERS_SUCCEEDED,
  paymentProviders: paymentProviders,
});

export const getPaymentProvidersFailedAction = (error) => ({
  type: GET_PAYMENT_PROVIDERS,
  error: error,
});

export const getOrderPaymentProvidersAction = (order) => ({
  type: GET_ORDER_PAYMENT_PROVIDERS,
  order: order,
});

export const getOrderPaymentProvidersSucceededAction = (paymentProviders) => ({
  type: GET_ORDER_PAYMENT_PROVIDERS_SUCCEEDED,
  paymentProviders: paymentProviders,
});

export const getOrderPaymentProvidersFailedAction = (error) => ({
  type: GET_ORDER_PAYMENT_PROVIDERS,
  error: error,
});

export const selectPaymentProviderAction = (paymentProvider) => ({
  type: SELECT_PAYMENT_PROVIDER,
  paymentProvider: paymentProvider,
});

export const getCartAction = () => ({
  type: GET_CART,
});

export const getCartSucceededAction = (cart) => ({
  type: GET_CART_SUCCEEDED,
  cart: cart,
});

export const getCartFailedAction = (error) => ({
  type: GET_CART_FAILED,
  error: error,
});

export const addToCartAction = (cart, product, variant) => ({
  type: ADD_TO_CART,
  cart: cart,
  product: product,
  variant: variant,
});

export const addToCartSucceededAction = (cart) => ({
  type: ADD_TO_CART_SUCCEEDED,
  cart: cart,
});

export const addToCartFailedAction = (error) => ({
  type: ADD_TO_CART_FAILED,
  error: error,
});

export const removeFromCartAction = (cart, cartItem) => ({
  type: REMOVE_FROM_CART,
  cart: cart,
  cartItem: cartItem,
});

export const removeFromCartSucceededAction = (cart) => ({
  type: REMOVE_FROM_CART_SUCCEEDED,
  cart: cart,
});

export const removeFromCartFailedAction = (error) => ({
  type: REMOVE_FROM_CART_FAILED,
  error: error,
});

export const updateProductInCartAction = (cart, cartItem, quantity) => ({
  type: UPDATE_PRODUCT_IN_CART,
  cart: cart,
  cartItem: cartItem,
  quantity: quantity,
});

export const updateProductInCartSucceededAction = (cart) => ({
  type: UPDATE_PRODUCT_IN_CART_SUCCEEDED,
  cart: cart,
});

export const updateProductInCartFailedAction = (error) => ({
  type: UPDATE_PRODUCT_IN_CART_FAILED,
  error: error,
});

export const addDiscountAction = (cart, discount) => ({
  type: ADD_DISCOUNT,
  cart: cart,
  discount: discount,
});

export const addDiscountSucceededAction = (cart) => ({
  type: ADD_DISCOUNT_SUCCEEDED,
  cart: cart,
});

export const addDiscountFailedAction = (error) => ({
  type: ADD_DISCOUNT_FAILED,
  error: error,
});

export const removeDiscountAction = (cart, discount) => ({
  type: REMOVE_DISCOUNT,
  cart: cart,
  discount: discount,
});

export const removeDiscountSucceededAction = (cart) => ({
  type: REMOVE_DISCOUNT_SUCCEEDED,
  cart: cart,
});

export const removeDiscountFailedAction = (error) => ({
  type: REMOVE_DISCOUNT_FAILED,
  error: error,
});

export const createOrderAction = () => ({
  type: CREATE_ORDER,
});

export const createOrderSucceededAction = (order) => ({
  type: CREATE_ORDER_SUCCEEDED,
  order: order,
});

export const createOrderFailedAction = (error) => ({
  type: CREATE_ORDER_FAILED,
  error: error,
});

export const getOrderAction = (id) => ({
  type: GET_ORDER,
  id: id,
});

export const getOrderSucceededAction = (order) => ({
  type: GET_ORDER_SUCCEEDED,
  order: order,
});

export const getOrderStatusSucceededAction = (order) => ({
  type: GET_ORDER_STATUS_SUCCEEDED,
  order: order,
});

export const getOrderFailedAction = (error) => ({
  type: GET_ORDER_FAILED,
  error: error,
});

export const abortCreatedOrderAction = () => ({
  type: ABORT_CREATE_ORDER,
});

export const closeCreatedOrderAction = () => ({
  type: CLOSE_CREATE_ORDER,
});

export const getSubscriptionsAction = () => ({
  type: GET_SUBSCRIPTIONS,
});

export const getSubscriptionsSucceededAction = (subscriptions) => ({
  type: GET_SUBSCRIPTIONS_SUCCEEDED,
  subscriptions: subscriptions,
});

export const getSubscriptionsFailedAction = (error) => ({
  type: GET_SUBSCRIPTIONS_FAILED,
  error: error,
});

export const getUsersEcomSubscriptionsAction = () => ({
  type: GET_USERS_ECOM_SUBSCRIPTIONS,
});

export const getUsersEcomSubscriptionsSucceededAction = (data) => ({
  type: GET_USERS_ECOM_SUBSCRIPTIONS_SUCCEEDED,
  subscriptions: data,
});

export const getUsersEcomSubscriptionsFailedAction = (error) => ({
  type: GET_USERS_ECOM_SUBSCRIPTIONS_FAILED,
  error: error,
});

export const getUserPaymentProviderSettingsAction = (user) => ({
  type: GET_USER_PAYMENT_PROVIDER_SETTINGS,
  user: user,
});

export const getUserPaymentProviderSettingsSucceededAction = (paymentProviders, paymentProviderSettings) => ({
  type: GET_USER_PAYMENT_PROVIDER_SETTINGS_SUCCEEDED,
  paymentProviders: paymentProviders,
  paymentProviderSettings: paymentProviderSettings,
});

export const getUserPaymentProviderSettingsFailedAction = (error) => ({
  type: GET_USER_PAYMENT_PROVIDER_SETTINGS_FAILED,
  error: error,
});

export const updatePaymentProviderSettingsAction = (user) => ({
  type: UPDATE_PAYMENT_PROVIDER_SETTINGS,
  user: user,
});

export const updatePaymentProviderSettingsSucceededAction = (paymentProviderSettings) => ({
  type: UPDATE_PAYMENT_PROVIDER_SETTINGS_SUCCEEDED,
  paymentProviderSettings: paymentProviderSettings,
});

export const updatePaymentProviderSettingsFailedAction = (error) => ({
  type: UPDATE_PAYMENT_PROVIDER_SETTINGS_FAILED,
  error: error,
});

export const cancelSubscriptionAction = (id) => ({
  type: CANCEL_SUBSCRIPTION,
  id,
});

export const cancelSubscriptionSucceededAction = (subscription) => ({
  type: CANCEL_SUBSCRIPTION_SUCCEEDED,
  subscription,
});

export const cancelSubscriptionFailedAction = (error) => ({
  type: CANCEL_SUBSCRIPTION_FAILED,
  error,
});

export const resumeSubscriptionAction = (id) => ({
  type: RESUME_SUBSCRIPTION,
  id,
});

export const resumeSubscriptionSucceededAction = (subscription) => ({
  type: RESUME_SUBSCRIPTION_SUCCEEDED,
  subscription,
});

export const resumeSubscriptionFailedAction = (error) => ({
  type: RESUME_SUBSCRIPTION_FAILED,
  error,
});

export const getOrdersAction = () => ({
  type: GET_ORDERS,
});

export const getOrdersSucceededAction = (orders) => ({
  type: GET_ORDERS_SUCCEEDED,
  orders: orders,
});

export const getOrdersFailedAction = (error) => ({
  type: GET_ORDERS_FAILED,
  error: error,
});

export const cancelOrderAction = () => ({
  type: CANCEL_ORDER,
});

export const cancelOrderSucceededAction = (order) => ({
  type: CANCEL_ORDER_SUCCEEDED,
  order: order,
});

export const cancelOrderFailedAction = (error) => ({
  type: CANCEL_ORDER_FAILED,
  error: error,
});

export const payPendingOrderAction = () => ({
  type: PAY_PENDING_ORDER,
});

export const payPendingOrderSucceededAction = () => ({
  type: PAY_PENDING_ORDER_SUCCEEDED,
});

export const payPendingOrderFailedAction = (error) => ({
  type: PAY_PENDING_ORDER_FAILED,
  error: error,
});

export const paidBrands = () => ({
  type: GET_PAID_BRANDS,
});

export const paidBrandsSucceeded = (stripeSpecificBrands, allPaidBrands) => ({
  type: GET_PAID_BRANDS_SUCCEEDED,
  stripeSpecificBrands: stripeSpecificBrands,
  allPaidBrands: allPaidBrands,
});

export const paidBrandsFailed = (error) => ({
  type: GET_PAID_BRANDS_FAILED,
  error: error,
});
