import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button } from 'jpi-cloud-web-ui-components';

class ConfirmCreateEventPopup extends React.PureComponent {
  static propTypes = {
    confirmCreateEvent: PropTypes.func.isRequired,
  };

  render() {
    const { confirmCreateEvent } = this.props;

    return (
      <div className="popup-body">
        <div className="popup-main">
          <div className="popup-header">
            <p className="text center">
              <FormattedMessage
                id="scheduling.replace-existing-scheduling"
                defaultMessage="This update will replace existing scheduling. Are you sure you want to continue?"
              />
            </p>
          </div>
          <div className="popup-bottom-panel">
            <Button className="button--secondary" type="submit" onClick={() => confirmCreateEvent(true)}>
              <FormattedMessage id="scheduling.title.yes" defaultMessage="Yes" />
            </Button>
            <Button onClick={() => confirmCreateEvent(false)} className="button--default" type="button">
              <FormattedMessage id="scheduling.title.cancel" defaultValue="Cancel" />
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default ConfirmCreateEventPopup;
