import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../layout/Modal';
import { Button } from 'jpi-cloud-web-ui-components';
import { FormattedMessage } from 'react-intl';

const TileRemovalConfirmationPopup = ({ isShown, onDiscard, onConfirm }) => (
  <Modal show={isShown} backdrop="static" onHide={onDiscard}>
    <div className="tile-configuration-popup">
      <h2>
        <FormattedMessage id="tiles.removal.confirmation.title" defaultMessage="Are you sure?" />
      </h2>
      <div className="popup-body">
        <FormattedMessage
          id="tiles.removal.confirmation.message"
          defaultMessage="Tile will be deleted from each device"
        />
      </div>
      <div className="popup-bottom-panel">
        <Button onClick={onDiscard} className="button--default" type="button">
          <FormattedMessage id="tiles.removal.buttons.cancel" defaultMessage="Cancel" />
        </Button>
        <Button onClick={onConfirm} className="button--secondary" type="button">
          <FormattedMessage id="tiles.removal.buttons.submit" defaultMessage="Submit" />
        </Button>
      </div>
    </div>
  </Modal>
);

TileRemovalConfirmationPopup.propTypes = {
  isShown: PropTypes.bool,
  onDiscard: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default TileRemovalConfirmationPopup;
