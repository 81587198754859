import { GET_SERVICE_PARTNER_SUCCEEDED, GET_SERVICE_PARTNER_FAILED } from './actions';

const initialState = {
  servicePartner: {},
};

const spNotFound = {
  id: 'spNotFound',
  name: '',
  contactPhone: '',
  contactEmail: '',
  address: {
    city: '',
    country: '',
    lineOne: '',
    lineTwo: '',
    postalCode: '',
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SERVICE_PARTNER_SUCCEEDED:
      return { ...state, servicePartner: action.servicePartner };
    case GET_SERVICE_PARTNER_FAILED:
      return { ...state, servicePartner: spNotFound };
    default:
      return state;
  }
};
