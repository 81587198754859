import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const StoreLink = ({ val, redirectIotStore, className, selectedSystem }) => {
  return (
    <>
      {selectedSystem?.brandId && (
        <div className={className ? className : 'desktopSubMenu__link'}>
          <button className="desktopSubMenu__iotStoreLink" onClick={() => redirectIotStore()}>
            <FormattedMessage id={val.id} defaultMessage={val.name} />
          </button>
        </div>
      )}
    </>
  );
};

StoreLink.propTypes = {
  val: PropTypes.object,
  redirectIotStore: PropTypes.func,
  className: PropTypes.string,
  selectedSystem: PropTypes.object,
};

export default StoreLink;
