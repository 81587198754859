import * as api from '../../../api';

export const GET_FIRMWARES_INFO_REQUEST = 'firmwares/GET_FIRMWARES_INFO_REQUEST';
export const GET_FIRMWARES_INFO_RESPONSE_SUCCESS = 'firmwares/GET_FIRMWARES_INFO_RESPONSE_SUCCESS';
export const GET_FIRMWARES_INFO_RESPONSE_FAIL = 'firmwares/GET_FIRMWARES_INFO_RESPONSE_FAIL';
export const getFirmwaresByBrand = (brandId) => async (dispatch) => {
  dispatch({ type: GET_FIRMWARES_INFO_REQUEST });

  const brand = !brandId ? 'NIBE' : brandId;
  try {
    const { status, data } = await api.getFirmwaresByBrand(brand);
    if (status === 200) {
      dispatch({ type: GET_FIRMWARES_INFO_RESPONSE_SUCCESS, firmwares: data });
    } else {
      dispatch({ type: GET_FIRMWARES_INFO_RESPONSE_FAIL });
    }
  } catch {
    dispatch({ type: GET_FIRMWARES_INFO_RESPONSE_FAIL });
  }
};

export const getMyFirmwares = () => async (dispatch) => {
  dispatch({ type: GET_FIRMWARES_INFO_REQUEST });

  try {
    const { status, data } = await api.getMySystemsFirmwares();
    if (status === 200) {
      dispatch({ type: GET_FIRMWARES_INFO_RESPONSE_SUCCESS, firmwares: data });
    } else {
      dispatch({ type: GET_FIRMWARES_INFO_RESPONSE_FAIL });
    }
  } catch {
    dispatch({ type: GET_FIRMWARES_INFO_RESPONSE_FAIL });
  }
};

export const GET_ACTIVE_BRANDS_REQUEST = 'firmwares/GET_ACTIVE_BRANDS_REQUEST';
export const GET_ACTIVE_BRANDS_RESPONSE_SUCCESS = 'firmwares/GET_ACTIVE_BRANDS_RESPONSE_SUCCESS';
export const GET_ACTIVE_BRANDS_RESPONSE_FAIL = 'firmwares/GET_ACTIVE_BRANDS_RESPONSE_FAIL';
export const getActiveBrands = () => async (dispatch) => {
  dispatch({ type: GET_ACTIVE_BRANDS_REQUEST });
  try {
    const { status, data } = await api.getActiveBrands();
    if (status === 200) {
      dispatch({ type: GET_ACTIVE_BRANDS_RESPONSE_SUCCESS, brands: data });
    } else {
      dispatch({ type: GET_ACTIVE_BRANDS_RESPONSE_FAIL });
    }
  } catch {
    dispatch({ type: GET_ACTIVE_BRANDS_RESPONSE_FAIL });
  }
};
