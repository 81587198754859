import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo } from '@fortawesome/free-solid-svg-icons';

import './infoIcon.scss';

const InfoIcon = () => (
  <div className="info-button">
    <span className="icon">
      <FontAwesomeIcon icon={faInfo} />
    </span>
  </div>
);

export default InfoIcon;
