import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Spinner, Title } from 'jpi-cloud-web-ui-components';

import { getUserInfo, getUserSystems } from '../../AppInitializer/actions';
import { removeSystemTiles, unsetSystemAsPrepopulated } from '../../tiles/TileManager/actions';
import { changePassword, resetEmailError, deleteAccount, resetDeleteError, validateUserCredentials } from './actions';
import ProfileTab from './components/ProfileTab/ProfileTab';
import AppearanceTab from './components/AppearanceTab/AppearanceTab';
import DeleteAccountTab from './components/DeleteAccountTab/DeleteAccountTab';
import ChangePasswordForm from './components/ChangePasswordForm';
import ThirdPartyApps from './components/ThirdPartyApps/ThirdPartyApps';
import NotificationSettingsTab from './components/NotificationSettingsTab/NotificationSettingsTab';
import './profilePage.scss';

class ProfilePage extends React.Component {
  state = {
    isLoaded: false,
    isEmailEditShown: false,
    isEmailConfirmationSent: false,
    isAddressEditShown: false,
    isPasswordChanged: false,
    selectedTab: 'profile',
  };

  selectTab = (tab) => {
    const isDemo = (this.props.userInfo && this.props.userInfo.isDemo) || false;

    if (isDemo && (tab === 'email' || tab === 'password' || tab === 'delete')) {
      return false;
    }

    this.setState({ ...this.state, selectedTab: tab });
  };

  async componentDidMount() {
    await this.props.getUserInfo();
    this.setState({ ...this.state, isLoaded: true });
  }

  getCurrentTab = () => {
    const userInfo = this.props.userInfo;
    switch (this.state.selectedTab) {
      case 'profile':
        return (
          <ProfileTab
            userInfo={userInfo}
            addressId={userInfo.addressId}
            requestError={this.props.profileTab.profileError}
          />
        );
      case 'appearance':
        return <AppearanceTab />;
      case 'notification-settings':
        return <NotificationSettingsTab />;
      case 'password':
        return (
          <div className="tabContainer">
            <ChangePasswordForm
              requestError={this.props.profilePage.passwordError}
              isChanged={this.state.isPasswordChanged}
              onSubmit={this.onPasswordSubmit}
            />
          </div>
        );
      case 'permission':
        return <ThirdPartyApps />;
      case 'delete':
        return (
          <DeleteAccountTab
            deleteError={this.props.profilePage.deleteError}
            deleteUser={this.props.deleteAccount}
            getUserSystems={this.props.getUserSystems}
            resetDeleteError={this.props.resetDeleteError}
            removeSystemTiles={this.props.removeSystemTiles}
            unsetSystemAsPrepopulated={this.props.unsetSystemAsPrepopulated}
            userId={userInfo.id}
            userEmail={userInfo.email}
            history={this.props.history}
            validatePasswordError={this.props.profilePage.validatePasswordError}
            validateUserCredentials={this.props.validateUserCredentials}
          />
        );
      default:
        return null;
    }
  };

  onPasswordSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    if (await this.props.changePassword(this.props.userInfo.id, values.oldPassword.trim(), values.newPassword.trim())) {
      this.setState({ ...this.state, isPasswordChanged: true });
      resetForm();
      setSubmitting(false);
      setTimeout(() => {
        this.setState({ ...this.state, isPasswordChanged: false });
      }, 2000);
    } else {
      setSubmitting(false);
    }
  };

  render() {
    const isDemo = this.props.userInfo && this.props.userInfo.isDemo;
    const isDisabledClassName = isDemo ? 'disabled' : '';

    if (!this.state.isLoaded) {
      return <Spinner />;
    }

    return (
      <div className="page-content">
        <div className="profile-page">
          <Title titleTranslationId="profile-settings.title" defaultMessage="Profile Settings" />
          <div className="tabordion">
            <section className="profile-section" id="profile-section">
              <div
                onClick={() => this.selectTab('profile')}
                className={this.state.selectedTab === 'profile' ? 'option-label selected' : 'option-label'}
              >
                <FormattedMessage id={'label.profile'} defaultMessage="Profile" />
              </div>
              <div
                onClick={() => this.selectTab('appearance')}
                className={this.state.selectedTab === 'appearance' ? 'option-label selected' : 'option-label'}
              >
                <FormattedMessage id={'label.appearance'} defaultMessage="appearance" />
              </div>
              <div
                onClick={() => this.selectTab('notification-settings')}
                className={
                  this.state.selectedTab === 'notification-settings' ? 'option-label selected' : 'option-label'
                }
              >
                <FormattedMessage id={'label.notification-settings'} defaultMessage="Notifications" />
              </div>
              <div
                onClick={() => this.selectTab('permission')}
                className={classNames(
                  this.state.selectedTab === 'permission' ? 'option-label selected' : 'option-label',
                  isDisabledClassName
                )}
              >
                <FormattedMessage id={'label.permissions'} defaultMessage="Permissions" />
              </div>
              <div
                onClick={() => this.selectTab('password')}
                className={classNames(
                  this.state.selectedTab === 'password' ? 'option-label selected' : 'option-label',
                  isDisabledClassName
                )}
              >
                <FormattedMessage id={'label.password'} defaultMessage="Password" />
              </div>
              <div
                onClick={() => this.selectTab('delete')}
                className={classNames(
                  this.state.selectedTab === 'delete' ? 'option-label selected' : 'option-label',
                  isDisabledClassName
                )}
              >
                <FormattedMessage id={'label.delete'} defaultMessage="Remove account" />
              </div>
            </section>
            <div className="tab-content">{this.getCurrentTab()}</div>
          </div>
        </div>
      </div>
    );
  }
}

ProfilePage.propTypes = {
  getUserInfo: PropTypes.func.isRequired,
  getUserSystems: PropTypes.func.isRequired,
  removeSystemTiles: PropTypes.func.isRequired,
  unsetSystemAsPrepopulated: PropTypes.func.isRequired,
  changePassword: PropTypes.func.isRequired,
  resetEmailError: PropTypes.func.isRequired,
  deleteAccount: PropTypes.func.isRequired,
  resetDeleteError: PropTypes.func.isRequired,
  history: PropTypes.object,
  profilePage: PropTypes.object,
  userInfo: PropTypes.object,
  validateUserCredentials: PropTypes.func.isRequired,
  profileTab: PropTypes.object,
};

export default connect(
  ({ app: { userInfo }, profilePage, profileTab }) => ({
    userInfo,
    profilePage,
    profileTab,
  }),
  {
    getUserInfo,
    removeSystemTiles,
    unsetSystemAsPrepopulated,
    getUserSystems,
    changePassword,
    resetEmailError,
    deleteAccount,
    resetDeleteError,
    validateUserCredentials,
  }
)(ProfilePage);
