import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import TileIcons, { getWeatherIcon } from '../GenericTile/tile-icons';
import { isEmpty } from 'lodash';

import './weekly-weather-forecast.scss';

const weekday = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const WeeklyWeatherSection = ({ data, brandId }) => {
  return (
    <div className="weekly-weather-section">
      <div className="day-of-week">
        <FormattedMessage
          id={`tile.weather.${weekday[new Date(data.date).getDay()]}`}
          defaultMessage={weekday[new Date(data.date).getDay()]}
        />
      </div>
      <div className="weekly-weather-section__data">
        <span className="weekly-section-icon">
          <img src={getWeatherIcon(TileIcons, brandId, data.icon)} />
        </span>
        <span className="weekly-section-temperature">{data?.temperatureHigh ? data.temperatureHigh : '--'}</span>
        <span className="weekly-section-temperature">{data?.temperatureLow ? data.temperatureLow : '--'}</span>
      </div>
    </div>
  );
};

WeeklyWeatherSection.propTypes = {
  data: PropTypes.object.isRequired,
  brandId: PropTypes.string.isRequired,
};

const WeeklyWeatherForecast = ({ weatherData, brandId }) => {
  const dailyForcast = weatherData.daily;

  if (isEmpty(dailyForcast)) {
    return null;
  }

  const weatherForcastForNext5Days = dailyForcast.slice(0, 5);

  return (
    <>
      {weatherForcastForNext5Days.map((d) => {
        return <WeeklyWeatherSection key={d.time} data={d} brandId={brandId} />;
      })}
    </>
  );
};

WeeklyWeatherForecast.propTypes = {
  weatherData: PropTypes.object.isRequired,
  brandId: PropTypes.string.isRequired,
};

export default WeeklyWeatherForecast;
