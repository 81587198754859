import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '../../Link';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { confirmEmail } from './actions';

class ConfirmEmail extends React.Component {
  static propTypes = {
    confirmEmail: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    user: PropTypes.any,
  };

  state = {
    isSubmitting: false,
  };

  async componentDidMount() {
    const { token } = this.props.match.params;
    if (await this.props.confirmEmail(token)) {
      this.props.history.push('/login');
    }
  }

  render() {
    return (
      <div className="form">
        <h1 className="form__heading">
          <FormattedMessage id="confirmEmail.heading" defaultMessage="Link is wrong or expired" />
        </h1>
        <div className="form__paragraph form__paragraph--no-padding-top">
          <div className="button-wrapper--large">
            <Link tagName="button" className="button button--primary" to="/login">
              <FormattedMessage id="login.login" defaultMessage="Log in" />
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, {
  confirmEmail,
})(ConfirmEmail);
