import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button } from 'jpi-cloud-web-ui-components';
import { isCountryUseAmPmHours } from '../../../../../api/countries';

import TimePicker from '../TimePicker';

class SelectTimeEvent extends React.PureComponent {
  static propTypes = {
    goPrevious: PropTypes.func.isRequired,
    selectedSystem: PropTypes.object,
    timeToStart: PropTypes.string,
    timeToStop: PropTypes.string,
    showStopTime: PropTypes.bool,
    eventToEdit: PropTypes.object,
    onCreateEvent: PropTypes.func.isRequired,
  };

  state = {
    timeToStart: '00:00:00',
    timeToStop: '00:00:00',
  };

  componentDidMount() {
    const { timeToStart, timeToStop } = this.props;

    if (timeToStart) {
      this.setState({ timeToStart });
    }

    if (timeToStop) {
      this.setState({ timeToStop });
    }
  }

  onStartTimeChange = (timeToStart) => {
    this.setState({ timeToStart });
  };

  onStopTimeChange = (timeToStop) => {
    this.setState({ timeToStop });
  };

  render() {
    const { goPrevious, onCreateEvent, eventToEdit, selectedSystem } = this.props;

    return (
      <Fragment>
        <div className="popup-body">
          <div className="popup-main">
            <div className="popup-header">
              <p className="text">
                {eventToEdit ? (
                  <FormattedMessage
                    id="scheduling.edit-event.title.step2.text"
                    defaultMessage="Change what time do you  want this mode to start?"
                  />
                ) : (
                  <FormattedMessage
                    id="scheduling.create-event.title.step2.text"
                    defaultMessage="What time do you want this mode to start?"
                  />
                )}
              </p>
            </div>
            <TimePicker
              time={this.state.timeToStart}
              onTimeChange={this.onStartTimeChange}
              isAmPmHours={isCountryUseAmPmHours(selectedSystem?.address?.country?.countryCode2Alpha)}
            />
            {this.props.showStopTime && (
              <Fragment>
                <div className="popup-header">
                  <p className="text">
                    {eventToEdit ? (
                      <FormattedMessage
                        id="scheduling.edit-event.title.step3.text"
                        defaultMessage="Change what time do you  want this mode to stop?"
                      />
                    ) : (
                      <FormattedMessage
                        id="scheduling.create-event.title.step3.text"
                        defaultMessage="What time do you want this mode to stop?"
                      />
                    )}
                  </p>
                </div>
                <TimePicker
                  time={this.state.timeToStop}
                  onTimeChange={this.onStopTimeChange}
                  isAmPmHours={isCountryUseAmPmHours(selectedSystem?.address?.country?.countryCode2Alpha)}
                />
              </Fragment>
            )}
          </div>
        </div>

        <div className="popup-bottom-panel">
          <Button
            className="button--secondary"
            type="submit"
            onClick={() => onCreateEvent(this.state.timeToStart, this.state.timeToStop)}
          >
            <FormattedMessage id="scheduling.title.save" defaultMessage="Save" />
          </Button>
          <Button onClick={goPrevious} className="button--default" type="button">
            <FormattedMessage id="scheduling.title.back" defaultValue="Back" />
          </Button>
        </div>
      </Fragment>
    );
  }
}

export default SelectTimeEvent;
