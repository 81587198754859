import React from 'react';
import { Link } from '../Link';
import myUplinkLogo from '../../assets/img/myUplink_logo.png';
import './logo.scss';

const Logo = () => (
  <Link className="logo" to={'/'} tabIndex={0}>
    <img src={myUplinkLogo} alt="This is myUplink logo" />
  </Link>
);

export default Logo;
