import React from 'react';
import * as client from '../Shop/client';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './shop.scss';

const getUserCountryFromProps = (userInfo) =>
  userInfo && userInfo.address && userInfo.address.country ? userInfo.address.country.name : '';
const getSelectedSystemCountryFromProps = (selectedSystem) =>
  selectedSystem && selectedSystem.address && selectedSystem.address.country ? selectedSystem.address.country.name : '';
const isShopAvailableForCountry = (supportedCountries, userCountryName) =>
  supportedCountries &&
  supportedCountries.loaded &&
  supportedCountries.countries &&
  supportedCountries.countries.includes(userCountryName.toLowerCase());

class Shop extends React.Component {
  constructor(props) {
    super(props);
  }

  getSnapshotBeforeUpdate(prevProps) {
    const {
      selectedSystem,
      userInfo,
      devices,
      cart,
      supportedCountries,
      paidBrands: { allPaidBrands },
    } = this.props;
    const { brandId = '' } = selectedSystem || {};
    const userCountryName = getUserCountryFromProps(this.props.userInfo);
    const selectedSystemCountryName = getSelectedSystemCountryFromProps(this.props.selectedSystem);
    const isPaidBrand = allPaidBrands?.includes(brandId?.toLowerCase());

    const isShopAvailable =
      isShopAvailableForCountry(supportedCountries, userCountryName) &&
      isShopAvailableForCountry(supportedCountries, selectedSystemCountryName);

    if (isShopAvailable && selectedSystem && userInfo && devices.length > 0 && isPaidBrand) {
      if (
        (!cart.init && !cart.loading) ||
        (!prevProps.devices[0] && devices[0]) ||
        (prevProps.devices[0] && prevProps.devices[0].id !== devices[0].id)
      ) {
        return Promise.resolve(
          this.props.createCart(
            userInfo.email,
            { ...selectedSystem.address, name: userInfo.fullName },
            { systemId: selectedSystem.id, deviceId: devices[0].id }
          )
        )

          .then(() => {
            this.props.getUserEcomSubscriptions(selectedSystem.devices[0].id, selectedSystem.brandId);
            return null;
          })
          .catch(() => {
            //show error message
          });
      }
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    const { cart } = this.props;
    const { cart: prevPropsCart } = prevProps;
    if (prevPropsCart.lineItems.length !== cart.lineItems.length) {
      localStorage.setItem('STORED_USER_CART_DETAILS', JSON.stringify(cart));
    }
  }

  render() {
    return <div className="shop"></div>;
  }
}

Shop.propTypes = {
  dispatch: PropTypes.func,
  products: PropTypes.object,
  supportedCountries: PropTypes.object,
  userInfo: PropTypes.object,
  selectedSystem: PropTypes.object,
  cart: PropTypes.object.isRequired,
  devices: PropTypes.array,
  createCart: PropTypes.func.isRequired,
  getProducts: PropTypes.func.isRequired,
  getUserEcomSubscriptions: PropTypes.func.isRequired,
  paidBrands: PropTypes.shape({
    allPaidBrands: PropTypes.array,
  }),
};

const mapStateToProps = (state) => ({
  selectedSystem: state.app.selectedSystem,
  language: state.language.selectedLanguage,
  devices: state.devices.devices,
  systems: state.app.systems,
  userInfo: state.app.userInfo,
  ...state.shop,
  paidBrands: state.shop.paidBrands,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  createCart(email, address, customAttributes) {
    dispatch(client.createCart(email, address, customAttributes));
  },
  getUserEcomSubscriptions(deviceId, brandId) {
    dispatch(client.getUserEcomSubscriptions(deviceId, brandId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Shop);
