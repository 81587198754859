import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { USER_SECURITY_LEVELS } from '../../pages/SystemProfile/components/SecurityTab/costants';
import TopMenu from '../TopMenu';
import Footer from '../Footer';
import Hero from '../Hero';
import { getDeviceFeatures } from '../../FeaturesFlags/actions';
import { getLinkSetForLoggedInUser } from '../../layout/SignOutLayout/blockPassLinks';

import './signed-in-layout.scss';

class SignInLayout extends Component {
  async componentDidMount() {
    document.title = this.props.intl.formatMessage({
      id: 'sign-in-layout.default-title',
      defaultMessage: 'myUplink Web',
    });

    // To close payment window if any error comes up
    window.parent.postMessage(
      {
        id: 'order-callback',
        path: window.location.pathname,
      },
      window.location.origin
    );

    document.body.classList.add('signed-in');
    this.props.devices.length > 0 && (await this.props.getDeviceFeatures(this.props.devices.devices[0].id));
  }

  componentWillUnmount() {
    document.body.classList.remove('signed-in');
  }

  render() {
    const { component: Component, selectedSystem, systems, intl, devices, status, ...rest } = this.props;

    const userIsAdmin =
      selectedSystem && selectedSystem.userRole && selectedSystem.userRole.toLowerCase() === USER_SECURITY_LEVELS.admin;

    const isPremiumButtonVisible =
      (userIsAdmin &&
        devices &&
        Array.isArray(devices.subscriptions) &&
        !devices.subscriptions.some((item) => item.hasPremiumFeature)) ||
      false;

    const hasSystems = systems && systems.length > 0;

    const isSmartGuideAvailable = this.props.deviceFeatures.smartGuide;

    if (window.self !== window.top) {
      document.body.style['display'] = 'none';
      return null;
    }

    return (
      <div>
        {status && status.statusCode && status.statusCode.value !== 'status-0' && (
          <div className="status-banner">{status.statusText}</div>
        )}
        <div id="skiplink">
          <div className="page-centered">
            {getLinkSetForLoggedInUser(intl).map((item, index) => (
              <a key={`item.href--${index}`} href={item.href}>
                {item.text}
              </a>
            ))}
          </div>
        </div>
        <TopMenu />
        <main id="main">
          <Hero />
          <section id="main-content">
            <Route
              {...rest}
              render={(matchProps) => (
                <div className="container-fluid">
                  <Component {...matchProps} />
                </div>
              )}
            ></Route>
          </section>
        </main>
        <Footer
          isPremiumButtonVisible={isPremiumButtonVisible}
          hasSystems={hasSystems}
          isSmartGuideAvailable={isSmartGuideAvailable}
        />
      </div>
    );
  }
}

export default connect(
  ({ app, features: { deviceFeatures }, ...state }) => ({
    systems: app.systems,
    devices: state.devices,
    selectedSystem: app.selectedSystem ? app.selectedSystem : { name: null },
    status: app.status,
    deviceFeatures,
  }),
  {
    getDeviceFeatures,
  }
)(injectIntl(SignInLayout));
