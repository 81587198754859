import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as yup from 'yup';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Input } from 'jpi-cloud-web-ui-components';
import { InputDropdown } from '../../../../inputs/Dropdown';
import { address, noRegion, defaultMaxLength } from '../../../../constants/constants';
import './address.scss';
import { formatErrorMessage } from '../../../../../localization/message-formatting';


const addressSchema = yup.object().shape({
  country: yup.object().nullable().required('country.required'),
  region: yup
    .string()
    .trim()
    .when('country', {
      is: (country) => country && country.regions && country.regions.length > 0,
      then: yup
        .string()
        .required('region.required')
        .max(address.regionNameMaxLength, 'region.maxlength')
        .matches(address.pattern, 'region.invalid-format'),
      otherwise: yup
        .string()
        .max(address.regionNameMaxLength, 'region.maxlength')
        .matches(address.pattern, 'region.invalid-format'),
    }),
  city: yup
    .string()
    .trim()
    .required('city.required')
    .matches(address.pattern, 'city.invalid-format')
    .max(address.cityNameMaxLength, 'city.max-length'),
  lineOne: yup
    .string()
    .trim()
    .required('address.line-one.required')
    .matches(address.pattern, 'address.line-one.invalid-format')
    .max(defaultMaxLength, 'address.line-one.max-length'),
  lineTwo: yup
    .string()
    .trim()
    .matches(address.pattern, 'address.line-two.invalid-format')
    .max(defaultMaxLength, 'address.line-two.max-length'),
  postalCode: yup
    .string()
    .trim()
    .required('postal-code.required')
    .max(address.postalCode.maxLength, 'postal-code.maxlength')
    .matches(address.postalCode.regex, 'postal-code.regexp'),
});

const errorMessages = {
  'country.required': {
    id: 'country.error.validation.required',
    defaultMessage: 'Country is mandatory field',
  },
  'city.required': {
    id: 'city.error.validation.required',
    defaultMessage: 'City is mandatory field',
  },
  'city.invalid-format': {
    id: 'city.error.validation.invalid-format',
    defaultMessage: 'City has invalid format',
  },
  'city.max-length': {
    id: 'city.error.validation.maxlength',
    defaultMessage: 'City cannot be longer than 100 characters',
  },
  'address.line-one.required': {
    id: 'address.error.validation.line-one.required',
    defaultMessage: 'Address line 1 is required',
  },
  'address.line-one.invalid-format': {
    id: 'address.error.validation.line-one.invalid-format',
    defaultMessage: 'Address line 1 has invalid format',
  },
  'address.line-one.max-length': {
    id: 'address.error.validation.line-one.maxlength',
    defaultMessage: 'Address line 1 cannot be longer than 255 characters',
  },
  'address.line-two.invalid-format': {
    id: 'address.error.validation.line-two.invalid-format',
    defaultMessage: 'Address line 2 has invalid format',
  },
  'address.line-two.max-length': {
    id: 'address.error.validation.line-two.maxlength',
    defaultMessage: 'Address line 2 cannot be longer than 255 characters',
  },
  'postal-code.maxlength': {
    id: 'postal-code.error.validation.maxlength',
    defaultMessage: 'Postal code cannot be longer than 20 characters',
  },
  'postal-code.required': {
    id: 'postal-code.error.validation.required',
    defaultMessage: 'Postal code is mandatory field',
  },
  'postal-code.regexp': {
    id: 'postal-code.error.validation.regexp',
    defaultMessage: 'Postal code has an invalid format',
  },
  'region.required': {
    id: 'region.error.validation.required',
    defaultMessage: 'Region is mandatory field',
  },
  'region.invalid-format': {
    id: 'region.error.validation.invalid-format',
    defaultMessage: 'Region has invalid format',
  },
  'region.max-length': {
    id: 'region.error.validation.maxlength',
    defaultMessage: 'Region cannot be longer than 100 characters',
  },
  unknown: {
    id: 'generic.error.request.unknown',
    defaultMessage: 'An error has occurred. Try again later.',
  },
};

const defaultAddress = {
  country: null,
  region: '',
  city: '',
  postalCode: '',
  lineOne: '',
  lineTwo: '',
};

const EditAddressInfo = ({ addressId, address, countries, onSubmit, onCancel, requestError, intl ,addressValidationError }) => {
  const editedAddress = { ...address, id: addressId } || defaultAddress;
  const country = countries.filter((country) => {
    return (
      country.name.toLowerCase() === editedAddress &&
      editedAddress.country &&
      editedAddress.country.name.toLowerCase().trim()
    );
  })[0];

  const [selectedCountry, setSelectedCountry] = useState(country);

  return (
    <Formik countries={countries} initialValues={editedAddress} validationSchema={addressSchema} onSubmit={onSubmit}>
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
      }) => (
        <div>
          <form onSubmit={handleSubmit} className="system-profile-form edit-address-form">
            <input type="hidden" id={values.id} value={values.id} />
            <div className="address-row">
              <InputDropdown
                placeholder={intl.formatMessage({ id: 'label.country', defaultMessage: 'Country' })}
                selectedItem={values.country || null}
                label={(values.country && values.country.name) || ''}
                id={Date.now().toString(36) + Math.random().toString(36).substr(2)}
                name="country"
                items={countries}
                error={errors.country ? formatErrorMessage(intl, errorMessages, errors.country) : ''}
                labelGetter={(e) => e.name}
                onSelect={(c) => {
                  setFieldTouched('region', false);
                  setFieldValue('region', '');
                  if (typeof c !== 'string') {
                    c.regions.length > 0
                      ? setSelectedCountry({ ...c, regions: [noRegion, ...c.regions] })
                      : setSelectedCountry(c);
                    setFieldValue('country', c);
                    return;
                  }

                  const matchedCountries =
                    (c &&
                      countries.filter((country) => {
                        return country.name.toLowerCase() === c.toLowerCase().trim();
                      })) ||
                    [];
                  if (matchedCountries.length > 0) {
                    const cntry = matchedCountries.pop();
                    cntry.regions.length > 0
                      ? setSelectedCountry({ ...cntry, regions: [noRegion, ...cntry.regions] })
                      : setSelectedCountry(country);
                    setFieldValue('country', cntry);
                  } else {
                    setSelectedCountry(null);
                    setFieldValue('country', null);
                  }
                }}
                itemFilter={(country, input) => {
                  return !input || country.name.toLowerCase().startsWith(input.toLowerCase().trim());
                }}
              />
              {selectedCountry && selectedCountry.regions.length > 0 ? (
                <InputDropdown
                  placeholder={intl.formatMessage({ id: 'label.region', defaultMessage: 'Region' })}
                  selectedItem={
                    (values.region &&
                      selectedCountry.regions.filter((region) => {
                        return region.name.toLowerCase() === values.region.toLowerCase().trim();
                      })[0]) ||
                    values.region
                  }
                  id={Date.now().toString(36) + Math.random().toString(36).substr(2)}
                  name="region"
                  items={selectedCountry.regions}
                  error={errors.region && touched.region ? formatErrorMessage(intl, errorMessages, errors.region) : ''}
                  labelGetter={(e) => e.name}
                  onSelect={(c) => {
                    if (typeof c !== 'string') {
                      setFieldValue('region', c.name);
                      return;
                    }
                    const matchedRegion =
                      (c &&
                        selectedCountry.regions.filter((region) => {
                          return region.name.toLowerCase() === c.toLowerCase().trim();
                        })) ||
                      [];
                    matchedRegion.length > 0
                      ? setFieldValue('region', matchedRegion.pop().name)
                      : setFieldValue('region', '');
                  }}
                  itemFilter={(region, input) => {
                    return !input || region.name.toLowerCase().startsWith(input.toLowerCase().trim());
                  }}
                />
              ) : (
                <Input
                  placeholder={intl.formatMessage({ id: 'label.region', defaultMessage: 'Region' })}
                  value={values.region || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  name="region"
                  error={errors.region && touched.region ? formatErrorMessage(intl, errorMessages, errors.region) : ''}
                />
              )}
            </div>
            <div className="address-row">
              <Input
                placeholder={intl.formatMessage({ id: 'label.city', defaultMessage: 'City' })}
                value={values.city || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                type="text"
                name="city"
                error={errors.city ? formatErrorMessage(intl, errorMessages, errors.city) : ''}
              />
              <Input
                placeholder={intl.formatMessage({ id: 'label.postalCode', defaultMessage: 'Postal Code' })}
                value={values.postalCode || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                type="text"
                name="postalCode"
                error={errors.postalCode ? formatErrorMessage(intl, errorMessages, errors.postalCode) : ''}
              />
            </div>
            <div className="address-row">
              <Input
                placeholder={intl.formatMessage({ id: 'label.addressLine1', defaultMessage: 'Address Line One' })}
                value={values.lineOne || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                type="text"
                name="lineOne"
                error={errors.lineOne ? formatErrorMessage(intl, errorMessages, errors.lineOne) : ''}
              />
              <Input
                placeholder={intl.formatMessage({ id: 'label.addressLine2', defaultMessage: 'Address Line Two' })}
                value={values.lineTwo || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                type="text"
                name="lineTwo"
                error={errors.lineTwo ? formatErrorMessage(intl, errorMessages, errors.lineTwo) : ''}
              />
            </div>
            {requestError && <p className="text-danger">{formatErrorMessage(intl, errorMessages, requestError)}</p>}
            <div className = "postalCode-error" >
            { addressValidationError && 
            <FormattedMessage id={`shop.page.${addressValidationError}`} defaultMessage="Address is Invalid" /> }
            </div>

            <div className="button-wrapper">
              <Button className="button--secondary" type="submit" disabled={isSubmitting}>
                <FormattedMessage id="button.save" defaultMessage="Save" />
              </Button>
              <Button className="button--default" type="button" onClick={onCancel} disabled={isSubmitting}>
                <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
              </Button>
            </div>
          </form>
        </div>
      )}
    </Formik>
  );
};

EditAddressInfo.propTypes = {
  addressId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  address: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  requestError: PropTypes.string,
  intl: PropTypes.object,
  countries: PropTypes.arrayOf(PropTypes.object),
  addressValidationError: PropTypes.object,
};

export default injectIntl(EditAddressInfo);
