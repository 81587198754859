import { GET_BRAND_DETAILS_REQUEST, GET_BRAND_DETAILS_RESPONSE } from './actions';

const initialState = {
  brandDetails: null,
  brandAddress: null,
  brandDetailsLoaded: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_BRAND_DETAILS_REQUEST:
      return { ...initialState };
    case GET_BRAND_DETAILS_RESPONSE:
      return {
        ...state,
        brandDetails: action.brandDetails,
        brandAddress: action.brandAddress,
        brandDetailsLoaded: true,
      };
    default:
      return state;
  }
};
