import React from 'react';
import './hero.scss';
import SystemSelector from '../SystemSelector';
import { FormattedMessage } from 'react-intl';

const Hero = () => {
  return (
    <section className="hero">
      <div className="hero__tint">
        <div className="hero__logo-wrapper">
          <span className="hero__logo" />
        </div>
        <div className="hero__systemSelector">
          <div className="hero__systemSelector-wrapper">
            <div className="hero__small-heading-wrapper">
              <h1 className="hero__small-heading">
                <FormattedMessage id="home.hero-heading" defaultMessage="Select Your Installation" />
              </h1>
            </div>
            <SystemSelector />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
