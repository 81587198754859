import React from 'react';
import PropTypes from 'prop-types';
import { ModalBody, ModalFooter } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import Modal from '../../../../../layout/Modal';
import { Button } from 'jpi-cloud-web-ui-components';

const DisconnectSystemConfirmation = ({ showModal, toggleModal, disconnectSystem }) => (
  <Modal show={showModal} backdrop={true} onHide={toggleModal}>
    <ModalBody>
      <p>
        <FormattedMessage
          id={'devices.aboutToDisconnectSystem'}
          defaultMessage="You are about to disconnect a system from your account. Please click Disconnect button to confirm this action. This action cannot be undone."
        />
      </p>
    </ModalBody>
    <ModalFooter>
      <Button className="button--danger" onClick={disconnectSystem} type="button">
        <FormattedMessage id="devices.Disconnect" defaultMessage="Disconnect" />
      </Button>
      <Button className="button--default" onClick={toggleModal} type="button">
        <FormattedMessage id="devices.Cancel" defaultMessage="Cancel" />
      </Button>
    </ModalFooter>
  </Modal>
);

DisconnectSystemConfirmation.propTypes = {
  showModal: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  disconnectSystem: PropTypes.func.isRequired,
};

export default DisconnectSystemConfirmation;
