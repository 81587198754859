import * as api from '../../api';

export const GET_USER_SUBSCRIPTIONS_REQUEST = 'features/GET_USER_SUBSCRIPTIONS_REQUEST';
export const GET_USER_SUBSCRIPTIONS_RESPONSE = 'features/GET_USER_SUBSCRIPTIONS_RESPONSE';
export const GET_USER_SUBSCRIPTIONS_FAILED = 'features/GET_USER_SUBSCRIPTIONS_FAILED';
export const getUserSubscriptions = (deviceId) => async (dispatch) => {
  dispatch({ type: GET_USER_SUBSCRIPTIONS_REQUEST });

  try {
    const res = await api.getUserSubscriptions(deviceId);
    if (res.status === 200) {
      dispatch({ type: GET_USER_SUBSCRIPTIONS_RESPONSE, subscriptions: res.data });
      return res.data;
    } else {
      dispatch({ type: GET_USER_SUBSCRIPTIONS_FAILED });
    }
  } catch (e) {
    dispatch({ type: GET_USER_SUBSCRIPTIONS_FAILED });
  }
};

export const GET_DEVICE_AVAILABLE_FEATURES_REQUEST = 'features/GET_DEVICE_AVAILABLE_FEATURES_REQUEST';
export const GET_DEVICE_AVAILABLE_FEATURES_RESPONSE = 'features/GET_DEVICE_AVAILABLE_FEATURES_RESPONSE';
export const GET_DEVICE_AVAILABLE_FEATURES_FAILED = 'features/GET_DEVICE_AVAILABLE_FEATURES_FAILED';
export const getDeviceFeatures = (deviceId) => async (dispatch) => {
  dispatch({ type: GET_DEVICE_AVAILABLE_FEATURES_REQUEST });

  try {
    const res = await api.getDeviceAvailableFeatures(deviceId);
    if (res.status === 200) {
      dispatch({ type: GET_DEVICE_AVAILABLE_FEATURES_RESPONSE, deviceFeatures: res.data });
    } else {
      dispatch({ type: GET_DEVICE_AVAILABLE_FEATURES_FAILED });
    }
  } catch (e) {
    dispatch({ type: GET_DEVICE_AVAILABLE_FEATURES_FAILED });
  }
};

export const getFeatures = (deviceId) => async (dispatch) => {
  const getUserSubscriptionsPromise = getUserSubscriptions(deviceId)(dispatch);
  const getDeviceFeaturesPromise = getDeviceFeatures(deviceId)(dispatch);

  await getUserSubscriptionsPromise;
  await getDeviceFeaturesPromise;
};
