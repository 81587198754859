import * as api from '../../../api';

export const GET_FAQ_REQUEST = 'faq/GET_FAQ_REQUEST';
export const GET_FAQ_RESPONSE_SUCCEEDED = 'faq/GET_FAQ_RESPONSE_SUCCEEDED';

export const getFaqItems =
  (brandId, lastUsedLanguage = 'en-US') =>
  async (dispatch) => {
    dispatch({ type: GET_FAQ_REQUEST });
    const response = await api.getFaqItems(brandId, lastUsedLanguage);
    if (response.status === 200) {
      dispatch({ type: GET_FAQ_RESPONSE_SUCCEEDED, faqItems: response.data });
    }
  };
