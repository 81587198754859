import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import DeleteAccountForm from './DeleteAccountForm';
import RemoveConfirmation from '../RemoveConfirmation';

class DeleteAccountTab extends React.Component {
  state = {
    showRemovePopup: false,
    isSubmiting: false,
    password: '',
  };

  clearUserSystemTiles = async (systems) => {
    if (systems) {
      await this.props.removeSystemTiles(systems.id, this.props.userId);
      if (systems.length) {
        systems.slice(1).forEach(async (system) => {
          await this.props.removeSystemTiles(system.id, this.props.userId);
        });
      }
    }
  };

  clearPrepopulatedSystems = async (systems) => {
    if (systems) {
      await this.props.unsetSystemAsPrepopulated(systems.id);
      if (systems.length) {
        systems.slice(1).forEach(async (system) => {
          await this.props.unsetSystemAsPrepopulated(system.id);
        });
      }
    }
  };

  onRemoveConfirm = async () => {
    this.props.resetDeleteError();
    this.setState({ ...this.state, isSubmiting: true });
    const systems = await this.props.getUserSystems();

    if (await this.props.deleteUser(this.props.userId, this.state.password)) {
      await this.clearPrepopulatedSystems(systems);
      await this.clearUserSystemTiles(systems);
    }
    this.setState({ ...this.state, isSubmiting: false, showRemovePopup: false });
  };

  onRemoveAbort = () => {
    this.setState({ ...this.state, showRemovePopup: false });
  };

  onRemoveButtonClick = async (values, { setSubmitting }) => {
    setSubmitting(true);

    const { userEmail, validateUserCredentials } = this.props;
    const password = values.password.trim();
    if (await validateUserCredentials(userEmail, password)) {
      this.setState({ ...this.state, showRemovePopup: true });
      this.setState({ ...this.state, password });
    }

    setSubmitting(false);
  };

  render() {
    const { deleteError, validatePasswordError } = this.props;
    return (
      <div>
        <div className="remove-user-account">
          <FormattedMessage id="label.system-profile.delete-account" defaultMessage="Remove Your Account" />
        </div>
        <DeleteAccountForm onSubmit={this.onRemoveButtonClick} requestError={validatePasswordError || deleteError} />
        {this.state.showRemovePopup && (
          <RemoveConfirmation
            isShown={this.state.showRemovePopup}
            onConfirm={this.onRemoveConfirm}
            onDiscard={this.onRemoveAbort}
            requestError={deleteError}
            isSubmiting={this.state.isSubmiting}
          />
        )}
      </div>
    );
  }
}

DeleteAccountTab.propTypes = {
  deleteUser: PropTypes.func.isRequired,
  getUserSystems: PropTypes.func.isRequired,
  removeSystemTiles: PropTypes.func.isRequired,
  unsetSystemAsPrepopulated: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
  resetDeleteError: PropTypes.func.isRequired,
  history: PropTypes.object,
  deleteError: PropTypes.any,
  validateUserCredentials: PropTypes.func.isRequired,
  validatePasswordError: PropTypes.any,
};

export default DeleteAccountTab;
