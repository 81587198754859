import {
  TILE_CREATION_START,
  TILE_EDITING_START,
  TILE_CONFIGURATION_SELECT_TYPE,
  TILE_CONFIGURATION_SELECT_TYPE_SILENT,
  TILE_CONFIGURATION_SET_TITLE,
  TILE_CONFIGURATION_SET_DISPLAY_PARAMETER,
  TILE_CONFIGURATION_SET_DISPLAY_PARAMETER_SILENT,
  TILE_CONFIGURATION_REMOVE_DISPLAY_PARAMETER,
  TILE_CONFIGURATION_SET_SETTINGS_PARAMETERS,
  TILE_CONFIGURATION_SET_SETTINGS_PARAMETERS_SILENT,
  TILE_CONFIGURATION_SET_CHART,
  TILE_CONFIGURATION_SET_CHART_SILENT,
  TILE_CONFIGURATION_REMOVE_CHART,
  TILE_CONFIGURATION_END,
  GET_TILE_TYPES_SUCCEEDED,
  GET_TILE_TYPES_SUCCEEDED_SILENT,
  GET_TILE_TYPES_FAILED,
  GET_TILE_PARAMETERS_RESPONSE,
} from './actions';

import { SELECT_SYSTEM } from '../../AppInitializer/actions';

const NOT_STARTED = 'NOT_STARTED';
const TILE_TYPE_SELECTION = 'TILE_TYPE_SELECTION';
const EDITING_PROPERTIES = 'EDITING_PROPERTIES';

export const EditStates = {
  NOT_STARTED,
  TILE_TYPE_SELECTION,
  EDITING_PROPERTIES,
};

const initialState = {
  loading: false,
  currentStateName: EditStates.NOT_STARTED,
  isNewTileCreation: true,
  tileTypes: null,
  tileParameters: [],
};

export const isPopupShown = (state) => state.currentStateName !== NOT_STARTED;
export const isSelectTileTypeEditorShown = (state) => state.currentStateName === TILE_TYPE_SELECTION;
export const isTilePropertyEditorShown = (state) => state.currentStateName === EDITING_PROPERTIES;

export const getProperty = (state) => (name) => state.editState[name];

export const getTitle = (state) => () => getProperty(state)('title');
export const getDisplayParameter = (state) => (index) => state.editState.properties.displayParameters[index];
export const getSettingParameter = (state) => (index) => state.editState.properties.settingParameters[index];
export const getChartValue = (state) => state.editState.properties.chart;
export const getCurrentTileType = (state) => getProperty(state)('type');
export const getCurrentTileNameTextId = (state) => getProperty(state)('tileNameTextId');

export const getEmptyTile = (type, deviceId, tileTypes, tileNameText, tileNameTextId) => ({
  type,
  id: null,
  deviceId,
  title:
    tileTypes && tileTypes.some((tt) => tt.id === type && tt.tileNameTextId === tileNameTextId)
      ? tileTypes.find((tt) => tt.id === type && tt.tileNameTextId === tileNameTextId).name
      : '',
  color: '78b637',
  properties: {
    displayParameters: [],
    settingParameters: [],
    chart: null,
  },
  tileNameText: tileNameText,
  tileNameTextId: tileNameTextId,
});

const setChartValue = ({ editState }, { chart }) => ({ ...editState, properties: { ...editState.properties, chart } });

const removeChartValue = ({ editState }) => ({ ...editState, properties: { ...editState.properties, chart: null } });

const setDisplayParameter = ({ editState }, { index, value }) => ({
  ...editState,
  properties: {
    ...editState.properties,
    displayParameters: Object.assign([...editState.properties.displayParameters], { [index]: value }),
  },
});

const removeDisplayParameter = ({ editState }, { index }) => ({
  ...editState,
  properties: {
    ...editState.properties,
    displayParameters: [...editState.properties.displayParameters.map((e, i) => (i === index ? null : e))],
  },
});

const setSettingsParameters = ({ editState }, { values }) => ({
  ...editState,
  properties: {
    ...editState.properties,
    settingParameters: values,
  },
});

export default (state = initialState, action) => {
  switch (action.type) {
    case TILE_CREATION_START:
      return {
        ...state,
        currentStateName: TILE_TYPE_SELECTION,
        isNewTileCreation: true,
        tileTypes: state.tileTypes,
        loading: true,
      };
    case GET_TILE_TYPES_FAILED:
    case GET_TILE_TYPES_SUCCEEDED:
      return {
        ...state,
        currentStateName: TILE_TYPE_SELECTION,
        isNewTileCreation: true,
        tileTypes: action.tileTypes,
        loading: false,
      };
    case GET_TILE_TYPES_SUCCEEDED_SILENT:
      return { ...state, tileTypes: action.tileTypes, loading: false };
    case TILE_EDITING_START:
      return {
        currentStateName: EDITING_PROPERTIES,
        tileTypes: state.tileTypes,
        isNewTileCreation: false,
        editState: action.tile,
        loading: true,
      };
    case TILE_CONFIGURATION_SELECT_TYPE:
      return {
        ...state,
        currentStateName: EDITING_PROPERTIES,
        editState: getEmptyTile(
          action.tileType,
          action.deviceId,
          state.tileTypes,
          action.tileNameText,
          action.tileNameTextId
        ),
        loading: true,
      };
    case TILE_CONFIGURATION_SELECT_TYPE_SILENT:
      return {
        ...state,
        editState: getEmptyTile(
          action.tileType,
          action.deviceId,
          state.tileTypes,
          action.tileNameText,
          action.tileNameTextId
        ),
        loading: true,
      };
    case TILE_CONFIGURATION_SET_TITLE:
      return {
        ...state,
        currentStateName: EDITING_PROPERTIES,
        editState: { ...state.editState, title: action.title, isTitleEdited: action.isTitleEdited },
      };
    case TILE_CONFIGURATION_SET_CHART:
      return { ...state, currentStateName: EDITING_PROPERTIES, editState: setChartValue(state, action) };
    case TILE_CONFIGURATION_SET_CHART_SILENT:
      return { ...state, editState: setChartValue(state, action) };
    case TILE_CONFIGURATION_REMOVE_CHART:
      return { ...state, currentStateName: EDITING_PROPERTIES, editState: removeChartValue(state, action) };
    case TILE_CONFIGURATION_SET_DISPLAY_PARAMETER:
      return { ...state, currentStateName: EDITING_PROPERTIES, editState: setDisplayParameter(state, action) };
    case TILE_CONFIGURATION_SET_DISPLAY_PARAMETER_SILENT:
      return { ...state, editState: setDisplayParameter(state, action) };
    case TILE_CONFIGURATION_REMOVE_DISPLAY_PARAMETER:
      return { ...state, currentStateName: EDITING_PROPERTIES, editState: removeDisplayParameter(state, action) };
    case TILE_CONFIGURATION_SET_SETTINGS_PARAMETERS:
      return { ...state, currentStateName: EDITING_PROPERTIES, editState: setSettingsParameters(state, action) };
    case TILE_CONFIGURATION_SET_SETTINGS_PARAMETERS_SILENT:
      return { ...state, editState: setSettingsParameters(state, action) };
    case TILE_CONFIGURATION_END:
      return { currentStateName: EditStates.NOT_STARTED, isNewTileCreation: true, tileTypes: null, loading: false };
    case GET_TILE_PARAMETERS_RESPONSE:
      return { ...state, tileParameters: action.tileParameters, loading: false };
    case SELECT_SYSTEM:
      return { ...state, tileTypes: null };
    default:
      return state;
  }
};
