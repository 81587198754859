import {
  DISCONNECT_USER_REQUEST,
  DISCONNECT_USER_RESPONSE_SUCCEEDED,
  DISCONNECT_USER_RESPONSE_FAILED,
} from './actions';

const initialState = {
  userIsDisconnecting: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DISCONNECT_USER_REQUEST:
      return { ...state, userIsDisconnecting: true };
    case DISCONNECT_USER_RESPONSE_SUCCEEDED:
    case DISCONNECT_USER_RESPONSE_FAILED:
      return { ...state, userIsDisconnecting: false };
    default:
      return state;
  }
};
