// @ts-check
import { isVersionUpdateAvailable } from '../components/AppInitializer/utils';

export function a11yKeyPress(handler) {
  return function onKeyPress(event) {
    var code = event.code;
    if (code === 'Enter' || code === 'Space') {
      return handler(event);
    }
  };
}

/**
 * @param {import ('../types').Device[]} devices
 * @returns {string[]} - an array of DeviceIDs
 */
export function getUpgradeableDeviceIds(devices) {
  return devices
    .filter(({ currentFwVersion, desiredFwVersion, slaves }) => {
      const isAvailableUpdateSAK = isVersionUpdateAvailable(desiredFwVersion, currentFwVersion);

      if (isAvailableUpdateSAK) {
        return true;
      }

      const isAvailableUpdateSlaves =
        slaves?.length > 0 &&
        slaves.some(({ firmware }) => isVersionUpdateAvailable(firmware?.desiredFwVersion, firmware?.currentFwVersion));

      return isAvailableUpdateSlaves;
    })
    .map(({ id }) => id);
}

/**
 * Sorts an array of objects based on a specific key.
 *
 * @param {Object[]} array - The array to be sorted.
 * @param {string} key - The key on which to sort the array.
 * @param {string} order - The order of the sorting - asc, desc.
 * @returns {Object[]} - The sorted array.
 */
export const sortArrayByLocaleCompare = (array, key, order) => {
  const sortedArray = array.slice().sort((a, b) => {
    return a[key].localeCompare(b[key]);
  });
  order === 'desc' && sortedArray.reverse();
  return sortedArray;
};
