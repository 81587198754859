import moment from 'moment';
import { API, reissueAuthToken } from '../api';
import { reissueAzureB2CTokens, getAzureB2CLoginLink } from '../api/azureB2C';
import { getAuthTokens, putAuthTokens, isAzureB2CAuth, deleteAuthTokens } from '../localStorage/authentication';
import { logoutUser } from '../components/pages/Login/actions';

// Mechanism to prevent getting multiple tokens from requests when Access Token expires
let isReEnter = false;

const reissueAzureB2CRefreshToken = async (selectedLanguage) => {
  deleteAuthTokens();
  window.location.href = await getAzureB2CLoginLink({
    redirectTo: window.location.pathname,
    language: selectedLanguage,
  });
};

// Interceptor
export const registerAuthHeaderInterceptor = (store) => {
  API.interceptors.request.use(async (config) => {
    let tokens = getAuthTokens();

    const { selectedLanguage } = store.getState().language;
    const langId = selectedLanguage || 'en-US';
    config.headers['Accept-Language'] = langId;

    if (tokens) {
      const { issuedAt, expires_in, refresh_token } = tokens;
      if (
        !config.url.includes('/oauth') &&
        moment(issuedAt).add(expires_in, 'seconds').isBefore(moment()) &&
        !isReEnter
      ) {
        try {
          isReEnter = true;
          const authResponse = isAzureB2CAuth()
            ? await reissueAzureB2CTokens(refresh_token)
            : await reissueAuthToken(refresh_token);
          tokens = authResponse.data;
          putAuthTokens(tokens);
        } catch (e) {
          if (isAzureB2CAuth()) {
            reissueAzureB2CRefreshToken(selectedLanguage);
          } else {
            store.dispatch(logoutUser());
          }
        } finally {
          isReEnter = false;
        }
      }

      config.headers.Authorization = `Bearer ${tokens.access_token}`;
    }

    return config;
  });

  API.interceptors.response.use(null, async (error) => {
    if (error.response.status === 401) {
      try {
        const { refresh_token } = getAuthTokens();
        const authResponse = isAzureB2CAuth()
          ? await reissueAzureB2CTokens(refresh_token)
          : await reissueAuthToken(refresh_token);
        putAuthTokens(authResponse.data);
      } catch (e) {
        if (isAzureB2CAuth()) {
          const { selectedLanguage } = store.getState().language;
          reissueAzureB2CRefreshToken({ language: selectedLanguage });
        } else {
          store.dispatch(logoutUser());
        }
        return Promise.reject(error);
      }

      return API(error.config);
    }
    if (!error.response.data) error.response.data = {};
    return Promise.reject(error);
  });
};
