import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { NotificationManager } from 'react-notifications';

import { Spinner } from 'jpi-cloud-web-ui-components';

import DisconnectSystem from './components/DisconnectSystem';
import DisconnectUser from './components/DisconnectUser';

import { getFeatures } from '../../../../FeaturesFlags/actions';
import { disconnectSystem, removeSystem, setDeviceUpdateAvailable } from '../../../../AppInitializer/actions';
import { removeSystemTiles, unsetSystemAsPrepopulated } from '../../../../tiles/TileManager/actions';
import { setLastUsedSystem } from '../../../../layout/SystemSelector/actions';
import { getDevices, getSubscriptionsInGroup } from '../../../Devices/actions';
import { disconnectUser } from './actions';

import './delete.scss';

const notificationTimeoutMilliseconds = 3000;

class DeleteTab extends React.Component {
  state = {
    isLoaded: false,
    showModalSystem: false,
    showModalUser: false,
  };

  componentDidMount() {
    this.setState({ ...this.state, isLoaded: true });
  }

  async redirectToHomePage(system) {
    if (system) {
      this.props.setLastUsedSystem(system.id, system.brandId);
      this.props.getSubscriptionsInGroup(system.id);
      const devices = await this.props.getDevices(system.id);
      if (devices && devices.length > 0) {
        await this.props.getFeatures(devices[0].id);
      }
      this.props.history.push('/');
    }
    this.props.history.push('/');
  }

  confirmSystemDisconnect = (system) => {
    this.toggleModalSystem(system);
  };

  toggleModalSystem = () => {
    const isDemo = this.props.userInfo && this.props.userInfo.isDemo;

    if (isDemo) {
      return;
    }

    this.setState({ ...this.state, showModalSystem: !this.state.showModalSystem });
  };

  getRedirectSystem = () => {
    const { selectedSystem, systems } = this.props;
    // if user account has more than one system and selectedSystem == systems[0]
    // then redirect to systems[1] else redirect to systems[0]
    if (systems.length > 1) {
      if (systems[0].id === selectedSystem.id) {
        return systems[1];
      }
      return systems[0];
    }
    return null;
  };

  disconnectSystem = async () => {
    const {
      selectedSystem,
      userInfo,
      disconnectSystem,
      removeSystemTiles,
      unsetSystemAsPrepopulated,
      setDeviceUpdateAvailable,
    } = this.props;
    const system = this.getRedirectSystem();
    const systemIsDisconnected = await disconnectSystem(selectedSystem, userInfo.id);
    this.toggleModalSystem();
    if (systemIsDisconnected) {
      await removeSystemTiles(selectedSystem.id, userInfo.id);
      await unsetSystemAsPrepopulated(userInfo.id, selectedSystem.id);
      setDeviceUpdateAvailable(false);
      this.redirectToHomePage(system);
    } else {
      NotificationManager.error(
        <FormattedMessage
          id="security.DisconnectSystemError"
          defaultMessage="Could not disconnect system. Please try again later."
        />,
        <FormattedMessage id="security.Error" defaultMessage="Error" />,
        notificationTimeoutMilliseconds
      );
    }
  };

  confirmUserDisconnect = (system) => {
    this.toggleModalUser(system);
  };

  toggleModalUser = () => {
    const isDemo = this.props.userInfo && this.props.userInfo.isDemo;

    if (isDemo) {
      return;
    }
    this.setState({ ...this.state, showModalUser: !this.state.showModalUser });
  };

  disconnectUser = async () => {
    const { selectedSystem, userInfo, disconnectUser, removeSystem, setDeviceUpdateAvailable } = this.props;
    const system = this.getRedirectSystem();
    const userIsDisconnected = await disconnectUser(selectedSystem, userInfo);
    this.toggleModalUser();
    if (userIsDisconnected) {
      removeSystem(selectedSystem);
      await removeSystemTiles(selectedSystem.id, userInfo.id);
      setDeviceUpdateAvailable(false);
      this.redirectToHomePage(system);
    } else {
      NotificationManager.error(
        <FormattedMessage id="security.DeleteUserError" defaultMessage="User could not be removed" />,
        <FormattedMessage id="security.Error" defaultMessage="Error" />,
        notificationTimeoutMilliseconds
      );
    }
  };

  render() {
    const { selectedSystem, userIsAdmin, userInfo } = this.props;
    const isDemo = userInfo && userInfo.isDemo;

    if (!this.state.isLoaded) {
      return <Spinner dark />;
    }

    if (!selectedSystem.id) {
      return null;
    }

    return (
      <div>
        {userIsAdmin ? (
          <DisconnectSystem
            system={selectedSystem}
            showModal={this.state.showModalSystem}
            toggleModal={this.toggleModalSystem}
            disconnectSystem={this.disconnectSystem}
            isDemo={isDemo}
          />
        ) : (
          <DisconnectUser
            system={selectedSystem}
            showModal={this.state.showModalUser}
            toggleModal={this.toggleModalUser}
            disconnectUser={this.disconnectUser}
            isDemo={isDemo}
          />
        )}
      </div>
    );
  }
}

DeleteTab.propTypes = {
  selectedSystem: PropTypes.object.isRequired,
  userInfo: PropTypes.object,
  disconnectSystem: PropTypes.func.isRequired,
  removeSystem: PropTypes.func.isRequired,
  disconnectUser: PropTypes.func.isRequired,
  userIsAdmin: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  systems: PropTypes.array,
  getFeatures: PropTypes.func.isRequired,
  setLastUsedSystem: PropTypes.func.isRequired,
  getDevices: PropTypes.func.isRequired,
  getSubscriptionsInGroup: PropTypes.func.isRequired,
  setDeviceUpdateAvailable: PropTypes.func.isRequired,
};

export default connect(
  ({ app: { userInfo, systems }, systemProfileDeleteTab }) => ({
    userInfo,
    systems,
    ...systemProfileDeleteTab,
  }),
  {
    disconnectUser,
    disconnectSystem,
    removeSystem,
    removeSystemTiles,
    unsetSystemAsPrepopulated,
    getFeatures,
    setLastUsedSystem,
    getDevices,
    getSubscriptionsInGroup,
    setDeviceUpdateAvailable,
  }
)(DeleteTab);
