import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import TileSettings from '../../tiles/TileSettings';
import TileChart from '../../tiles/TileChart';
import { TileTypes } from '../TileManager/TileTypes';
import Indicator from '../../inputs/Indicator';
import { defaultValueLowerLimit } from '../../constants/constants';
import DailyWeatherForecast from '../WeatherForecast/DailyWeatherForecast';
import WeeklyWeatherForecast from '../WeatherForecast/WeeklyWeatherForecast';
import Divider from '../Divider';

import PremiumFeatureTooltip from '../../layout/PremiumFeatureTooltip';

import TileIcons, { getTileIcon, getWeatherIcon, getParameterIcon } from './tile-icons';
import './generic-tile.scss';

const DisplayParameter = ({ parameter: tile = {} }) => {
  const {
    name,
    /**
     * @name kind
     * @type {("Str" | "Number")}
     */
    parameter: { enumValues = [], unit: paramUnit, scaleVal, kind } = {},
    /**
     * @name value
     * @type {(string | number)}
     */
    value: tileValue,
    unit: tileUnit,
  } = tile || {};

  if (enumValues.length > 0) {
    const value = enumValues.find((ev) => parseInt(ev.value) === tileValue);
    if (value?.text) return <Indicator value={value.text} title={name} unit="" />;
  }

  let value = tileValue;

  if (kind !== 'Str') {
    value = tileValue > defaultValueLowerLimit ? tileValue * (Number(scaleVal) || 1) : '--';
  }

  return <Indicator value={value} title={name} unit={tileUnit || paramUnit} />;
};

DisplayParameter.propTypes = {
  parameter: PropTypes.object.isRequired,
};

const GenericTile = ({ expanded, tileData, onChangeSetting, weatherData }) => {
  const parameterIcon = [...tileData.properties.displayParameters, ...tileData.properties.settingParameters].find(
    (p) => p && p.parameter && p.parameter.tileIcon
  );

  const iconId = parameterIcon ? getParameterIcon(parameterIcon) : tileData.type;

  const paramsWithRightLeft = tileData.properties.displayParameters.map((p, index) => ({
    ...p,
    placement: index === 0 ? 'left' : 'right',
  }));

  const isOnlyRightParameter = paramsWithRightLeft.length === 1 && paramsWithRightLeft[0].placement === 'right';
  const isWeatherOutdoorTile = tileData.type === TileTypes.WEATHER_OUTDOOR_TEMPERATURE;

  return (
    <div className={'generic-tile' + (expanded ? ' generic-tile--expanded tile--expanded' : '')}>
      <div className="generic-tile__tile-heading">
        <h4 title={tileData.title}>{tileData.title}</h4>
        {tileData.tileHelpText && (
          <div className="generic-tile__tile-help-icon">
            <i className="fas fa-info-circle">
              <PremiumFeatureTooltip
                translationId={tileData.tileHelpText ? '' : 'tile.no-help-text'}
                defaultMessage={tileData.tileHelpText}
              />
            </i>
          </div>
        )}
      </div>

      <div className="generic-tile__tile-data">
        <div className={'generic-tile__display-parameter-wrapper' + (isOnlyRightParameter ? ' right' : '')}>
          {paramsWithRightLeft.map((parameter, i) => (
            <DisplayParameter parameter={parameter} key={i} />
          ))}
        </div>

        <div className="generic-tile__icon-wrapper tile-icon-wrapper">
          {tileData.type !== TileTypes.WEATHER_OUTDOOR_TEMPERATURE && (
            <img src={getTileIcon(TileIcons, tileData.brandId, iconId, tileData.firmwareIds)} alt={`${iconId} icon`} />
          )}
          {tileData.type === TileTypes.WEATHER_OUTDOOR_TEMPERATURE && weatherData?.current && (
            <img
              className="weather-icon"
              src={getWeatherIcon(TileIcons, tileData.brandId, weatherData.current.icon)}
              alt="weather icon"
            />
          )}
        </div>
      </div>
      {expanded && (
        <div
          className={`generic-tile__expanded-section tile__expanded-section ${
            isWeatherOutdoorTile ? 'generic-tile__weather-outdoor' : ''
          }`}
        >
          <TileSettings
            brandId={tileData.brandId}
            settingParameters={tileData.properties.settingParameters}
            onChangeSetting={onChangeSetting}
          />
          {isWeatherOutdoorTile && (
            <>
              <Divider />

              {weatherData && <DailyWeatherForecast weatherData={weatherData} brandId={tileData.brandId} />}

              <Divider />

              {weatherData && <WeeklyWeatherForecast weatherData={weatherData} brandId={tileData.brandId} />}

              {!weatherData && (
                <div className="setting-parameter-wrapper">
                  <FormattedMessage
                    id={'tile.weather.weatherDataNotFound'}
                    defaultMessage="Weather data is not currently available. Please try again later."
                  />
                </div>
              )}

              <Divider />
            </>
          )}
          {/* FIXME: Styles are linked to tileType="generic-tile", well done for logic :D */}
          <TileChart variant={tileData.type} tileType="generic-tile" tileData={tileData.properties.chart} />
        </div>
      )}
    </div>
  );
};

GenericTile.defaultProps = {
  tileData: {},
  expanded: false,
  weatherData: {},
};

GenericTile.propTypes = {
  tileData: PropTypes.object,
  tilesData: PropTypes.object,
  expanded: PropTypes.bool,
  onChangeSetting: PropTypes.func.isRequired,
  weatherData: PropTypes.object,
  firmwareIds: PropTypes.arrayOf(PropTypes.string),
};

export default connect(({ tilesData }) => ({
  tilesData,
}))(GenericTile);
