import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import AccessoryList from './components/AccessoryList';
import { isVersionUpdateAvailable } from '../../../../AppInitializer/utils';
import tickCircle from '../../../../../assets/img/tick-circle.svg';
import downloadCircle from '../../../../../assets/img/download-circle.svg';
import './devicelist.scss';

const Device = ({ device, onSelect }) => {
  const isOnline = device.connectionState === 'Connected';

  // desiredFwVersion is under firmware field for slaves and on root level for gateway devices
  const hasDesiredFWVersion = !!(device.desiredFwVersion ?? device.firmware?.desiredFwVersion);

  const isSmoDeviceHasUpdate =
    device.firmware && isVersionUpdateAvailable(device.firmware.desiredFwVersion, device.firmware.currentFwVersion);

  const smoStatusRender = isSmoDeviceHasUpdate ? (
    <div className="status available" onClick={() => onSelect(device)}>
      <FormattedMessage id={'devices.update-available'} defaultMessage="Update available" />
      &nbsp;
      <img className="icon" src={downloadCircle} />
    </div>
  ) : (
    <div className="status updated">
      <FormattedMessage id={'devices.up-to-date'} defaultMessage="Up to date" />
      &nbsp;
      <img className="icon" src={tickCircle} />
    </div>
  );

  const sakStatusRender = device.outdatedFirmware ? (
    <div className="status available" onClick={() => onSelect(device)}>
      <FormattedMessage id={'devices.update-available'} defaultMessage="Update available" />
      &nbsp;
      <img className="icon" src={downloadCircle} />
    </div>
  ) : (
    <div className="status updated">
      <FormattedMessage id={'devices.up-to-date'} defaultMessage="Up to date" />
      &nbsp;
      <img className="icon" src={tickCircle} />
    </div>
  );

  return (
    <div key={device.id} className="list-item">
      <div className="row detail-item">
        <div className="col-md-8">
          <span className="item-heading">{device.name || device.productName}</span>
        </div>
        {(device.currentFwVersion || (device.firmware && device.firmware.currentFwVersion)) && (
          <div className="col-md-4 version">
            <FormattedMessage id={'devices.version'} defaultMessage="Version" />:{' '}
            {device.currentFwVersion || (device.firmware && device.firmware.currentFwVersion)}
          </div>
        )}
      </div>
      <div className="row detail-item">
        <div className="col-md-8">
          {isOnline ? (
            <FormattedMessage id={'devices.online'} defaultMessage="Online" />
          ) : (
            <FormattedMessage id={'devices.offline'} defaultMessage="Offline" />
          )}
        </div>
        {hasDesiredFWVersion && (
          <div className="col-md-4 update-status">
            {device.desiredFwVersion && device.desiredFwVersion.length ? sakStatusRender : smoStatusRender}
          </div>
        )}
      </div>
      <div className="row detail-item">
        <div className="col-md-12">
          {(device.serialNumber || device.serialId) && (
            <span className="serial-number-container">
              <FormattedMessage id={'devices.SerialNumber'} defaultMessage="Serial Number" /> :{' '}
              {device.serialNumber || device.serialId}
            </span>
          )}
        </div>
      </div>
      {location.pathname === '/devices' && device.accessories && device.accessories.length > 0 && (
        <AccessoryList list={device.accessories} />
      )}
    </div>
  );
};

Device.propTypes = {
  device: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
};

const DevicesList = ({ devices, onSelect }) => {
  return (
    <div className="list">
      {devices.map((d) => {
        return (
          <div key={d.id}>
            <Device device={d} onSelect={onSelect} />
            {d.slaves && d.slaves.length ? <DevicesList devices={d.slaves} onSelect={onSelect} /> : null}
          </div>
        );
      })}
    </div>
  );
};

DevicesList.propTypes = {
  devices: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateFirmware: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
};

export default DevicesList;
