import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { languageSelected, setLastUsedLanguage } from '../../../../LanguageSelector/actions';
import { languageOptions } from '../../../../../../constants/languages';

import { sortArrayByLocaleCompare } from '../../../../../../utils';

import './customlanguageselector.scss';

const CustomLanguageSelector = ({
  onlanguageChange,
  setLastUsedLanguage,
  className,
  languageSelected,
  showShortCodes,
  showIcon = true,
}) => {
  const changeLanguage = (e) => {
    e.preventDefault();
    const { value } = e.target;
    onlanguageChange(value, { id: null });
    setLastUsedLanguage(value);
  };

  const customClasses = className || '';

  const sortedLanguageOptions = sortArrayByLocaleCompare(languageOptions, 'label');

  return (
    <div className={'customLanguageSelector ' + customClasses}>
      <select
        className="languageSelector__selectControl"
        id="language-selector"
        onChange={(e) => changeLanguage(e)}
        value={languageSelected}
        aria-label="Select language"
      >
        {sortedLanguageOptions.map((item) => {
          return (
            <option key={item.value} value={item.value}>
              {showShortCodes ? item.value.toUpperCase() : item.label}
            </option>
          );
        })}
      </select>
      {showIcon && <FontAwesomeIcon icon="globe-americas" size="2x" id="language-globe" />}
    </div>
  );
};

function selectLanguage(language, userInfo) {
  return (dispatch) => {
    dispatch(languageSelected(language, userInfo));
  };
}

CustomLanguageSelector.propTypes = {
  onlanguageChange: PropTypes.func,
  languageSelected: PropTypes.string,
  selectedLanguage: PropTypes.string,
  className: PropTypes.string,
  setLastUsedLanguage: PropTypes.func.isRequired,
  showShortCodes: PropTypes.bool,
  showIcon: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    languageSelected: state.language.selectedLanguage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onlanguageChange: (language, userInfo) => {
      dispatch(selectLanguage(language, userInfo));
    },
    setLastUsedLanguage: (language) => {
      dispatch(setLastUsedLanguage(language));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CustomLanguageSelector);
