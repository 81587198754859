import PropTypes from 'prop-types';
import useMavenoid from '../../assets/js/mavenoid';

const Mavenoid = ({ show, email, language }) => {
  const { mount, unmount } = useMavenoid();
  if (show) {
    unmount();
    mount(email, language);
  } else {
    unmount();
  }

  return null;
};

Mavenoid.propTypes = {
  show: PropTypes.bool.isRequired,
  email: PropTypes.string,
  language: PropTypes.string,
};

export default Mavenoid;
