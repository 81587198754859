import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { Title } from 'jpi-cloud-web-ui-components';
import './termsofservice-page.scss';

const TermsOfServicePage = () => {
  return (
    <div className="page-content">
      <Title titleTranslationId="tos.title" defaultMessage="Terms of Service" />
      <FormattedHTMLMessage id="terms-of-service" />
    </div>
  );
};

export default TermsOfServicePage;
