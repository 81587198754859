import * as api from '../../../api';

export const GET_INVITATION_REQUEST = 'invitation/GET_INVITATION_REQUEST';
export const GET_INVITATION_RESPONSE = 'invitation/GET_INVITATION_RESPONSE';
export const GET_INVITATION_RESPONSE_FAILED = 'invitation/GET_INVITATION_RESPONSE_FAILED';

export const getInvitation = (invitationId, email) => async (dispatch) => {
  dispatch({ type: GET_INVITATION_REQUEST });

  let valid = true;

  // Get Invitation
  try {
    const getInvitationResult = await api.getInvitation(invitationId);

    if (getInvitationResult.status === 200) {
      const invitation = getInvitationResult.data;
      const invitedUserEmail = (invitation.email && invitation.email.toLowerCase()) || '';
      const currentUserEmail = email && email.toLowerCase();

      if (invitedUserEmail !== currentUserEmail) {
        valid = false;
      }

      if (!invitedUserEmail || invitation.status === 'Removed') {
        valid = false;
        dispatch({ type: GET_INVITATION_RESPONSE_FAILED, valid: valid, invitation: invitation });
      } else {
        // Get Systems
        const getUserSystemsResult = await api.getUserSystems();
        if (getUserSystemsResult.status === 200) {
          const systems = getUserSystemsResult.data;

          // Get SP
          const getServicePartnerResult = await api.getServicePartner(invitation.servicePartnerId);
          if (getServicePartnerResult.status === 200) {
            const sp = getServicePartnerResult.data;
            dispatch({ type: GET_INVITATION_RESPONSE, invitation: invitation, systems: systems, sp: sp, valid: valid });
          }
        }
      }
    }
  } catch (err) {
    valid = false;
    dispatch({ type: GET_INVITATION_RESPONSE_FAILED, valid: valid });
  }
};

export const ACCEPT_INVITATION = 'invitation/ACCEPT_INVITATION';
export const DECLINE_INVITATION = 'invitation/DECLINE_INVITATION';

export const acceptInvitation =
  ({ invitationId, groupIds, userId, servicePartnerId }) =>
  async (dispatch) => {
    try {
      const res = await api.acceptInvitation({ invitationId, groupIds, userId, servicePartnerId });
      if (res.status === 200) {
        const getInvitationResult = await api.getInvitation(invitationId);
        dispatch({ type: ACCEPT_INVITATION, invitation: getInvitationResult.data });
        return true;
      }
    } catch (e) {
      return false;
    }
    return false;
  };

export const declineInvitation = (invitationId) => async (dispatch) => {
  try {
    const res = await api.declineInvitation(invitationId);
    if (res.status === 200) {
      const getInvitationResult = await api.getInvitation(invitationId);
      dispatch({ type: DECLINE_INVITATION, invitation: getInvitationResult.data });
      return true;
    }
  } catch (e) {
    return false;
  }
  return false;
};
