import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './content.scss';

const Content = ({ children, className, tagName: Tag = 'div', ...props }) => (
  <Tag className={classNames('content', className)} {...props}>
    {children}
  </Tag>
);

Content.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  className: PropTypes.string,
  tagName: PropTypes.string,
};

export default Content;
