import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import differenceBy from 'lodash/differenceBy';

import { Spinner, Checkbox, Button } from 'jpi-cloud-web-ui-components';
import { ModalBody } from 'react-bootstrap';

import PremiumFeatureTooltip from '../../../layout/PremiumFeatureTooltip';
import Modal from '../../../layout/Modal';
import { chartNumber } from '../chart-utils';

import { events, groups } from '../../../../services/clarity/events';
import { setupTracker } from '../../../../services/clarity';

import './chart-config.scss';

const trackEvent = setupTracker(groups.HISTORY);

const ChartConfiguration = ({
  premiumFeatures,
  parameters,
  charts,
  addChart,
  deleteChart,
  dateRange,
  dateFrom,
  dateTo,
  aggregationMethod,
  userIsViewer,
  showChartConfig,
  showChartConfigModal,
  isFirstChart,
}) => {
  const [selectedParameters, setSelectedParameters] = useState([]);
  const [secondChartSelectedParameters, setSecondChartSelectedParameters] = useState([]);
  const [isExtended, setIsExtended] = useState('');

  const onSelectParameters = async () => {
    const selectedChartParams = isFirstChart ? selectedParameters : secondChartSelectedParameters;
    const newAddedParameters = differenceBy(selectedChartParams, charts, 'parameterId');
    const removedParameters = differenceBy(charts, selectedChartParams, 'parameterId');
    removedParameters &&
      removedParameters.length > 0 &&
      removedParameters.forEach((chart) =>
        deleteChart(chart.deviceId, chart.parameterId, chartNumber[Number(!isFirstChart)])
      );
    newAddedParameters &&
      newAddedParameters.length &&
      newAddedParameters.map((param) => addChart({ ...param, numberOfChart: chartNumber[Number(!isFirstChart)] }));

    trackEvent(events.CHART_CONFIG_PARAM_ADDED);
    showChartConfig(false);
  };

  const checkDisabledParameter = (p) => {
    if (isFirstChart) {
      return (
        selectedParameters &&
        selectedParameters.length === 10 &&
        !selectedParameters.some((param) => param.parameterId === p.parameterId)
      );
    }
    return (
      secondChartSelectedParameters &&
      secondChartSelectedParameters.length === 10 &&
      !secondChartSelectedParameters.some((param) => param.parameterId === p.parameterId)
    );
  };

  useEffect(() => {
    const chartParam =
      charts &&
      charts.length > 0 &&
      charts.map((param) => {
        return { ...param, range: dateRange, dateFrom: dateFrom, dateTo: dateTo };
      });
    isFirstChart
      ? setSelectedParameters(chartParam ? chartParam : [])
      : setSecondChartSelectedParameters(chartParam ? chartParam : []);
  }, [charts, dateRange, dateFrom, dateTo]);

  useEffect(() => {
    if (!showChartConfigModal) return;
    trackEvent(events.SHOW_CHART_CONFIG);
  }, [showChartConfigModal]);

  const isSubscriptionValid = localStorage.getItem('IS_SUBSCRIPTION_VALID');

  return (
    <Modal
      className="chart-config-modal-dialog"
      show={showChartConfigModal}
      onHide={() => {
        isFirstChart ? setSelectedParameters(charts) : setSecondChartSelectedParameters(charts);
        setIsExtended('');
        return showChartConfig(false);
      }}
    >
      <div className="chart-config">
        <ModalBody>
          <h4 className="chart-config__title">
            <FormattedMessage
              id="tiles.configuration.select-type.title"
              defaultMessage="What would you like to show?"
            />
          </h4>
          <p className="chart-config__message">
            <FormattedMessage id="chart.configuration.maximum.selection" defaultMessage="Max selection of 10 items" />
          </p>
          {!premiumFeatures.history && !isSubscriptionValid && (
            <p className="chart-config__premium-history-message">
              <FormattedMessage
                id="premiumFeatures.history.select.chart.parameter"
                defaultMessage="*You need to subscribe to the premium functionality Extended History to be able to select more than one parameter."
              />
            </p>
          )}
          <form className="chart-config">
            {!parameters && (
              <div className="spinner-wrapper">
                <Spinner />
              </div>
            )}
            {parameters && parameters.length > 0
              ? parameters.map((d) => (
                  <div key={`${d.name}`} className="chart-config__device">
                    <div className="chart-config-wrapper">
                      <div
                        className="chart-config-wrapper__topContent"
                        onClick={() =>
                          isExtended && isExtended === d.name ? setIsExtended('') : setIsExtended(d.name)
                        }
                      >
                        <div className="chart-config-wrapper__topContent--left">
                          <div className="item-id">{d.name}</div>
                        </div>
                        <div className="chart-config-wrapper__topContent--right">
                          <div className="chart-config-wrapper__expand-button">
                            {isExtended === d.name ? (
                              <i className="fas fa-chevron-up"></i>
                            ) : (
                              <i className="fas fa-chevron-down"></i>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="chart-config-wrapper__checkbox">
                        {isExtended === d.name && (
                          <div className="chart-config-wrapper__checkbox-info">
                            <ul>
                              {d.parameters.map((p, i) => (
                                <li key={`${p.deviceId}_${p.parameterId}_${i}`}>
                                  <Checkbox
                                    id={`${p.deviceId}_${p.parameterId}_${i}`}
                                    disabled={
                                      (!premiumFeatures.history && p.accessLevel.toLowerCase() !== 'all') ||
                                      checkDisabledParameter(p)
                                    }
                                    checked={
                                      isFirstChart
                                        ? selectedParameters.some(
                                            (c) => c.parameterId === p.parameterId && c.deviceId === p.deviceId
                                          )
                                        : secondChartSelectedParameters.some(
                                            (c) => c.parameterId === p.parameterId && c.deviceId === p.deviceId
                                          )
                                    }
                                    onChange={(e) => {
                                      const check = isFirstChart
                                        ? selectedParameters.length < 10
                                        : secondChartSelectedParameters.length < 10;
                                      if (e.target.checked) {
                                        const checkedParameter = {
                                          deviceId: p.deviceId,
                                          parameterId: p.parameterId,
                                          parameter: p.parameterName,
                                          unit: p.parameterUnit,
                                          range: dateRange,
                                          dateFrom: dateFrom,
                                          dateTo: dateTo,
                                          aggregationMethod: aggregationMethod,
                                          category: d.id,
                                        };
                                        if (!premiumFeatures.history) {
                                          isFirstChart
                                            ? setSelectedParameters([checkedParameter])
                                            : setSecondChartSelectedParameters([checkedParameter]);
                                        } else if (check) {
                                          isFirstChart
                                            ? setSelectedParameters((prev) => [...(prev || []), checkedParameter])
                                            : setSecondChartSelectedParameters((prev) => [
                                                ...(prev || []),
                                                checkedParameter,
                                              ]);
                                        }
                                      } else {
                                        if (isFirstChart) {
                                          const checkedParameter =
                                            selectedParameters && selectedParameters.length > 0
                                              ? selectedParameters.filter(
                                                  (param) => param.parameterId !== p.parameterId
                                                )
                                              : [];
                                          setSelectedParameters(checkedParameter);
                                        } else {
                                          const checkedParameter =
                                            secondChartSelectedParameters && secondChartSelectedParameters.length > 0
                                              ? secondChartSelectedParameters.filter(
                                                  (param) => param.parameterId !== p.parameterId
                                                )
                                              : [];
                                          setSecondChartSelectedParameters(checkedParameter);
                                        }
                                      }
                                    }}
                                  >
                                    <div className="parameter-title-container">
                                      {!premiumFeatures.history && p.accessLevel.toLowerCase() !== 'all' && (
                                        <>
                                          <div className="badge badge-success premium-badge">PREMIUM</div>
                                          <PremiumFeatureTooltip
                                            premiumFeatureType={userIsViewer ? 'permissionAccess' : 'history'}
                                          />
                                        </>
                                      )}
                                      <span>{p.parameterName}</span>
                                    </div>
                                  </Checkbox>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))
              : parameters?.length === 0 && (
                  <p className="chart-config__message">
                    <FormattedMessage
                      id="chart.config.no-device-parameters"
                      defaultMessage="Your device don't have any parameters to select."
                    />
                  </p>
                )}
            <div className="chart-config__button-container">
              {((isFirstChart ? selectedParameters.length > 0 : secondChartSelectedParameters.length > 0) ||
                (charts?.length > 0 &&
                  (isFirstChart ? !selectedParameters.length : !secondChartSelectedParameters.length))) && (
                <Button className="button--primary" type="button" onClick={onSelectParameters}>
                  <FormattedMessage id="group.systems-list.select" defaultMessage="Select" />
                </Button>
              )}
              <Button
                className="button--cancel"
                type="button"
                onClick={() => {
                  isFirstChart ? setSelectedParameters(charts) : setSecondChartSelectedParameters(charts);
                  showChartConfig(false);
                }}
              >
                <i className="fas fa-times"></i>
              </Button>
            </div>
          </form>
        </ModalBody>
      </div>
    </Modal>
  );
};

ChartConfiguration.propTypes = {
  systemId: PropTypes.string.isRequired,
  premiumFeatures: PropTypes.object.isRequired,
  parameters: PropTypes.array,
  charts: PropTypes.array.isRequired,
  addChart: PropTypes.func.isRequired,
  deleteChart: PropTypes.func.isRequired,
  dateRange: PropTypes.string,
  dateFrom: PropTypes.instanceOf(Date).isRequired,
  dateTo: PropTypes.instanceOf(Date).isRequired,
  aggregationMethod: PropTypes.string,
  userIsViewer: PropTypes.bool,
  showChartConfigModal: PropTypes.bool,
  showChartConfig: PropTypes.func,
  isFirstChart: PropTypes.bool,
};

export default ChartConfiguration;
