import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { languageSelected, setLastUsedLanguage } from './actions';
import { getDeviceFeatures } from '../../FeaturesFlags/actions';
import { menuSystem } from '../../FeaturesFlags/menuSystem';

import { sortArrayByLocaleCompare } from '../../../utils';

import './languageselector.scss';

class LanguageSelector extends Component {
  static propTypes = {
    languageSelected: PropTypes.func,
    selectedLanguage: PropTypes.string,
    userInfo: PropTypes.object,
    setLastUsedLanguage: PropTypes.func.isRequired,
    devices: PropTypes.arrayOf(PropTypes.object),
    getDeviceFeatures: PropTypes.func.isRequired,
    menuType: PropTypes.string.isRequired,
    languages: PropTypes.array.isRequired,
  };

  changeLanguage = (e) => {
    e.preventDefault();
    const { value } = e.target;
    const { languageSelected, userInfo, devices, setLastUsedLanguage, getDeviceFeatures, menuType } = this.props;
    languageSelected(value, userInfo || { id: null });
    setLastUsedLanguage(value);
    if (devices && devices.length > 0 && devices[0].id && menuType === menuSystem.simplified) {
      getDeviceFeatures(devices[0].id);
    }
  };

  render() {
    const { languages = [] } = this.props;
    const sortKey = 'displayName';
    const sortedLanguages = sortArrayByLocaleCompare(languages, sortKey);

    return (
      <div className="languageSelector">
        <select
          className="languageSelector__selectControl"
          onChange={this.changeLanguage}
          value={this.props.selectedLanguage}
          aria-label="Select language"
        >
          {sortedLanguages.length > 0 ? (
            sortedLanguages.map(({ id, name, displayName }) => {
              const [lang] = name.split('-');
              return (
                <option key={id} value={lang}>
                  {displayName}
                </option>
              );
            })
          ) : (
            <option value="en">English</option>
          )}
        </select>
        <FontAwesomeIcon icon="globe-americas" className="languageSelector__globeIcon" />
      </div>
    );
  }
}

export default connect(
  ({
    language: { selectedLanguage },
    app: { userInfo, languages },
    devices: { devices },
    features: { deviceFeatures },
  }) => ({
    selectedLanguage,
    userInfo,
    devices,
    menuType: deviceFeatures && deviceFeatures.menuSystem,
    languages,
  }),
  { languageSelected, setLastUsedLanguage, getDeviceFeatures }
)(LanguageSelector);
