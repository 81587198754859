import React from 'react';
import PropTypes from 'prop-types';

import './divider.scss';

export default function Divider({ className }) {
  return <div className={`generic-tile__divider ${className}`}></div>;
}

Divider.propTypes = {
  className: PropTypes.string,
};

Divider.defaultProps = {
  className: '',
};
