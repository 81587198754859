import React, { Fragment } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import classNames from 'classnames';
import { Button } from 'jpi-cloud-web-ui-components';
import ScheduleTooltip from '../ScheduleTooltip';
import PremiumFeatureTooltip from '../../../../layout/PremiumFeatureTooltip';
import PropTypes from 'prop-types';
import Spacer from '../../../../layout/Spacer';
import { weeklySchedulesType } from '../../types';

import './mode-list.scss';

class ModeList extends React.PureComponent {
  static propTypes = {
    modes: PropTypes.array.isRequired,
    weeklySchedules: weeklySchedulesType,
    selectedMode: PropTypes.object,
    scheduleConfig: PropTypes.object.isRequired,
    goNext: PropTypes.func.isRequired,
    goPrevious: PropTypes.func.isRequired,
    nextButtonLabel: PropTypes.string,
    modeSelectorEnabled: PropTypes.bool,
    userIsViewer: PropTypes.bool.isRequired,
    canEditMode: PropTypes.bool.isRequired,
    onAdd: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onRename: PropTypes.func.isRequired,
    onToggle: PropTypes.func.isRequired,
    closePopup: PropTypes.func.isRequired,
    modeSettings: PropTypes.object.isRequired,
  };

  titles = {
    modeSelector: {
      id: 'scheduling.edit-mode.title.step1.mode-selector',
      defaultMessage: 'Select scheduled mode',
    },
    modeEditor: {
      id: 'scheduling.edit-mode.title.step1.text',
      defaultMessage: 'Which mode do you want to edit?',
    },
    noModes: {
      id: 'scheduling.edit-mode.title.step1.text.noModes',
      defaultMessage: 'No modes are available. Please add a mode to continue.',
    },
  };

  componentDidMount() {
    const { modes, onToggle } = this.props;

    if (modes && modes.length === 1) {
      onToggle(modes[0]);
    }
  }

  isActiveMode = ({ mode, weeklySchedules }) => {
    if (!weeklySchedules) return false;

    return mode?.isActive;
  };

  render() {
    const {
      modes,
      weeklySchedules,
      selectedMode,
      scheduleConfig,
      nextButtonLabel,
      userIsViewer,
      canEditMode,
      goPrevious,
      goNext,
      onRename,
      onDelete,
      onAdd,
    } = this.props;

    const title =
      modes.length > 0
        ? this.props.modeSelectorEnabled
          ? this.titles.modeSelector
          : this.titles.modeEditor
        : this.titles.noModes;

    const canEditList = scheduleConfig.maxModesNumber !== scheduleConfig.minModesNumber;
    const canDelete = selectedMode && modes.length > scheduleConfig.minModesNumber && canEditList;
    const canAdd = modes.filter((mode) => mode.modeId !== 0).length < scheduleConfig.maxModesNumber && canEditList;
    return (
      <Fragment>
        <div className="popup-body">
          {!userIsViewer && (
            <div className="popup-header edit-modes">
              <p className="text">
                <FormattedMessage id={title.id} defaultMessage={title.defaultMessage} />
              </p>
              {!nextButtonLabel && scheduleConfig.allowRename && canEditList && (
                <div className="icon">
                  <Button className="button--round edit-modes-add" onClick={onAdd} type="button" disabled={!canAdd}>
                    +
                  </Button>
                  {modes.length >= scheduleConfig.maxModesNumber && (
                    <PremiumFeatureTooltip translationId="scheduling.edit-mode.maxNumModes" />
                  )}
                  <span className="icon-text icon-text-brand">
                    <FormattedMessage id="button.add" defaultMessage="Add" />
                  </span>
                </div>
              )}
            </div>
          )}

          <div className="popup-main">
            {modes.map((mode) => (
              <div key={mode.modeId} className={classNames('popup-item', { active: selectedMode === mode })}>
                <Button
                  type="button"
                  key={mode.modeId}
                  className="button--info"
                  disabled={mode.modeId === 0}
                  onClick={() => this.props.onToggle(mode)}
                >
                  <span className="mode-name">{mode.name}</span>
                  {this.isActiveMode({ mode, weeklySchedules }) && (
                    <>
                      <Spacer x={10} inline />
                      <span>&#x2022;</span>
                      <Spacer x={10} inline />
                      <span className="active-mode">
                        <FormattedMessage id="scheduling.title.active-mode" defaultMessage="Active Mode" />
                      </span>
                    </>
                  )}
                </Button>
                <ScheduleTooltip modeSettings={this.props.modeSettings[mode.modeId] || []} hidden={mode.modeId === 0} />
              </div>
            ))}
          </div>
        </div>

        <div className="popup-bottom-panel">
          {!userIsViewer && (
            <Button className="button--secondary" type="submit" onClick={goNext} disabled={!selectedMode}>
              <FormattedMessage
                id={`${nextButtonLabel ? nextButtonLabel : 'scheduling.title.edit'}`}
                defaultMessage={nextButtonLabel ? 'Create' : 'Edit'}
              />
            </Button>
          )}
          {canEditMode && (
            <Button
              className="button--default edit-modes-rename"
              type="button"
              onClick={onRename}
              id="edit-modes-rename"
              disabled={!selectedMode}
            >
              <FormattedMessage id="button.rename" defaultMessage="Rename" />
            </Button>
          )}
          {canEditMode && canEditList && (
            <Fragment>
              <Button
                className="button--default edit-modes-delete"
                type="button"
                onClick={onDelete}
                id="edit-modes-delete"
                disabled={!canDelete}
              >
                <FormattedMessage id="button.delete" defaultMessage="Delete" />
              </Button>
              {modes.length <= scheduleConfig.minModesNumber && (
                <PremiumFeatureTooltip translationId="scheduling.edit-mode.minNumModes" />
              )}
            </Fragment>
          )}
          {nextButtonLabel && (
            <Button onClick={goPrevious} className="button--default" type="button">
              <FormattedMessage id={'scheduling.title.back'} defaultValue={'Back'} />
            </Button>
          )}
        </div>
      </Fragment>
    );
  }
}

export default injectIntl(ModeList);
