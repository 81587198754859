import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button, Spinner } from 'jpi-cloud-web-ui-components';

const SelectTileType = ({ onCancel, onTypeSelected, tileTypes, devices }) => (
  <div className="tile-configuration-popup">
    <h2>
      <FormattedMessage id="tiles.configuration.select-type.title" defaultMessage="What tile do you want to add?" />
    </h2>
    <div className="popup-body">
      {tileTypes ? (
        tileTypes.map((tile) => (
          <div key={`${tile.id}-${tile.tileNameTextId}`}>
            <Button
              onClick={() => onTypeSelected(tile.id, devices[0].id, tile.tileNameText, tile.tileNameTextId)}
              type="button"
              className="button--info"
            >
              {tile.name}
            </Button>
          </div>
        ))
      ) : (
        <Spinner dark />
      )}
    </div>

    <div className="popup-bottom-panel select-tile-type">
      <Button onClick={onCancel} className="button--default" type="button">
        <FormattedMessage id="tiles.configuration.buttons.discard" defaultValue="Cancel" />
      </Button>
    </div>
  </div>
);

SelectTileType.propTypes = {
  onCancel: PropTypes.func,
  onTypeSelected: PropTypes.func,
  tileTypes: PropTypes.array,
  devices: PropTypes.array,
};

export default SelectTileType;
