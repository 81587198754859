import * as api from '../../../api';

export const GET_REGISTERED_PRODUCTS_REQUEST = 'productRegistration/GET_REGISTERED_PRODUCTS_REQUEST';
export const GET_REGISTERED_PRODUCTS_RESPONSE = 'productRegistration/GET_REGISTERED_PRODUCTS_RESPONSE';

export const getRegisteredProducts = (userId) => async (dispatch) => {
  dispatch({ type: GET_REGISTERED_PRODUCTS_REQUEST });
  const response = await api.getRegisteredProducts(userId);
  if (response.status === 200) {
    dispatch({ type: GET_REGISTERED_PRODUCTS_RESPONSE, productList: response.data });
  } else {
    dispatch({ type: GET_REGISTERED_PRODUCTS_RESPONSE, productList: [] });
  }
};

export const FIND_PRODUCT_REQUEST = 'productRegistration/FIND_PRODUCT_REQUEST';
export const FIND_PRODUCT_RESPONSE = 'productRegistration/FIND_PRODUCT_RESPONSE';

export const findProduct = (serialNumber) => async (dispatch) => {
  dispatch({ type: FIND_PRODUCT_REQUEST });
  const response = await api.findProduct(serialNumber);
  if (response.status === 200) {
    dispatch({ type: FIND_PRODUCT_RESPONSE, productFound: response.data });
  } else {
    dispatch({ type: FIND_PRODUCT_RESPONSE, productFound: { canBeRegistered: false, validCountries: [] } });
  }
};

export const REGISTER_PRODUCT_REQUEST = 'productRegistration/REGISTER_PRODUCT_REQUEST';
export const REGISTER_PRODUCT_RESPONSE = 'productRegistration/REGISTER_PRODUCT_RESPONSE';

export const registerProduct = (values, userInfo) => async (dispatch) => {
  dispatch({ type: REGISTER_PRODUCT_REQUEST });
  const response = await api.registerProduct(values, userInfo);
  if (response.status === 200) {
    dispatch({ type: REGISTER_PRODUCT_RESPONSE, registrationSucceeded: true });
  } else {
    dispatch({ type: REGISTER_PRODUCT_RESPONSE, registrationSucceeded: false });
  }
};

export const GET_SERVICE_PARTNERS_LIST_REQUEST = 'productRegistration/GET_SERVICE_PARTNERS_LIST_REQUEST';
export const GET_SERVICE_PARTNERS_LIST_RESPONSE = 'productRegistration/GET_SERVICE_PARTNERS_LIST_RESPONSE';

export const getServicePartnersList = () => async (dispatch) => {
  dispatch({ type: GET_SERVICE_PARTNERS_LIST_REQUEST });
  const response = await api.getServicePartnersList();
  if (response.status === 200) {
    dispatch({ type: GET_SERVICE_PARTNERS_LIST_RESPONSE, servicePartner: response.data });
  } else {
    dispatch({ type: GET_SERVICE_PARTNERS_LIST_RESPONSE, servicePartner: {} });
  }
};

export const GET_SERVICE_PARTNER_BY_NAME_HINT_REQUEST = 'productRegistration/GET_SERVICE_PARTNER_BY_NAME_HINT_REQUEST';
export const GET_SERVICE_PARTNER_BY_NAME_HINT_RESPONSE =
  'productRegistration/GET_SERVICE_PARTNER_BY_NAME_HINT_RESPONSE';

export const getServicePartnerByNameHint = (value) => async (dispatch) => {
  dispatch({ type: GET_SERVICE_PARTNER_BY_NAME_HINT_REQUEST });
  const response = await api.getServicePartnerByNameHint(value);
  if (response.status === 200) {
    dispatch({ type: GET_SERVICE_PARTNER_BY_NAME_HINT_RESPONSE, servicePartnerResult: response.data });
  } else {
    dispatch({ type: GET_SERVICE_PARTNER_BY_NAME_HINT_RESPONSE, servicePartnerResult: [] });
  }
};
