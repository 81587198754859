import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { DateRange } from 'react-date-range';
import * as locales from 'react-date-range/dist/locale';
import { addDays } from 'date-fns';
import moment from 'moment';

import { Button, Input } from 'jpi-cloud-web-ui-components';

import ToggleSwitch from '../../../../../components/inputs/ToggleSwitch';
import DeleteVacation from './DeleteVacation';

import { /* nameValidator,*/ weekdayFormat } from './config';

const enumerateDaysBetweenDates = (startDate, endDate) => {
  const dates = [];

  var currDate = moment(startDate).startOf('day');
  var lastDate = moment(endDate).startOf('day');
  dates.push(currDate.clone().toDate());

  while (currDate.add(1, 'days').diff(lastDate) <= 0) {
    dates.push(currDate.clone().toDate());
  }

  return dates;
};
// TODO: Add TS
function VacationForm({
  disabledDateRanges = [],
  selectedDateRange,
  selectedLanguage,
  weekStartsOnIndex,
  vacationDetails,
  scheduleConfig = {},
  showDeleteBtn,
  onDeleteVacation,
  onDetailsChange,
  onRangeChange,
  goPrevious,
  goNext,
  intl,
}) {
  const { formatMessage } = intl;
  const [raiseDeleteModal, setRaiseDeleteModal] = useState(false);

  const [enabled, setEnabled] = useState(vacationDetails?.isEnabled || false);
  const [name, setName] = useState(vacationDetails?.name || '');
  const [nameError, setNameError] = useState(null);

  const [selectionRange, setSelectionRange] = useState(
    selectedDateRange || {
      startDate: addDays(new Date(), 1),
      endDate: addDays(new Date(), 1),
      key: 'selectionRange',
    }
  );

  const onSelectionChange = ({ selectionRange: newRange }) => {
    setSelectionRange({ ...selectionRange, ...newRange });

    if (newRange.startDate !== newRange.endDate) {
      // TODO: Consider error notification
      onRangeChange(newRange);
    }
  };

  const handleDeleteVacation = () => {
    closeDeleteModal();
    onDeleteVacation();
  };

  const closeDeleteModal = () => {
    setRaiseDeleteModal(false);
  };

  const toggleVacationEnabled = (value) => {
    setEnabled(Boolean(value));
  };

  const handleNameChange = ({ target: { value: name } }) => {
    validateName(name);
    setName(name);
  };

  const validateName = (value) => {
    const nameErrorContent = !value // ||  !nameValidator.test(value)
      ? formatMessage({
          id: 'scheduling.vacation.name-invalid',
          // defaultMessage: 'Vacation name can contain only letters and numbers.',
          defaultMessage: 'Vacation name can not be empty.',
        })
      : null;
    setNameError(nameErrorContent);

    return nameErrorContent === null;
  };

  const handleNext = () => {
    if (scheduleConfig?.namedVacations && !validateName(name)) return;

    onDetailsChange({ name, isEnabled: enabled });
    goNext();
  };

  const isNextDisabled = moment(selectionRange.startDate).isSame(moment(selectionRange.endDate), 'day');
  const locale = Object.keys(locales).find((l) => l.includes(selectedLanguage));

  const disabledDates = disabledDateRanges
    .map((r) => enumerateDaysBetweenDates(moment(r.start).toDate(), moment(r.end).toDate()))
    .reduce((acc, i) => [...acc, ...i], []);

  return (
    <Fragment>
      <div className="popup-body">
        <div className="popup-header">
          <p className="text">
            {!raiseDeleteModal ? (
              <FormattedMessage id="new-vacation.step1.text" defaultMessage="Use the calendar to set vacation dates" />
            ) : (
              <FormattedMessage
                id="scheduling.edit-vacation.delete"
                defaultMessage="Are you sure you want to delete the selected vacation?"
              />
            )}
          </p>
        </div>
        {!raiseDeleteModal && (
          <div className="vacation-details">
            {scheduleConfig?.namedVacations && (
              <Input
                type="text"
                value={name}
                maxLength={20}
                placeholder={formatMessage({
                  id: 'scheduling.vacation.name-placeholder',
                  defaultMessage: 'Vacation name',
                })}
                onChange={handleNameChange}
                error={nameError}
              />
            )}
            <div aria-label="Calendar" className="popup-main">
              <DateRange
                months={1}
                direction="vertical"
                minDate={new Date()}
                scroll={{ enabled: false }}
                locale={locales[locale] || locales.enUS}
                weekdayDisplayFormat={weekdayFormat}
                weekStartsOn={weekStartsOnIndex}
                disabledDates={disabledDates}
                ranges={[selectionRange]}
                showMonthAndYearPickers
                onChange={onSelectionChange}
              />
            </div>
            {scheduleConfig?.vacationDisableAvailable && (
              <div className="vacation-enabled">
                <span>
                  {formatMessage({ id: 'scheduling.vacation.toggle-label', defaultMessage: 'Enable Vacation' })}
                </span>
                <ToggleSwitch
                  isChecked={enabled}
                  premiumFeatureType="permissionAccess"
                  onChange={toggleVacationEnabled}
                />
              </div>
            )}
          </div>
        )}
      </div>

      {raiseDeleteModal ? (
        <DeleteVacation
          onSubmit={handleDeleteVacation}
          editableVacation={vacationDetails}
          onCancel={closeDeleteModal}
        />
      ) : (
        <div className="popup-bottom-panel">
          <Button
            type="submit"
            className="button--secondary"
            disabled={isNextDisabled || Boolean(nameError)}
            onClick={handleNext}
          >
            <FormattedMessage id="scheduling.title.next" defaultMessage="Next" />
          </Button>
          {showDeleteBtn && (
            <Button className="button--default" type="button" onClick={setRaiseDeleteModal}>
              <FormattedMessage id="button.delete" defaultMessage="Delete" />
            </Button>
          )}
          <Button onClick={goPrevious} className="button--default" type="button">
            <FormattedMessage id="scheduling.title.cancel" defaultValue="Cancel" />
          </Button>
        </div>
      )}
    </Fragment>
  );
}

VacationForm.propTypes = {
  selectedDateRange: PropTypes.object.isRequired,
  disabledDateRanges: PropTypes.array.isRequired,
  selectedLanguage: PropTypes.string.isRequired,
  weekStartsOnIndex: PropTypes.number.isRequired,
  vacationDetails: PropTypes.object,
  onDeleteVacation: PropTypes.func,
  onRangeChange: PropTypes.func.isRequired,
  onDetailsChange: PropTypes.func.isRequired,
  goPrevious: PropTypes.func.isRequired,
  goNext: PropTypes.func.isRequired,
  showDeleteBtn: PropTypes.bool,
  scheduleConfig: PropTypes.object,
  intl: PropTypes.object,
};

export default injectIntl(VacationForm);
