import React from 'react';

import { Warranty as WarrantyInfo } from '../ProductRegistrationInfo/Warranty';

import './warranty.scss';

const Warranty = () => (
  <div className="page-content">
    <div className="warranty__wrapper">
      <WarrantyInfo />
    </div>
  </div>
);

export default Warranty;
