import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './formInput.scss';

const FormInput = ({ className, children, hidden }) => (
  <div className={classNames('formInput', className, hidden && 'formInput--hidden')}>{children}</div>
);

FormInput.propTypes = {
  className: PropTypes.string,
  hidden: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

FormInput.defaultProps = {
  className: '',
  hidden: false,
};

export default FormInput;
