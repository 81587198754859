import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import Modal from '../../../../../layout/Modal';
import { Button, Title } from 'jpi-cloud-web-ui-components';

import './termsofservicemodal.scss';

const termsOfServiceModal = ({ isTermsOfService, toggleTermsOfService, toggleProductDetailsPopup }) => {
  return (
    <>
      <Modal className="checkout-modal-dialog" show={isTermsOfService}>
        <div className="TermsOfService">
          <div className="popup-modal">
            <div className="TermsOfService__heading">
              <Title
                className="TermsOfServiceTitle"
                titleTranslationId="menu.terms-of-service"
                defaultMessage="Terms of Service"
              />
            </div>
            <div className="TermsOfService__description">
              <FormattedMessage
                id="shop.cart.acceptToContinue"
                defaultMessage="Please read and accept our terms of service to continue"
              />
            </div>
            <div className="TermsOfService__content-wrapper">
              <div className="TermsOfService__content">
                <FormattedHTMLMessage id="terms-of-service" />
              </div>
            </div>
            <div className="TermsOfService__button-wrapper">
              <Button
                className="TermsOfService__back-button"
                onClick={() => {
                  toggleTermsOfService();
                  toggleProductDetailsPopup(true);
                }}
              >
                <FormattedMessage id="button.back" defaultMessage="Back" />
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

termsOfServiceModal.propTypes = {
  isTermsOfService: PropTypes.bool.isRequired,
  toggleTermsOfService: PropTypes.func.isRequired,
  toggleProductDetailsPopup: PropTypes.func.isRequired,
};
export default termsOfServiceModal;
