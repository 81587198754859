import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Button, Checkbox } from 'jpi-cloud-web-ui-components';
import PremiumFeatureTooltip from '../../../../layout/PremiumFeatureTooltip';

class SelectModeSettings extends React.Component {
  static propTypes = {
    modeSettings: PropTypes.array,
    scheduleConfig: PropTypes.object.isRequired,

    goNext: PropTypes.func.isRequired,
    goPrevious: PropTypes.func.isRequired,
    onSelectedChanged: PropTypes.func.isRequired,
  };

  onItemSelected = (setting) => {
    const { scheduleConfig } = this.props;
    const modeSettings = this.props.modeSettings.map((s) =>
      setting.settingId === s.settingId
        ? { ...s, selected: scheduleConfig.forceAllModeSettings || !s.selected, value: s.value || s.defaultValue }
        : s
    );
    this.props.onSelectedChanged(modeSettings);
  };

  render() {
    const { goNext, goPrevious } = this.props;
    const { modeSettings } = this.props;

    const isNextDisabled = !modeSettings.some((s) => s.selected);

    return (
      <Fragment>
        <div className="popup-body">
          <div className="popup-header">
            <p className="text">
              <FormattedMessage
                id="scheduling.edit-mode.title.step2.text"
                defaultMessage="Which items would you like to include in this mode?"
              />
            </p>
          </div>
          <div className="popup-main">
            {modeSettings.map((item) => (
              <div key={`${item.settingId} ${item.name}`} className="edit-mode-item">
                <Button type="button" className="button--info" onClick={() => this.onItemSelected(item)}>
                  {item.name}
                </Button>
                <Checkbox
                  id={`${item.settingId}-${item.name}`}
                  name={item.name}
                  checked={item.selected}
                  disabled={this.props.scheduleConfig.forceAllModeSettings}
                  onChange={() => this.onItemSelected(item)}
                />
                {this.props.scheduleConfig.forceAllModeSettings && (
                  <PremiumFeatureTooltip translationId="scheduling.edit-mode.forceAllModeSettings" />
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="popup-bottom-panel">
          <Button className="button--secondary" type="submit" onClick={goNext} disabled={isNextDisabled}>
            <FormattedMessage id="scheduling.title.next" defaultMessage="Next" />
          </Button>
          <Button onClick={goPrevious} className="button--default" type="button">
            <FormattedMessage id="scheduling.title.back" defaultValue="Back" />
          </Button>
        </div>
      </Fragment>
    );
  }
}

export default SelectModeSettings;
