import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { Spinner } from 'jpi-cloud-web-ui-components';
import SmartGuideTile from '../../tiles/SmartGuide';
import './smartguide.scss';

class SmartGuidePage extends React.Component {
  componentDidUpdate() {
    const isSmartGuideAvailable = this.props.deviceFeatures.smartGuide;

    if (!isSmartGuideAvailable) {
      this.props.history.push('/');
    }
  }

  render() {
    const { devices } = this.props;
    if (devices.length === 0) return <Spinner />;

    return (
      <div className="page-content">
        <div className="smartguide">
          <div className="smartguide__wrapper">
            <h1 id="smartguide">
              <FormattedMessage
                id="smartguide.title"
                defaultMessage="Smart Guide"
                description="Title on SmartGuide page"
              />
            </h1>

            <div className="well well-lg well-center">
              <SmartGuideTile deviceId={devices[0].id} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SmartGuidePage.propTypes = {
  devices: PropTypes.array.isRequired,
  deviceFeatures: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default connect(({ devices: { devices }, features: { deviceFeatures } }) => ({
  devices,
  deviceFeatures,
}))(SmartGuidePage);
