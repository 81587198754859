import * as api from '../../../../../api';
import { USER_SECURITY_LEVELS } from './costants';
import { eventEmitter, events } from '../../../../tracking/AppInsights';

export const CONNECT_USER_REQUEST = 'security/CONNECT_USER_REQUEST';
export const CONNECT_USER_RESPONSE_SUCCEEDED = 'security/CONNECT_USER_RESPONSE_SUCCEEDED';
export const CONNECT_USER_RESPONSE_FAILED = 'security/CONNECT_USER_RESPONSE_FAILED';

export const connectUser = (systemId, userEmail, securityLevel) => async (dispatch) => {
  dispatch({ type: CONNECT_USER_REQUEST });

  try {
    const response = await api.connectUser(systemId, userEmail, securityLevel);

    if (response.status === 200)
      dispatch({ type: CONNECT_USER_RESPONSE_SUCCEEDED, info: response.data, status: response.status });
    else throw { response };
  } catch (err) {
    dispatch({ type: CONNECT_USER_RESPONSE_FAILED, status: err.response.status });
    eventEmitter.emit(events.logError, JSON.stringify(err));
  }
};

export const DISCONNECT_USER_REQUEST = 'security/DISCONNECT_USER_REQUEST';
export const DISCONNECT_USER_RESPONSE_SUCCEEDED = 'security/DISCONNECT_USER_RESPONSE_SUCCEEDED';
export const DISCONNECT_USER_RESPONSE_FAILED = 'security/DISCONNECT_USER_RESPONSE_FAILED';

export const disconnectUser = (systemId, user) => async (dispatch) => {
  dispatch({ type: DISCONNECT_USER_REQUEST });

  try {
    let response;
    switch (user.role.toLowerCase()) {
      case USER_SECURITY_LEVELS.servicePartner.toLowerCase():
        response = await api.disconnectServicePartner(systemId, user.id);
        break;
      case USER_SECURITY_LEVELS.hp24installer:
        response = await api.disconnectHP24Account(systemId, user.id);
        break;
      default:
        response = await api.disconnectUser(systemId, user.id);
    }

    if (response.status === 204) dispatch({ type: DISCONNECT_USER_RESPONSE_SUCCEEDED, removedUserId: user.id });
    else throw { response };
  } catch (err) {
    dispatch({ type: DISCONNECT_USER_RESPONSE_FAILED });
    eventEmitter.emit(events.logError, JSON.stringify(err));
  }
};

export const GET_USERS_REQUEST = 'security/GET_USERS_REQUEST';
export const GET_USERS_RESPONSE = 'security/GET_USERS_RESPONSE';
export const getSystemUsers = (systemId) => async (dispatch) => {
  dispatch({ type: GET_USERS_REQUEST });

  try {
    const response = await api.getSystemUsers(systemId);

    if (response.status === 200) dispatch({ type: GET_USERS_RESPONSE, users: response.data });
    else dispatch({ type: GET_USERS_RESPONSE, users: [] });
  } catch {
    dispatch({ type: GET_USERS_RESPONSE, users: [] });
  }
};

export const GET_SYSTEM_ROLES_REQUEST = 'security/GET_SYSTEM_ROLES_REQUEST';
export const GET_SYSTEM_ROLES_RESPONSE = 'security/GET_SYSTEM_ROLES_RESPONSE';
export const getSystemRolesAction = (systemId) => async (dispatch) => {
  dispatch({ type: GET_SYSTEM_ROLES_REQUEST });

  try {
    const response = await api.getSystemRoles(systemId);

    if (response.status === 200)
      dispatch({ type: GET_SYSTEM_ROLES_RESPONSE, roles: response.data.map((i) => i.toLowerCase()) });
    else throw { response };
  } catch (err) {
    dispatch({ type: GET_SYSTEM_ROLES_RESPONSE, roles: [] });
    eventEmitter.emit(events.logError, JSON.stringify(err));
  }
};

export const PATCH_USER_SECURITY_LEVEL_REQUEST = 'security/PATCH_USER_SECURITY_LEVEL_REQUEST';
export const PATCH_USER_SECURITY_LEVEL_RESPONSE_SUCCEEDED = 'security/PATCH_USER_SECURITY_LEVEL_RESPONSE_SUCCEEDED';
export const PATCH_USER_SECURITY_LEVEL_RESPONSE_FAILED = 'security/PATCH_USER_SECURITY_LEVEL_RESPONSE_FAILED';
export const changeUserSecurityLevel = (systemId, userId, securityLevel) => async (dispatch) => {
  dispatch({ type: PATCH_USER_SECURITY_LEVEL_REQUEST });

  try {
    const response = await api.changeUserSecurityLevel(systemId, userId, securityLevel);

    if (response.status === 200)
      dispatch({ type: PATCH_USER_SECURITY_LEVEL_RESPONSE_SUCCEEDED, userId, securityLevel });
    else dispatch({ type: PATCH_USER_SECURITY_LEVEL_RESPONSE_FAILED });
  } catch {
    dispatch({ type: PATCH_USER_SECURITY_LEVEL_RESPONSE_FAILED });
  }
};
