import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import classNames from 'classnames';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

import { Button, Spinner } from 'jpi-cloud-web-ui-components';
import PremiumFeatureTooltip from '../../../layout/PremiumFeatureTooltip';
import Pagination from '../../../layout/Pagination';
import CreateEventPopup from './CreateEventPopup';
import CopySchedulePopup from './CopySchedulePopup';
import ChangeWeekFormat from './ChangeWeekFormat';

import { WeekFormats, humanStringToDayIndex, DEFAULT_WEEK_FORMAT_ID, MONDAY, SATURDAY, SUNDAY } from '../weekFormats';
import eventCalculators from '../eventCalculateUtils';
import { getModes, getWeeklySchedules } from '../actions';
import { isCountryUseAmPmHours } from '../../../../api/countries';

const timeToMoment = (t) => moment(t, 'HH:mm:ss');

const TimeSlot = ({
  index,
  events,
  mode,
  editEvent,
  isAmPmHours,
  stopTimeAvailable,
  onEventHover,
  onEventLeave,
  hoveredEvent,
}) => {
  const event = events[index];
  const startTime = timeToMoment(event.startTime);
  const totalHoursInDay = 24;

  const isLast = events.length === index + 1;

  const nextEventStartTime = !isLast
    ? timeToMoment(events[index + 1].startTime) // We have next event
    : timeToMoment(event.stopTime ? event.stopTime : '00:00:00'); // We're on the last event - so taking the end of the day;

  const nextEventDiff = nextEventStartTime.diff(startTime, 'hours', true);

  let stopTimedPoint = event.stopTime;
  if (stopTimeAvailable && event.stopTime === '00:00:00') {
    stopTimedPoint = '24:00:00';
  }

  const isEndOfDay = (!stopTimeAvailable && nextEventDiff <= 0) || stopTimedPoint >= '23:59:59';
  const eventLength = !stopTimeAvailable
    ? nextEventDiff > 0
      ? nextEventDiff
      : totalHoursInDay - Math.abs(nextEventDiff)
    : timeToMoment(stopTimedPoint).diff(startTime, 'hours', true);

  const width = (eventLength / totalHoursInDay) * 100;
  const minWidth = 5;

  const timeSlotStyle = { width: `${width}%` };

  if (!event.isEmptyEvent) {
    timeSlotStyle.backgroundColor = event.color;
  }

  const timeFormat = isAmPmHours ? 'hh:mm A' : 'HH:mm';

  const eventIsHovered =
    hoveredEvent &&
    ((event.phantom_id && event.phantom_id === hoveredEvent.phantom_id) || (event.id && event.id === hoveredEvent.id));

  const timeSlotClass =
    'timeslot' +
    (event.isEmptyEvent || event.enabled ? '' : ' timeslot-disabled') +
    (eventIsHovered ? ' hovered' : '') +
    (isEndOfDay ? ' timeslot-eod' : '');

  const TimeSlotElement = (
    <div
      onMouseEnter={() => {
        !event.isEmptyEvent && onEventHover(event);
      }}
      onMouseLeave={() => {
        !event.isEmptyEvent && onEventLeave(event);
      }}
      className={timeSlotClass}
      style={timeSlotStyle}
      onClick={() => editEvent(event)}
    >
      {!event.isEmptyEvent && !event.splitMark && (
        <Fragment>
          <div className="timeslot-name">{mode.name}</div>
          <div className="timeslot-time">{startTime.format(timeFormat)}</div>
          {event.stopTime && <div className="timeslot-time"> - {nextEventStartTime.format(timeFormat)}</div>}
        </Fragment>
      )}
    </div>
  );

  if (width > minWidth || event.splitMark) {
    return TimeSlotElement;
  }

  const TimeSlotOverlay = (
    <Popover id="timeslot">
      <div className="timeslot-name">{mode.name}</div>
      <div className="timeslot-time">
        {startTime.format(timeFormat)} - {nextEventStartTime.format(timeFormat)}
      </div>
    </Popover>
  );

  if (event.isEmptyEvent) {
    return <div className="timeslot" style={timeSlotStyle}></div>;
  }

  return (
    <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={TimeSlotOverlay}>
      <div
        className={timeSlotClass}
        style={timeSlotStyle}
        onMouseEnter={() => {
          !event.isEmptyEvent && onEventHover(event);
        }}
        onMouseLeave={() => {
          !event.isEmptyEvent && onEventLeave(event);
        }}
        onClick={() => editEvent(event)}
      ></div>
    </OverlayTrigger>
  );
};

TimeSlot.propTypes = {
  index: PropTypes.number.isRequired,
  events: PropTypes.array.isRequired,
  mode: PropTypes.object.isRequired,
  isAmPmHours: PropTypes.bool,
  editEvent: PropTypes.func.isRequired,
  stopTimeAvailable: PropTypes.bool.isRequired,
  onEventHover: PropTypes.func.isRequired,
  onEventLeave: PropTypes.func.isRequired,
  hoveredEvent: PropTypes.object,
};

const Day = ({
  day,
  filteredEvents,
  modes,
  openPopup,
  openCopyPopup,
  isAmPmHours,
  editEvent,
  userIsViewer,
  eventsCountExceeded,
  scheduleDaysCount,
  stopTimeAvailable,
  onEventHover,
  onEventLeave,
  hoveredEvent,
}) => {
  const startOfADay = '00:00:00';
  const startOfADayParsed = timeToMoment(startOfADay);
  if (
    !stopTimeAvailable &&
    filteredEvents.length !== 0 &&
    timeToMoment(filteredEvents[0].startTime).diff(startOfADayParsed, 'hours') !== 0
  ) {
    filteredEvents.push({ ...filteredEvents[0], startTime: startOfADay, isEmptyEvent: true });
  }

  const isAddDisabled = userIsViewer || eventsCountExceeded || modes.length <= 0;
  const isCopyDisabled = scheduleDaysCount < 2 || userIsViewer || modes.length <= 0;
  const premiumFeatureTypeMessage =
    scheduleDaysCount < 2 ? 'copyAvailable' : isAddDisabled ? 'maxNumEvents' : 'permissionAccess';

  filteredEvents.sort((a, b) => timeToMoment(a.startTime).diff(timeToMoment(b.startTime), 'hours', true));

  return (
    <Fragment>
      <div className="device-scheduling__item">
        <h3>
          <FormattedMessage id={`scheduling.${day.name}`} />
        </h3>

        <div className="icons">
          <div className={classNames('icon', { disabled: isCopyDisabled })}>
            <Button
              className="button--round icon--default"
              onClick={openCopyPopup}
              type="button"
              disabled={isCopyDisabled}
            >
              <FontAwesomeIcon icon={faCopy} size="2x" />
            </Button>
            <span className={classNames('icon-text', isCopyDisabled && 'disabled')}>
              <FormattedMessage id="button.copy" defaultMessage="Copy" />
            </span>
            {userIsViewer ? (
              <PremiumFeatureTooltip premiumFeatureType={premiumFeatureTypeMessage} />
            ) : eventsCountExceeded ? (
              <PremiumFeatureTooltip
                translationId="scheduling.edit-event.maxNumEvents"
                defaultMessage="Maximum allowed events have been created. Please edit or delete an event to continue."
              />
            ) : modes.length <= 0 ? (
              <PremiumFeatureTooltip
                translationId="scheduling.edit-event.noModes"
                defaultMessage="No modes are available. Please add a mode to continue."
              />
            ) : null}
          </div>
          <div className={classNames('icon', { disabled: isAddDisabled })}>
            <Button className="button--round" onClick={openPopup} type="button" disabled={isAddDisabled}>
              +
            </Button>
            <span className={classNames('icon-text icon-text-brand', isAddDisabled && 'disabled')}>
              <FormattedMessage id="button.add" defaultMessage="Add" />
            </span>
            {userIsViewer ? (
              <PremiumFeatureTooltip premiumFeatureType={premiumFeatureTypeMessage} />
            ) : eventsCountExceeded ? (
              <PremiumFeatureTooltip
                translationId="scheduling.edit-event.maxNumEvents"
                defaultMessage="Maximum allowed events have been created. Please edit or delete an event to continue."
              />
            ) : modes.length <= 0 ? (
              <PremiumFeatureTooltip
                translationId="scheduling.edit-event.noModes"
                defaultMessage="No modes are available. Please add a mode to continue."
              />
            ) : null}
          </div>
        </div>
      </div>

      <div className="timeslots">
        {filteredEvents.map((e, i) => (
          <TimeSlot
            index={i}
            events={filteredEvents}
            mode={modes.find((m) => m.modeId === e.modeId) || {}} // modes might not be loaded yet
            isAmPmHours={isAmPmHours}
            key={`day#${day.name}_event#${e.startTime}_${e.phantom_id}`}
            stopTimeAvailable={stopTimeAvailable}
            editEvent={editEvent}
            onEventHover={onEventHover}
            onEventLeave={onEventLeave}
            hoveredEvent={hoveredEvent}
          />
        ))}
      </div>
    </Fragment>
  );
};

Day.propTypes = {
  day: PropTypes.object.isRequired,
  modes: PropTypes.array.isRequired,
  filteredEvents: PropTypes.array.isRequired,
  openPopup: PropTypes.func.isRequired,
  openCopyPopup: PropTypes.func.isRequired,
  editEvent: PropTypes.func.isRequired,
  isAmPmHours: PropTypes.bool,
  userIsViewer: PropTypes.bool.isRequired,
  eventsCountExceeded: PropTypes.bool.isRequired,
  scheduleDaysCount: PropTypes.number,
  stopTimeAvailable: PropTypes.bool.isRequired,
  onEventHover: PropTypes.func.isRequired,
  onEventLeave: PropTypes.func.isRequired,
  hoveredEvent: PropTypes.object,
};

class WeeklySchedule extends React.Component {
  static propTypes = {
    modes: PropTypes.array.isRequired,
    maxEventsNumber: PropTypes.number.isRequired,
    weeklySchedules: PropTypes.array.isRequired,
    devices: PropTypes.array.isRequired,
    selectedSystem: PropTypes.object.isRequired,
    userIsViewer: PropTypes.bool.isRequired,
    weekFormatAvailable: PropTypes.bool.isRequired,
    stopTimeAvailable: PropTypes.bool.isRequired,
    getModes: PropTypes.func.isRequired,
    getWeeklySchedules: PropTypes.func.isRequired,
  };

  state = {
    isLoading: true,
    currentSchedule: 0,
    isEventPopupOpen: false,
    copyPopupVisible: false,
    changeWFVisible: false,
    eventToEdit: null,
    currentEditableDay: null,
    hoveredEvent: null,
  };

  async componentDidMount() {
    this.setState({ isLoading: true });
    await this.props.getModes(this.props.devices[0].id);
    await this.props.getWeeklySchedules(this.props.devices[0].id);

    this.setState({
      isLoading: false,
    });
  }

  _onPageChange = (pageIndex) => {
    this.setState({ currentSchedule: pageIndex });
  };

  openPopup = (currentEditableDay) => {
    this.setState({
      isEventPopupOpen: true,
      currentEditableDay,
      eventToEdit: null,
    });
  };

  getEventById = (id) => {
    const { weeklySchedules } = this.props;
    const { currentSchedule } = this.state;
    const schedule = weeklySchedules[currentSchedule];
    const originalEvents = schedule.events;

    return originalEvents.find((e) => e.phantom_id === id || e.id === id);
  };

  editEvent = (eventToEdit, currentEditableDay) => {
    if (!eventToEdit.isEmptyEvent) {
      this.setState({
        isEventPopupOpen: true,
        currentEditableDay,
        eventToEdit: this.getEventById(eventToEdit.phantom_id ? eventToEdit.phantom_id : eventToEdit.id),
      });
    }
  };

  closePopup = () => {
    this.setState({ isEventPopupOpen: false, currentEditableDay: null });
  };

  openCopyPopup = (currentEditableDay) => {
    this.setState({ copyPopupVisible: true, currentEditableDay });
  };

  closeCopyPopup = () => {
    this.setState({ copyPopupVisible: false, currentEditableDay: null });
  };

  openWFPopup = () => {
    this.setState({ changeWFVisible: true });
  };

  closeWFPopup = () => {
    this.setState({ changeWFVisible: false });
  };

  getWeeklyEvents(events, weekFormatId) {
    const weeklyEvents = eventCalculators[weekFormatId]
      ? eventCalculators[weekFormatId].calculateEventsForWeek(events)
      : [];

    return weeklyEvents;
  }

  onEventHover = (hoveredEvent) => {
    this.setState({
      hoveredEvent,
    });
  };

  onEventLeave = (hoveredEvent) => {
    if (hoveredEvent.phantom_id !== this.state.phantom_id && hoveredEvent.id !== this.state.id) {
      return;
    }

    this.setState({
      hoveredEvent: null,
    });
  };

  getEventsCount = (schedule) => {
    const events = schedule.events;
    let totalEventsCount = events.length;
    if (schedule.weekFormat === 'mon-sun') {
      totalEventsCount = events.length * 7;
    }
    if (schedule.weekFormat === 'mon-fri,sat,sun') {
      totalEventsCount =
        events.filter((e) => {
          return e.startDay === 'Monday' || e.startDay === MONDAY;
        }).length *
          5 +
        events.filter((e) => {
          return e.startDay === 'Saturday' || e.startDay === SATURDAY;
        }).length +
        events.filter((e) => {
          return e.startDay === 'Sunday' || e.startDay === SUNDAY;
        }).length;
    }
    if (schedule.weekFormat === 'mon-fri,sat-sun') {
      totalEventsCount =
        events.filter((e) => {
          return e.startDay === 'Monday' || e.startDay === MONDAY;
        }).length *
          5 +
        events.filter((e) => {
          return e.startDay === 'Saturday' || e.startDay === SATURDAY;
        }).length *
          2;
    }
    return totalEventsCount;
  };

  render() {
    const {
      modes,
      weeklySchedules,
      userIsViewer,
      weekFormatAvailable,
      stopTimeAvailable,
      maxEventsNumber,
      selectedSystem,
    } = this.props;
    const { currentSchedule, isLoading } = this.state;
    let schedule,
      scheduleDays,
      weekFormat,
      events = [];

    if (isLoading) return <Spinner dark />;

    if (weeklySchedules.length) {
      schedule = weeklySchedules[currentSchedule];
      weekFormat = WeekFormats.find((fw) => fw.id === schedule.weekFormat);
      scheduleDays = weekFormat.days;
      events = schedule.events;
    }

    const weekFormatId = weekFormat ? weekFormat.id + (stopTimeAvailable ? '-stoptime' : '') : DEFAULT_WEEK_FORMAT_ID;
    const weeklySpots = this.getWeeklyEvents(events, weekFormatId);
    const totalEventsCount = (schedule && this.getEventsCount(schedule)) || 0;

    return (
      <div>
        {scheduleDays ? (
          scheduleDays.map((day) => (
            <Day
              key={`day#${day.name}`}
              day={day}
              stopTimeAvailable={stopTimeAvailable}
              filteredEvents={weeklySpots[humanStringToDayIndex(day.name)] || []}
              modes={modes}
              openCopyPopup={() => this.openCopyPopup(day)}
              openPopup={() => this.openPopup(day)}
              editEvent={(eventToEdit) => this.editEvent(eventToEdit, day)}
              isAmPmHours={isCountryUseAmPmHours(selectedSystem?.address?.country?.countryCode2Alpha)}
              userIsViewer={userIsViewer}
              scheduleDaysCount={scheduleDays.length}
              eventsCountExceeded={totalEventsCount >= maxEventsNumber}
              onEventHover={this.onEventHover}
              onEventLeave={this.onEventLeave}
              hoveredEvent={this.state.hoveredEvent}
            />
          ))
        ) : (
          <p className="error-message">
            <FormattedMessage
              id="scheduling.no-week-format"
              defaultMessage="Your device doesn't provide week formats"
            />
          </p>
        )}

        {weeklySchedules.length > 0 && (
          <Fragment>
            {weekFormatAvailable && (
              <div className="week-format">
                <Button className="button--default" type="submit" onClick={this.openWFPopup} disabled={userIsViewer}>
                  <FormattedMessage id="scheduling.change-week-format-btn" defaultMessage="Change week format" />
                </Button>
                <PremiumFeatureTooltip premiumFeatureType="permissionAccess" />
              </div>
            )}
            {weeklySchedules.length > 1 && (
              <Pagination totalPages={weeklySchedules.length} onPageChange={this._onPageChange} />
            )}
          </Fragment>
        )}

        {!userIsViewer && this.state.isEventPopupOpen && (
          <CreateEventPopup
            currentEditableDay={this.state.currentEditableDay}
            isEventPopupOpen={this.state.isEventPopupOpen}
            closePopup={this.closePopup}
            schedule={schedule}
            getEventsCount={this.getEventsCount}
            eventToEdit={this.state.eventToEdit}
          />
        )}
        {this.state.copyPopupVisible && (
          <CopySchedulePopup
            schedule={schedule}
            scheduleDays={scheduleDays}
            closePopup={this.closeCopyPopup}
            getEventsCount={this.getEventsCount}
            currentEditableDay={this.state.currentEditableDay}
            isPopupVisible={this.state.copyPopupVisible}
          />
        )}
        {this.state.changeWFVisible && (
          <ChangeWeekFormat
            closePopup={this.closeWFPopup}
            schedule={schedule}
            isPopupVisible={this.state.changeWFVisible}
          />
        )}
      </div>
    );
  }
}

export default connect(
  ({ app: { selectedSystem }, devices: { devices }, deviceScheduling: { modes, weeklySchedules } }) => ({
    selectedSystem,
    modes,
    weeklySchedules,
    devices,
  }),
  {
    getModes,
    getWeeklySchedules,
  }
)(WeeklySchedule);
