import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Link as ReactLink } from 'react-router-dom';

class Link extends Component {
  handleClick = (e) => {
    const { preNavigationCallback, goToPage, to } = this.props;

    if (preNavigationCallback) {
      preNavigationCallback();
    }

    return to ? goToPage(to) : e.preventDefault();
  };

  render() {
    // eslint-disable-next-line no-unused-vars
    const { children, className, goToPage, preNavigationCallback, ...restProps } = this.props;

    return (
      <ReactLink className={className} onClick={this.handleClick} {...restProps}>
        {children}
      </ReactLink>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    goToPage: (path) => {
      dispatch(push(path));
    },
  };
};

Link.propTypes = {
  to: PropTypes.string,
  goToPage: PropTypes.func,
  children: PropTypes.any,
  className: PropTypes.string,
  preNavigationCallback: PropTypes.func,
  tagName: PropTypes.string,
};

export default connect(null, mapDispatchToProps)(Link);
