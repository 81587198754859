import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import InputRange from 'react-input-range';

import PremiumFeatureTooltip from '../../layout/PremiumFeatureTooltip';

import 'react-input-range/lib/css/index.css';
import './slider.scss';

let timeout;

function Slider({
  value,
  pendingValue = null,
  disabled,
  minVal,
  maxVal,
  premiumFeatureType,
  disabledDueToOffline,
  onChange,
  valuesOverride,
  scaleVal,
  stepVal,
  changeDelay = 500,
}) {
  const [slideVal, setSlideVal] = useState(0);

  const handleChange = (value) => {
    setSlideVal(value);

    if (!onChange || pendingValue !== null) return;

    clearTimeout(timeout);
    // TODO: Extend timeout with each new change
    timeout = setTimeout(() => {
      timeout = undefined;
      onChange(value);
    }, changeDelay);
  };

  useEffect(() => {
    // Prevent updates while change request is active
    if (timeout) return;

    setSlideVal(value);
  }, [value]);

  return (
    <div className="slider-container" style={{ opacity: pendingValue !== null ? 0.5 : 1 }}>
      <InputRange
        disabled={disabled || disabledDueToOffline || pendingValue !== null}
        maxValue={parseInt(maxVal)}
        minValue={parseInt(minVal)}
        value={isNaN(slideVal) ? parseInt(minVal) : Number(slideVal)}
        onChange={handleChange}
        step={Number(stepVal)}
        formatLabel={(value, type) => {
          if (valuesOverride && type === 'value') {
            const override = valuesOverride.find((ov) => {
              return ov.value * scaleVal === value;
            });
            return override ? override.text : value;
          }

          return value;
        }}
      />
      {disabled && (
        <PremiumFeatureTooltip premiumFeatureType={premiumFeatureType ? premiumFeatureType : 'permissionAccess'} />
      )}
      {disabledDueToOffline && (
        <PremiumFeatureTooltip
          translationId="addDevice.systemSelector.offline"
          defaultMessage="System is currently offline"
        />
      )}
    </div>
  );
}

Slider.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  pendingValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  minVal: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxVal: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  stepVal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  disabledDueToOffline: PropTypes.bool,
  premiumFeatureType: PropTypes.string,
  valuesOverride: PropTypes.array,
  scaleVal: PropTypes.string,
  changeDelay: PropTypes.number,
};

export default Slider;
