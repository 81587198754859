import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Link from '../../inputs/Link';
import moment from 'moment';
import googlePlayBadge from '../../../assets/img/google-play-badge.png';
import appStoreBadge from '../../../assets/img/app-store-badge.png';
import uuid from 'uuid';

import './footer.scss';

const Badge = ({ linksTo, src, alt, className }) => (
  <a target="_blank" rel="noopener noreferrer" href={linksTo}>
    <img src={src} alt={alt} className={className} />
  </a>
);

Badge.propTypes = {
  linksTo: PropTypes.string,
  src: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
};

const topMenuLinks = [
  {
    href: '/smartguide',
    id: 'footer.smartguide',
    title: 'Smart Guide Assistance',
    disabled: ({ hasSystems }) => !hasSystems,
  },
  {
    href: '/system-profile#service-partner',
    id: 'footer.contact-installer',
    title: 'Contact Your Installer',
    disabled: ({ hasSystems }) => !hasSystems,
  },
];

const welcomeTopMenuLinks = [
  {
    href: '/faq',
    id: 'footer.faq',
    title: 'FAQ',
  },
  {
    href: '/contact',
    id: 'footer.contact',
    title: 'Contact',
  },
];

const menuLinks = [
  {
    href: '/about',
    id: 'about.subtitle',
    title: 'About',
  },
  {
    href: '/works-with',
    id: 'slide.title.works-with',
    title: 'Works With',
  },
  {
    href: '/faq',
    id: 'footer.faq',
    title: 'FAQ',
  },
  {
    href: '/contact',
    id: 'footer.contact',
    title: 'Contact',
  },
  {
    href: '/cookies',
    id: 'footer.cookies',
    title: 'Cookies',
  },
  {
    href: '/privacy-policy',
    id: 'footer.privacyPolicy',
    title: 'Privacy policy',
  },
  {
    href: '/terms-of-service',
    id: 'footer.termsOfService',
    title: 'Terms of service',
  },
];

const linkTarget = {
  pathname: '/store',
  key: uuid(),
  state: {
    applied: true,
  },
};

const Footer = ({ isPremiumButtonVisible, hasSystems, isSmartGuideAvailable, isWelcome, languageSelected }) => {
  const welcomeMenuLinks = [
    {
      href: `/legal/about/${languageSelected}`,
      id: 'about.subtitle',
      title: 'About',
    },
    {
      href: `/legal/works-with/${languageSelected}`,
      id: 'slide.title.works-with',
      title: 'Works With',
    },
    {
      href: `/legal/privacy-policy/${languageSelected}`,
      id: 'footer.privacyPolicy',
      title: 'Privacy policy',
    },
    {
      href: `/legal/terms-of-service/${languageSelected}`,
      id: 'footer.termsOfService',
      title: 'Terms of service',
    },
  ];

  const menu = isWelcome ? welcomeMenuLinks : menuLinks;
  const topMenu = isWelcome ? welcomeTopMenuLinks : topMenuLinks;

  return (
    <footer className="footer-container">
      {isPremiumButtonVisible && (
        <div className="footer-premium page-centered">
          <Link to={linkTarget} className="link--secondary footer-premium-button">
            <FormattedMessage id="button.getPremium" defaultMessage="Get Premium" />
          </Link>
        </div>
      )}
      <div className="page-centered">
        <div className="footer-links">
          {!isWelcome && (
            <div className="footer-links-item">
              <div className="footer-link-text">
                <FormattedMessage id="footer.need-help.short" defaultMessage="Need help?" />
              </div>
              {topMenu.map((link, index) => {
                if (link.id === 'footer.smartguide' && !isSmartGuideAvailable) return null;

                return (
                  <div key={index} className="footer-link">
                    {link.disabled && link.disabled({ hasSystems }) ? (
                      <div className="footer-link">
                        <Link keyFor={link.href + link.title} to={link.href || ''}>
                          <FormattedMessage id={link.id} defaultMessage={link.title} />
                        </Link>
                      </div>
                    ) : (
                      <Link keyFor={link.href + link.title} to={link.href || ''}>
                        <FormattedMessage id={link.id} defaultMessage={link.title} />
                      </Link>
                    )}
                  </div>
                );
              })}
            </div>
          )}
          <div className="footer-links-item">
            <div className="footer-link-text" id="footer">
              <FormattedMessage id="footer.quick-links.short" defaultMessage="About application" />
            </div>
            {menu.map((link, index) => (
              <div key={index} className="footer-link">
                <Link keyFor={link.href + link.title} to={link.href || ''} tabIndex="0">
                  <FormattedMessage id={link.id} defaultMessage={link.title} />
                </Link>
              </div>
            ))}
          </div>
        </div>
        <div className="footer-block">
          <div className="footer-text">
            <div className="download-heading">
              <FormattedMessage id="footer.download-app.heading" defaultMessage="Download myUplink app" />
            </div>
            <FormattedMessage
              id="footer.download-app"
              defaultMessage="Download the app and keep track of your system wherever you are."
            />
          </div>
          <div className="footer-badges">
            <Badge
              linksTo="https://itunes.apple.com/app/id1466744542"
              src={appStoreBadge}
              className="app-store-badge"
              alt="App store link"
            />
            <Badge
              linksTo="https://play.google.com/store/apps/details?id=com.myuplink.consumer"
              src={googlePlayBadge}
              className="google-play-badge"
              alt="Get it on Google Play"
            />
          </div>
        </div>
      </div>
      <div className="footer-copyright page-centered">
        <span>Copyright © {moment().year()} myUpTech AB</span>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  isPremiumButtonVisible: PropTypes.bool,
  hasSystems: PropTypes.bool,
  isSmartGuideAvailable: PropTypes.bool,
  isWelcome: PropTypes.bool,
  languageSelected: PropTypes.string,
};

export default Footer;
