import { STRIPE_RECURRING, STRIPE_ONE_TIME } from '../constants';

export const redirectUserToPaymentWindow = (paymentHostedUrl, languageTag, paymentProviderName) => {
  // Check if the payment provider is Stripe, if it is stripe avoid including languageTag
  const isStripePaymentProvider = paymentProviderName === STRIPE_RECURRING || paymentProviderName === STRIPE_ONE_TIME;
  const suffix = !isStripePaymentProvider ? `&language=${languageTag}` : '';
  const paymentProviderUrl = `${paymentHostedUrl}${suffix}`;
  if (paymentProviderUrl) {
    window.open(paymentProviderUrl, '_self', 'width=400,height=500');
  }
};

export const isStripeSpecificBrand = (paidBrandList, brandId) => {
  return paidBrandList.includes(brandId?.toLowerCase());
};
