export const TileChartColors = {
  NIBE_TileChartBackgroundColor: '#FFFFFF',
  NIBE_TileChartLineColor: '#222222',
  NIBE_TileChartTextColor: '#222222',
  MYUPLINK_TileChartBackgroundColor: '#0B1259',
  MYUPLINK_TileChartLineColor: '#FF5722',
  MYUPLINK_TileChartTextColor: '#FFFFFF',
  ENERTECH_TileChartBackgroundColor: '#015384',
  ENERTECH_TileChartLineColor: '#FF6B00',
  ENERTECH_TileChartTextColor: '#FFFFFF',
  CLIMATEMASTER_TileChartBackgroundColor: '#2A6452',
  CLIMATEMASTER_TileChartLineColor: '#67C8AB',
  CLIMATEMASTER_TileChartTextColor: '#FFFFFF',
  CTA_TileChartBackgroundColor: '#0D0D0D',
  CTA_TileChartLineColor: '#CC0033',
  CTA_TileChartTextColor: '#FFFFFF',
  CTC_TileChartBackgroundColor: '#FFFFFF',
  CTC_TileChartLineColor: '#024B2B',
  CTC_TileChartTextColor: '#024B2B',
  CONTURA_TileChartBackgroundColor: '#444444',
  CONTURA_TileChartLineColor: '#EA7125',
  CONTURA_TileChartTextColor: '#FFFFFF',
  HOIAX_TileChartBackgroundColor: '#444444',
  HOIAX_TileChartLineColor: '#1E97D0',
  HOIAX_TileChartTextColor: '#FFFFFF',
  METROTHERM_TileChartBackgroundColor: '#364A4F',
  METROTHERM_TileChartLineColor: '#D52B1E',
  METROTHERM_TileChartTextColor: '#FFFFFF',
  AIT_TileChartBackgroundColor: '#3F3F3F',
  AIT_TileChartLineColor: '#A61F2C',
  AIT_TileChartTextColor: '#FFFFFF',
  NIBEF_TileChartBackgroundColor: '#FFFFFF',
  NIBEF_TileChartLineColor: '#222222',
  NIBEF_TileChartTextColor: '#FFFFFF',
  NOVELAN_TileChartBackgroundColor: '#3F3F3F',
  NOVELAN_TileChartLineColor: '#95C03D',
  NOVELAN_TileChartTextColor: '#FFFFFF',
  CETETHERM_TileChartBackgroundColor: '#1A2737',
  CETETHERM_TileChartLineColor: '#71B750',
  CETETHERM_TileChartTextColor: '#FFFFFF',
  JASPI_TileChartBackgroundColor: '#131313',
  JASPI_TileChartLineColor: '#983222',
  JASPI_TileChartTextColor: '#FFFFFF',
  JAMA_TileChartBackgroundColor: '#131313',
  JAMA_TileChartLineColor: '#2B3990',
  JAMA_TileChartTextColor: '#FFFFFF',
  AIRSITE_TileChartBackgroundColor: '#00509A',
  AIRSITE_TileChartLineColor: '#FF7100',
  AIRSITE_TileChartTextColor: '#FFFFFF',
  BIAWAR_TileChartBackgroundColor: '#4B4B4B',
  BIAWAR_TileChartLineColor: '#4BB945',
  BIAWAR_TileChartTextColor: '#FFFFFF',
  MHI_TileChartBackgroundColor: '#2B2B2B',
  MHI_TileChartLineColor: '#7F9CA6',
  MHI_TileChartTextColor: '#FFFFFF',
  DZD_TileChartBackgroundColor: '#FFFFFF',
  DZD_TileChartLineColor: '#A81524',
  DZD_TileChartTextColor: '#585858',
  TEMPEFF_TileChartBackgroundColor: '#0076BB',
  TEMPEFF_TileChartLineColor: '#F15623',
  TEMPEFF_TileChartTextColor: '#FFFFFF',
  TIKI_TileChartBackgroundColor: '#3e4951',
  TIKI_TileChartLineColor: '#2d88a0',
  TIKI_TileChartTextColor: '#FFFFFF',
};
