import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ItemDate from '../ItemDate';
import ServicePartnerInfo from '../ServicePartnerInfo';
import { connect } from 'react-redux';
import { resetAlarm, activateAidModeForAlarm } from './actions';
import './alarmsListItem.scss';
import ToggleSwitch from '../../../../inputs/ToggleSwitch';
import { FormattedMessage } from 'react-intl';
import { inboxTab } from '../../constants';
import PremiumFeatureTooltip from '../../../../layout/PremiumFeatureTooltip';
import { Button, Spinner } from 'jpi-cloud-web-ui-components';

function getAlarmStatus(severity) {
  switch (severity) {
    case 1:
      return <i className="fas fa-exclamation-triangle"></i>;
    case 2:
      return <i className="fas fa-info-circle"></i>;
    default:
      return null;
  }
}

const AlarmTimestamp = ({ alarm, className, isAlarmItemReset }) => {
  const millisecondsInSecond = 1000;

  const resetTimestamp = isAlarmItemReset ? (
    <div>
      <span className="item-info-wrapper__alarm-message">
        <FormattedMessage id={'alarms.message-reset-at'} defaultMessage="Reset at:" />
      </span>
      <ItemDate timestamp={alarm.datetime * millisecondsInSecond} />
    </div>
  ) : null;

  const createdTimestamp = (
    <div className="item-date-wrapper">
      <span className="item-info-wrapper__alarm-message">
        <FormattedMessage id={'alarms.message-occured-at'} defaultMessage="Occurred at:" />
      </span>
      <ItemDate timestamp={alarm.createdDatetime * millisecondsInSecond} />
    </div>
  );

  return (
    <div className={className}>
      {createdTimestamp}
      {resetTimestamp}
    </div>
  );
};

AlarmTimestamp.propTypes = {
  className: PropTypes.string,
  alarm: PropTypes.object.isRequired,
  isAlarmItemReset: PropTypes.bool.isRequired,
};

AlarmTimestamp.defaultProps = {
  className: '',
};

const ResetAlarmButton = ({
  item,
  type,
  alarmItemIsReseting,
  onResetButtonClick,
  resetAlarmIsSupported,
  isManageEnabled,
  isAlarmItemReset,
}) => {
  if (!resetAlarmIsSupported || !item || !item.severity || item.severity !== 1) {
    return null;
  }

  return (
    <div className="resetButton">
      <FormattedMessage id={'alarms.reset-alarm'} defaultMessage="Reset Alarm">
        {(value) => (
          <>
            <Button
              type="submit"
              className="button--secondary"
              disabled={alarmItemIsReseting || !isManageEnabled || isAlarmItemReset}
              onClick={() => onResetButtonClick(item, type)}
            >
              {value}
            </Button>
            {!isManageEnabled && <PremiumFeatureTooltip premiumFeatureType="manage" />}
          </>
        )}
      </FormattedMessage>
      {alarmItemIsReseting ? <Spinner dark /> : null}
    </div>
  );
};

ResetAlarmButton.propTypes = {
  item: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  isManageEnabled: PropTypes.bool.isRequired,
  alarmItemIsReseting: PropTypes.bool.isRequired,
  onResetButtonClick: PropTypes.func.isRequired,
  resetAlarmIsSupported: PropTypes.bool.isRequired,
  isAlarmItemReset: PropTypes.bool.isRequired,
};

const ActivateAidModeToggleSwitch = ({
  item,
  activatingAidModeForAlarmItem,
  onActivateAidModeButtonClick,
  aidModeIsSupported,
}) => {
  if (!aidModeIsSupported || !item || !item.severity || item.severity !== 1) {
    return null;
  }

  return (
    <>
      <FormattedMessage id={'alarms.activate-aid-mode'} defaultMessage="Activate aid mode">
        {(activateAidModeString) => (
          <FormattedMessage id={'alarms.aid-mode-activated'} defaultMessage="Aid mode activated">
            {(aidModeActivatedString) => (
              <ToggleSwitch
                isChecked={activatingAidModeForAlarmItem ? item.activeAidMode !== 'on' : item.activeAidMode === 'on'}
                disabled={activatingAidModeForAlarmItem}
                onChange={() => onActivateAidModeButtonClick(item)}
                textOff={activateAidModeString}
                textOn={aidModeActivatedString}
              />
            )}
          </FormattedMessage>
        )}
      </FormattedMessage>
      {activatingAidModeForAlarmItem ? <Spinner dark={true} /> : null}
    </>
  );
};

ActivateAidModeToggleSwitch.propTypes = {
  item: PropTypes.object.isRequired,
  activatingAidModeForAlarmItem: PropTypes.bool.isRequired,
  onActivateAidModeButtonClick: PropTypes.func.isRequired,
  aidModeIsSupported: PropTypes.bool.isRequired,
};

class AlarmsListItem extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    resetedAlarms: PropTypes.array,
    alarmsInAidMode: PropTypes.array,
    deletingAlarmsId: PropTypes.array,
    resetAlarm: PropTypes.func.isRequired,
    activateAidModeForAlarm: PropTypes.func.isRequired,
    resetAlarmIsSupported: PropTypes.bool.isRequired,
    aidModeIsSupported: PropTypes.bool.isRequired,
    userIsViewer: PropTypes.bool.isRequired,
    isManageEnabled: PropTypes.bool.isRequired,
  };

  state = {
    isExtended: false,
  };

  onExtendButtonClick = () => {
    this.setState({ ...this.state, isExtended: !this.state.isExtended });
  };

  onResetButtonClick = async (item, type) => {
    if (type === inboxTab) {
      await this.props.resetAlarm(item);
    }
  };

  onActivateAidModeButtonClick = async (item) => {
    await this.props.activateAidModeForAlarm(item);
  };

  splitDescriptionStatements = (description = '') =>
    description.split('\n').map((_, i) => (
      <p className="item-message" aria-label={`description-segment-${i}`} key={i}>
        {_.trim()}
      </p>
    ));

  render() {
    const {
      item,
      type,
      userIsViewer,
      resetedAlarms,
      alarmsInAidMode,
      isManageEnabled,
      aidModeIsSupported,
      resetAlarmIsSupported,
    } = this.props;
    const isExtended = this.state.isExtended;
    const isAlarmItemReset = item.status !== 'Active';
    const millisecondsInSecond = 1000;

    const alarmItemIsReseting = resetedAlarms.some((a) => a.deviceId === item.deviceId && a.alarmId === item.alarmId);
    const activatingAidModeForAlarmItem = alarmsInAidMode.some((a) => a.deviceId === item.deviceId);

    return (
      <div className={isExtended ? 'item-wrapper item-wrapper__extended' : 'item-wrapper'}>
        <div className="item-info-wrapper">
          <div className="item-info-wrapper__topContent">
            <div className="item-info-wrapper__topContent--left">
              <div className="item-info-wrapper__item-icon-wrapper">
                <div className="item-icon">{getAlarmStatus(item.severity)}</div>
                <div className="item-id">{item.alarmId}</div>
              </div>
              <div className="item-info-wrapper__topContent--text">
                <div className={`item-name ${isExtended ? 'item-name--extended' : ''}`}>
                  {item.header + ` - ${item.equipName}`}
                </div>
                {!isExtended && <ItemDate timestamp={item.createdDatetime * millisecondsInSecond} />}
                {isExtended && (
                  <AlarmTimestamp
                    alarm={item}
                    isAlarmItemReset={isAlarmItemReset}
                    className="item-info-wrapper__alarm-timestamp"
                  />
                )}
              </div>
            </div>
            <div className="item-info-wrapper__topContent--right">
              <div className="expand-button" onClick={this.onExtendButtonClick}>
                {isExtended ? <span className="caret caret__up"></span> : <span className="caret caret__down"></span>}
              </div>
            </div>
          </div>

          <div className="messages-wrapper">
            <div className="messages">{this.splitDescriptionStatements(item.description)}</div>
            {isExtended && item.servicePartner && (
              <div className="partner-info">
                <ServicePartnerInfo
                  name={item.servicePartner.name}
                  contactPhone={item.servicePartner.contactPhone}
                  contactEmail={item.servicePartner.contactEmail}
                />
              </div>
            )}
            {type === inboxTab && !userIsViewer && (
              <div className="discard-button-wrapper">
                <ResetAlarmButton
                  item={item}
                  type={type}
                  isManageEnabled={isManageEnabled}
                  alarmItemIsReseting={alarmItemIsReseting}
                  isAlarmItemReset={isAlarmItemReset}
                  onResetButtonClick={this.onResetButtonClick}
                  resetAlarmIsSupported={resetAlarmIsSupported}
                />
                <ActivateAidModeToggleSwitch
                  item={item}
                  activatingAidModeForAlarmItem={activatingAidModeForAlarmItem}
                  onActivateAidModeButtonClick={this.onActivateAidModeButtonClick}
                  aidModeIsSupported={aidModeIsSupported}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  ({ alarms, features: { deviceFeatures, premiumFeatures } }) => ({
    ...alarms,
    resetAlarmIsSupported: deviceFeatures && deviceFeatures.resetAlarm,
    aidModeIsSupported: deviceFeatures && deviceFeatures.aidMode,
    isManageEnabled: premiumFeatures && premiumFeatures.manage,
  }),
  {
    resetAlarm,
    activateAidModeForAlarm,
  }
)(AlarmsListItem);
