import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Spinner } from 'jpi-cloud-web-ui-components';
import Failed from './components/Failed';
import Success from './components/Success';
import Updating from './components/Updating';
import Available from './components/Available';
import Restarting from './components/Restarting';
import RequestUpdate from './components/RequestUpdate';

import { getDeviceUpdateStatus, setFwInfoLoading, setSelectedDevice, getDevices } from '../../actions';

import './selecteddevice.scss';

class SelectedDevice extends React.Component {
  static propTypes = {
    updateInfo: PropTypes.object,
    loadingUpdateInfo: PropTypes.bool.isRequired,
    selectedDevice: PropTypes.object.isRequired,
    setFwInfoLoading: PropTypes.func.isRequired,
    getDeviceUpdateStatus: PropTypes.func.isRequired,
    deviceFeatures: PropTypes.object.isRequired,
    setSelectedDevice: PropTypes.func.isRequired,
    selectedSystem: PropTypes.object,
    getDevices: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.pollingId = null;
    this.pollingDelay = 3000; // 03 seconds
    this.refreshStatus = this.refreshStatus.bind(this);
  }

  state = {
    initialFirmwareStatus: '',
  };

  componentDidMount() {
    this.refreshStatus(true);
    this.pollingId = setInterval(this.refreshStatus, this.pollingDelay);
  }

  refreshStatus(setLoading = false) {
    this.props.setFwInfoLoading(setLoading);
    const selectedDevice = this.props.selectedDevice.id || this.props.selectedDevice.gatewayDeviceId;
    this.props.getDeviceUpdateStatus(selectedDevice);
  }

  componentWillUnmount() {
    clearInterval(this.pollingId);
    this.pollingId = null;
  }

  componentDidUpdate(prevProps) {
    const prevPropsInfo = this.getFirmwareInfo(prevProps);

    if (!prevPropsInfo) {
      this.setState({ initialFirmwareStatus: prevPropsInfo.fwUpdateStatus });
    }
    //remove initialFirmwareStatus
    if (
      this.state.initialFirmwareStatus === 'success' &&
      prevPropsInfo.fwUpdateStatus !== this.state.initialFirmwareStatus
    ) {
      this.setState({ initialFirmwareStatus: '' });
    }
  }

  getFirmwareInfo(props) {
    return (
      props.updateInfo ||
      props.selectedDevice.firmware ||
      (props.selectedDevice.slaves && props.selectedDevice.slaves.length && props.selectedDevice.slaves[0].firmware)
    );
  }

  getCurrentStatus() {
    const { updateInfo, selectedDevice, deviceFeatures } = this.props;

    const isGatewaySelected = !!selectedDevice.id; // selected slave device has `slaveDeviceId` set instead of `id`
    const fwUpdateInfo = isGatewaySelected
      ? updateInfo
      : updateInfo.slaves.find((s) => s.slaveDeviceId === selectedDevice.slaveDeviceId);

    const fwUpdateInProgress = (fwUpdateInfo && fwUpdateInfo.fwUpdateInProgress) || false;
    const status = fwUpdateInfo && fwUpdateInfo.fwUpdateStatus;
    const progress = fwUpdateInfo && parseInt(fwUpdateInfo.fwUpdateSubStatus, 10);

    if (fwUpdateInProgress === false) {
      if (status === 'confirmPending') {
        return ['confirmPending', 0];
      }

      if (fwUpdateInfo && !fwUpdateInfo.desiredFwVersion.length && !fwUpdateInfo.currentFwVersion.length) {
        this.props.getDevices(this.props.selectedSystem.id);
        this.props.setSelectedDevice();
      }

      if (deviceFeatures.requestUpdate && fwUpdateInfo && fwUpdateInfo.desiredFwVersion.length) {
        return ['updateConfirm', 0];
      }

      return ['available', 0];
    }
    // If status is downloading or flashing, show progress bar.
    if (['downloading', 'flashing'].includes(status)) {
      // if flashing is finished(100), show rebooting until status is success
      if ('flashing' === status && progress === 100) {
        return ['rebooting', 0];
      }
      return [status, progress];
    }
    //handle when update firmware page loads with success status
    if (this.state.initialFirmwareStatus === 'success') {
      if (this.props.deviceFeatures.requestUpdate) {
        return ['updateConfirm', 0];
      }

      return ['available', 0];
    }
    return [status, 0];
  }

  getCurrentScreen(currentStatus) {
    switch (currentStatus) {
      case 'started':
      case 'downloading':
      case 'flashing':
        return Updating;
      case 'rebooting':
        return Restarting;
      case 'updateConfirm':
      case 'confirmPending':
        return RequestUpdate; // yes / no / download
      case 'success':
        return Success;
      case 'failed':
        return Failed;
      case 'available':
      default:
        return Available; // only download
    }
  }

  render() {
    if (this.props.loadingUpdateInfo) {
      return (
        <div className="device">
          <div className="device__wrapper">
            <Spinner />
          </div>
        </div>
      );
    }
    const [status, progress] = this.getCurrentStatus();
    const CurrentScreen = this.getCurrentScreen(status);
    return (
      <div className="selected-device">
        <div className="device__wrapper">
          <CurrentScreen progress={progress} status={status} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ app: { selectedSystem }, devices, features: { deviceFeatures } }) => ({
  ...devices,
  deviceFeatures,
  selectedSystem,
});

const mapDispatchToProps = { getDeviceUpdateStatus, setFwInfoLoading, setSelectedDevice, getDevices };

export default connect(mapStateToProps, mapDispatchToProps)(SelectedDevice);
