export const MAX_SYSTEMS_SELECTED = 50;

export const systemCells = [
  {
    id: 'group.systems-list.select',
  },
  {
    id: 'group.systems-list.system',
    defaultMessage: 'System',
  },
  {
    id: 'group.systems-list.serial-number',
    defaultMessage: 'Serial Number',
  },
  {
    id: 'group.systems-list.search',
  },
];
