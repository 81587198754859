import * as api from '../../../api';

export const CONFIRM_EMAIL_UPDATE_REQUEST = 'email-update-confirmation/CONFIRM_EMAIL_UPDATE_REQUEST';
export const CONFIRM_EMAIL_UPDATE_RESPONSE_SUCCEEDED =
  'email-update-confirmation/CONFIRM_EMAIL_UPDATE_RESPONSE_SUCCEEDED';
export const CONFIRM_EMAIL_UPDATE_RESPONSE_FAILED = 'email-update-confirmation/CONFIRM_EMAIL_UPDATE_RESPONSE_FAILED';

export const confirmEmailUpdate = (token) => async (dispatch) => {
  dispatch({ type: CONFIRM_EMAIL_UPDATE_REQUEST });
  try {
    if (token) {
      await api.confirmEmailUpdate(token);
      dispatch({ type: CONFIRM_EMAIL_UPDATE_RESPONSE_SUCCEEDED });
      return true;
    }
    dispatch({ type: CONFIRM_EMAIL_UPDATE_RESPONSE_FAILED, error: 'token_undefined' });
  } catch (e) {
    dispatch({ type: CONFIRM_EMAIL_UPDATE_RESPONSE_FAILED, error: e.response.data.description });
  }
  return false;
};
