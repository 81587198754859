// @ts-check
import { MY_UPLINK_CLIENT_ID, CONFIG_FORM, API } from './index';
import { getCodeVerifierAndRemoveFromLocalStorage } from '../localStorage/authentication';
import { generateAzureB2CCodeVerifierAndCodeChallenge } from '../utils/azureB2C';
import { getCurrentCountryCode } from './countries';

export const AZURE_B2C_TENANT_ID = process.env.AZURE_B2C_TENANT_ID || '';
export const AZURE_B2C_CLIENT_ID = process.env.AZURE_B2C_CLIENT_ID || '';

const azureB2CBaseUrl = `https://${AZURE_B2C_TENANT_ID}.b2clogin.com/${AZURE_B2C_TENANT_ID}.onmicrosoft.com`;
const azureB2CScope = `https://${AZURE_B2C_TENANT_ID}.onmicrosoft.com/api/READSYSTEM https://${AZURE_B2C_TENANT_ID}.onmicrosoft.com/api/WRITESYSTEM`;
const azureB2CPolicyName = 'B2C_1A_LOGIN_FLOW_EMAIL_CONSUMERS';

/**
 * Generates and returns the Azure B2C login link with specified options.
 *
 * @async
 * @param {{redirectTo?: string, language?: string}} options - The options for generating the login link.
 * @returns {Promise<string>} The Azure B2C login link.
 */
export const getAzureB2CLoginLink = async ({ redirectTo, language } = {}) => {
  const { codeChallenge } = await generateAzureB2CCodeVerifierAndCodeChallenge();
  const countryCode = getCurrentCountryCode();

  const baseUrl = `${azureB2CBaseUrl}/${azureB2CPolicyName}/oauth2/v2.0/authorize`;
  const params = new URLSearchParams({
    client_id: AZURE_B2C_CLIENT_ID,
    response_type: 'code',
    redirect_uri: `${window.location.origin}/login`,
    response_mode: 'query',
    scope: azureB2CScope,
    state: redirectTo || '',
    lang: language || '',
    code_challenge: codeChallenge,
    code_challenge_method: 'S256',
    clientId: MY_UPLINK_CLIENT_ID,
    countryCode: countryCode || '',
  });

  return `${baseUrl}?${params.toString()}`;
};

/**
 * Issues Azure B2C tokens using an authorization code.
 *
 * @param {string} code - The authorization code obtained after user authentication.
 * @returns {Promise<AccessToken>} A Promise that resolves when the tokens are issued successfully.
 */
export const issueAzureB2CTokens = (code) => {
  const codeVerifier = getCodeVerifierAndRemoveFromLocalStorage();

  const baseUrl = `${azureB2CBaseUrl}/${azureB2CPolicyName}/oauth2/v2.0/token`;
  const body = new URLSearchParams({
    grant_type: 'authorization_code',
    client_id: AZURE_B2C_CLIENT_ID,
    scope: azureB2CScope,
    code: code,
    redirect_uri: `${window.location.origin}/login`,
    code_verifier: codeVerifier,
  });
  return API.post(baseUrl, body, CONFIG_FORM);
};

/**
 * Reissues Azure B2C tokens using a refresh token.
 *
 * @param {string} refreshToken - The refresh token used to obtain new tokens.
 * @returns {Promise<AccessToken>} A Promise that resolves when the tokens are reissued successfully.
 */
export const reissueAzureB2CTokens = (refreshToken) => {
  const baseUrl = `${azureB2CBaseUrl}/${azureB2CPolicyName}/oauth2/v2.0/token`;
  const body = new URLSearchParams({
    grant_type: 'refresh_token',
    client_id: AZURE_B2C_CLIENT_ID,
    refresh_token: refreshToken,
  });
  return API.post(baseUrl, body, CONFIG_FORM);
};

/**
 *  Generates and returns the Azure B2C logout link.
 *
 * @returns {string} The Azure B2C logout link.
 */
export const getAzureB2CLogoutLink = () => {
  const baseUrl = `${azureB2CBaseUrl}/oauth2/v2.0/logout`;
  const params = new URLSearchParams({
    p: azureB2CPolicyName,
    post_logout_redirect_uri: `${window.location.origin}/login`,
  });

  return `${baseUrl}?${params.toString()}`;
};

/**
 * @typedef {Object} AccessToken
 * @property {string} access_token
 * @property {number} expires_in
 * @property {number} expires_on
 * @property {number} not_before
 * @property {string} profile_info
 * @property {string} refresh_token
 * @property {number} refresh_token_expires_in
 * @property {string} resource
 * @property {string} scope
 * @property {string} token_type
 */
