import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import './systemProfileInfoItem.scss';

const SystemProfileInfoItem = ({ className, itemName, children }) =>
  children && (
    <div className={'system-profile-info-item ' + className}>
      <div className="system-profile-info-item-label">
        <FormattedMessage id={'label.' + itemName} defaultMessage={itemName} />:
      </div>
      <div className="system-profile-info-item-value">{children}</div>
    </div>
  );

SystemProfileInfoItem.propTypes = {
  className: PropTypes.string.isRequired,
  itemName: PropTypes.string.isRequired,
  children: PropTypes.any,
};

export default SystemProfileInfoItem;
