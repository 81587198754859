import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { setSelectedDevice } from '../../../../actions';

import './updating.scss';

class Updating extends React.PureComponent {
  static propTypes = {
    progress: PropTypes.number.isRequired,
    selectedDevice: PropTypes.object.isRequired,
    setSelectedDevice: PropTypes.func.isRequired,
  };
  render() {
    return (
      <div className="update-updating">
        <div className="row">
          <div className="col-sm-8">
            <div className="device-name">{this.props.selectedDevice.name}</div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 progress-section">
            <strong>
              <FormattedMessage id="devices.updating" defaultMessage="Updating" />
            </strong>
            <div className="progress">
              <div className="progress-bar" style={{ width: `${this.props.progress}%` }}></div>
            </div>
            <FormattedMessage
              id="devices.almost-there"
              defaultMessage="Almost there, we will let you know when it is ready."
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ devices: { selectedDevice } }) => ({ selectedDevice });

const mapDispatchToProps = {
  setSelectedDevice,
};

export default connect(mapStateToProps, mapDispatchToProps)(Updating);
