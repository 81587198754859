import * as api from '../../../api';

export const GET_USERS_AGREEMENTS_REQUEST = 'agreements/GET_USERS_AGREEMENTS_REQUEST';
export const GET_USERS_AGREEMENTS_RESPONSE = 'agreements/GET_USERS_AGREEMENTS_RESPONSE';
export const getUsersAgreements = (userId) => async (dispatch) => {
  dispatch({ type: GET_USERS_AGREEMENTS_REQUEST });

  try {
    const res = await api.getUserAgreements(userId);
    dispatch({ type: GET_USERS_AGREEMENTS_RESPONSE, agreements: res.data });
  } catch (e) {
    dispatch({ type: GET_USERS_AGREEMENTS_RESPONSE, agreements: [] });
  }
};

export const ACCEPT_AGREEMENT_REQUEST = 'agreements/ACCEPT_AGREEMENT_REQUEST';
export const ACCEPT_AGREEMENT_RESPONSE = 'agreements/ACCEPT_AGREEMENT_RESPONSE';
export const acceptAgreement = (userId, agreement) => async (dispatch) => {
  dispatch({ type: ACCEPT_AGREEMENT_REQUEST });

  const res = await api.acceptAgreement(userId, agreement);
  if (res.status === 204) {
    dispatch({ type: ACCEPT_AGREEMENT_RESPONSE, agreement });
  }
};
