import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '../../Link';
import { FormattedMessage } from 'react-intl';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { confirmEmailUpdate } from './actions';
import { Spinner } from 'jpi-cloud-web-ui-components';

const getTokenFromQueryString = (qs) => {
  const parsedQueryString = queryString.parse(qs);
  return parsedQueryString.token;
};

const NewEmailConfirmed = ({ emailConfirmed, userLoggedIn }) => {
  return (
    <>
      <h1 className="form__heading confirm-email">
        {emailConfirmed ? (
          <FormattedMessage
            id="confirmEmailUpdate.emailUpdated"
            defaultMessage="Your email has been changed successfully."
          />
        ) : (
          <FormattedMessage id="confirmEmailUpdate.linkExpired" defaultMessage="Link is wrong or expired" />
        )}
      </h1>
      {!userLoggedIn && (
        <div className="form__paragraph form__paragraph--no-padding-top">
          <div className="button-wrapper--large">
            <Link tagName="button" className="button button--primary" to="/login">
              <FormattedMessage id="login.login" defaultMessage="Log in" />
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

NewEmailConfirmed.propTypes = {
  emailConfirmed: PropTypes.bool.isRequired,
  userLoggedIn: PropTypes.bool.isRequired,
};

class ConfirmEmailUpdate extends React.Component {
  static propTypes = {
    confirmEmailUpdate: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    userLoggedIn: PropTypes.bool,
    loading: PropTypes.bool,
    emailConfirmed: PropTypes.bool,
  };

  async componentDidMount() {
    const token = getTokenFromQueryString(this.props.location.search);
    await this.props.confirmEmailUpdate(token);
  }

  render() {
    const { userLoggedIn, emailConfirmed, loading } = this.props;

    return (
      <div className="form">
        {!loading ? (
          <NewEmailConfirmed emailConfirmed={emailConfirmed} userLoggedIn={userLoggedIn} />
        ) : (
          <Spinner dark={true} />
        )}
      </div>
    );
  }
}

export default connect(
  ({ app: { userLoggedIn }, confirmEmailUpdate }) => ({
    ...confirmEmailUpdate,
    userLoggedIn,
  }),
  {
    confirmEmailUpdate,
  }
)(ConfirmEmailUpdate);
