import {
  RENAME_PROFILE_RESPONSE_SUCCEEDED,
  RENAME_PROFILE_REQUEST_FAILED,
  RESET_PROFILE_FORM_ERROR_REQUEST,
} from './actions';

const initialState = {
  profileError: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RENAME_PROFILE_RESPONSE_SUCCEEDED:
      return { ...state, profileError: null };
    case RENAME_PROFILE_REQUEST_FAILED:
      return { ...state, profileError: action.error };
    case RESET_PROFILE_FORM_ERROR_REQUEST:
      return { ...state, profileError: null };
    default:
      return state;
  }
};
