import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import './trialsubscription.scss';
import Price from '../../../../../../Shop/components/Price';

const TrialSubscription = ({ subscriptionValidationDate, currencyCode, price }) => {
  const trialDueDate = moment(subscriptionValidationDate).format('MMMM DD YYYY');

  return (
    <div className="trialSubscription">
      <div className="trialSubscription__description">
        <FormattedMessage
          id="shop.page.recurringTrialSubscriptionExisting"
          defaultMessage="You have an active trial for this product, if you wish to continue using this service make sure you have added payment details when the trial ends."
        />
      </div>
      <div className="trialSubscription__title">
        <FormattedMessage id="shop.page.trialEnding" defaultMessage="Your Trial period will end on" />
        &#058;
      </div>
      <div className="trialSubscription__border" />
      <div className="trialSubscription__dueDateAndPrice">
        <p className="trialSubscription__trialDueDate">
          <FormattedMessage id="shop.page.subscriptionDetails.dueDate" defaultMessage="Due" />
          &emsp;
          {trialDueDate}
        </p>

        <p className="trialSubscription__trialDueDate">
          &emsp;
          <Price currency={currencyCode} price={price} />{' '}
        </p>
      </div>
      <div className="trialSubscription__border" />
    </div>
  );
};

TrialSubscription.propTypes = {
  subscriptionValidationDate: PropTypes.string.isRequired,
  currencyCode: PropTypes.any.isRequired,
  price: PropTypes.string.isRequired,
};
export default TrialSubscription;
