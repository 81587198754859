import React from 'react';
import PropTypes from 'prop-types';
import AlarmsListItem from './AlarmsListItem';

const AlarmsList = ({ items, type, userIsViewer }) => (
  <div className="list">
    {items.map((item) => {
      return <AlarmsListItem type={type} key={item.id} item={item} userIsViewer={userIsViewer} />;
    })}
  </div>
);

AlarmsList.propTypes = {
  items: PropTypes.instanceOf(Array).isRequired,
  type: PropTypes.string.isRequired,
  userIsViewer: PropTypes.bool.isRequired,
};

export default AlarmsList;
