import { humanStringToDayIndex, MONDAY, SATURDAY, SUNDAY } from './weekFormats';

const getWeekPlaceholder = () => [[], [], [], [], [], [], []];

const colors = [
  '#33658a',
  '#ff8a50',
  '#c5d5e2',
  '#abafd0',
  '#000051',
  '#5c7780',
  '#70A37F',
  '#b6967d',
  '#FF5722',
  '#5f9192',
  '#6c8dc3',
  '#D7D0A7',
  '#F8E16C',
  '#1A237E',
  '#A26769',
  '#ff9472',
  '#C2D8B9',
];

class SunMonTueWedThuFriSat {
  getColorByModeId(modeId) {
    return modeId % colors.length;
  }

  getSortedEvents(events) {
    const eventsPlaceholder = getWeekPlaceholder();

    events.forEach((e) => {
      const startDay = humanStringToDayIndex(e.startDay);
      if (eventsPlaceholder[startDay]) {
        eventsPlaceholder[startDay].push({
          ...e,
          color: e.modeId === 0 ? '#C4C4C4' : colors[this.getColorByModeId(e.modeId)],
        });
      }
    });

    return eventsPlaceholder.map((eventListByDay) =>
      eventListByDay.sort((eventLeft, eventRight) => eventLeft.startTime.localeCompare(eventRight.startTime))
    );
  }

  __getInitialEvent() {
    return {
      startDay: MONDAY,
      startTime: '00:00:00',
      is_mock: true,
    };
  }

  calculateEventsForWeek(events) {
    const sortedEvents = this.getSortedEvents(events);

    const result = getWeekPlaceholder();
    let eventFrom = this.__getInitialEvent();

    let eventToFill = this.findTheFirstEvent(sortedEvents);
    let lastEvent = false;
    while (eventToFill) {
      if (events.length > 1 && !lastEvent) {
        const endEvent = { ...this.findTheLastEvent(sortedEvents), ...eventFrom };
        this.fillByVisibleEvents(endEvent, eventToFill, result);
      } else {
        this.fillByVisibleEvents(eventFrom, eventToFill, result);
      }

      lastEvent = eventToFill;
      eventFrom = eventToFill;
      eventToFill = this.findNextEvent(eventFrom, sortedEvents);
      if (lastEvent === eventToFill) {
        break;
      }
    }

    if (lastEvent) {
      this.fillByVisibleEvents(eventFrom, { startDay: SUNDAY, startTime: '23:59:59', is_mock: true }, result);
    }

    return result;
  }

  _findEventWithinDay(eventFrom, dayWithEvents, sundayAtTheAnd = false) {
    const dayToStart = humanStringToDayIndex(eventFrom.startDay);

    for (const scheduledEvent of dayWithEvents) {
      const timeCompare = scheduledEvent.startTime.localeCompare(eventFrom.startTime);
      const scheduledStartDay = humanStringToDayIndex(scheduledEvent.startDay);

      if (scheduledStartDay === dayToStart && scheduledEvent.startTime === eventFrom.startTime) {
        continue;
      }

      let startDayIsLower = dayToStart < scheduledStartDay;
      if (sundayAtTheAnd && !startDayIsLower && dayToStart == SATURDAY && scheduledStartDay === SUNDAY) {
        startDayIsLower = true;
      }

      if (startDayIsLower || timeCompare > 0) {
        return scheduledEvent;
      }
    }

    return null;
  }

  findNextEvent(eventFrom, sortedEvents) {
    const dayToStart = humanStringToDayIndex(eventFrom.startDay);
    for (let i = dayToStart; i < sortedEvents.length; ++i) {
      const event = this._findEventWithinDay(eventFrom, sortedEvents[i]);
      if (event) {
        return event;
      }
    }

    return null;
  }

  findTheLastEvent(sortedEvents) {
    for (let i = SUNDAY; i > -1; --i) {
      if (sortedEvents[i].length) {
        return sortedEvents[i][sortedEvents[i].length - 1];
      }
    }
  }

  findTheFirstEvent(sortedEvents) {
    for (let i = MONDAY; i <= SUNDAY; ++i) {
      if (sortedEvents[i].length) {
        return sortedEvents[i][0];
      }
    }
  }

  __prepareEvent(source, eventFromFill, eventToFill) {
    if (!eventFromFill.is_mock) {
      return { ...eventFromFill, ...source };
    }

    if (!eventToFill.is_mock) {
      return { ...eventToFill, ...source };
    }
  }

  fillByVisibleEvents(eventFromFill, eventToFill, results) {
    let currentEvent = this.__prepareEvent(eventFromFill, eventFromFill, eventToFill);
    const startDayFrom = humanStringToDayIndex(eventFromFill.startDay);
    const startDayTo = humanStringToDayIndex(eventToFill.startDay);

    for (let fillDay = startDayFrom; fillDay <= startDayTo; ++fillDay) {
      if (startDayTo === fillDay && eventToFill.startTime === '00:00:00') {
        break;
      }

      if (!this._eventInSlot(results, fillDay, currentEvent)) {
        results[fillDay].push(currentEvent);
      }

      currentEvent = {
        ...currentEvent,
        startDay: fillDay + 1,
        startTime: '00:00:00',
      };
    }
  }

  _eventInSlot(slots, fillDay, currentEvent) {
    return slots[fillDay].find(
      (e) =>
        (e.id == currentEvent.id || e.phantom_id == currentEvent.phantom_id) && e.startTime == currentEvent.startTime
    );
  }
}

class SunMonTueWedThuFriSatStopTime extends SunMonTueWedThuFriSat {
  splitEventsToNextDay(sortedEvents) {
    sortedEvents.forEach((dayEvents, dayIndex) => {
      dayEvents.forEach((event) => {
        const timeCompare = event.startTime.localeCompare(event.stopTime);
        if (!event.splitMark && timeCompare >= 0 && event.stopTime != '00:00:00') {
          // split to next day
          const nextDay = dayIndex < SUNDAY ? dayIndex + 1 : MONDAY;
          sortedEvents[nextDay].push({
            ...event,
            startTime: '00:00:00',
            startDay: nextDay,
            splitMark: true,
          });
          event.stopTime = '23:59:59';
        }
      });
    });

    return sortedEvents;
  }

  fillEmptyEvents(weekEvents) {
    const startOfADay = '00:00:00';
    const result = getWeekPlaceholder();
    weekEvents.forEach((dayEvents, dayIndex) => {
      dayEvents = dayEvents.sort((eventLeft, eventRight) => eventLeft.startTime.localeCompare(eventRight.startTime));

      for (let eventIndex = 0; eventIndex < dayEvents.length; ++eventIndex) {
        const addEmptyDay = (startTime) => {
          result[dayIndex].push({
            ...dayEvents[eventIndex],
            startTime,
            stopTime: dayEvents[eventIndex].startTime,
            isEmptyEvent: true,
          });
        };

        if (eventIndex === 0) {
          if (dayEvents[eventIndex].startTime !== startOfADay) {
            addEmptyDay(startOfADay);
          }
        } else {
          const compareWithPrev = dayEvents[eventIndex].startTime.localeCompare(dayEvents[eventIndex - 1].stopTime);
          if (compareWithPrev > 0) {
            addEmptyDay(dayEvents[eventIndex - 1].stopTime);
          }
        }

        result[dayIndex].push(dayEvents[eventIndex]);
      }
    });

    return result;
  }

  calculateEventsForWeek(events) {
    const sortedEvents = this.getSortedEvents(events);
    const splitEvents = this.splitEventsToNextDay(sortedEvents);

    const fillEmptyEvents = this.fillEmptyEvents(splitEvents);

    return fillEmptyEvents;
  }
}

class Mon_FriSatSun extends SunMonTueWedThuFriSat {
  __getInitialEvent(eventFrom) {
    return {
      startDay: eventFrom.startDay,
      startTime: '00:00:00',
      is_mock: true,
    };
  }

  calculateEventsForWeek(events) {
    const sortedEventsMon_Fry = [...this.getSortedEvents(events)];
    sortedEventsMon_Fry[SATURDAY] = [];
    sortedEventsMon_Fry[SUNDAY] = [];

    const sortedEvents = [...this.getSortedEvents(events)];
    const sortedEventsSat = getWeekPlaceholder();
    sortedEventsSat[SATURDAY] = sortedEvents[SATURDAY];

    const sortedEventsSan = getWeekPlaceholder();
    sortedEventsSan[SUNDAY] = sortedEvents[SUNDAY];

    const eventsForMon_Fry = this.calculateEventsForWeekForPartOfWeek(sortedEventsMon_Fry.flat(), sortedEventsMon_Fry);
    const eventsForSat = this.calculateEventsForWeekForPartOfWeek(sortedEventsSat.flat(), sortedEventsSat);
    const eventsForSun = this.calculateEventsForWeekForPartOfWeek(sortedEventsSan.flat(), sortedEventsSan);

    const result = getWeekPlaceholder();

    for (let day = MONDAY; day <= SUNDAY; ++day) {
      result[day].push(...eventsForMon_Fry[day]);
      result[day].push(...eventsForSat[day]);
      result[day].push(...eventsForSun[day]);
    }

    return result;
  }

  calculateEventsForWeekForPartOfWeek(events, sortedEvents) {
    const result = getWeekPlaceholder();
    let eventFrom = {};
    let eventToFill = this.findTheFirstEvent(sortedEvents);

    if (eventToFill) {
      eventFrom = this.__getInitialEvent(eventToFill);
    }

    const cyclicEventsBreadCrumbs = [];
    let lastEvent = false;
    let theFirstIteration = true;
    while (eventToFill) {
      if (events.length > 1 && !lastEvent) {
        const theLastEvent = this.findTheLastEvent(sortedEvents);
        const endEvent = { ...theLastEvent, ...eventFrom };
        this.fillByVisibleEvents(endEvent, eventToFill, result);
      } else {
        this.fillByVisibleEvents(eventFrom, eventToFill, result);
      }
      lastEvent = eventToFill;
      eventFrom = eventToFill;

      // hook to prevent permanent cycle
      if (cyclicEventsBreadCrumbs.find((e) => e === eventFrom)) {
        break;
      }

      eventToFill = this.findNextEvent(eventFrom, sortedEvents);
      if (!theFirstIteration) {
        cyclicEventsBreadCrumbs.push(eventFrom);
      } else {
        theFirstIteration = false;
      }

      if (lastEvent === eventToFill) {
        break;
      }
    }

    if (lastEvent) {
      this.fillByVisibleEvents(
        eventFrom,
        { startDay: lastEvent.startDay, startTime: '23:59:59', is_mock: true },
        result
      );
    }

    return result;
  }
}

class Mon_FriSatSunStopTime extends SunMonTueWedThuFriSatStopTime {
  splitEventsToNextDay(sortedEvents) {
    sortedEvents.forEach((dayEvents, dayIndex) => {
      dayEvents.forEach((event) => {
        const timeCompare = event.startTime.localeCompare(event.stopTime);
        if (!event.splitMark && timeCompare >= 0 && event.stopTime != '00:00:00') {
          sortedEvents[dayIndex].push({
            ...event,
            startTime: '00:00:00',
            startDay: dayIndex,
            splitMark: true,
          });
          event.stopTime = '23:59:59';
        }
      });
    });

    return sortedEvents;
  }
}

export default {
  'mon,tue,wed,thu,fri,sat,sun': new SunMonTueWedThuFriSat(),
  'mon,tue,wed,thu,fri,sat,sun-stoptime': new SunMonTueWedThuFriSatStopTime(),
  'mon-sun': new SunMonTueWedThuFriSat(),
  'mon-sun-stoptime': new Mon_FriSatSunStopTime(),
  'mon-fri,sat-sun': new Mon_FriSatSun(),
  'mon-fri,sat-sun-stoptime': new Mon_FriSatSunStopTime(),
  'mon-fri,sat,sun': new Mon_FriSatSun(),
  'mon-fri,sat,sun-stoptime': new Mon_FriSatSunStopTime(),
};
