import { GET_FAQ_REQUEST, GET_FAQ_RESPONSE_SUCCEEDED } from './actions';

const initialState = {
  loading: false,
  faqItems: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_FAQ_REQUEST:
      return { loading: true, faqItems: [] };
    case GET_FAQ_RESPONSE_SUCCEEDED:
      return { loading: false, faqItems: action.faqItems };
    default:
      return state;
  }
};
