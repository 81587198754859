import indoorClimate from './indoor-climate.svg';
import hotWater from './hotWater.svg';
import weather from './weather.svg';
import status from './status.svg';
import ventilation from './ventilation.svg';
import pool from './pool.svg';
import solar from './solar.svg';
import smartGuide from './smart-guide.svg';
import energy from './energy.svg';
import smartPlug from './smartplug.svg';
import clearDay from './clear-day.svg';
import clearNight from './clear-night.svg';
import cloudy from './cloudy.svg';
import fog from './fog.svg';
import hail from './hail.svg';
import partlyCloudyDay from './partly-cloudy-day.svg';
import partlyCloudyNight from './partly-cloudy-night.svg';
import rain from './rain.svg';
import sleet from './sleet.svg';
import snow from './snow.svg';
import thunderstorm from './thunderstorm.svg';
import tornado from './tornado.svg';
import wind from './wind.svg';
import myUplinkIcons from '../myuplink';
import nibeEnergyLog from './nibe-energy-log.svg';

export default {
  ...myUplinkIcons,
  indoorClimate,
  hotWater,
  weather,
  status,
  ventilation,
  pool,
  solar,
  smartGuide,
  energy,
  nibeEnergyLog,
  smartPlug,
  clearDay,
  clearNight,
  cloudy,
  fog,
  hail,
  partlyCloudyDay,
  partlyCloudyNight,
  rain,
  sleet,
  snow,
  thunderstorm,
  tornado,
  wind,
};
