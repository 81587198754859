import { LANGUAGE_SELECTED } from './actions';
import { get } from '../../../api/language';

const initialState = {
  selectedLanguage: get() || 'en',
  languageTag: 'en-US',
};

export default function language(state = initialState, action) {
  switch (action.type) {
    case LANGUAGE_SELECTED:
      return { ...state, selectedLanguage: action.selectedLanguage, languageTag: action.languageTag };
    default:
      return state;
  }
}
