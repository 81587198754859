import {
  CONFIRM_EMAIL_UPDATE_REQUEST,
  CONFIRM_EMAIL_UPDATE_RESPONSE_FAILED,
  CONFIRM_EMAIL_UPDATE_RESPONSE_SUCCEEDED,
} from './actions';

const initialState = {
  emailConfirmed: false,
  loading: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CONFIRM_EMAIL_UPDATE_REQUEST:
      return { ...state, emailConfirmed: false, loading: true };
    case CONFIRM_EMAIL_UPDATE_RESPONSE_SUCCEEDED:
      return { ...state, emailConfirmed: true, loading: false };
    case CONFIRM_EMAIL_UPDATE_RESPONSE_FAILED:
      return { ...state, emailConfirmed: false, loading: false };
    default:
      return state;
  }
};
