import { GET_SMART_GUIDE_QUESTIONS_RESPONSE, SET_SMART_GUIDE_ANSWER_OPTION } from './actions';

const initialState = {};

const saveGuideQuestions = (state, deviceId, guideNumber, questions) => {
  if (state[deviceId]) {
    return { ...state, [deviceId]: { ...state[deviceId], [guideNumber]: questions } };
  }

  return { ...state, [deviceId]: { [guideNumber]: questions } };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SMART_GUIDE_QUESTIONS_RESPONSE:
      return saveGuideQuestions(state, action.deviceId, action.guideNumber, action.questions);
    case SET_SMART_GUIDE_ANSWER_OPTION:
      return saveGuideQuestions(state, action.deviceId, action.guideNumber, action.questions);
    default:
      return state;
  }
};
