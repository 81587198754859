export const POPUP_STATES = {
  VIEW: 'view',
  EDIT: 'edit',
  ADD: 'add',
  RENAME: 'rename',
  DELETE: 'delete',
  DELETE_ERROR: 'delete_error',
};

export const POPUP_TITLES = {
  [POPUP_STATES.VIEW]: {
    id: 'scheduling.title.view-modes',
    defaultMessage: 'View Modes',
  },
  [POPUP_STATES.EDIT]: {
    id: 'scheduling.title.edit-modes',
    defaultMessage: 'Edit Modes',
  },
  [POPUP_STATES.ADD]: {
    id: 'scheduling.title.add-mode',
    defaultMessage: 'Add Mode',
  },
  [POPUP_STATES.RENAME]: {
    id: 'scheduling.title.rename-mode',
    defaultMessage: 'Rename Mode',
  },
  [POPUP_STATES.DELETE]: {
    id: 'scheduling.title.delete-mode',
    defaultMessage: 'Delete Mode',
  },
  [POPUP_STATES.DELETE_ERROR]: {
    id: 'scheduling.title.delete-mode',
    defaultMessage: 'Delete Mode',
  },
};
