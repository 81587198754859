import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Formik } from 'formik';
import * as yup from 'yup';
import { InputDropdown } from '../../inputs/Dropdown';
import { Button, Input, Spinner, Title } from 'jpi-cloud-web-ui-components';
import FormInput from '../../inputs/FormInput';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as api from '../../../api';
import { formatErrorMessage } from '../../../localization/message-formatting';
import { topics, messageMinLength, messageMaxLength, errorMessages, submitStatus } from './constants';

import './contact.scss';

const schema = yup.object().shape({
  fromEmail: yup.string().email('from-email.email').trim().required('from-email.required'),
  topic: yup.string().trim().required('topic.required'),
  system: yup.string().trim().required('system.required'),
  summary: yup.string().trim().required('summary.required'),
  message: yup
    .string()
    .trim()
    .required('message.required')
    .min(messageMinLength, 'message.required')
    .max(messageMaxLength, 'messageMaxLength.required'),
});

const platform = 'myUplink (Web)';
const zeroDeviceId = '00000000';

const ContactFormInner = ({ initialValues, systems, onSubmit, status, isDemo, intl }) => {
  return (
    <Formik
      initialValues={initialValues}
      systems={systems}
      validationSchema={schema}
      onSubmit={onSubmit}
      status={status}
    >
      {({ values, errors, touched, handleChange, handleSubmit, isSubmitting, setFieldValue }) => {
        const { message, summary, system, topic, fromName, fromEmail } = values;
        const sendButtonIsDisabled = isDemo || isSubmitting;

        return (
          <>
            <div className="row">{isSubmitting && <Spinner />}</div>
            {!isSubmitting && (
              <div className="contact">
                <form onSubmit={handleSubmit} className="form-wrapper--half">
                  <FormInput className="formInput--half" hidden={true}>
                    <FormattedMessage id="contact.fromName" defaultMessage="Name">
                      {(placeholder) => (
                        <Input
                          name="fromName"
                          id="fromName"
                          type="text"
                          placeholder={placeholder}
                          value={fromName}
                          onChange={handleChange}
                          readOnly={true}
                        />
                      )}
                    </FormattedMessage>
                  </FormInput>
                  <FormattedMessage id="contact.fromEmail" defaultMessage="E-mail">
                    {(placeholder) => (
                      <Input
                        name="fromEmail"
                        id="fromEmail"
                        type="text"
                        placeholder={placeholder}
                        value={fromEmail}
                        error={errors.fromEmail ? formatErrorMessage(intl, errorMessages, errors.fromEmail) : ''}
                        onChange={handleChange}
                        readOnly={true}
                        disabled={isDemo}
                      />
                    )}
                  </FormattedMessage>
                  <FormattedMessage id="contact.requestType" defaultMessage="Request Type">
                    {(placeholder) => (
                      <InputDropdown
                        placeholder={placeholder}
                        id={Date.now().toString(36) + Math.random().toString(36).slice(2) + 'topic'}
                        name="topic"
                        value={topic || ''}
                        items={topics}
                        labelGetter={(e) => intl.formatMessage(e.value)}
                        onSelect={(s) => setFieldValue('topic', s.id)}
                        selectedItem={topics.find((e) => e.id === topic)}
                        disabled={isDemo}
                        isReadOnly={true}
                        error={errors.topic && touched.topic && formatErrorMessage(intl, errorMessages, errors.topic)}
                      />
                    )}
                  </FormattedMessage>
                  <FormattedMessage id="contact.system" defaultMessage="Select a System">
                    {(placeholder) => (
                      <InputDropdown
                        placeholder={placeholder}
                        id={Date.now().toString(36) + Math.random().toString(36).slice(2) + 'system'}
                        name="system"
                        value={system || ''}
                        items={systems}
                        labelGetter={(e) => `${e.name}`}
                        onSelect={(s) => {
                          setFieldValue('system', s.devices?.[0]?.id || zeroDeviceId);
                        }}
                        selectedItem={systems.find((e) => e.devices?.[0]?.id === system)}
                        disabled={isDemo}
                        isReadOnly={true}
                        error={
                          errors.system && touched.system && formatErrorMessage(intl, errorMessages, errors.system)
                        }
                      />
                    )}
                  </FormattedMessage>
                  <FormattedMessage id="contact.summary" defaultMessage="Summary">
                    {(placeholder) => (
                      <Input
                        name="summary"
                        id="summary"
                        type="text"
                        placeholder={placeholder}
                        value={summary}
                        onChange={handleChange}
                        error={
                          touched.summary && errors.summary && formatErrorMessage(intl, errorMessages, errors.summary)
                        }
                      />
                    )}
                  </FormattedMessage>
                  <FormInput>
                    {
                      <p className="errorField">
                        {touched.message && errors.message ? (
                          formatErrorMessage(intl, errorMessages, errors.message)
                        ) : (
                          <br />
                        )}
                      </p>
                    }
                    <FormattedMessage id="contact.description" defaultMessage="Description">
                      {(placeholder) => (
                        <textarea
                          placeholder={placeholder}
                          name="message"
                          id="message"
                          value={message}
                          rows={10}
                          style={{
                            width: '100%',
                            border: touched.message && errors.message ? '2px solid #A83131' : '',
                          }}
                          onChange={handleChange}
                          disabled={isDemo}
                        />
                      )}
                    </FormattedMessage>
                  </FormInput>
                  <div className="button-wrapper">
                    <Button className="button--secondary" type="submit" disabled={sendButtonIsDisabled}>
                      <FormattedMessage id="contact.send" defaultMessage="Send" />
                    </Button>
                  </div>
                  {status === submitStatus.invalid && (
                    <div className="alert alert-danger">
                      <FormattedMessage
                        id="contact.invalid"
                        defaultMessage="Please enter all required fields and check the length of your message."
                      />
                    </div>
                  )}
                  {status === submitStatus.unknown && (
                    <div className="alert alert-danger">
                      <FormattedMessage id="contact.unknown" defaultMessage="An error has occurred. Try again later." />
                    </div>
                  )}
                  {status === submitStatus.success && (
                    <div className="alert alert-success">
                      <FormattedMessage id="contact.success" defaultMessage="Your message has been sent." />
                    </div>
                  )}
                </form>
              </div>
            )}
          </>
        );
      }}
    </Formik>
  );
};

ContactFormInner.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  systems: PropTypes.array,
  status: PropTypes.string.isRequired,
  isDemo: PropTypes.bool,
  intl: PropTypes.object,
};

const ContactForm = injectIntl(ContactFormInner);

class Contact extends React.Component {
  static propTypes = {
    userInfo: PropTypes.object,
    systems: PropTypes.array,
  };

  state = {
    fromName: '',
    fromEmail: '',
    topic: '',
    system: '',
    summary: '',
    message: '',
    status: '',
    brandId: '',
  };

  static getDerivedStateFromProps(props, state) {
    if (props.userInfo && props.userInfo.email) {
      return { ...state, fromEmail: props.userInfo.email };
    }
    return { ...state };
  }

  onSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);

    const { fromName, fromEmail, topic, system, summary, message } = values;

    const brandId = this.props.systems.find((e) => e.devices?.[0]?.id === system)?.brandId || '';

    this.setState({
      fromName,
      fromEmail,
      topic,
      system,
      summary,
      message,
      status: '',
      brandId,
    });

    // if the name honeypot is not empty, we sleep for 500ms before proceeding, just to slow down any potential spammers
    if (fromName.length > 0) {
      setTimeout(
        function () {
          this.setState({ status: submitStatus.success });
        }.bind(this),
        500
      );
    } else if (topic.length == 0 || message.length < messageMinLength || message.length > messageMaxLength) {
      this.setState({
        fromName,
        fromEmail,
        topic,
        system,
        summary,
        message,
        status: submitStatus.invalid,
      });
    } else {
      values = {
        ...values,
        platform,
        brandId,
        system: values.system === zeroDeviceId ? null : values.system,
        deviceId:
          values.system === zeroDeviceId
            ? null
            : this.props.systems?.find((s) => s.devices[0].id === values.system)?.devices[0].id,
      };

      const createSupportTicket = await api.createJiraServiceDeskSupportTicket(values);
      if (createSupportTicket.status === 201) {
        this.setState({ status: submitStatus.success });
      } else {
        this.setState({ status: submitStatus.unknown });
      }
    }
    resetForm({ fromName, fromEmail });
    setSubmitting(false);
  };

  render() {
    const isDemo = this.props.userInfo && this.props.userInfo.isDemo;
    const systems = this.props.systems.length > 0 ? this.props.systems : [{ name: 'N/A', deviceId: zeroDeviceId }];

    return (
      <div className="page-content">
        <Title titleTranslationId="contact.title" defaultMessage="Contact" />
        <FormattedMessage
          id="contact.intro"
          defaultMessage="We are so confident in the quality and performance of our products that you can get a 5-year manufacturer's warranty for each heat pump. Registering your equipment is one of several requirements for obtaining the warranty. The manufacturer's warranty applies exclusively in compliance with the warranty conditions, professional commissioning and regular maintenance."
        />

        {(!this.props.userInfo || !this.props.userInfo.email) && <Spinner />}

        {this.props.userInfo && this.props.userInfo.email && (
          <ContactForm
            initialValues={this.state}
            systems={systems}
            onSubmit={this.onSubmit}
            status={this.state.status}
            isDemo={!!isDemo}
          />
        )}
      </div>
    );
  }
}

export default connect(({ app: { userInfo, systems } }) => ({ userInfo, systems }), {})(Contact);
