import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { push } from 'connected-react-router';
import { Button, Spinner } from 'jpi-cloud-web-ui-components';

import Dropdown from '../../inputs/Dropdown';
import { setLastUsedSystem } from './actions';
import { selectSystemId, setDeviceUpdateAvailable } from '../../AppInitializer/actions';
import { getDevices, getSubscriptionsInGroup, setSelectedDevice } from '../../pages/Devices/actions';
import { getFeatures, getDeviceFeatures } from '../../FeaturesFlags/actions';
import { removeCart } from '../../AppInitializer/utils';
import * as historyCharts from '../../../api/history';
import { getUpgradeableDeviceIds } from '../../../utils';

import './system-selector.scss';
import '../../inputs/Dropdown/Dropdown.scss';

export class SystemSelector extends React.Component {
  static propTypes = {
    systemsLoaded: PropTypes.bool,
    systems: PropTypes.arrayOf(PropTypes.object),
    selectedSystem: PropTypes.object,
    selectSystemId: PropTypes.func,
    intl: PropTypes.object.isRequired,
    goToPage: PropTypes.func.isRequired,
    getDevices: PropTypes.func,
    setSelectedDevice: PropTypes.func,
    getFeatures: PropTypes.func,
    getSubscriptionsInGroup: PropTypes.func,
    setLastUsedSystem: PropTypes.func,
    setDeviceUpdateAvailable: PropTypes.func,
    getDeviceFeatures: PropTypes.func,
  };

  render() {
    const { systemsLoaded, systems, selectedSystem, intl, goToPage } = this.props;
    const SYSTEM_NAME_TO_SHOW_MAX = 40;
    const SYSTEM_NAME_TO_SHOW_MAX_OFFLINE = 35;
    const SYSTEM_NAME_TO_SHOW_MAX_ONLINE = 37;
    const SYSTEM_NAME_TO_SHOW_MAX_DOTS = '...';
    const systemNameShowMaxExceeded = selectedSystem && selectedSystem.name.length > SYSTEM_NAME_TO_SHOW_MAX;

    return (
      <div className="system-selector">
        {!systemsLoaded && <Spinner />}
        {systemsLoaded &&
          (systems.length > 0 && systemsLoaded ? (
            <Dropdown
              id="system-selector"
              selectedItem={selectedSystem}
              items={[...systems, new AddSystemDropdownItem()]}
              tooltipGetter={(item) => {
                if (item instanceof AddSystemDropdownItem) {
                  return intl.formatMessage({ id: 'addDevice.systemSelector.label', defaultMessage: 'Add System' });
                }
                return item.name.length > SYSTEM_NAME_TO_SHOW_MAX ? item.name : null;
              }}
              labelGetter={(item) => {
                if (item instanceof AddSystemDropdownItem) {
                  return <AddSystemDropdownItem intl={intl} />;
                }
                if (item.name.length <= SYSTEM_NAME_TO_SHOW_MAX) {
                  return !item.online ? `⚠ ${item.name}` : item.name;
                } 
                  return !item.online
                    ? '⚠ ' + item.name.substring(0, SYSTEM_NAME_TO_SHOW_MAX_OFFLINE) + SYSTEM_NAME_TO_SHOW_MAX_DOTS
                    : item.name.substring(0, SYSTEM_NAME_TO_SHOW_MAX_ONLINE) + SYSTEM_NAME_TO_SHOW_MAX_DOTS;
                
              }}
              onSelect={(item) => {
                if (item instanceof AddSystemDropdownItem) {
                  goToPage('/add-device');
                } else {
                  this.changeSelectedSystem(item);
                }
              }}
              isItemNameMax={systemNameShowMaxExceeded}
            />
          ) : (
            <div className="dropdown">
              <Button id="system-selector" type="button" onClick={() => goToPage('/add-device')}>
                <FormattedMessage id="addDevice.systemSelector.label" defaultMessage="Add System" />
              </Button>
            </div>
          ))}
      </div>
    );
  }

  async changeSelectedSystem(system) {
    removeCart();
    await this.props.selectSystemId(system.id);

    this.props.setLastUsedSystem(system.id, system.brandId);
    this.props.getSubscriptionsInGroup(system.id);

    const devices = await this.props.getDevices(system.id);
    this.props.setSelectedDevice();
    if (devices && devices.length > 0) {
      await this.props.getFeatures(devices[0].id);
      await this.props.getDeviceFeatures(devices[0].id);
    }

    const upgradableDeviceIds = getUpgradeableDeviceIds(devices);

    if (upgradableDeviceIds.length > 0) {
      this.props.setDeviceUpdateAvailable(true);
    } else {
      this.props.setDeviceUpdateAvailable(false);
    }

    // NOTE: Not safe to be added to actions because of race-condition...
    historyCharts.removeFirstChart();
    historyCharts.removeSecondChart();
  }
}

class AddSystemDropdownItem extends React.Component {
  render() {
    return (
      <>
        <div className="button--round">
          <span title="Add new tile">+</span>
        </div>
        <span className="add-system-label">
          {this.props.intl.formatMessage({ id: 'addDevice.systemSelector.label', defaultMessage: 'Add System' })}
        </span>
      </>
    );
  }

  static propTypes = {
    intl: PropTypes.object.isRequired,
  };
}

const mapStateToProps = ({ app: { systemsLoaded, systems, selectedSystem } }) => ({
  systemsLoaded,
  systems,
  selectedSystem,
});

const mapDispatchToProps = {
  goToPage: push,
  selectSystemId,
  getDevices,
  setSelectedDevice,
  getFeatures,
  getSubscriptionsInGroup,
  setLastUsedSystem,
  setDeviceUpdateAvailable,
  getDeviceFeatures,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SystemSelector));
