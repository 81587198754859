import indoorClimate from './enertech-indoortemperature.svg';
import ventilation from './enertech-ventilation.svg';
import solar from './enertech-solar.svg';
import energy from './enertech-energy.svg';
import hotWater from './enertech-hotwater.svg';
import weather from './enertech-weather.svg';
import status from './enertech-status.svg';
import pool from './enertech-pool.svg';
import smartGuide from './enertech-smartguide.svg';
import enertechAuto from './enertech-auto.svg';
import enertechCooling from './enertech-cooling.svg';
import enertechHeating from './enertech-heating.svg';
import enertechOff from './enertech-off.svg';
import enertechOn from './enertech-on.svg';
import geothermalLoop from './enertech-loop-19.svg';
import savings from './enertech-savings.svg';
import fanMode from './enertech-fanmode.svg';
import clearDay from './clear-day.svg';
import clearNight from './clear-night.svg';
import cloudy from './cloudy.svg';
import fog from './fog.svg';
import hail from './hail.svg';
import partlyCloudyDay from './partly-cloudy-day.svg';
import partlyCloudyNight from './partly-cloudy-night.svg';
import rain from './rain.svg';
import sleet from './sleet.svg';
import snow from './snow.svg';
import thunderstorm from './thunderstorm.svg';
import tornado from './tornado.svg';
import wind from './wind.svg';
import myUplinkIcons from '../myuplink';

export default {
  ...myUplinkIcons,
  indoorClimate,
  ventilation,
  solar,
  energy,
  hotWater,
  weather,
  status,
  pool,
  smartGuide,
  enertechAuto,
  enertechCooling,
  enertechHeating,
  enertechOff,
  enertechOn,
  geothermalLoop,
  savings,
  fanMode,
  clearDay,
  clearNight,
  cloudy,
  fog,
  hail,
  partlyCloudyDay,
  partlyCloudyNight,
  rain,
  sleet,
  snow,
  thunderstorm,
  tornado,
  wind,
};
