import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import { Button } from 'jpi-cloud-web-ui-components';
import ToggleSwitch from '../../inputs/ToggleSwitch';
import PremiumFeatureTooltip from '../../layout/PremiumFeatureTooltip';

import { loadSmartHomeMode, setSmartHomeMode, getSystemStatus } from './actions';
import { getScheduleConfig } from '../../pages/DeviceScheduling/actions';
import { USER_SECURITY_LEVELS } from '../../pages/SystemProfile/components/SecurityTab/costants';

import './systemstatus.scss';

const PremiumFeatureType = {
  scheduling: 'scheduling',
  subscriptionActive: 'subscriptionActive',
  loading: 'loading',
};

const SMART_HOME_MODES = {
  default: 'default',
  away: 'away',
  vacation: 'vacation',
};

class SystemStatus extends Component {
  static propTypes = {
    currentStatus: PropTypes.string,
    selectedSystemName: PropTypes.string,
    selectedSystemId: PropTypes.string,
    selectedSystem: PropTypes.object,
    brandId: PropTypes.string,
    smartHomeMode: PropTypes.string,
    isSmartHomeLoading: PropTypes.bool,
    schedulingDeviceFeatures: PropTypes.object.isRequired,
    loadSmartHomeMode: PropTypes.func,
    setSmartHomeMode: PropTypes.func,
    getSystemStatus: PropTypes.func,
    push: PropTypes.func.isRequired,
    isManageEnabled: PropTypes.bool,
    isDashboardFree: PropTypes.bool,
    smartMode: PropTypes.bool.isRequired,
    deviceId: PropTypes.string,
    getScheduleConfig: PropTypes.func.isRequired,
    weeklySchedulesNumber: PropTypes.number,
    intl: intlShape.isRequired,
  };

  componentDidMount() {
    if (this.props.selectedSystemId) {
      this.props.getSystemStatus(this.props.selectedSystemId);
      this.props.loadSmartHomeMode(this.props.selectedSystemId);
    }
    if (this.props.deviceId) {
      this.props.getScheduleConfig(this.props.deviceId);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedSystemId !== prevProps.selectedSystemId) {
      this.props.getSystemStatus(this.props.selectedSystemId);
      this.props.loadSmartHomeMode(this.props.selectedSystemId);
    }
    if (this.props.deviceId !== prevProps.deviceId) {
      this.props.getScheduleConfig(this.props.deviceId);
    }
  }

  switchSmartHomeMode() {
    const newMode =
      this.props.smartHomeMode === SMART_HOME_MODES.default ? SMART_HOME_MODES.away : SMART_HOME_MODES.default;
    this.props.setSmartHomeMode(this.props.selectedSystemId, newMode);
  }

  _goToScheduling = () => {
    this.props.push('/device-scheduling');
  };

  render() {
    const {
      schedulingDeviceFeatures: { scheduleOverride, scheduleWeekly, scheduleVacation },
      selectedSystem,
      selectedSystemId,
      selectedSystemName,
      currentStatus,
      isManageEnabled,
      brandId,
      isSmartHomeLoading,
      intl,
      smartHomeMode,
      smartMode,
      weeklySchedulesNumber,
      isDashboardFree,
    } = this.props;

    if (!selectedSystemId) {
      return null;
    }

    // TODO: SCHEDULING MOCK. Remove all mocking logic when scheduling support is implemented on the device level.
    const fullSchedulingAvailable = scheduleOverride || scheduleWeekly || scheduleVacation;
    const smartModeAvailable = !(scheduleOverride || scheduleWeekly || scheduleVacation) && smartMode;
    const isHomeAwayOn = smartHomeMode !== SMART_HOME_MODES.default;
    const isSchedulingDisabled = currentStatus === 'offline' || weeklySchedulesNumber < 1;
    const isDeviceOffline = currentStatus === 'offline';
    const isSubscriptionActive = isManageEnabled;
    const disableSchedulingButton = () => {
      if (isSchedulingDisabled) return true; //if device is offline, disable scheduling
      if (isDashboardFree) return false; //if user has the right to view Dashboard, enable scheduling
      if (isSubscriptionActive) return false; //if Subscription is Active, enable scheduling

      return true;
    };
    const premiumFeatureType =
      fullSchedulingAvailable && isSchedulingDisabled
        ? PremiumFeatureType.scheduling
        : smartModeAvailable
        ? PremiumFeatureType.loading
        : PremiumFeatureType.subscriptionActive;
    const schedulingButtonType = brandId === 'MYUPLINK' ? 'button--secondary' : 'button--primary';

    const userIsViewer = selectedSystem && selectedSystem.userRole === USER_SECURITY_LEVELS.viewer;

    return (
      <div className="system-info-wrapper">
        <div className="status-wrapper">
          <div className="status">
            <div className="status-text">
              {selectedSystemName}&nbsp;
              <FormattedMessage id={`system-status.${currentStatus}`} defaultMessage="is online" />
            </div>
            {fullSchedulingAvailable && (
              <div className="switch-text">
                <Button
                  className={classNames(schedulingButtonType, 'button--sm')}
                  type="button"
                  disabled={disableSchedulingButton()}
                  onClick={this._goToScheduling}
                >
                  <FormattedMessage id="system-status.scheduling" defaultMessage="Scheduling" />
                </Button>
                <PremiumFeatureTooltip premiumFeatureType={premiumFeatureType} />
              </div>
            )}
            {smartModeAvailable && (
              <div className="status-info">
                <div className="toggle-switch-text">
                  <FormattedMessage id="system-status.away" defaultMessage="Away from home" />
                  &nbsp;
                </div>
                <div className="switch">
                  <ToggleSwitch
                    isChecked={isHomeAwayOn}
                    disabled={isSmartHomeLoading || userIsViewer}
                    disabledDueToOffline={isDeviceOffline}
                    onChange={() => this.switchSmartHomeMode()}
                    premiumFeatureType={!userIsViewer ? premiumFeatureType : ''}
                    label={intl.formatMessage({
                      id: 'system-status.away',
                      defaultMessage: 'Away from home',
                    })}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  app: { selectedSystem },
  systemStatus: { smartHomeMode, isSmartHomeLoading, currentStatus },
  features: { deviceFeatures, premiumFeatures, isDashboardFree },
  deviceScheduling: { scheduleConfig },
}) => ({
  deviceId: selectedSystem && selectedSystem.devices && selectedSystem.devices.length && selectedSystem.devices[0].id,
  selectedSystemName: selectedSystem && selectedSystem.name,
  selectedSystemId: selectedSystem && selectedSystem.id,
  brandId: selectedSystem && selectedSystem.brandId && selectedSystem.brandId.toUpperCase(),
  selectedSystem,
  smartHomeMode: smartHomeMode,
  isSmartHomeLoading: isSmartHomeLoading,
  currentStatus: currentStatus,
  schedulingDeviceFeatures: deviceFeatures.scheduling,
  smartMode: deviceFeatures.smartMode,
  isManageEnabled: premiumFeatures && premiumFeatures.manage,
  weeklySchedulesNumber: scheduleConfig && scheduleConfig.weeklySchedulesNumber,
  isDashboardFree,
});

const mapDispatchToProps = {
  loadSmartHomeMode,
  setSmartHomeMode,
  getSystemStatus,
  getScheduleConfig,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SystemStatus));
