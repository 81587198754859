import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from 'react-intl';
import { Formik } from 'formik';
import * as yup from 'yup';
import { ModalBody, ModalFooter } from 'react-bootstrap';
import Modal from '../../../layout/Modal';
import { Button, Checkbox } from 'jpi-cloud-web-ui-components';
import { formatErrorMessage } from '../../../../localization/message-formatting';

const schemaStepOne = yup.object().shape({
  acceptedAgreement: yup.boolean().required('tos.required').oneOf([true], 'tos.required'),
  acceptedPrivacyPolicy: yup.boolean().required('privacy-policy.required').oneOf([true], 'privacy-policy.required'),
});

const errorMessages = {
  'tos.required': {
    id: 'agreements.error.validation.tos.required',
    defaultMessage: 'Must accept Terms and Conditions',
  },
  'privacy-policy.required': {
    id: 'agreements.error.validation.privacy-policy.required',
    defaultMessage: 'Must accept Privacy Policy',
  },
  unknown: {
    id: 'migration.error.unknown',
    defaultMessage: 'An error has occurred. Try again later.',
  },
  'user was not found': {
    id: 'migration.error.request.user-not-found',
    defaultMessage: 'Migration was failed. Please try again',
  },
};

const MigrationConfirmation = ({
  isShown,
  onDiscard,
  onConfirm,
  requestError,
  children,
  state,
  toggleLegalModal,
  intl,
}) => {
  return (
    <div>
      <Formik
        initialValues={{
          acceptedAgreement: 0,
          acceptedPrivacyPolicy: 0,
        }}
        onSubmit={onConfirm}
        validationSchema={schemaStepOne}
        enableReinitialized={true}
      >
        {({ values, isSubmitting, handleChange, handleSubmit, errors }) => (
          <Modal show={isShown} backdrop={true} onHide={onDiscard}>
            <form onSubmit={handleSubmit}>
              <ModalBody>
                <h2>{children}</h2>
                <Checkbox
                  id="acceptedAgreement"
                  name="acceptedAgreement"
                  className="underline"
                  onChange={handleChange}
                  checked={values.acceptedAgreement}
                  label={<FormattedMessage id="register.user-terms" defaultMessage="I accept the Terms of Service" />}
                  onLabelClick={() => toggleLegalModal('terms-of-service')}
                  error={
                    errors.acceptedAgreement ? formatErrorMessage(intl, errorMessages, errors.acceptedAgreement) : ''
                  }
                />
                <Checkbox
                  id="acceptedPrivacyPolicy"
                  name="acceptedPrivacyPolicy"
                  className="underline"
                  onChange={handleChange}
                  checked={values.acceptedPrivacyPolicy}
                  label={
                    <FormattedMessage
                      id="register.privacy-policy"
                      defaultMessage="I have read and understood the Privacy Policy"
                    />
                  }
                  onLabelClick={() => toggleLegalModal('privacy-policy')}
                  error={
                    errors.acceptedPrivacyPolicy
                      ? formatErrorMessage(intl, errorMessages, errors.acceptedPrivacyPolicy)
                      : ''
                  }
                />
                <h4>
                  {requestError && (
                    <p className="text-danger">{formatErrorMessage(intl, errorMessages, requestError)}</p>
                  )}
                </h4>
              </ModalBody>
              <ModalFooter>
                <Button onClick={onDiscard} type="button" className="button--default">
                  <FormattedMessage id="migrations.confirmation.buttons.abort" defaultMessage="Abort" />
                </Button>
                <Button type="submit" className="button--secondary" disabled={isSubmitting}>
                  <FormattedMessage id="migrations.confirmation.buttons.proceed" defaultMessage="Proceed" />
                </Button>
              </ModalFooter>
            </form>
          </Modal>
        )}
      </Formik>
      {state.showLegalInfoModal && state.legalInfoType && (
        <Modal show={state.showLegalInfoModal} backdrop={true} onHide={() => toggleLegalModal()}>
          <ModalBody>
            <FormattedHTMLMessage id={state.legalInfoType} />
          </ModalBody>
        </Modal>
      )}
    </div>
  );
};

MigrationConfirmation.propTypes = {
  isShown: PropTypes.bool,
  onDiscard: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  requestError: PropTypes.any,
  children: PropTypes.any,
  state: PropTypes.object.isRequired,
  toggleLegalModal: PropTypes.func.isRequired,
  intl: PropTypes.object,
};

export default injectIntl(MigrationConfirmation);
