import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import LineChart from './LineChart';
import { Spinner } from 'jpi-cloud-web-ui-components';
import PremiumFeatureTooltip from '../../layout/PremiumFeatureTooltip';
import { TileChartColors } from '../../../assets/brands/colors';
import { USER_SECURITY_LEVELS } from '../../pages/SystemProfile/components/SecurityTab/costants';

import { getTileChartData, DateRange, cleanChartData } from './actions';
import { TileTypes } from '../TileManager/TileTypes';

import { events, groups } from '../../../services/clarity/events';
import { setupTracker } from '../../../services/clarity';

import { eventEmitter, events as insightEvents } from '../../tracking/AppInsights';

import './tile-chart.scss';

const REFRESH_INTERVAL = 10 * 1000;
const EP_REFRESH_INTERVAL = 60 * 1000;
const ELECTRICAL_PRICE_VARIANT = 'electricalPrice';

const trackEvent = setupTracker(groups.DASHBOARD);

const getInitialDateRange = (variant) => {
  return variant === ELECTRICAL_PRICE_VARIANT ? DateRange.Today : DateRange.Week;
};

class TileChart extends React.Component {
  static propTypes = {
    variant: PropTypes.string,
    tileData: PropTypes.object,
    tileType: PropTypes.string.isRequired,
    tileCharts: PropTypes.object.isRequired,
    getTileChartData: PropTypes.func.isRequired,
    cleanChartData: PropTypes.func.isRequired,
    isHistoryEnabled: PropTypes.bool,
    selectedSystem: PropTypes.object,
    isDashboardFree: PropTypes.bool,
  };

  startRefreshIntervalCycle = (handler) => {
    const refreshIntervalCycle = setInterval(
      handler,
      this.props.variant === TileTypes.ELECTRICAL_PRICE ? EP_REFRESH_INTERVAL : REFRESH_INTERVAL
    );
    this.setState({ refreshIntervalCycle });
  };

  disposeRefreshIntervalCycle = () => {
    if (!this.state.refreshIntervalCycle) return;

    clearInterval(this.state.refreshIntervalCycle);
    this.setState({ refreshIntervalCycle: null });
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedDateRange: getInitialDateRange(this.props.variant),
      liveUpdateRefresh: false,
      refreshIntervalCycle: null,
    };
  }

  componentDidMount() {
    if (!this.props.tileData) return;

    const { tileData, getTileChartData } = this.props;
    getTileChartData(tileData.deviceId, tileData.id, this.state.selectedDateRange);

    this.startRefreshIntervalCycle(async () => {
      this.setState({ liveUpdateRefresh: true });

      await getTileChartData(tileData.deviceId, tileData.id, this.state.selectedDateRange);
      this.setState({ liveUpdateRefresh: false });
    });
  }

  changeDateRange = (dateRange) => {
    this.setState({ ...this.state, selectedDateRange: dateRange }, () => {
      if (this.props.tileData)
        this.props.getTileChartData(this.props.tileData.deviceId, this.props.tileData.id, dateRange);
    });
  };

  componentWillUnmount() {
    this.props.cleanChartData();
    this.disposeRefreshIntervalCycle();
  }

  render() {
    const { variant, tileType, tileCharts, tileData, isHistoryEnabled, isDashboardFree, selectedSystem } = this.props;

    const userIsViewer = selectedSystem && selectedSystem.userRole === USER_SECURITY_LEVELS.viewer;
    const brandId = selectedSystem.brandId;

    const chartBackgroundColor = TileChartColors[`${brandId}_TileChartBackgroundColor`];
    const chartLineColor = TileChartColors[`${brandId}_TileChartLineColor`];
    const chartTextColor = TileChartColors[`${brandId}_TileChartTextColor`];
    const isTileAccessible = !isHistoryEnabled || isDashboardFree; //if Dashboard is free, then also Premiuim will be accessible.

    const getDateRangeSelectorClassName = (dateRange) =>
      this.state.selectedDateRange === dateRange ? 'date-range-selected' : 'date-range';

    const makeRange = (range) => () => {
      const event = [events.TILE_CHART, variant, range].join('_').toLowerCase();

      eventEmitter.emit(insightEvents.track, event);
      trackEvent(event);

      this.changeDateRange(range);
    };

    const isElectricalPriceVariant = variant === ELECTRICAL_PRICE_VARIANT;
    const noElectricalPrices = tileCharts?.points?.length === 0 && isElectricalPriceVariant;
    return (
      tileData && (
        <div className={`${tileType}__graph-wrapper tile__graph-wrapper`}>
          {noElectricalPrices ? null : <div className="graphName">{tileData.name}</div>}
          <div className={`${tileType}__graph-placeholder tile__graph-placeholder`}>
            {!this.state.liveUpdateRefresh && (!tileCharts.points || tileCharts.loading) ? (
              <Spinner />
            ) : tileCharts.points ? (
              noElectricalPrices ? (
                <div className="no-prices-cont">
                  <div className="no-prices-title">
                    <FormattedMessage id="electricalPriceTile_noPrices_title" defaultMessage="No prices yet!" />
                  </div>
                  <br />
                  <div className="no-prices-subtitle">
                    <FormattedMessage
                      id="electricalPriceTile_noPrices_subtitle"
                      defaultMessage="Tomorrow's prices will be available later today."
                    />
                  </div>
                </div>
              ) : (
                <div className="chart_wrapper">
                  <LineChart
                    points={tileCharts.points}
                    precedingPoint={tileCharts.precedingPoint}
                    predictedPoint={tileCharts.predictedPoint}
                    unit={tileData.parameter.unit}
                    selectedDateRange={this.state.selectedDateRange}
                    chartBackgroundColor={chartBackgroundColor}
                    chartLineColor={chartLineColor}
                    chartTextColor={chartTextColor}
                    curve={tileData.parameter.curveStyle}
                  />
                </div>
              )
            ) : null}
          </div>
          <div className={`${tileType}__graph-wrapper-controller tile__graph-wrapper-controller ${variant}`}>
            {isElectricalPriceVariant ? (
              <>
                {' '}
                <div
                  className={`${tileType}__graph-wrapper-controller-item tile__graph-wrapper-controller-item date-range-item ${getDateRangeSelectorClassName(
                    DateRange.Today
                  )}`}
                  onClick={makeRange(DateRange.Today)}
                >
                  <FormattedMessage class="premiumBadge" id="history.range.today" defaultMessage="Today" />
                </div>
                <div
                  className={`${tileType}__graph-wrapper-controller-item tile__graph-wrapper-controller-item date-range-item ${getDateRangeSelectorClassName(
                    DateRange.Tomorrow
                  )}`}
                  onClick={makeRange(DateRange.Tomorrow)}
                >
                  <FormattedMessage class="premiumBadge" id="history.range.tomorrow" defaultMessage="Tomorrow" />
                </div>
              </>
            ) : (
              <>
                <div
                  className={`${tileType}__graph-wrapper-controller-item tile__graph-wrapper-controller-item date-range-item ${getDateRangeSelectorClassName(
                    DateRange.Day
                  )}`}
                  onClick={makeRange(DateRange.Day)}
                >
                  <FormattedMessage class="premiumBadge" id="history.range.day" defaultMessage="Day" />
                </div>
                <div
                  className={`${tileType}__graph-wrapper-controller-item tile__graph-wrapper-controller-item date-range-item ${getDateRangeSelectorClassName(
                    DateRange.Week
                  )}`}
                  onClick={makeRange(DateRange.Week)}
                >
                  <FormattedMessage class="premiumBadge" id="history.range.week" defaultMessage="Week" />
                </div>
                <div
                  className={`${tileType}__graph-wrapper-controller-item tile__graph-wrapper-controller-item date-range-item ${getDateRangeSelectorClassName(
                    DateRange.Month
                  )}`}
                  onClick={makeRange(DateRange.Month)}
                >
                  <FormattedMessage class="premiumBadge" id="history.range.month" defaultMessage="Month" />
                </div>
                <div
                  className={`${tileType}__graph-wrapper-controller-item tile__graph-wrapper-controller-item date-range-item ${getDateRangeSelectorClassName(
                    DateRange.Year
                  )}`}
                  disabled={isDashboardFree ? false : !isHistoryEnabled}
                  onClick={isHistoryEnabled || isDashboardFree ? makeRange(DateRange.Year) : null}
                >
                  <FormattedMessage class="premiumBadge" id="history.range.year" defaultMessage="Year" />
                  {isTileAccessible && (
                    <PremiumFeatureTooltip premiumFeatureType={userIsViewer ? 'permissionAccess' : 'history'} />
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      )
    );
  }
}

export default connect(
  ({ tileCharts, features: { premiumFeatures, isDashboardFree }, app: { selectedSystem } }) => ({
    tileCharts,
    isHistoryEnabled: premiumFeatures && premiumFeatures.history,
    selectedSystem,
    isDashboardFree,
  }),
  { getTileChartData, cleanChartData }
)(TileChart);
