import * as api from '../../../../../api';
import { USER_SECURITY_LEVELS } from '../SecurityTab/costants';

export const GET_SERVICE_PARTNER_REQUEST = 'service-partner/GET_SERVICE_PARTNER_REQUEST ';
export const GET_SERVICE_PARTNER_SUCCEEDED = 'service-partner/GET_SERVICE_PARTNER_SUCCEEDED';
export const GET_SERVICE_PARTNER_FAILED = 'service-partner/GET_SERVICE_PARTNER_FAILED';

export const getServicePartner = () => async (dispatch) => {
  dispatch({ type: GET_SERVICE_PARTNER_REQUEST });
  const response = await api.getServicePartnerInfo();
  if (response.status === 200) {
    dispatch({ type: GET_SERVICE_PARTNER_SUCCEEDED, servicePartner: response.data });
  }
};

export const getServicePartnerInfo = (groupId) => async (dispatch) => {
  dispatch({ type: GET_SERVICE_PARTNER_REQUEST });
  const users = await api.getSystemUsers(groupId);

  if (users.status === 200 && users.data.length > 0) {
    const servicePartner = users.data.find((u) => u.role === USER_SECURITY_LEVELS.servicePartner);

    if (!servicePartner) return dispatch({ type: GET_SERVICE_PARTNER_FAILED });

    const response = await api.getServicePartner(servicePartner.id);
    if (response.status !== 200) return dispatch({ type: GET_SERVICE_PARTNER_FAILED });

    dispatch({ type: GET_SERVICE_PARTNER_SUCCEEDED, servicePartner: response.data });
  }
};
