import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const Tabs = ({ labels, activeTab, children, onClickTabItem }) => (
  <div className="tabBlock">
    <ol className="tabBlock-tabs">
      {labels.map((label) => {
        return (
          <li
            key={label}
            className={activeTab === label ? 'tabBlock-tab is-active' : 'tabBlock-tab'}
            onClick={() => onClickTabItem(label)}
          >
            <FormattedMessage id={label} defaultMessage="Tab" />
          </li>
        );
      })}
    </ol>
    <div className="tabBlock-content">{children}</div>
  </div>
);

Tabs.propTypes = {
  labels: PropTypes.instanceOf(Array).isRequired,
  children: PropTypes.any,
  onClickTabItem: PropTypes.func,
  activeTab: PropTypes.string,
};

export default Tabs;
