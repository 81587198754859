import {
  GET_MODES_REQUEST,
  GET_MODES_RESPONSE_SUCCESS,
  GET_WEEKLY_SCHEDULE_REQUEST,
  GET_WEEKLY_SCHEDULE_SUCCESS,
  GET_SCHEDULE_CONFIG_SUCCESS,
  SET_WEEKLY_SCHEDULE_SUCCESS,
  SET_VACATION_SCHEDULES_RESPONSE_SUCCESS,
  SET_MODES_RESPONSE_SUCCESS,
  GET_VACATION_SCHEDULES_SUCCESS,
  GET_SCHEDULE_MODE_STATUS_SUCCESS,
  SET_SCHEDULE_OVERRIDE_RESPONSE_SUCCESS,
  SET_SCHEDULE_OVERRIDE_MODE,
} from './actions';

export const defaultState = {
  modes: [],
  weeklySchedules: [],
  vacationSchedules: [],
  scheduleOverride: null,
  scheduleConfig: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_MODES_REQUEST:
      return { ...state, modes: [] };
    case GET_MODES_RESPONSE_SUCCESS:
      return { ...state, modes: action.modes };
    case SET_MODES_RESPONSE_SUCCESS:
      return { ...state, modes: action.modes };
    case SET_WEEKLY_SCHEDULE_SUCCESS:
      return { ...state, weeklySchedules: action.weeklySchedules };
    case GET_WEEKLY_SCHEDULE_REQUEST:
      return { ...state, weeklySchedules: [] };
    case GET_WEEKLY_SCHEDULE_SUCCESS:
      return { ...state, weeklySchedules: action.weeklySchedules };
    case GET_SCHEDULE_CONFIG_SUCCESS:
      return { ...state, scheduleConfig: action.scheduleConfig };
    case SET_VACATION_SCHEDULES_RESPONSE_SUCCESS:
    case GET_VACATION_SCHEDULES_SUCCESS:
      return { ...state, vacationSchedules: action.vacationSchedules };
    case GET_SCHEDULE_MODE_STATUS_SUCCESS:
      return { ...state, scheduleOverride: action.scheduleModeStatus };
    case SET_SCHEDULE_OVERRIDE_RESPONSE_SUCCESS:
      return { ...state, scheduleOverride: action.scheduleOverride };
    case SET_SCHEDULE_OVERRIDE_MODE:
      return { ...state, scheduleOverride: { ...state.scheduleOverride, modeId: action.modeId } };
    default:
      return state;
  }
};
