import { ACCEPT_AGREEMENT_RESPONSE, GET_USERS_AGREEMENTS_RESPONSE } from './actions';

const initialState = {
  acceptedAgreements: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACCEPT_AGREEMENT_RESPONSE:
      return { ...state, acceptedAgreements: [...state.acceptedAgreements, action.agreement] };
    case GET_USERS_AGREEMENTS_RESPONSE:
      return { ...state, acceptedAgreements: action.agreements };
    default:
      return state;
  }
};
