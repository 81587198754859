const skiplinkLogin = (intl) => [
  {
    href: '#login',
    text: intl.formatMessage({
      id: 'skiplink.login.login',
      defaultMessage: 'Skip to Log in form',
    }),
  },
];

const skiplinkCreateAccount = (intl) => [
  {
    href: '#create-account',
    text: intl.formatMessage({
      id: 'skiplink.create-account.login',
      defaultMessage: 'Skip to Create Account form',
    }),
  },
];

const skiplinkResetPassword = (intl) => [
  {
    href: '#reset-password',
    text: intl.formatMessage({
      id: 'skiplink.reset-password.login',
      defaultMessage: 'Skip to Reset password form',
    }),
  },
];

const skiplinkConfirmation = (intl) => [
  {
    href: '#back-to-login',
    text: intl.formatMessage({
      id: 'skiplink.reset-password.login',
      defaultMessage: 'Back to Log in form',
    }),
  },
];

const skiplinkForgotPassword = (intl) => [
  {
    href: '#forgot-password',
    text: intl.formatMessage({
      id: 'skiplink.reset-password.login',
      defaultMessage: 'Skip to Reset password form',
    }),
  },
];

const sliderLink = (intl) => ({
  href: '#slider',
  text: intl.formatMessage({
    id: 'skiplink.login.slider',
    defaultMessage: 'Skip to Intro Screens',
  }),
});

const mainContentLink = (intl) => ({
  href: '#main',
  text: intl.formatMessage({
    id: 'skiplink.login.main',
    defaultMessage: 'Skip to Main Content',
  }),
});

const footerLink = (intl) => ({
  href: '#footer',
  text: intl.formatMessage({
    id: 'skiplink.login.footer',
    defaultMessage: 'Skip to Footer',
  }),
});

export const getLinkSet = (path, intl) => {
  let res = [];

  switch (path) {
    case '/login':
      res = skiplinkLogin(intl);
      break;
    case '/register':
      res = skiplinkCreateAccount(intl);
      break;
    case '/password-reset':
      res = skiplinkResetPassword(intl);
      break;
    case '/confirmation-link':
      res = skiplinkConfirmation(intl);
      break;
    case '/forgot-password':
      res = skiplinkForgotPassword(intl);
      break;
  }

  return [sliderLink(intl), ...res, footerLink(intl)];
};

export const getLinkSetForLoggedInUser = (intl) => [mainContentLink(intl), footerLink(intl)];
