import React from 'react';
import PropTypes from 'prop-types';
import { defaultValueLowerLimit } from '../../constants/constants';

import './indicator.scss';

const getValueLengthClass = (length) => {
  if (length < 13) return 'value-xl';
  if (length < 19) return 'value-lg';
  return 'value-md';
};

const formatValue = (value) => {
  const noneValue = '--';
  let formattedValue = value || noneValue;

  try {
    const number = Math.sign(Number(value)) * Math.round(Math.abs(Number(value)));
    if (!isNaN(number)) {
      if (number < defaultValueLowerLimit) {
        // predefined invalid value for a device data. CLOUDTEAM-3141
        formattedValue = noneValue;
      } else {
        formattedValue = number;
      }
    }
  } catch (err) {
    formattedValue = noneValue;
  }
  return formattedValue;
};

const getFormattedValueLen = (formattedValue, unit) => {
  const valueTextLength = formattedValue.toString().length;
  return unit ? valueTextLength + unit.length + 1 : valueTextLength; //+ for &nbsp;
};

const Indicator = ({ value, title, unit }) => {
  const formattedValue = formatValue(value);
  const valueTextLength = getFormattedValueLen(formattedValue, unit);
  const unitIsString = unit === 'String';
  const displayUnit = unitIsString ? '' : unit;
  return (
    <>
      {title ? (
        <div className="indicator">
          <div className="indicator__value">
            <div className={`indicator__value-text ${getValueLengthClass(valueTextLength)}`}>
              {unit && unit.trim() === '$' ? (
                <>
                  {displayUnit}
                  {formattedValue}
                </>
              ) : (
                <>
                  <span className="val">{formattedValue}</span>&nbsp;
                  {!!unit && displayUnit}
                </>
              )}
            </div>
            <span className="indicator__title">{title}</span>
          </div>
        </div>
      ) : (
        <div className="indicator"></div>
      )}
    </>
  );
};

Indicator.defaultProps = {
  value: '',
  title: '',
  unit: '',
};

Indicator.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.string,
  unit: PropTypes.string,
};

export default Indicator;
