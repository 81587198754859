import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from '../../../../../../Link';
import StoreLink from '../../../StoreLink/StoreLink';
import MenuContext from '../../../../MenuContext';

class HamburgerSubMenuItem extends Component {
  state = {
    hover: false,
  };

  handleHover() {
    this.setState({ hover: !this.state.hover });
  }

  render() {
    const { menuItem, redirectIotStore, selectedSystem, logoutUser } = this.props;
    const { hover } = this.state;
    const styles = {
      menuItem: {
        color: hover ? 'gray' : '#fafafa',
      },
    };

    let menuItemContent;

    if (menuItem.disabled) {
      menuItemContent = (
        <div key={menuItem.id} className="hamburgerMenuItem__menuItemLink-disabled">
          <FormattedMessage id={menuItem.id} defaultMessage={menuItem.name} />
        </div>
      );
    }
    if (menuItem.id === 'menu.items.iot-store') {
      menuItemContent = (
        <StoreLink
          val={menuItem}
          redirectIotStore={redirectIotStore}
          className="hamburgerMenuItem__menuItemStoreLink"
          selectedSystem={selectedSystem}
        />
      );
    }
    if (menuItem.id === 'menu.items.logout') {
      menuItemContent = (
        <div className="hamburgerMenuItem__button hamburgerMenuItem__menuItemLink" onClick={logoutUser}>
          <FormattedMessage id={menuItem.id} defaultMessage={menuItem.name} />
        </div>
      );
    }

    return (
      <MenuContext.Consumer>
        {(context) => (
          <div
            className="hamburgerMenuItem__menuItem"
            style={styles.menuItem}
            onMouseEnter={() => {
              this.handleHover();
            }}
            onMouseLeave={() => {
              this.handleHover();
            }}
            role="link"
            tabIndex={0}
          >
            {menuItemContent || (
              <Link key={menuItem.id} className={'hamburgerMenuItem__menuItemLink'} to={menuItem.path}>
                {menuItem.id === 'menu.items.devices' && context.deviceUpdateAvailable && (
                  <span className="badge badge-danger">!</span>
                )}
                {(menuItem.menuName && menuItem.menuName(context)) || (
                  <FormattedMessage id={menuItem.id} defaultMessage={menuItem.name} />
                )}
              </Link>
            )}
          </div>
        )}
      </MenuContext.Consumer>
    );
  }
}

HamburgerSubMenuItem.propTypes = {
  menuItem: PropTypes.object,
  redirectIotStore: PropTypes.func,
  selectedSystem: PropTypes.object,
  logoutUser: PropTypes.func,
};

export default HamburgerSubMenuItem;
