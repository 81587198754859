import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import './RadioGroup.scss';
import PremiumFeatureTooltip from '../../layout/PremiumFeatureTooltip';

const RadioGroup = (props) => {
  const {
    id,
    items,
    onChange,
    labelGetter = (item) => item.text,
    selectedItem: initialSelectedItem,
    comparator = (a, b) => a === b,
    disabled,
    premiumFeatureType,
  } = props;

  const [selectedItem, setSelectedItem] = useState(initialSelectedItem);
  const timeout = useRef(null);
  const selectedItemRef = useRef(selectedItem);
  selectedItemRef.current = selectedItem;

  const sendChangeRequest = async (item) => {
    setSelectedItem(item);
    if (!onChange) return;

    clearTimeout(timeout.current);

    timeout.current = setTimeout(async () => {
      timeout.current = null;

      if (item !== selectedItemRef.current) {
        return;
      }

      await onChange(item);
    }, 1000);
  };

  useEffect(() => {
    if (timeout.current || initialSelectedItem !== selectedItemRef.current) return;

    setSelectedItem(initialSelectedItem);

    return () => {
      clearTimeout(timeout.current);
    };
  }, [initialSelectedItem]);

  const handleChange = (event, item) => {
    setSelectedItem(item);
    sendChangeRequest(item);
  };

  const getControlId = (i) => `${id}_${i}`;

  return (
    <div className="radio-group-wrapper">
      {items.map((item, i) => (
        <div className="radioGroupComponent" key={getControlId(i)}>
          <input
            className="radioGroupComponent__input"
            type="radio"
            id={getControlId(i)}
            name={id}
            value={item.value}
            disabled={disabled || item.disabled}
            checked={selectedItem ? comparator(selectedItem, item) : item.checked}
            onChange={(event) => handleChange(event, item)}
          />
          <PremiumFeatureTooltip premiumFeatureType={premiumFeatureType ? premiumFeatureType : 'option'} />
          <label className="radioGroupComponent__label" htmlFor={getControlId(i)}>
            {labelGetter(item)}
          </label>
        </div>
      ))}
    </div>
  );
};

RadioGroup.propTypes = {
  id: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func,
  labelGetter: PropTypes.func,
  selectedItem: PropTypes.object,
  comparator: PropTypes.func,
  disabled: PropTypes.bool,
  premiumFeatureType: PropTypes.string,
};

export default RadioGroup;
