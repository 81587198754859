import ctcIcons from './ctc';
import enertechIcons from './enertech';
import myUplinkIcons from './myuplink';
import nibeIcons from './nibe';
import climatemasterIcons from './climatemaster';
import conturaIcons from './contura';
import nibefIcons from './nibef';
import aitIcons from './ait';
import iecIcons from './iec';
import novelanIcons from './novelan';
import ctaIcons from './cta';
import ctaaitv3Icons from './ctaaitv3';
import _ from 'lodash';

export default {
  ait: aitIcons,
  nibe: nibeIcons,
  ctc: ctcIcons,
  enertech: enertechIcons,
  climatemaster: climatemasterIcons,
  iec: iecIcons,
  contura: conturaIcons,
  nibef: nibefIcons,
  default: myUplinkIcons,
  novelan: novelanIcons,
  cta: ctaIcons,
  ctaaitv3: ctaaitv3Icons,
};

const CTA = 'cta';
const CTA_FIRMWARE_ELIGIBLE_FOR_ICONS = 'ait-V3';

export const getTileIcon = (icons, brandId, tileType, firmwareIds) => {
  const isAitV3 = firmwareIds?.includes(CTA_FIRMWARE_ELIGIBLE_FOR_ICONS);
  let brandName = brandId && brandId.toLowerCase();

  brandName = brandName === CTA && isAitV3 ? 'ctaaitv3' : brandName;

  const brandIcons = icons[brandName] || icons.default;
  return brandIcons[tileType] || icons.default.status;
};

export const getWeatherIcon = (icons, brandId, weatherIcon) => {
  const iconId = _.camelCase(weatherIcon);
  const brandName = brandId && brandId.toLowerCase();
  const brandIcons = icons[brandName] || icons.default;

  return brandIcons[iconId] || icons.default[iconId];
};

export const getParameterIcon = (parameter) => {
  return parameter.parameter.enumIconId
    ? getEnumIconId(parameter.parameter, parameter.value)
    : parameter.parameter.iconId;
};

const getEnumIconId = (parameter, enumValue) => {
  const iconIds = parameter.enumIconId.split(',');
  const enumValues = parameter.enumVal.split(',');
  const valueIdx = enumValue === null || enumValue === undefined ? -1 : enumValues.indexOf(enumValue.toString());

  return valueIdx >= 0 && valueIdx < iconIds.length ? iconIds[valueIdx] : '';
};
