import React from 'react';
import PropTypes from 'prop-types';

class BrandStylesLoader extends React.Component {
  static propTypes = {
    children: PropTypes.any,
    brandId: PropTypes.string,
  };

  state = {
    plugedCss: [],
  };

  plugBrandCss = (brandId) => {
    if (!this.state.plugedCss.some((b) => b === brandId)) {
      const lnk = document.createElement('link');
      lnk.type = 'text/css';
      lnk.href = '/assets/brands/' + brandId + '/styles.css';
      lnk.rel = 'stylesheet';
      document.getElementsByTagName('head')[0].appendChild(lnk);

      this.setState({ plugedCss: [...this.state.plugedCss, brandId] });
    }
  };

  componentDidMount() {
    this.plugBrandCss(this.props.brandId);
  }

  componentDidUpdate() {
    this.plugBrandCss(this.props.brandId);
  }

  render() {
    const { children, brandId } = this.props;

    return <div className={`branded-page--${brandId}`}>{children}</div>;
  }
}

export default BrandStylesLoader;
