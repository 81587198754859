import {
  CONNECT_USER_REQUEST,
  CONNECT_USER_RESPONSE_SUCCEEDED,
  CONNECT_USER_RESPONSE_FAILED,
  DISCONNECT_USER_REQUEST,
  DISCONNECT_USER_RESPONSE_SUCCEEDED,
  DISCONNECT_USER_RESPONSE_FAILED,
  GET_USERS_REQUEST,
  GET_USERS_RESPONSE,
  PATCH_USER_SECURITY_LEVEL_REQUEST,
  PATCH_USER_SECURITY_LEVEL_RESPONSE_FAILED,
  PATCH_USER_SECURITY_LEVEL_RESPONSE_SUCCEEDED,
  GET_SYSTEM_ROLES_REQUEST,
  GET_SYSTEM_ROLES_RESPONSE,
} from './actions';

const initialState = {
  usersLoading: false,
  users: [],
  userConnectLoading: false,
  userConnectInfo: null,
  userConnectStatus: null,
  userAdded: false,
  userUpdateLoading: false,
  userUpdated: false,
  userRemoveLoading: false,
  userRemoved: false,
  roles: [],
  rolesLoading: false,
};

const updateUserSecurityLevel = (users, userId, securityLevel) => {
  return users.map((user) => {
    if (user.id === userId) user = { ...user, role: securityLevel };
    return user;
  });
};

const removeUserFromState = (users, userId) => {
  return users.filter((u) => u.id !== userId);
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CONNECT_USER_REQUEST:
      return {
        ...state,
        userConnectLoading: true,
        userAdded: false,
        userConnectInfo: null,
        userConnectStatus: null,
      };
    case CONNECT_USER_RESPONSE_SUCCEEDED:
      return {
        ...state,
        userConnectLoading: false,
        userAdded: true,
        userConnectStatus: action.status,
        userConnectInfo: action.info,
      };
    case CONNECT_USER_RESPONSE_FAILED:
      return {
        ...state,
        userConnectLoading: false,
        userAdded: false,
        userConnectStatus: action.status,
      };
    case DISCONNECT_USER_REQUEST:
      return { ...state, userRemoveLoading: true, userRemoved: false };
    case DISCONNECT_USER_RESPONSE_SUCCEEDED:
      return {
        ...state,
        userRemoveLoading: false,
        userRemoved: true,
        users: removeUserFromState([...state.users], action.removedUserId),
      };
    case DISCONNECT_USER_RESPONSE_FAILED:
      return { ...state, userRemoveLoading: false, userRemoved: false };
    case GET_USERS_REQUEST:
      return { ...state, usersLoading: true };
    case GET_USERS_RESPONSE:
      return { ...state, usersLoading: false, users: action.users };
    case PATCH_USER_SECURITY_LEVEL_REQUEST:
      return { ...state, userUpdateLoading: true, userUpdated: false };
    case PATCH_USER_SECURITY_LEVEL_RESPONSE_SUCCEEDED:
      return {
        ...state,
        userUpdateLoading: false,
        userUpdated: true,
        users: updateUserSecurityLevel([...state.users], action.userId, action.securityLevel),
      };
    case PATCH_USER_SECURITY_LEVEL_RESPONSE_FAILED:
      return { ...state, userUpdateLoading: false, userUpdated: false };
    case GET_SYSTEM_ROLES_REQUEST:
      return { ...state, rolesLoading: true };
    case GET_SYSTEM_ROLES_RESPONSE:
      return { ...state, rolesLoading: false, roles: action.roles };
    default:
      return state;
  }
};
