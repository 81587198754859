import * as api from '../../../api';

export const GET_STATUS_REQUEST = 'systemStatus/GET_STATUS_REQUEST';
export const GET_STATUS_REQUEST_SUCCEEDED = 'systemStatus/GET_STATUS_REQUEST_SUCCEEDED';
export const GET_STATUS_REQUEST_FAILED = 'systemStatus/GET_STATUS_REQUEST_FAILED';

export const SET_STATUS_REQUEST = 'systemStatus/SET_STATUS_REQUEST';
export const SET_STATUS_REQUEST_SUCCEEDED = 'systemStatus/SET_STATUS_REQUEST_SUCCEEDED';
export const SET_STATUS_REQUEST_FAILED = 'systemStatus/SET_STATUS_REQUEST_FAILED';

export const SET_SMART_HOME_MODE = 'smartHomeMode/SET_SMART_HOME_MODE';

export const statuses = {
  online: 'online',
  offline: 'offline',
};

export const smartHomeMode = {
  default: 'default',
  away: 'away',
};

export const getSystemStatus = (groupId) => async (dispatch) => {
  dispatch({ type: GET_STATUS_REQUEST });

  const response = await api.getGroupStatus(groupId);
  if (response.status === 200) {
    const status = response.data.online ? 'online' : 'offline';
    if (window.location.pathname === '/device-scheduling' && status === 'offline') {
      setTimeout(() => {
        dispatch({
          type: GET_STATUS_REQUEST_SUCCEEDED,
          data: { id: groupId, status: status, online: response.data.online },
        });
      }, 10 * 1000);
      return status;
    }
    dispatch({
      type: GET_STATUS_REQUEST_SUCCEEDED,
      data: { id: groupId, status: status, online: response.data.online },
    });
    return true;
  }
  dispatch({ type: GET_STATUS_REQUEST_FAILED, data: {} });

  return false;
};

export const loadSmartHomeMode = (groupId) => async (dispatch) => {
  const response = await api.getSmartHomeMode(groupId);
  if (response.status === 200) {
    dispatch({
      type: SET_SMART_HOME_MODE,
      smartHomeMode: response.data.smartHomeMode,
    });
  }
};

export const setSmartHomeMode = (groupId, smartHomeMode) => async (dispatch) => {
  dispatch({ type: SET_STATUS_REQUEST });

  try {
    const response = await api.setSmartHomeMode(groupId, smartHomeMode);
    if (response.status === 200) {
      dispatch({ type: SET_STATUS_REQUEST_SUCCEEDED, smartHomeMode });
    } else dispatch({ type: SET_STATUS_REQUEST_FAILED });
  } catch {
    dispatch({ type: SET_STATUS_REQUEST_FAILED });
  }
};
