import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { sendConfirmationLink } from '../Register/actions';
import { Button } from 'jpi-cloud-web-ui-components';

class SendConfirmEmailLink extends React.Component {
  static propTypes = {
    sendConfirmationLink: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    user: PropTypes.any,
  };

  state = {
    isSubmitting: false,
  };

  onSendConfirmLink = async () => {
    this.setState({ isSubmitting: true });
    if (this.props.user) {
      await this.props.sendConfirmationLink(this.props.user.id);
    }

    setTimeout(
      function () {
        this.setState({ isSubmitting: false });
      }.bind(this),
      2000
    );
  };

  goToLogin = () => {
    this.props.history.push('/login');
  };

  render() {
    return (
      <div className="form">
        <h1 className="form__heading">
          <FormattedMessage id="sendConfirmation.heading" defaultMessage="Confirmation link was sent to your email" />
        </h1>
        <div className="form__inputWrapper">
          <div className="button-wrapper--large">
            <Button
              className="button--secondary space-bottom"
              type="button"
              onClick={this.goToLogin}
              disabled={this.state.isSubmitting}
              id="back-to-login"
            >
              <FormattedMessage id="login.login" defaultMessage="Log in" />
            </Button>
            <Button
              className="button--default"
              type="button"
              onClick={this.onSendConfirmLink}
              disabled={this.state.isSubmitting}
            >
              <FormattedMessage id="sendConfirmationButton.heading" defaultMessage="Send again" />
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  ({ register }) => ({
    ...register,
  }),
  {
    sendConfirmationLink,
  }
)(SendConfirmEmailLink);
