import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Title } from 'jpi-cloud-web-ui-components';

const MessageInboxHeader = () => (
  <div className="message-inbox-description">
    <div className="description-wrapper">
      <Title titleTranslationId="alarms.message-inbox-header" defaultMessage="Messages Inbox" />
      <div className="description-text">
        <FormattedMessage
          id="alarms.message-inbox-description"
          defaultMessage="Here you'll find messages regarding your systems"
        />
      </div>
    </div>
    <div className="description-icon">
      <i className="fas fa-mail-bulk"></i>
    </div>
  </div>
);

export default MessageInboxHeader;
