import { API_URL, API } from './index';
import { EL_PRICE_PARAM_ID } from '../components/constants/constants';

export const getPoints = async (deviceId, parameterId, aggregationUnit, method, dateFrom, dateTo, history) => {
  const validateStatus = (s) => s === 200 || s === 400;
  const urlBase = `${API_URL}/v2/devices/${deviceId}`;

  const url =
    parameterId === EL_PRICE_PARAM_ID && !history
      ? `${urlBase}/${dateFrom}/${dateTo}/electrical-prices`
      : `${urlBase}/points/${parameterId}/${dateFrom}/${dateTo}/${method}/${aggregationUnit}`;

  const res = await API.get(url, { validateStatus });
  return res.status === 200 ? res.data : [];
};

export const getPrecedingPoint = async (deviceId, parameterId, date) => {
  if (parameterId === EL_PRICE_PARAM_ID) return;

  try {
    const res = await API.get(`${API_URL}/v2/devices/${deviceId}/points/${parameterId}/preceding/${date}`);
    return res.status === 200 ? res.data : null;
  } catch (err) {
    console.warn(err);
    return null;
  }
};
