import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import uuid from 'uuid';
import { Button, Spinner } from 'jpi-cloud-web-ui-components';
import Modal from '../../../../layout/Modal';
import ConfirmEventExceededPopup from '../CreateEventPopup/ConfirmEventExceededPopup';
import { setWeeklySchedules } from '../../actions';

import { humanStringToDayIndex, getPrevDay, getNextDay, dayIndexToDeviceDay } from '../../weekFormats';

import '../../device-scheduling.scss';

const EVENTS_CONFIRMATION_TYPE = {
  eventsCountExceeded: 'eventsCountExceeded',
};

class CopySchedulePopup extends React.Component {
  static propTypes = {
    currentEditableDay: PropTypes.object.isRequired,
    schedule: PropTypes.object.isRequired,
    scheduleConfig: PropTypes.object.isRequired,
    scheduleDays: PropTypes.array.isRequired,
    devices: PropTypes.array.isRequired,
    setWeeklySchedules: PropTypes.func.isRequired,
    weeklySchedules: PropTypes.array,
    isPopupVisible: PropTypes.bool.isRequired,
    closePopup: PropTypes.func.isRequired,
    getEventsCount: PropTypes.func.isRequired,
  };

  state = {
    loading: false,
    scheduleDays: [],
    eventConfirmationStatus: false,
  };

  componentDidMount() {
    const { scheduleDays, currentEditableDay } = this.props;
    this.setState({
      scheduleDays: scheduleDays.filter((d) => d.name !== currentEditableDay.name),
    });
  }

  getStartDay = (day) => {
    for (let dayOfWeek = 0; dayOfWeek <= 6; ++dayOfWeek) {
      if (day.comparer(dayOfWeek)) {
        return dayOfWeek;
      }
    }

    return 0;
  };

  getDeviceStartDay = (day) => {
    return dayIndexToDeviceDay(this.getStartDay(day));
  };

  selectCopyDay = (sd) => {
    this.setState({
      scheduleDays: this.state.scheduleDays.map((d) => (d.name === sd.name ? { ...d, selected: !d.selected } : d)),
    });
  };

  confirmExceededEvents = () => {
    const { closePopup } = this.props;
    closePopup();
  };

  copyEventsWithStopTimes = async () => {
    const { scheduleDays } = this.state;
    const {
      schedule,
      scheduleConfig,
      currentEditableDay,
      weeklySchedules,
      devices,
      closePopup,
      setWeeklySchedules,
      getEventsCount,
    } = this.props;
    const selectedDays = scheduleDays.filter((d) => d.selected);

    let newEventsSet = [...schedule.events];
    selectedDays.forEach((day) => {
      // clear destination day events
      newEventsSet = newEventsSet.filter((e) => day.comparer(e.startDay) === false);
    });

    // arrange prev day stop time to midnight if needed
    selectedDays.forEach((day) => {
      const prevDay = getPrevDay(humanStringToDayIndex(day.name));

      newEventsSet = newEventsSet.map((ev) => {
        const currentEventStartDay = humanStringToDayIndex(ev.startDay);
        if (currentEventStartDay === prevDay && ev.startDay !== ev.stopDay) {
          return {
            ...ev,
            stopTime: '23:59:59',
            stopDay: dayIndexToDeviceDay(ev.startDay),
          };
        }
        return { ...ev };
      });
    });

    const eventsToCopy = selectedDays
      .map((d) =>
        schedule.events
          .filter((e) => currentEditableDay.comparer(e.startDay))
          .map((e) => ({
            ...e,
            startDay: this.getDeviceStartDay(d),
            stopDay:
              e.startTime.localeCompare(e.stopTime) >= 0
                ? dayIndexToDeviceDay(getNextDay(humanStringToDayIndex(this.getStartDay(d))))
                : this.getDeviceStartDay(d),
            phantom_id: uuid(),
          }))
      )
      .reduce((acc, i) => [...acc, ...i], []);

    newEventsSet = [...newEventsSet, ...eventsToCopy];

    const totalEventsCount = getEventsCount({ ...schedule, events: newEventsSet });

    if (totalEventsCount > scheduleConfig.maxEventsNumber) {
      // raise limit exceeded error
      this.setState({
        eventConfirmationStatus: EVENTS_CONFIRMATION_TYPE.eventsCountExceeded,
      });
      return;
    }

    const newWeeklySchedules = weeklySchedules.map((ws) => {
      if (ws.weeklyScheduleId === schedule.weeklyScheduleId) {
        return {
          ...ws,
          events: newEventsSet,
        };
      }
      return ws;
    });

    this.setState({ loading: true });
    await setWeeklySchedules(devices[0].id, newWeeklySchedules);
    this.setState({ loading: false });

    closePopup();
  };

  copyEvents = async () => {
    const {
      schedule,
      currentEditableDay,
      weeklySchedules,
      devices,
      setWeeklySchedules,
      closePopup,
      scheduleConfig,
      getEventsCount,
    } = this.props;

    if (scheduleConfig.stopTimeAvailable && scheduleConfig.allowUnscheduled) {
      this.copyEventsWithStopTimes();
      return;
    }

    const { scheduleDays } = this.state;
    const selectedDays = scheduleDays.filter((d) => d.selected);

    const eventsToCopy = selectedDays
      .map((d) =>
        schedule.events
          .filter((e) => currentEditableDay.comparer(e.startDay))
          .map((e) => ({ ...e, startDay: this.getDeviceStartDay(d), phantom_id: uuid() }))
      )
      .reduce((acc, i) => [...acc, ...i], []);

    const currentWs = weeklySchedules.find((ws) => ws.weeklyScheduleId === schedule.weeklyScheduleId);
    let currentEventsCount = 0;
    if (currentWs && currentWs.events) {
      const eventsAfterProcess = currentWs.events.filter((e) => !selectedDays.some((d) => d.comparer(e.startDay)));
      const newSchedule = {
        ...currentWs,
        events: [...eventsAfterProcess],
      };

      if (eventsAfterProcess) {
        currentEventsCount = getEventsCount(newSchedule);
      }
    }

    if (currentEventsCount + eventsToCopy.length > scheduleConfig.maxEventsNumber) {
      // rise limit exceeded error
      this.setState({
        eventConfirmationStatus: EVENTS_CONFIRMATION_TYPE.eventsCountExceeded,
      });

      return;
    }

    const newWeeklySchedules = weeklySchedules.map((ws) => {
      if (ws.weeklyScheduleId === schedule.weeklyScheduleId) {
        const events = [
          ...ws.events.filter((e) => !selectedDays.some((d) => d.comparer(e.startDay))),
          ...eventsToCopy,
        ].flat();

        return {
          ...ws,
          events,
        };
      }
      return ws;
    });

    const totalEventsCount = getEventsCount(
      newWeeklySchedules.find((ws) => ws.weeklyScheduleId === schedule.weeklyScheduleId)
    );
    if (totalEventsCount > scheduleConfig.maxEventsNumber) {
      // raise limit exceeded error
      this.setState({
        eventConfirmationStatus: EVENTS_CONFIRMATION_TYPE.eventsCountExceeded,
      });
      return;
    }

    this.setState({ loading: true });
    await setWeeklySchedules(devices[0].id, newWeeklySchedules);
    this.setState({ loading: false });

    closePopup();
  };

  render() {
    const { isPopupVisible, currentEditableDay, closePopup } = this.props;
    const { scheduleDays, loading, eventConfirmationStatus } = this.state;

    return (
      <Modal show={isPopupVisible} backdrop="static">
        <div className="mode-popup">
          <h1 className="titleComponent">
            <FormattedMessage id="button.copy" defaultMessage="Copy" />{' '}
            <FormattedMessage id={`scheduling.${currentEditableDay.name}`} />
          </h1>

          {loading && <Spinner dark />}
          {!loading && !eventConfirmationStatus && (
            <>
              <div className="popup-body">
                <div className="popup-header">
                  <p className="text">
                    <FormattedMessage
                      id="scheduling.copy-events.description"
                      defaultMessage="Choose which days to copy to"
                    />
                  </p>
                </div>
                <div className="popup-main">
                  {scheduleDays
                    .filter((d) => d.name !== currentEditableDay.name)
                    .map((d) => (
                      <div
                        key={`day#${d.name}`}
                        onClick={() => this.selectCopyDay(d)}
                        className={classNames({ 'day-selector-item': true, 'day-selector-item--selected': d.selected })}
                      >
                        <FormattedMessage id={`scheduling.${d.name}`} />
                      </div>
                    ))}
                </div>
              </div>
              <p className="text--small">
                <FormattedMessage
                  id="scheduling.copy-events.warning"
                  defaultMessage="Copying a schedule will replace what is currently scheduled on the selected days"
                />
              </p>
              <div className="popup-bottom-panel">
                <Button
                  className="button--secondary"
                  type="submit"
                  onClick={this.copyEvents}
                  disabled={!scheduleDays.some((d) => d.selected)}
                >
                  <FormattedMessage id="button.copy" defaultMessage="Copy" />
                </Button>
                <Button onClick={closePopup} className="button--default" type="button">
                  <FormattedMessage id="scheduling.title.cancel" defaultValue="Cancel" />
                </Button>
              </div>
            </>
          )}

          {!loading && eventConfirmationStatus == EVENTS_CONFIRMATION_TYPE.eventsCountExceeded && (
            <ConfirmEventExceededPopup proceedConfirm={this.confirmExceededEvents} />
          )}
        </div>
      </Modal>
    );
  }
}

export default connect(
  ({ devices: { devices }, deviceScheduling: { weeklySchedules, scheduleConfig } }) => ({
    devices,
    weeklySchedules,
    scheduleConfig,
  }),
  {
    setWeeklySchedules,
  }
)(CopySchedulePopup);
