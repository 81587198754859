import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import { DragDropContext, DropTarget } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { FormattedMessage } from 'react-intl';

import {
  moveTile,
  getTiles,
  addTile,
  removeTile,
  expandTile,
  minimizeTile,
  editTile,
  editTileSetting,
  getPrepopulationStatus,
  setSystemAsPrepopulated,
  getIsTileRefreshNeeded,
  setTilesLastRefreshed,
  refreshTileParameters,
  refreshTileTypes,
  getWeatherTileData,
} from './actions';

import {
  startTileCreation,
  startTileEditing,
  selectTileTypeSilent,
  getTileTypes,
  getTileTypesSilent,
  getTileParameters,
  setDisplayParameterSilent,
  setChartValueSilent,
  setSettingsParametersSilent,
  saveTileSilent,
  migrateTileParameters,
  TILE_MIGRATION_VERSION,
} from '../TileConfigurationPopup/actions';
import { getDevices } from '../../pages/Devices/actions';

import { ParameterTypes, sortByPrio } from '../TileConfigurationPopup/TilePropertyEditor';
import TileConfigurationPopup from '../TileConfigurationPopup/TileConfigurationPopup';
import TileRemovalConfirmationPopup from './TileRemovalConfirmationPopup';
import TilesDataUpdater from '../TileData/TilesDataUpdater';
import { TileTypes } from './TileTypes';
import TileData from '../TileData';
import AddTile from './AddTile';
import Tile from '../Tile';

import GenericTile from '../GenericTile';
import SmartGuide from '../SmartGuide';

import './tile-manager.scss';

const notificationTimeoutMilliseconds = 3000;

// See Tiles Specification at https://nibejpi.atlassian.net/wiki/spaces/JCT/pages/376995870/Tiles+Specifications
// requiredTileTypes are auto populated when adding a new system
// Order of tiles should match the List Order in the specification
const requiredTileTypes = [
  { id: TileTypes.SMART_GUIDE, tileListPrio: 10, tileNameTextId: '' },
  { id: TileTypes.INDOOR_CLIMATE, tileListPrio: 20, tileNameTextId: '' },
  { id: TileTypes.WEATHER_OUTDOOR_TEMPERATURE, tileListPrio: 30, tileNameTextId: '' },
  { id: TileTypes.DOMESTIC_HOT_WATER, tileListPrio: 40, tileNameTextId: '' },
  { id: TileTypes.VENTILATION, tileListPrio: 50, tileNameTextId: '' },
  { id: TileTypes.POOL, tileListPrio: 60, tileNameTextId: '' },
  { id: TileTypes.SOLAR_PANEL, tileListPrio: 70, tileNameTextId: '' },
];

class TileManager extends React.Component {
  static propTypes = {
    loading: PropTypes.bool,
    init: PropTypes.bool,
    tiles: PropTypes.array.isRequired,
    devices: PropTypes.array,
    selectedSystemId: PropTypes.string,
    brandId: PropTypes.string,
    getTileTypes: PropTypes.func.isRequired,
    getTileTypesSilent: PropTypes.func.isRequired,
    isTypesLoaded: PropTypes.bool,
    selectTileTypeSilent: PropTypes.func.isRequired,
    getPrepopulationStatus: PropTypes.func.isRequired,
    tileParameters: PropTypes.array,
    getTileParameters: PropTypes.func.isRequired,
    setDisplayParameterSilent: PropTypes.func.isRequired,
    setChartValueSilent: PropTypes.func.isRequired,
    setSettingsParametersSilent: PropTypes.func.isRequired,
    saveTileSilent: PropTypes.func.isRequired,
    migrateTileParameters: PropTypes.func.isRequired,
    getIsTileRefreshNeeded: PropTypes.func.isRequired,
    setTilesLastRefreshed: PropTypes.func.isRequired,
    refreshTileParameters: PropTypes.func.isRequired,
    refreshTileTypes: PropTypes.func.isRequired,
    getWeatherTileData: PropTypes.func.isRequired,
  };

  static defaultProps = {
    tiles: [],
    expandedTile: null,
    init: false,
    loading: false,
  };

  state = {
    isRemovePopupOpen: false,
    tileToRemove: null,
    refreshIntervalCycle: null,
  };

  async fetchTiles(systemId) {
    return await this.props.getTiles(systemId);
  }

  isMigrateNeeded() {
    return this.props.tiles.some((tile) => tile.__migration_version !== TILE_MIGRATION_VERSION);
  }

  startRefreshIntervalCycle = (handler) => {
    const refreshIntervalCycle = setInterval(handler, 10 * 60 * 1000);
    this.setState({ refreshIntervalCycle });
  };

  disposeRefreshIntervalCycle = (isSettable) => {
    if (this.state.refreshIntervalCycle) {
      clearInterval(this.state.refreshIntervalCycle);
      if (isSettable) {
        this.setState({ refreshIntervalCycle: null });
      }
    }
  };

  async componentDidMount() {
    const { userId, selectedSystemId, migrateTileParameters, getIsTileRefreshNeeded, getWeatherTileData } = this.props;
    const oldTiles = await this.fetchTiles(selectedSystemId);

    await getWeatherTileData(selectedSystemId);
    this.startRefreshIntervalCycle(async () => {
      await getWeatherTileData(selectedSystemId);
    });

    const prepopulationRequired = await this.isPrepopulationRequired(userId, selectedSystemId, oldTiles);
    if (prepopulationRequired) {
      this.prepopulateTiles(userId, selectedSystemId);
    }

    this.isMigrateNeeded() && migrateTileParameters(oldTiles, selectedSystemId);

    const tileRefreshNeeded = await getIsTileRefreshNeeded(userId, selectedSystemId);
    if (tileRefreshNeeded) {
      await this.refreshTiles(userId, selectedSystemId, oldTiles);
    }
  }

  async componentDidUpdate(prevProps) {
    const { userId, selectedSystemId, getIsTileRefreshNeeded, getWeatherTileData } = this.props;
    if (selectedSystemId !== prevProps.selectedSystemId) {
      const oldTiles = await this.fetchTiles(selectedSystemId);

      this.disposeRefreshIntervalCycle(false);
      await getWeatherTileData(selectedSystemId);
      this.startRefreshIntervalCycle(async () => {
        await getWeatherTileData(selectedSystemId);
      });

      const prepopulationRequired = await this.isPrepopulationRequired(userId, selectedSystemId, oldTiles);
      if (prepopulationRequired) {
        this.prepopulateTiles(userId, selectedSystemId);
      } else {
        const tileRefreshNeeded = await getIsTileRefreshNeeded(userId, selectedSystemId);
        if (tileRefreshNeeded) {
          await this.refreshTiles(userId, selectedSystemId, oldTiles);
        }
      }
    }
  }

  componentWillUnmount() {
    this.disposeRefreshIntervalCycle(false);
  }

  async refreshTiles(userId, systemId, tiles) {
    const tileTypes = await this.props.refreshTileTypes(systemId);
    for (const tile of tiles) {
      const matchingTileType = tileTypes.find((t) => {
        return t.id === tile.type && t.tileNameTextId === tile.tileNameTextId;
      });

      if (matchingTileType) {
        const tileParameters = await this.props.refreshTileParameters(systemId, tile.type, tile.tileNameTextId);

        const sortedDisplayParameters = [];
        if (tile.properties.displayParameters.length > 0) {
          for (const displayParam of tile.properties.displayParameters) {
            const matchingParam = tileParameters.find((p) => {
              return (
                p.id === (displayParam && displayParam.id) &&
                p.parameterTypes.some((t) => t === ParameterTypes.Display && t !== ParameterTypes.Setting)
              );
            });
            if (matchingParam) {
              sortedDisplayParameters.push(matchingParam);
            }
          }
        }

        let sortedChartParameters = null;
        const chartParam = tile.properties.chart;
        if (chartParam) {
          const matchingParam = tileParameters.find((p) => {
            return (
              p.id === chartParam.id &&
              p.parameterTypes.some((t) => t === ParameterTypes.Chart && t !== ParameterTypes.Setting)
            );
          });
          if (matchingParam) {
            sortedChartParameters = matchingParam;
          }
        }

        const settingsParameters = tileParameters.filter((p) =>
          p.parameterTypes.some((t) => t === ParameterTypes.Setting && t !== ParameterTypes.Display)
        );

        const refreshTitle = !tile.isTitleEdited;
        const newTile = {
          ...tile,
          properties: {
            displayParameters: sortedDisplayParameters,
            settingParameters: settingsParameters,
            chart: sortedChartParameters,
          },
          title: refreshTitle ? matchingTileType.name : tile.title,
          tileNameText: matchingTileType.tileNameText,
          tileNameTextId: matchingTileType.tileNameTextId,
          tileHelpText: matchingTileType?.tileHelpText,
        };

        this.props.editTile(newTile);
      }
    }
    await this.props.setTilesLastRefreshed(userId, systemId);
  }

  async isPrepopulationRequired(userId, systemId, tiles) {
    if (!systemId || tiles.length !== 0) {
      return false;
    }

    await this.props.getPrepopulationStatus(userId, systemId);
    return !this.props.hasBeenPrepopulated;
  }

  compareTilePriorities = (a, b) => {
    let result = 0;
    if (a.tileListPrio < b.tileListPrio) {
      result = -1;
    } else if (a.tileListPrio > b.tileListPrio) {
      result = 1;
    }
    return result;
  };

  async prepopulateTiles(userId, systemId) {
    //TODO: Remove this additional call once AppInitializer's internal getDevices call is properly awaited for
    await this.props.getDevices(systemId);
    if (this.props.devices.length === 0) {
      return;
    }

    await this.props.getTileTypesSilent(systemId, true);

    const autoPopulateTiles = [...requiredTileTypes];

    for (const tileType of this.props.configurationState.tileTypes) {
      if (tileType.tileListPrio && requiredTileTypes.some((requiredType) => requiredType.id !== tileType.id)) {
        autoPopulateTiles.push({
          id: tileType.id,
          tileListPrio: tileType.tileListPrio,
          tileNameText: tileType.tileNameText,
          tileNameTextId: tileType.tileNameTextId,
          isAdded: true,
        });
      }
    }

    const uniqueAutoPopulateTiles = this.removeDuplicates(autoPopulateTiles);
    const sortedUniqueAutoPopulateTiles = uniqueAutoPopulateTiles.sort(this.compareTilePriorities);

    for (const tileType of sortedUniqueAutoPopulateTiles) {
      const tileTypeSupported = this.props.configurationState.tileTypes.some((type) => {
        return type.id === tileType.id && type.tileNameTextId === tileType.tileNameTextId;
      });
      if (!tileTypeSupported) {
        continue;
      }

      const filteredTiles = this.props.configurationState.tileTypes.filter(
        (type) => type.id === tileType.id && type.tileNameTextId === tileType.tileNameTextId
      );

      for (const ft of filteredTiles) {
        this.props.selectTileTypeSilent(tileType.id, this.props.devices[0].id, ft.tileNameText, ft.tileNameTextId);

        await this.props.getTileParameters(systemId, tileType.id, ft.tileNameTextId);
        const tileParameters = this.props.configurationState.tileParameters;

        const displayTileParameters = [...tileParameters].filter((param) => {
          return (
            param.parameter.tilePrio &&
            param.parameterTypes.some((t) => t === ParameterTypes.Display && t !== ParameterTypes.Setting)
          );
        });
        const sortedDisplayParameters = sortByPrio(displayTileParameters);

        if (sortedDisplayParameters.length >= 2) {
          this.props.setDisplayParameterSilent(0, sortedDisplayParameters[0]);
          this.props.setDisplayParameterSilent(1, sortedDisplayParameters[1]);
        } else if (sortedDisplayParameters.length === 1) {
          this.props.setDisplayParameterSilent(0, sortedDisplayParameters[0]);
        }

        const chartTileParameters = [...tileParameters].filter((param) => {
          return (
            param.parameter.tilePrio != null &&
            param.parameterTypes.some((t) => t === ParameterTypes.Chart && t !== ParameterTypes.Setting)
          );
        });
        const sortedChartParameters = sortByPrio(chartTileParameters);

        if (sortedChartParameters.length >= 1) {
          this.props.setChartValueSilent(sortedChartParameters[0]);
        }

        const settingsParameters = tileParameters.filter((p) =>
          p.parameterTypes.some((t) => t === ParameterTypes.Setting && t !== ParameterTypes.Display)
        );
        const sortedSettingsParameters = sortByPrio(settingsParameters);

        this.props.setSettingsParametersSilent(sortedSettingsParameters);

        this.props.saveTileSilent();
      }
    }

    this.props.setSystemAsPrepopulated(userId, systemId);
  }

  expandTile(tile) {
    if (this.props.expandedTile !== null) this.props.minimizeTile(this.props.expandedTile);

    this.props.expandTile(tile);
  }

  minimizeTile(tile) {
    this.props.minimizeTile(tile);
  }

  findTile(id) {
    const { tiles } = this.props;
    const tile = tiles.filter((c) => c.id === id)[0];

    return {
      tile,
      index: tiles.indexOf(tile),
    };
  }

  removeDuplicates(arr) {
    const uniqueMap = new Map();

    arr.forEach((obj) => {
      const key = obj.id + '-' + obj.tileNameTextId;

      if (!uniqueMap.has(key) || obj.isAdded) {
        uniqueMap.set(key, obj);
      }
    });

    const uniqueArray = Array.from(uniqueMap.values());

    return uniqueArray;
  }

  moveTile(id, atIndex) {
    const { tile } = this.findTile(id);
    this.props.moveTile(tile, atIndex);
  }

  editTile(tile) {
    this.props.startTileEditing(tile);
  }

  openRemovalPopup = (tile) => {
    this.setState({ isRemovePopupOpen: true, tileToRemove: tile });
  };

  closeRemovalPopup = () => {
    this.setState({ isRemovePopupOpen: false, tileToRemove: null });
  };

  confirmRemoval = () => {
    this.props.removeTile(this.state.tileToRemove);
    this.closeRemovalPopup();
  };

  changeSetting = async (deviceId, parameterId, unit, value) => {
    try {
      await this.props.editTileSetting(deviceId, parameterId, value, unit);
    } catch (error) {
      if (error.response.status == 403 || error.response.status == 401) {
        return NotificationManager.error(
          <FormattedMessage
            id="devices.noPermissionToChangeSettings"
            defaultMessage="You are not authorized to change device settings"
          />,
          <FormattedMessage id="security.Error" defaultMessage="Error" />,
          notificationTimeoutMilliseconds
        );
      }

      if (error.response.status == 400) {
        return NotificationManager.error(
          <FormattedMessage id="devices.failedToChangeSettings" defaultMessage="Failed to change device settings" />,
          <FormattedMessage id="security.Error" defaultMessage="Error" />,
          notificationTimeoutMilliseconds
        );
      }

      NotificationManager.error(
        <FormattedMessage id="migration.error.unknown" defaultMessage="Unknown error occured." />,
        notificationTimeoutMilliseconds
      );

      // TODO: Log unknown error
    }
  };

  render() {
    let { tiles } = this.props;
    const { expandedTile, brandId, weatherData, devices } = this.props;
    const expandBottom = expandedTile && expandedTile.lastTwo;
    let index = 0;
    // Add one to take into account add tile
    let tileLength = tiles.length + 1;
    tiles = tiles.map((obj, i) => {
      let lastTwo = [];

      if (tileLength % 2 === 1) {
        lastTwo = [tileLength - 1];
      } else {
        lastTwo = [tileLength - 1, tileLength - 2];
      }
      const wideTileTypes = [TileTypes.SMART_GUIDE];
      const isWide = wideTileTypes.includes(obj.type);
      const newObj = {
        ...obj,
        position: index % 2 == 0 ? 'left' : 'right',
        isWide,
        lastTwo: lastTwo.indexOf(index) !== -1 && !(i == tileLength - 2 && isWide),
        brandId: brandId,
        firmwareIds: devices.map((device) => device.firmwareId),
      };

      if (newObj.isWide) {
        tileLength -= index + 1;
        index = 0;
      } else {
        index += 1;
      }

      return newObj;
    });

    const getTileByType = (tile, allTiles, weatherData) => {
      switch (tile.type) {
        case TileTypes.SMART_GUIDE:
          return <SmartGuide {...tile} />;
        default:
          return (
            <GenericTile
              tileData={tile}
              expanded={expandedTile && expandedTile.id === tile.id}
              onChangeSetting={this.changeSetting}
              weatherData={weatherData}
              allTiles={allTiles}
            />
          );
      }
    };

    return (
      <div className="tileManager">
        <TileConfigurationPopup />
        <TileRemovalConfirmationPopup
          isShown={this.state.isRemovePopupOpen}
          onConfirm={this.confirmRemoval}
          onDiscard={this.closeRemovalPopup}
        />
        <div className={'tileManager__wrapper' + (expandBottom ? ' tileManager__wrapper--expand-bottom' : '')}>
          <TilesDataUpdater>
            {tiles.map((t, index) => (
              <TileData key={'tile-' + index} tile={t}>
                {(tile) => (
                  <Tile
                    {...tile}
                    tileType={tile.type}
                    isWide={tile.isWide}
                    backgroundColor={`#${tile.color}`}
                    hasExpandedTile={expandedTile !== null}
                    findTile={(id) => this.findTile(id)}
                    moveTile={(id, atIndex) => this.moveTile(id, atIndex)}
                    onExpand={() => {
                      this.expandTile(tile);
                    }}
                    onMinimize={() => {
                      this.minimizeTile(tile);
                    }}
                    onDelete={() => {
                      this.openRemovalPopup(tile);
                    }}
                    onEdit={() => {
                      this.editTile(tile);
                    }}
                    expandable={!tile.isWide}
                    brandId={this.props.brandId}
                  >
                    {getTileByType(tile, tiles, weatherData)}
                  </Tile>
                )}
              </TileData>
            ))}
          </TilesDataUpdater>
          <Tile key={'tile-add'} expandable={false} deletable={false} menuVisible={false} movable={false}>
            <AddTile
              onCreateTile={this.props.startTileCreation}
              getTileTypes={this.props.getTileTypes}
              selectedSystemId={this.props.selectedSystemId}
              isTypesLoaded={this.props.isTypesLoaded}
            />
          </Tile>
        </div>
      </div>
    );
  }
}

const ConnectedTileManager = connect(
  ({ tileManager, app, tileConfigurationPopup, devices }) => ({
    ...tileManager,
    app,
    userId: app.userInfo ? app.userInfo.id : null,
    brandId: app.selectedSystem?.brandId || 'MYUPLINK',
    selectedSystemId: app.selectedSystem?.id || null,
    configurationState: tileConfigurationPopup,
    devices: devices.devices,
  }),
  {
    expandTile,
    minimizeTile,
    moveTile,
    getTiles,
    addTile,
    removeTile,
    editTile,
    startTileCreation,
    startTileEditing,
    selectTileTypeSilent,
    getTileTypes,
    getTileTypesSilent,
    editTileSetting,
    getPrepopulationStatus,
    setSystemAsPrepopulated,
    getTileParameters,
    setDisplayParameterSilent,
    setChartValueSilent,
    setSettingsParametersSilent,
    saveTileSilent,
    migrateTileParameters,
    getDevices,
    getIsTileRefreshNeeded,
    setTilesLastRefreshed,
    refreshTileParameters,
    refreshTileTypes,
    getWeatherTileData,
  }
)(TileManager);

function containerCollect(connect) {
  return {
    connectDropTarget: connect.dropTarget(),
  };
}
const containerTarget = {
  drop() {},
};
const DropTargetTileContainer = DropTarget('tile', containerTarget, containerCollect)(ConnectedTileManager);
const DragDropContextContainer = DragDropContext(HTML5Backend)(DropTargetTileContainer);

export default DragDropContextContainer;
