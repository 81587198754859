import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateAllTilesData } from './actions';

const VISIBILITY_EVENT = 'visibilitychange';
const REFRESH_INTERVAL = 10000;

const PAGE_STATES = {
  VISIBLE: 'visible',
  HIDDEN: 'hidden',
};

const TilesDataUpdater = ({ children, updateAllTilesData }) => {
  let intervalReference = null;

  const disposeRefreshIntervalCycle = () => {
    if (!intervalReference) return;
    clearInterval(intervalReference);
  };

  const startRefreshIntervalCycle = () => {
    intervalReference = setInterval(updateAllTilesData, REFRESH_INTERVAL);
  };

  const visibilityListener = () => {
    if (PAGE_STATES.HIDDEN === document.visibilityState) return disposeRefreshIntervalCycle();
    startRefreshIntervalCycle();
  };

  useEffect(() => {
    startRefreshIntervalCycle();
    document.addEventListener(VISIBILITY_EVENT, visibilityListener);
    return () => {
      disposeRefreshIntervalCycle();
      document.removeEventListener(VISIBILITY_EVENT, visibilityListener);
    };
  }, []);

  return children;
};

TilesDataUpdater.propTypes = {
  children: PropTypes.node,
  updateAllTilesData: PropTypes.func.isRequired,
};

export default connect(null, { updateAllTilesData })(TilesDataUpdater);
