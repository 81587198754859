import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button } from 'jpi-cloud-web-ui-components';

class ConfirmEventExceededPopup extends React.PureComponent {
  static propTypes = {
    proceedConfirm: PropTypes.func.isRequired,
  };

  render() {
    const { proceedConfirm } = this.props;

    return (
      <div className="popup-body">
        <div className="popup-main">
          <div className="popup-header">
            <p className="text center">
              <FormattedMessage
                id="scheduling.edit-event.maxNumEvents"
                defaultValue="Maximum allowed events have been created. Please edit or delete an event to continue."
              />
            </p>
          </div>
          <div className="popup-bottom-panel">
            <Button onClick={() => proceedConfirm()} className="button--default" type="button">
              <FormattedMessage id="button.ok" defaultValue="Ok" />
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default ConfirmEventExceededPopup;
