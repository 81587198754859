import { Button } from 'jpi-cloud-web-ui-components';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Price from '../../../../../../Shop/components/Price';
import TrialSubscription from '../TrialSubscription';
import PropTypes from 'prop-types';

const NoPremiumAvailable = ({
  handle,
  description,
  currencyCode,
  isSubscriptionTrial,
  validUntil,
  hasRightsToBuy,
  disabled,
  addToCart,
  variants,
}) => (
  <div className="Product__noPremiumAvailable">
    <div className="Product__description">
      <FormattedMessage id={'product.' + handle + '.description'} defaultMessage={description} />
    </div>
    <div className="Product__price-wrapper">
      {(variants || []).map((variant, index) => (
        <div key={'productVariant__' + index} className="Product__variant-price">
          {currencyCode &&
            variant.price &&
            (isSubscriptionTrial ? (
              <TrialSubscription
                subscriptionValidationDate={validUntil}
                currencyCode={currencyCode}
                price={variant.price}
              />
            ) : (
              <div className="Product__price">
                <Price currency={currencyCode} price={variant.price} />
                &nbsp;{variant.length}
              </div>
            ))}
          {!isSubscriptionTrial && hasRightsToBuy && (
            <Button
              className="product__subscription-button"
              onClick={() => {
                addToCart(variant, 1);
              }}
              disabled={disabled}
            >
              <FormattedMessage id="shop.page.addToCart" defaultMessage="Add to cart" />
            </Button>
          )}
        </div>
      ))}
    </div>
  </div>
);

NoPremiumAvailable.propTypes = {
  variants: PropTypes.shape({
    product: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
  handle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  currencyCode: PropTypes.string.isRequired,
  validUntil: PropTypes.string.isRequired,
  isSubscriptionTrial: PropTypes.bool.isRequired,
  hasRightsToBuy: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  addToCart: PropTypes.func.isRequired,
};

export default NoPremiumAvailable;
