import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import './addcarddetail.scss';

const AddCardDetail = ({ isStripeSpecificBrand }) => {
  return (
    <div className="addCardDetail">
      <div className="addCardDetail__descriptionWrapper">
        <FormattedMessage
          id="paymentProviders.settingsPage.futurePayment"
          defaultMessage="You need to add a credit{debit} card for future payment of the subscription."
          values={{
            debit: isStripeSpecificBrand ? '/debit' : '',
          }}
        />
        <br />
      </div>
      <p className="addCardDetail__noCardMessage">
        <FormattedMessage id="paymentProviders.dibs-ticket.noCardAdded" defaultMessage="No card added" />
      </p>
    </div>
  );
};

AddCardDetail.propTypes = {
  isStripeSpecificBrand: PropTypes.bool.isRequired,
};

export default AddCardDetail;
