import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import moment from 'moment';
import enLocalization from 'moment/locale/en-gb';
import deLocalization from 'moment/locale/de-ch';
import svLocalization from 'moment/locale/sv';

function getLocalization(locale) {
  switch (locale) {
    case 'de':
      return deLocalization;
    case 'sv':
      return svLocalization;
    default:
      return enLocalization;
  }
}

const ItemDate = ({ timestamp, intl }) => {
  const locale = intl.locale;
  return (
    <div className="item-date">
      {moment(timestamp)
        .locale(locale, getLocalization(locale))
        .format('D MMM YYYY [' + intl.formatMessage({ id: 'time-preposition.at' }) + '] HH:mm:ss')}
    </div>
  );
};

ItemDate.propTypes = {
  timestamp: PropTypes.number.isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(ItemDate);
