import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Button } from 'jpi-cloud-web-ui-components';

import { setSelectedDevice } from '../../../../actions';

import './failed.scss';

class Failed extends React.PureComponent {
  static propTypes = {
    selectedDevice: PropTypes.object.isRequired,
    setSelectedDevice: PropTypes.func.isRequired,
  };
  render() {
    return (
      <div className="update-failed">
        <div className="row">
          <div className="col-sm-8">
            <div className="device-name">{this.props.selectedDevice.name}</div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 progress-section">
            <strong>
              <FormattedMessage id="devices.Failed" defaultMessage="Failed" />
            </strong>
            <div className="progress">
              <div className="progress-bar"></div>
            </div>
          </div>
          <div className="col-sm-12 text-center action-buttons">
            <Button
              className="button--default button-close"
              onClick={() => this.props.setSelectedDevice()}
              type="button"
            >
              <FormattedMessage id="devices.close" defaultMessage="Close" />
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ devices: { selectedDevice } }) => ({ selectedDevice });

const mapDispatchToProps = {
  setSelectedDevice,
};

export default connect(mapStateToProps, mapDispatchToProps)(Failed);
